import React, {Component} from 'react';
import ReactTable from 'react-table';
import localization from '../../../config/localization';
import PropTypes from 'prop-types';
import {makePageSizeFrom} from './utils';
import {Add, Remove} from '@material-ui/icons';
import _ from 'lodash';
import {IconButton} from '@material-ui/core';
import ErrorBoundary from '../ErrorBoundary';

class ClientSidePaginationTable extends Component {
  constructor(props) {
    super(props);
    this.state = { visibleData: this.props.data };
  }

  componentWillReceiveProps = props => this.setState({ visibleData: props.data });

  removeFromVisibleData = filter => {
    this.setState(state => ({
      ...state,
      visibleData: state.visibleData.filter(filter),
    }));
  };

  remove = item => this.removeFromVisibleData(vd => vd !== item);

  removeById = id => this.removeFromVisibleData(vd => vd.id !== id);

  render = () => {
    const { columns, subComponent, data, perPage, showPagination, filterable, sortable, zebraStyle, getTrProps, handleSort = null, noDataText = localization.get('table.no_results'), getThProps } = this.props;

    const expand = {
      Header: '',
      width: 50,
      filterable: false,
      sortable: false,
      expander: true,
      Expander: ({ isExpanded, ...rest }) => {
        if (isExpanded) {
          return (
            <IconButton style={{ height: '35px', width: '35px' }}>
              <Remove />
            </IconButton>
          );
        } else {
          return (
            <IconButton style={{ height: '35px', width: '35px' }}>
              <Add />
            </IconButton>
          );
        }
      },
    };

    return (
      <ErrorBoundary>
        <ReactTable
          data={this.state.visibleData}
          style={this.props.style || {}}
          columns={subComponent ? _.concat(expand, columns) : columns}
          SubComponent={subComponent}
          onSortedChange={handleSort}
          previousText={localization.get('table.previous_text')}
          nextText={localization.get('table.next_text')}
          noDataText={noDataText}
          pageText={localization.get('table.page_text')}
          ofText={localization.get('table.of_text')}
          rowsText={localization.get('table.rowsText')}
          loadingText={localization.get('table.loading_text')}
          pageSize={makePageSizeFrom(perPage, data.length, this.props.disableCustomLength)}
          showPaginationBottom={false}
          showPageSizeOptions={false}
          showPagination={showPagination}
          className={zebraStyle ? '-striped -highlight' : ''}
          filterable={filterable}
          sortable={sortable}
          resizable={false}
          defaultFilterMethod={(filter, row) => {
            if(this.props.onFilter) return this.props.onFilter(filter, row);

            if (row[filter.id]) {
              return row[filter.id]
                .toString()
                .toLowerCase()
                .includes(filter.value.toLowerCase());
            }
            return false;
          }}
          showPaginationTop
          getTrProps={getTrProps}
          getTheadThProps={getThProps}
          getTheadTrProps={getThProps}
        />
      </ErrorBoundary>
    );
  };
}

ClientSidePaginationTable.defaultProps = {
  data: [],
  perPage: 12,
  showPagination: true,
  filterable: true,
  sortable: true,
  zebraStyle: true,
};

ClientSidePaginationTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  subComponent: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  perPage: PropTypes.number,
  showPagination: PropTypes.bool,
  filterable: PropTypes.bool,
  sortable: PropTypes.bool,
  zebraStyle: PropTypes.bool,
  onFilter: PropTypes.func,
};

export default ClientSidePaginationTable;
