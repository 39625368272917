import React, {Component} from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import TagSelection from "../../../components/Letflow/TagSelection/TagSelection";
import {request} from "../../../api-client";
import {get} from "lodash";
import localization from "../../../config/localization";


export default class extends Component {
  constructor(props) {
      super(props);
      this.state = {
        tagGroups: [],
        loading: false,
      };
    }

    componentWillUpdate(nextProps) {
      if (get(nextProps, "channel.value") != get(this, "props.channel.value") && get(nextProps, "channel.value")) {
        const restrictedTags = get(nextProps, "channel.restrictedTags", [])

        const filterBy = [
          { column: 'channel_id', filter: get(nextProps, "channel.value")}, 
          { column: "creation", filter: 1 },
        ]
        
        this.setState({ loading: true }, () => 
          request.tagGroup.getAll({filterBy})
            .then((tagGroups) => this.setState({
              tagGroups: tagGroups.map(tg => ({
                ...tg,
                tags: tg.tags.map(t => ({
                    ...t,
                    state: restrictedTags.includes(t.id.toString()) ? "selected" : "available" 
                }))
            })), 
              loading: false}))
        );
      }
    }

    onSelectedTagsChanged = selectedTag => {
      let restrictedTags = this.props.restrictedTags
      if (this.props.restrictedTags.includes(selectedTag.toString())) {
        restrictedTags = restrictedTags.filter(tags => tags != selectedTag.toString());
        
        this.setState({ 
          tagGroups: this.state.tagGroups.map(tg => ({
            ...tg,
            tags: tg.tags.map(t => ({
                ...t,
                state: restrictedTags.includes(t.id.toString()) ? "selected" : "available" 
            }))
          }))
        });

        return this.props.updateChannel({...this.props.channel, restrictedTags: restrictedTags})
      }else { 
        restrictedTags.push(selectedTag.toString())
        
        this.setState({ 
          tagGroups: this.state.tagGroups.map(tg => ({
            ...tg,
            tags: tg.tags.map(t => ({
                ...t,
                state: restrictedTags.includes(t.id.toString()) ? "selected" : "available" 
            }))
          }))
        });
        
        return this.props.updateChannel({...this.props.channel, restrictedTags})
      }
    }
    

  render() {
    return (
      <Dialog open={!!this.props.channel} onBackdropClick={this.props.onClose}>
        <DialogTitle>{localization.get("form.restricted_access", get(this, 'props.channel.label'))}</DialogTitle>
        <DialogContent style={{width: 600}}>  
          {this.state.loading ? <div>Loading...</div> :
            <TagSelection
              homeElementTagSelection 
              data={this.state.tagGroups}
              onSelectedTagsChanged={this.onSelectedTagsChanged}
              cardView
              showFilter
              colapsable
            />
          } 
        </DialogContent>
        <DialogActions >
          <Button onClick={this.props.onClose}>{localization.get('form.close')}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}