import React from "react";
import CreationItem from "../CreationItem";
import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import AsyncSelect from "react-select/lib/Async";
import { getActiveChannel, getStoredChannel, getActiveClient, setContentCreationFavorites, getContentCreationFavorites, userHasRestrictedAccess } from "../../../api-client/core/authentication/utils";
import { request } from "../../../api-client";
import { Search, Clear } from "@material-ui/icons";
import localization from "../../../config/localization";
import EventManager, {events} from "../../../utils/EventManager";
import GridItem from "../../../layouts/Home/HomeElements/GridItem";
import HomeElementGridContainer from "../../../layouts/Home/HomeElements/HomeElementGridContainer";
import HomeElementFeaturedItem from "../../../layouts/Home/HomeElements/HomeElementFeaturedItem";
import { get } from "lodash"
import moment from "moment";



export default class LiteSmartSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.contentCreatorType,
      contentType: props.contentType, // material or content
      contentCreatorType: null,
      searchInput: "",
      tagGroups: [],
      selectedTag: [],
      searchResultsTags: [],
      loaded: false,
      selectedRange: null
    }
  }

    componentDidMount = () => {
      const channelContentCreatorTypes = getStoredChannel().channelContentCreatorTypes;

      this.setState({ contentCreatorType: channelContentCreatorTypes.find(type => type.contentCreatorType.slug == this.state.type) })

      this.fetchTagGroups();
    }

    fetchTagGroups = () =>  {
        let paginationConfig = {};
        
        if (this.state.contentType === "material") {
            paginationConfig = { filterBy: [
                { column: 'content_creations', filter: true},
                { column: 'inheritable', filter: "true"},
                { column: 'show_on_smart_search', filter: '1'},
                { column: 'type', filter: `${this.state.type}`},
                { column: 'channel_id', filter: `${getActiveChannel()}`}
            ]};

        } else {
            paginationConfig = { filterBy:  [
                { column: 'type', filter: `${this.state.type}`},
                { column: 'creation_group', filter: '0'},
                { column: 'show_on_smart_search', filter: '1'},
                { column: 'channel_id', filter: `${getActiveChannel()}`}
              ]};
        }
        
        
        return request.tagGroup.getAll(paginationConfig, this.state.contentType)
          .then(tagGroups => {
            request.tag.getAvailableTagsForClient({
              clientId: getActiveClient(),
              tagGroupType: this.state.contentType == "content" ? "contentCreator" : "contentCreation",
              contentCreatorType: this.state.type,
              tags: [],
              notTags: [],
              lastTagId: null,
              channelId: getActiveChannel()
            }).then(availableTags => {
              const mappedAvailableTags = availableTags.map(x => x.id)
              const requestedTagGroups = get(this.props, "tagGroups", [])
                .map(id => tagGroups.find(tagGroup => tagGroup.id == `${id}`))
                .filter(tg => get(tg, "tags", []).length > 0)

              return this.setState({
                 tagGroups: requestedTagGroups.map(tgs => ({
                  ...tgs,
                  tags: tgs.tags.map(tag => ({
                    ...tag,
                    state: mappedAvailableTags.includes(tag.id) ? 'available' : 'disable'
                  }))
                })) 
              })
            })
        })
      }

      cleanSearch = () => {
        this.setState( {
          tagGroup: this.state.tagGroups.forEach(tagGroup => tagGroup.tags.forEach(tag => tag.state = "available")),
          selectedRange: null,
          searchInput: "", 
          searchResultsTags: []
        })
      }

      handleSmartSearchSelectedTagsChanged = (selectedTags, lastTagId = null) => {
        this.setState(state => ({
          ...state,
          tagGroups: state.tagGroups.map(tgs => ({
            ...tgs,
            tags: tgs.tags.map(tag => ({
              ...tag,
              state: selectedTags.includes(tag.id) ? 'selected' : "available"
            }))
          }))
        }), () => {
          this.requestAvailableTags({lastTagId})
          this.requestTracks()
        })
      }
    
  requestTracks = () => {
     const options = {perPage: get(this.props, "limitContent", 30), page: 0}; 
     
     const props = {
      type: this.state.type,
      tags: this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected').map(tag => tag.id),
      notTags: [],
      options,
      channelId: getActiveChannel(),
      search: this.state.searchInput,
    }
 
    if (this.state.contentType === "material") {
      options.orderBy = {column: 'order', type: 'desc'}
      
     return request.contentCreation.getAllWithTags(props)
       .then(searchResults => this.setState({searchResultsTags: searchResults.data}))
    }else {
      const {dateStart, dateEnd} = this.getDate();

     return request.contentCreator.getAllWithTags(
        this.state.type,
        this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected').map(tag => tag.id),
        [],
        null,
        null,
        options,
        getActiveChannel(),
        this.state.searchInput,
        this.props.dateSearch == "event" ? dateStart : null,
        this.props.dateSearch == "event" ? dateEnd : null,
        null,
        this.props.dateSearch == "publish" ? dateStart : null,
        this.props.dateSearch == "publish" ? dateEnd : null,
    )
     .then(searchResults => this.setState({searchResultsTags: searchResults.data}))
    }
   }

   requestAvailableTags = ({
      clientId = getActiveClient(),
      searchGroup = this.state.contentType == "content" ? "contentCreator" : "contentCreation",
      type = this.state.type,
      searchTags = this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected'),
      lastTagId = null,
      channelId = getActiveChannel()
    } = {}) => {
    const selectedTags = searchTags.map(tag => tag.id);

    return request.tag.getAvailableTagsForClient({
      clientId,
      tagGroupType: searchGroup,
      contentCreatorType: type,
      tags: selectedTags,
      notTags: [],
      lastTagId,
      channelId
    }).then(availableTags => {
      const mappedAvailableTags = availableTags.map(x => x.id)
      return this.setState({
         tagGroups: this.state.tagGroups.map(tgs => ({
          ...tgs,
          tags: tgs.tags.map(tag => ({
            ...tag,
            state: ['selected'].includes(tag.state) ? tag.state : (mappedAvailableTags.includes(tag.id) ? 'available' : 'disable')
          }))
        })) 
      })
    })
  }

   getDate = () => {
    let today = moment();
    let tomorrow = moment().add(1, 'day');
    let yesterday = moment().subtract(1, 'day');
    let dateStart = '';
    let dateEnd = '';
    if (!this.state.selectedRange) return {dateStart: null, dateEnd: null};
    switch (this.state.selectedRange.value) {
      case 'today':
        dateStart = today;
        dateEnd = today;
        break;
      case 'tomorrow':
        dateStart = tomorrow;
        dateEnd = tomorrow;
        break;
      case 'thisWeekend':
        dateStart = moment().endOf('week').subtract(2, 'day');
        dateEnd = moment().endOf('week');
        break;
      case 'thisWeek':
        dateStart = today;
        dateEnd = moment().endOf('week');
        break;
      case 'nextWeek':
        dateStart = moment().add(1, 'week').startOf('week');
        dateEnd = moment().add(1, 'week').endOf('week');
        break;
      case 'next30days':
        dateStart = today;
        dateEnd = moment().add(30, 'day');
        break;
      case 'yesterday':
        dateStart = yesterday;
        dateEnd = yesterday;
        break;
      case 'lastWeek':
        dateStart= moment().subtract(7, 'day');
        dateEnd= today;
        break;
      case 'last30days':
        dateEnd = today;
        dateStart = moment().subtract(30, 'day');
        break;
      default:
        return {dateStart: null, dateEnd: null};
    }

    return {dateStart: dateStart.format('YYYY-MM-DD'), dateEnd: dateEnd.format('YYYY-MM-DD')};
  }

  onFavorite = (creation) => {
    const favorites = getContentCreationFavorites()

    if(! favorites.includes(creation.id)) {
      setContentCreationFavorites(favorites)
      request.contentCreationChannelFavorite.add(creation.id)
    } else  { 
      request.contentCreationChannelFavorite.remove(creation.id)
    }
    this.setState({loaded: true})
  }

    render = () => {
      const selectedTags = this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected');
      const isMobile = window.innerWidth <= 600;
      let rangeOptions = [{value: "today", label: localization.get("smart_search.today")}]
      const viewType = this.props.viewType ? this.props.viewType : "grid6";

      if (["publish", "event"].includes(this.props.dateSearch) && this.state.contentType == 'content') {
        if (this.props.dateSearch == "publish") {
           rangeOptions = [
            {value: "today", label: localization.get("smart_search.today")},
            {value: "yesterday", label: localization.get("smart_search.yesterday")},
            {value: "lastWeek", label: localization.get("smart_search.last_seven_days")},
            {value: "last30days", label: localization.get("smart_search.last_thirty_days")},
          ];
        }else {
          rangeOptions = [
            {value: "today", label: localization.get("smart_search.today")},
            {value: "tomorrow", label: localization.get("smart_search.tomorrow")},
            {value: "thisWeekend", label: localization.get("smart_search.this_weekend")},
            {value: "thisWeek", label: localization.get("smart_search.this_week")},
            {value: "nextWeek", label: localization.get("smart_search.next_week")},
            {value: "next30days", label: localization.get("smart_search.next_thirty_days")},
          ];
        }
      }

      return (
          <div style={{ padding: !isMobile ? "0 10%" : 0}}>
              <div style={{
                  display: "flex",
                  width: "100%",
                  color: "var(--main-font-color, black)",
                  border: "1px solid var(--main-font-color, rgba(0,0,0, .5))",
                  borderRadius: 5,
                  padding: 5,
                  marginBottom: 8,
                  justifyContent: "space-between"
              }}>
                  <TextField
                  inputProps={{
                    ref: input => {
                      this.searchInput = input;
                    },
                    spellCheck: false,
                    style: {color: "var(--main-font-color, black)"}
                  }}
                  InputProps={{disableUnderline: true}}
                  autoComplete={false}
                  className="smart-search-input-search"
                  placeholder={localization.get("searchbar.content_creators")}
                  value={this.state.searchInput}
                  onChange={e => this.setState({searchInput: e.target.value})}
                  onKeyPress={e => {
                      if (e.key === 'Enter') this.requestTracks()
                  }}
                  style={{color: "var(--main-font-color, black)", width: "100%"}}
                  />
                  {(this.state.searchInput || selectedTags.length > 0 || !!this.state.selectedRange) && 
                    <Clear style={{marginLeft: 7, marginTop: 5, color: "var(--main-font-color, black)"}} onClick={this.cleanSearch}/>
                  }
                  <Search style={{marginLeft: 7, marginTop: 5, color: "var(--main-font-color, black)"}} onClick={this.requestTracks}/>
              </div>
              <Grid container fullWidth spacing={8} >  
                {["publish", "event"].includes(this.props.dateSearch) && this.state.contentType == 'content' &&
                  <Grid key={"0"} xs={12} sm={6} md={4} lg={3} xl={2} item style={{width: "100%"}}>
                    <AsyncSelect
                      key={"0"}
                      placeholder={localization.get(this.props.dateSearch == "event" ? "smart_search.event_search" : "smart_search.date_publish_label")}
                      input={(styles) => ({ ...styles, margin: "5px 0", })}
                      styles={{
                        input: base => ({
                          ...base, margin: "5px 0",
                        }),
                        input: (styles) => ({ ...styles, color: "var(--main-font-color, black)", }),
                        placeholder: (styles) => ({ ...styles, color: "var(--main-font-color, black)", }),
                        singleValue: (styles) => ({ ...styles, color: "var(--main-font-color, black)", }),
                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                          return {
                            ...styles,
                            borderColor: "var(--main-font-color, black)",
                            backgroundColor: (isFocused || isSelected) ? "var(--main-font-color, black)" : "var(--main-color, white)",
                            color: (isFocused || isSelected) ? "var(--main-color, white)" : "var(--main-font-color, black)",
                            opacity: isDisabled ? 0.7 : isSelected ? 1 : 1,
                          }
                        },
                        menu: base => ({
                          ...base,
                          zIndex: 10,
                          borderColor: "var(--main-font-color, black)",
                          backgroundColor: "var(--main-color, white)",
                          color: "var(--main-font-color, black)",
                        }),
                        control: (baseStyles, state) => {
                          return {
                          ...baseStyles,
                          borderColor: "var(--main-font-color, black)",
                          backgroundColor: "var(--main-color, white)",
                          color: "var(--main-font-color, black)",
                        }}
                      }}
                      theme={theme => {
                        return {
                          ...theme,
                          backgroundColor: "var(--main-color, white)",
                          borderRadius: 5,
                          borderColor: "var(--main-font-color, black)",
                          backgroundColor: "var(--main-color, white)",
                          color: "var(--main-font-color, black)",
                        }
                      }}
                      style={{margin: "10px 0", maxWidth: 185}}
                      isOptionDisabled={(option) => option.disabled}
                      value={this.state.selectedRange}
                      onChange={e => this.setState({selectedRange: e}, this.requestTracks)}
                      defaultOptions={rangeOptions}
                    />
                  </Grid>
                }
                {this.state.tagGroups.map((tagGroup, key) => {
                  let tags = tagGroup.tags;
          
                  tags = tags.filter(tag => {
                    if (tag.state == "selected" || !tagGroup.parent) return true;
      
                    return tag.parents.some(p => selectedTags.some(st => st.id == p.id))
                  })

                  return (
                    <Grid key={key} xs={12} sm={6} md={4} lg={3} xl={2} item style={{width: "100%"}}>
                      <AsyncSelect
                        key={key}
                        input={(styles) => ({ ...styles, margin: "5px 0", })}
                        styles={{
                          
                          input: (styles) => ({ ...styles, color: "var(--main-font-color, black)", }),
                          placeholder: (styles) => ({ ...styles, color: "var(--main-font-color, black)", }),
                          singleValue: (styles) => ({ ...styles, color: "var(--main-font-color, black)", }),
                          menu: base => ({
                            ...base,
                            zIndex: 10,
                            borderColor: "var(--main-font-color, black)",
                            backgroundColor: "var(--main-color, white)",
                            color: "var(--main-font-color, black)",
                          }),
                          control: (baseStyles, state) => {
                            return {
                            ...baseStyles,
                            borderColor: "var(--main-font-color, black)",
                            backgroundColor: "var(--main-color, white)",
                            color: "var(--main-font-color, black)",
                          }},
                          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            return {
                              ...styles,
                              borderColor: "var(--main-font-color, black)",
                              backgroundColor: (isFocused || isSelected) ? "var(--main-font-color, black)" : "var(--main-color, white)",
                              color: (isFocused || isSelected) ? "var(--main-color, white)" : "var(--main-font-color, black)",
                              opacity: isSelected ? 0.7 : 1,
                            }
                          },
                        }}
                        theme={theme => {
                          return {
                            ...theme,
                            backgroundColor: "var(--main-color, white)",
                            borderRadius: 5,

                            borderColor: "var(--main-font-color, black)",
                            backgroundColor: "var(--main-color, white)",
                            color: "var(--main-font-color, black)",
                          }
                        }}
                        isDisabled={tagGroup.tags.every(t => t.state== "disable")}
                        style={{margin: "10px 0", maxWidth: 185}}
                        isOptionDisabled={(option) => option.disabled}
                        value={tagGroup.tags.find(t => t.state == "selected")  ? 
                          { value: tagGroup.tags.find(t => t.state == "selected").id, 
                            label: tagGroup.tags.find(t => t.state == "selected").name
                          }  : {label: tagGroup.name, value: "0"}
                        }
                        onChange={e => {
                          const selectedValue = e ? e : { value: "0", label: tagGroup.name };
                          const prevSelectedTag = tagGroup.tags.find(t => t.state == "selected") ? tagGroup.tags.find(t => t.state == "selected").id : "0";                      
                          let currentTags = this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected').map(tag => tag.id).filter(selectedTag => selectedTag != prevSelectedTag);

                          if (selectedValue.value != "0") { 
                            currentTags.push(selectedValue.value)
                          };

                          return this.handleSmartSearchSelectedTagsChanged(currentTags, selectedValue.value)
                        }}
                        loadOptions={(inputValue, callback) => {
                          callback([{label: tagGroup.name, value: "0"}, ...tagGroup.tags.filter(t =>  t.state != "disable").map(t => ({label: t.name, value: t.id}))]
                            .filter(t => t.label.toLowerCase().includes(inputValue.toLowerCase())))
                        }}
                        isClearable={tagGroup.tags.find(t => t.state == "selected")}
                        defaultOptions={[{label: tagGroup.name, value: "0"}, ...tagGroup.tags.filter(t =>  t.state != "disable").map(t => ({label: t.name, value: t.id}))]}
                      />
                    </Grid>
                  )}
                )}
              </Grid>
              <div style={{ margin: "20px 0",display: "flex", alignItems: "center", justifyContent: "center"}}>
                {this.state.searchResultsTags.length == 0 ? 
                  <p style={{textAlign: "center", fontSize: 24, padding: isMobile ? "40px 0 " : "120px 0"}}>{localization.get(selectedTags.length === 0 ? 'select_tag' : 'searchbar.no_result')}</p>
                  :
                  <>
                    {this.state.contentType === "material" ? 
                      <Grid container fullWidth>
                        {this.state.searchResultsTags.map((creation, key) =>
                          <Grid key={key} xs={12} sm={6} md={4} lg={3} xl={3} item style={{padding: 10, width: "100%"}}>
                            <CreationItem
                              contentCreation={{...creation, favorite: getContentCreationFavorites().includes(creation.id)}}
                              onFavorite={() => this.onFavorite(creation)}
                              onPlay={(event) => {
                                EventManager.getInstance().dispatch(events.OPEN_MATERIAL_MODAL, {id:  creation.id, materialList: this.state.searchResultsTags.map((item, index) => ({index, id: item.id}))});
                              }}
                              noAuthorLink={userHasRestrictedAccess()}
                            />
                          </Grid>
                        )}
                      </Grid>
                    :
                      <HomeElementGridContainer
                        {...this.props}
                        hidePadding
                        scrollable
                        trackType={'voice'}
                        homeElement={{
                          ...this.state.searchResultsTags,
                          items: {
                            data: this.state.searchResultsTags.map(item => ({
                              ...item,
                              title: item.name,
                              shortDescription: item.synopsis,
                              kind: 'content_creator',
                              favorite: false,
                            }))
                          }
                        }}
                        dataType={'content_creator'}
                        showSectionTitle={false}
                        showMore={true}
                        rowsToShow={10}
                        showEvents={true}
                        search={true}
                        rounded={["grid6_rounded"].includes(viewType)}
                        alternative={["movie_alternative"].includes(viewType)}
                        maxPerRow={["featured2", "featured2_alternative"].includes(viewType) ? 2 : (["movie", "movie_alternative"].includes(viewType))? 1 : 6}
                        fixedTitle={viewType != "grid6_rounded"}
                        sizes={{
                          xs: ["movie", "movie_alternative"].includes(viewType) ? 2 : 6,
                          sm: ["movie", "movie_alternative"].includes(viewType) ? 2 : 4,
                          md: ["movie", "movie_alternative"].includes(viewType) ? 2 : 3,
                          lg: 2,
                          xl: 2,
                        }}
                        itemComponent={(["featured2", "featured2_alternative", "movie", "movie_alternative"].includes(viewType)) ? HomeElementFeaturedItem : GridItem}
                        editorial={viewType === "featured2"}
                        editorialInverted={viewType === "featured2_alternative"}
                        shape={(viewType == "grid6_rounded" ? "round" : ["featured2", "featured2_alternative"].includes(viewType) ? "x2" : 'x1')}
                        movie={["movie", "movie_alternative"].includes(viewType)}
                      />
                    
                    }
                  </>
                }
            </div>
          </div>
      );
    }
}