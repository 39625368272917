import "layouts/Home/Home.css";
import React, {Component} from "react";
import {Grid} from "@material-ui/core";
import getInsertableIndex from "./getInsertableIndex";
import EventManager, {events} from "../../../utils/EventManager";
import localization from '../../../config/localization'
import ContentCreationPlayer from "../../../components/Letflow/NewContentCreationPlayer";
import {
  addHttpsToUrl,
  getActiveChannel, 
  getMainTranslation,
  getStoredChannel,
  userHasRestrictedAccess
} from "../../../api-client/core/authentication/utils";
import styleFixed from "./Styles/Grid/directors.module.css";
import hoverStyleX6 from "./Styles/Grid/musicRecommended.module.css";
import styleX4 from "./Styles/Grid/musicLibrary.module.css";
import gridStyle from "./Styles/Grid/grid.module.css";
import styleX4Round from "./Styles/Grid/gridX4Round.module.css";
import styleRectangle from './Styles/Grid/books.module.css';
import styleRectangleX4 from './Styles/Grid/booksX4.module.css';
import style16_9 from './Styles/Grid/grid16_9.module.css';
import style16_9X4 from './Styles/Grid/grid16_9X4.module.css';
import font from "./Styles/Fonts/fonts.module.css";
import st from "../HomeNew.module.css"
import analyticsInstance from "../../../analytics/analyticsInstance";

class HomeElementGrid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentDimension: this.getDimension(),
      requestedElementIndex: -1,
      requestedElement: null,
      rowsToShow: props.rowsToShow,
      hideShowMoreButton: false,
      showContentCreationDialog: false,
      originalRowsToShow: props.rowsToShow,
    }
  }

  resetRequestedElement = () => {
    this.setState({
      requestedElementIndex: -1,
      requestedElement: null
    })
  }

  componentDidMount = () => {
    this.setCurrentDimensionRelativeToScreenWidth()
    window.addEventListener('resize', this.setCurrentDimensionRelativeToScreenWidth)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.setCurrentDimensionRelativeToScreenWidth)
  }

  getDimension = () => {
    const boundaries = [
      { dimension: 'xs', limit: 600 },
      { dimension: 'sm', limit: 960 },
      { dimension: 'md', limit: 1280 },
      { dimension: 'lg', limit: 1920 },
    ]
    const match = boundaries.find(b => window.innerWidth < b.limit)
    const currentDimension = match ? match.dimension : 'xl'
    return currentDimension;
  }

  setCurrentDimensionRelativeToScreenWidth = () => {
    this.setState({ currentDimension: this.getDimension() })
  }

  handleShowMore = () => {
    this.props.requestTracks && this.props.requestTracks({nextPage:true, clearResults: false})
    this.setState({rowsToShow: this.state.rowsToShow + this.state.rowsToShow})
  }

  handleShowLess = () => {
    this.setState({rowsToShow: this.state.originalRowsToShow, hideShowMoreButton: false })
  }

  makeGridClasses = (element) => {
    let gridClasses = ''
    switch (element) {
      case 'container':
        if (this.props.shape === 'round') {
          let style = this.props.maxPerRow === 6 ? hoverStyleX6 : styleX4Round
          gridClasses = gridClasses + `${style['musicRecommended']} ${style['round']} ${gridStyle['row']}`
        }
        if (this.props.shape === 'square' && !this.props.fixedTitle) {
          gridClasses = gridClasses + `${hoverStyleX6['musicRecommended']} ${gridStyle['row']}`
        }
        if ((this.props.shape === 'rectangle' || this.props.shape === '16:9') && !this.props.fixedTitle) {
          gridClasses = gridClasses + `${hoverStyleX6['musicRecommended']} ${gridStyle['row']}`
        }
        break
      case 'item':
        if (this.props.maxPerRow === 2) {
          gridClasses = gridClasses + `${gridStyle['col-xs-12']} ${gridStyle['col-sm-6']} ${gridStyle['col-md-6']} ${gridStyle['col-lg-6']}`
          gridClasses = gridClasses + ` ${styleFixed['directors-content']} `
        }else if (this.props.maxPerRow === 1) {
        gridClasses = gridClasses + `${gridStyle['col-xs-12']} ${gridStyle['col-sm-12']} ${gridStyle['col-md-12']} ${gridStyle['col-lg-12']}`
        gridClasses = gridClasses + ` ${styleFixed['directors-content']} `
        } else if (this.props.maxPerRow === 6) {
          gridClasses = gridClasses + `${gridStyle['col-xs-6']} ${gridStyle['col-sm-4']} ${gridStyle['col-md-3']} ${gridStyle['col-lg-2']}`
          if (this.props.fixedTitle) {
            gridClasses = gridClasses + ` ${styleFixed['directors-content']} `
          } else if (this.props.shape === 'rectangle') {
            gridClasses = gridClasses + ` ${styleRectangle['books-content']} `
          } else if (this.props.shape === '16:9') {
            gridClasses = gridClasses + ` ${style16_9['grid16_9-content']} `
          } else {
            gridClasses = gridClasses + ` ${hoverStyleX6['musicRecommended-content']} `
          }
        } else {
          gridClasses = gridClasses + `${gridStyle['col-xs-12']} ${gridStyle['col-sm-6']} ${gridStyle['col-md-4']} ${gridStyle['col-lg-3']}`
          if (this.props.fixedTitle) {
            gridClasses = gridClasses + ` ${styleFixed['directors-content']} `
          } else if (this.props.shape === 'rectangle') {
            gridClasses = gridClasses + ` ${styleRectangleX4['books-content']} `
          } else if (this.props.shape === '16:9') {
            gridClasses = gridClasses + ` ${style16_9X4['grid16_9-content']} `
          } else if (this.props.shape === 'round') {
            gridClasses = gridClasses + ` ${styleX4Round['musicRecommended-content']} `
          } else {
            gridClasses = gridClasses + ` ${styleX4['musicLibrary-content']} `
          }
        }

        break

    }

    return gridClasses
  }

  render = () => {
    const { sizes, homeElement } = this.props
    const {currentDimension, hideShowMoreButton, rowsToShow, originalRowsToShow} = this.state
    if (!homeElement.items.data.length) {
      if (!hideShowMoreButton) this.setState({hideShowMoreButton: true})
    }
    const border = this.props.shape === 'round' ? "50%" : (this.props.rounded ? ((this.props.shape === 'x1' && this.props.movie && window.innerWidth < 650) ? "25px" : this.props.alternative ? "20px" : "50px") : "0px");

    const gridElements  =
        this.props.showMore ?
            homeElement.items.data.slice(0, rowsToShow * (12 / sizes[currentDimension])).map((element, index) => {
                if (index === (homeElement.items.data.length - 1)) {
                  if (!hideShowMoreButton) this.setState({hideShowMoreButton: true})
                }
                return (
                  <div
                    key={element.id}
                    style={{borderRadius: border}}
                    className={`${this.makeGridClasses('item')}`}
                  >
                    {this.props.renderGridElement(element, this.onElementClicked(element, index))}
                  </div>
              )
            })
          :
            homeElement.items.data.map((element, index) => {
              if (!hideShowMoreButton) this.setState({hideShowMoreButton: true})
              return (
                <div
                  key={element.id}
                  style={{borderRadius: border}}
                  className={`${this.makeGridClasses('item')}`}
                >
                  {this.props.renderGridElement(element, this.onElementClicked(element, index))}
                </div>
              )
            })

    if (this.state.loading || this.state.requestedElement) {
      this.addRequestedElementTo(gridElements)
    }

    return (
      <div style={{ width: "100%", position: "relative", float: "left" }}>
        <div className={this.makeGridClasses('container')} >{gridElements}</div>
        {!hideShowMoreButton ?
          <div style={{width: "100%", float: "left", position: "relative", textAlign: "center", height: '100px'}}>
            <div
              onClick={this.handleShowMore}
              className={`${st["button"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
              style={{
                boxShadow: "2px 2px 5px 0px rgba(0,0,0,0.75)",
                borderRadius: getActiveChannel() && getStoredChannel().preferences.show_more_button !== "square" ? '25px' : 0,
                fontSize: (window.innerWidth > 650 && getActiveChannel()) ? getStoredChannel().preferences.container_description.size : "13px",
                color: getActiveChannel() ? getStoredChannel().preferences.title.color : 'black',
                borderColor: getActiveChannel() ? getStoredChannel().preferences.title.color : 'black',
              }}
            >
              {localization.get('see_more.contents')}
            </div>
          </div>
          :
          <>
            {rowsToShow !== originalRowsToShow &&
              <div style={{width: "100%", float: "left", position: "relative", textAlign: "center", height: '80px'}}>
                <div
                  onClick={this.handleShowLess}
                  className={`${st["button"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                  style={{
                    borderRadius: getActiveChannel() && getStoredChannel().preferences.show_more_button !== "square" ? '25px' : 0,
                    fontSize: (window.innerWidth > 650 && getActiveChannel()) ? getStoredChannel().preferences.container_description.size : "13px",
                    color: getActiveChannel() ? getStoredChannel().preferences.title.color : 'black',
                    borderColor: getActiveChannel() ? getStoredChannel().preferences.title.color : 'black',
                  }}
                >
                  {localization.get('show_less.contents')}</div>
              </div>
            }
          </>
        }
          <ContentCreationPlayer
            noAuthorLink={userHasRestrictedAccess()}
            show={this.state.showContentCreationDialog}
            currentContentCreation={this.state.currentPlayingContent}
            handleClose={() => this.setState({showContentCreationDialog: false,currentPlayingContent: {}})}
          />
      </div>
    )
  }

  onElementClicked = (element, index) => () => {
    if (this.state.requestedElementIndex !== index) {
      EventManager.getInstance().dispatch(events.HOME_ELEMENT_ITEM_CLICKED, element)
      if(element.kind !== 'video_content_creation') {
        this.setState({requestedElementIndex: index, requestedElement: null, loading: true})
        this.props.requestElement(element.id, element.kind)
          .then(requestedElement => this.setState({requestedElement, loading: false}))
          .catch(() => this.setState({loading: false}))
      }else {
        if (element.audio || element.video) {
          // GAevent('Contenido', "Play", element.name)
          this.setState({currentPlayingContent: element, showContentCreationDialog: true})
        } else if(element.binary || element.link) {
          // GAevent('Contenido', "Descargar", element.title)
          analyticsInstance.download({
            name: getMainTranslation(element, "title"),
            type: "channel",
            from: "Material_For_Download",
          })
          element.binary ? window.open(element.binary.url, '_blank') : window.open(addHttpsToUrl(element.link, '_blank'))
        }
      }
    }
  }

  addElementTo = (gridElements, component) => {
    const { currentDimension, requestedElementIndex } = this.state
    const { homeElement, sizes } = this.props
    const mapSizeToPerRow = size => 12 / size
    const perRow = mapSizeToPerRow(sizes[currentDimension])
    const insertAtIndex = getInsertableIndex(requestedElementIndex, perRow, homeElement.items.data.length)
    gridElements.splice(insertAtIndex, 0, <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>{component}</Grid>)
  }

  addRequestedElementTo = gridElements => this.addElementTo(gridElements, this.props.renderElementDescription(
    this.state.requestedElement || this.props.homeElement.items.data[this.state.requestedElementIndex],
    () => {
      this.setState({ requestedAlbum: null })
    })
  )
}

export default HomeElementGrid