import React, {Component} from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText, MenuItem, Select, TextField
} from '@material-ui/core';
import localization from '../../config/localization';
import {request} from '../../api-client';
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import TranslatableInputs from "../../components/Letflow/TranslatableInputs";
import {checkDataSourcesAgainstPermissions, dataSourceTypes} from "./HomeGridAndCarouselBase";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../components/Letflow/Snackbar/GlobalSnackbar";
import {ImagePicker, SelectValidator} from "../../components/Letflow/Form";
import AsyncSelect from "react-select/lib/Async";
import HomeElementCreateDialogTagSelection from "./HomeElementCreateDialogTagSelection";
import {Radio, RadioGroup} from "@material-ui/core/index";
import HomeElementStyleSelection from "./HomeElementStyleSelection";
import {getUpdateFormImageUrlFromItem, validateUrl} from "../../utils";
import FilePondUploader from "../../components/Letflow/FilePondUploader";
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
import EditorialEditor from "../../components/Letflow/RichTextEditor/EditorialEditor";
import {stateToHTML} from "draft-js-export-html";
import {clearUnwantedStateStyles} from "../../components/Letflow/RichTextEditor/utils";
import AvailableLanguages from "../../config/localization/availableLanguages.json";
import {get} from "lodash";
import ColorPicker from '../../components/Letflow/ColorPicker';

const isCustomDate = val => !(["none", "today", "tomorrow", "week", "weekend", "next_week", "month", "range", "yesterday", "week", "last_week", "customHours"]).includes(val);

class HomeElementCreateDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: props.type || '',
      submitting: false,
      rowsToShow: 4,
      columns: 4,
      rowsToShowMobile: 4,
      translations: AvailableLanguages.map(language => ({
        language: language,
        title: "",
        author: "",
        category: "",
        synopsis: "",
      })),
      includeGold: false,
      dataSource: '',
      dataSourceTypes: dataSourceTypes,
      onlyImage: false,
      fixedTitle: false,
      withBackground: false,
      numbered: false,
      catalogs: [],
      contentCreatorTypes: props.contentCreatorTypes || [],
      contentCreatorTypesForCreations:  props.contentCreatorTypes.filter(creators => creators.type !== "editorial").map(creators => ({value: creators.value, label: creators.label || creators.name, slug: creators.value})) || [],
      contentCreatorType: null,
      tagGroups: [],
      selectedTags: [],
      unselectedTags: [],
      tagsAlreadyRequested: false,
      channel: props.channel,
      channelPlaylist: '',
      link: "",
      isCreating: props.isCreating || false,
      shape: props.shape || '',
      navbarViewType: "grid",
      parent: props.parent || null,
      featuredType: props.inverted ? "inverted" : props.editorial ? "editorial" : props.editorial_inverted ? "editorial_inverted": props.movie ? (props.alternative ? "movie_alternative" : "movie") : "default",
      useFeaturedFormat: false,
      square: false,
      hasMargin: false,
      spacing: "none",
      newTab: false,
      image: { src: null, file: null },
      sections: props.sections || [],
      rounded: false,
      halfHeight: false,
      mobileHalfHeight: false,
      backgroundColor: "rgba(255,255,255, 1)",
      backgroundStyle: "default",
      selectPlaylists: "0",
      availableGlobalPlaylists: props.availableGlobalPlaylists,
      globalPlaylists: [],
      useFilterByDate: "0",
      filterByDate: 'none',
      rangeStartDate: null,
      rangeEndDate: null,
      uniqueTexts: AvailableLanguages.map(language => ({
        language: language,
        text: new EditorState.createEmpty(),
      })),
      videoId: null,
      video: { src: props.video || null, file: null },
      imageId: null,
      disableForm: false,
      showAuthor: true,
      textPosition: null,
      withText: false,
      playOnHover: false,
      limitContent: 30,
      useFilterByPublishDate: "0",
      filterByPublishDate: 'none',
      rangeStartPublishDate: null,
      rangeEndPublishDate: null,
      subHours: 0,
      orderBy: "default",
      selectedLanguage: null,
      videos: AvailableLanguages.map(language => ({
          language: language,
          image_id: null,
          image: { src: null, file: null },
          video_id: null,
          video: null,
          play_on_hover: false,
      })),
      images: AvailableLanguages.map(language => ({
            language: language,
            image_id:  null,
            image: { src: null, file: null },
            mobileImage:  { src: null, file: null },
            link: null,
            new_tab: false,
            mobile_image_id: false,
      })),
      mobileFontResize: 100,
      htmlTitle: false,
      contentType: "all",
      embed: "",
      smartSearchContentType: "material",
      smartSearchContentCreatorType: null,
      availableTagGroups: [],
      dateSearch: "none",
      artists: [],
      reference: "",
      fontColor: "rgba(0,0,0,1)",
      pillBackgroundColor: "rgba(255,255,255, 1)",
      pillFontColor: "rgba(255,255,255, 1)",
      mobileGrid: false
    }
  }

  componentDidMount = () => {
    this.setState({dataSourceTypes: checkDataSourcesAgainstPermissions(this.props.permissions, dataSourceTypes, this.props.channel, null ,this.props.parent, this.props.custom)})
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.contentCreatorTypes && nextProps.contentCreatorTypes.length > 0) {
      this.setState({
        contentCreatorTypes: nextProps.contentCreatorTypes,
        contentCreatorTypesForCreations: nextProps.contentCreatorTypes
          .filter(creators => creators.type !== "editorial")
          .map(creators => ({value: creators.value, label: creators.label, slug: creators.value})) || []
      })
    }
  }

  clearAllTags = () =>
    new Promise(resolve => {
      this.setState( {tagGroups: this.state.tagGroups.map(tagGroup => {
          return {...tagGroup,
            tags: tagGroup.tags.map(tag => {
                return {...tag, state: 'available'}
            })
          }
        })
      })
      resolve()
    }).then(() => this.setState({selectedTags: [], unselectedTags: []}))


  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.dataSource === 'catalog' && !this.state.tagsAlreadyRequested) {
      this.setState({tagsAlreadyRequested: true})
      request.tagGroup.getAllOfTypeMusic()
        .then(tags => this.setTagGroups(tags))
    }

    if ((this.state.dataSource === 'content_creator_talent' || this.state.dataSource === 'content_creator_audiovisual' || this.state.dataSource === 'content_creator_editorial') && (prevState.contentCreatorType !== this.state.contentCreatorType)) {
      request.tagGroup.getAll({filterBy: [{ column: 'type', filter: this.state.contentCreatorType}, { column: 'creation_group', filter: '0'}]})
        .then(tags => this.setTagGroups(tags))
    }

    if (this.state.dataSource === 'video_content_creation' && (prevState.contentCreatorType !== this.state.contentCreatorType)) {
      request.tagGroup.getAllPrivate({filterBy: [
        { column: 'type', filter: this.state.contentCreatorType},
        { column: "creation", filter: 1 },
        { column: "dashboard", filter: 1 }
      ]})
        .then(tags => this.setTagGroups(tags))
    }
  }

  onEditTags = (dataSource, type = this.state.contentCreatorType) => {
    if (dataSource === 'content_creator') {
      return request.tagGroup.getAll(
        {filterBy: [
          { column: 'type', filter: type},
          { column: 'creation_group', filter: '0'}
        ]
        })
        .then(tagGroups => this.setState({tagGroups}))
    }

    if (dataSource === 'video_content_creation') {
      return request.tagGroup.getAllPrivate({filterBy: [
        { column: 'type', filter: type},
        { column: "creation", filter: 1 },
        { column: "dashboard", filter: 1 }
      ]})
        .then(tagGroups => this.setState({tagGroups}))
    }
  }

  setTagGroups = tagGroups => this.setState(state => ({...state, tagGroups}))

  updateTagColor = (selectedTag, value) =>
    this.setState( {tagGroups: this.state.tagGroups.map(tagGroup => {
          return {...tagGroup,
            tags: tagGroup.tags.map(tag => {
                if (tag.id === selectedTag) {
                  return {...tag, state: value}
                }
                return tag
              })
            }
          }
        )
      })

  onSelectedTagsChanged = selectedTag => {
    if (this.state.selectedTags.includes(selectedTag)) { //Remove from selected / add to unselected tags
      this.state.unselectedTags.push(selectedTag)
      this.setState({
        selectedTags: this.state.selectedTags.filter(tags => tags !== selectedTag),
        unselectedTags: this.state.unselectedTags
        }, () => this.updateTagColor(selectedTag, "unselected"))
    }else if(this.state.unselectedTags.includes(selectedTag)) { //Remove from unselected / add to available tags
      this.setState({
        unselectedTags: this.state.unselectedTags.filter(tags => tags !== selectedTag),
      }, () => this.updateTagColor(selectedTag, "available"))
    }else { //Remove from available / add to selected tags
      this.state.selectedTags.push(selectedTag);
      this.setState({selectedTags: this.state.selectedTags}, () => this.updateTagColor(selectedTag, "selected"))
    }
  };

  onTagsTest = () =>
    request.client.home.elements.test(
      this.props.clientId, {
        type: this.state.dataSource === 'content_creator_talent' || this.state.dataSource === 'content_creator_audiovisual' || this.state.dataSource === 'content_creator_editorial' ? 'content_creator' : this.state.dataSource,
        catalogs: this.state.catalogs.map(catalog => catalog.value),
        artists: this.state.artists.map(artist  => artist.value),
        selectedTags: this.state.selectedTags,
        unselectedTags: this.state.unselectedTags,
        contentCreatorType: this.state.contentCreatorType,
        content_creator_type: this.state.contentCreatorType,
        limitContent: this.state.limitContent,
        filterByDate: isCustomDate(this.state.filterByDate) ? this.state.customDate : this.state.filterByDate,
        rangeStartDate: this.state.rangeStartDate,
        rangeEndDate: this.state.rangeEndDate,
        channelId: this.state.channel ? this.state.channel.id : false,
        channelPlaylist: this.state.channelPlaylist && this.state.channelPlaylist.value,
        filterByPublishDate: isCustomDate(this.state.filterByPublishDate) ? this.state.customPublishDate : this.state.filterByPublishDate,
        rangeStartPublishDate: this.state.rangeStartPublishDate,
        rangeEndPublishDate: this.state.rangeEndPublishDate,
        subHours: this.state.customPublishDate,
        orderBy: this.state.orderBy,
        testing: true
      }
    )

  hasContent = (editorState) => {
    let text =editorState;

    if (this.state.htmlTitle) {
      const content = editorState.getCurrentContent();
       text = content.getPlainText();
    }

    return !!text;
  }

  checkIfHasTitle = () => this.state.translations.some(tr => this.hasContent(tr.title))

  handleCancel = () => {
    this.props.onClose('cancel')
  }

  handleSubmit = () => {
    if (this.state.submitting) {
      GlobalSnackbar.show({
        message: localization.get( "spotlights.loading"),
        type: GlobalSnackbarTypes.ERROR,
      })
    }else if (!this.state.type) {
      GlobalSnackbar.show({
        message: localization.get('home_editor.validate_type'),
        type: GlobalSnackbarTypes.ERROR,
      })
    } else if (this.state.type === "custom" && !this.state.imageId) {
      GlobalSnackbar.show({
        message: localization.get('home_editor.validate_image'),
        type: GlobalSnackbarTypes.ERROR,
      })
    } else if (this.state.dataSource === "video" && !this.state.videos.some(v => v.video_id && v.image_id)) {
      GlobalSnackbar.show({
        message: localization.get('home_editor.validate_image_video'),
        type: GlobalSnackbarTypes.ERROR,
      })
    } else if (this.state.dataSource === "image" && !this.state.images.some(v => v.image_id)) {
      GlobalSnackbar.show({
        message: localization.get('home_editor.validate_image'),
        type: GlobalSnackbarTypes.ERROR,
      })
    }else if (this.state.translations.some(tr => `${tr.author};${tr.category}`.length > 255)) {
      GlobalSnackbar.show({
        message: localization.get( 'home_editor.validate_texts'),
        type: GlobalSnackbarTypes.ERROR,
      })
    }else if (this.state.translations.some(tr => tr.synopsis && tr.synopsis.length > 600)) {
      GlobalSnackbar.show({
        message: localization.get( "home_editor.validate_synopsis_length"),
        type: GlobalSnackbarTypes.ERROR,
      })
    }else if (this.state.dataSource === "change_language" && !this.state.selectedLanguage) {
      GlobalSnackbar.show({
        message: localization.get( "change_language.validation"),
        type: GlobalSnackbarTypes.ERROR,
      })
    }else if (this.state.dataSource == "link" && !validateUrl(this.state.link)) {
      GlobalSnackbar.show({
        message: localization.get( "home_editor.validate_link"),
        type: GlobalSnackbarTypes.ERROR,
      })
    } else  if (this.state.dataSource === 'lite_smart_search' && !this.state.smartSearchContentCreatorType ) {
      GlobalSnackbar.show({
        message: localization.get( "home_editor.validate_type"),
        type: GlobalSnackbarTypes.ERROR,
      })
    }else  if (this.state.dataSource === 'lite_smart_search' && this.state.availableTagGroups.length == 0 ) {
      GlobalSnackbar.show({
        message: localization.get( "home_editor.validate_tags"),
        type: GlobalSnackbarTypes.ERROR,
      })
    }else if (this.state.dataSource === 'lite_smart_search' && this.state.limitContent > 300 ) {
      GlobalSnackbar.show({
        message: localization.get( "home_editor.validate_limit_content"),
        type: GlobalSnackbarTypes.ERROR,
      })
    }else if (!this.state.reference && !this.props.channel.redirectButton) {
      GlobalSnackbar.show({
        message: localization.get( "home_editor.validate_reference"),
        type: GlobalSnackbarTypes.ERROR,
      })

      this.setState({validateReference: true})
    } else {
       let link = this.state.link; 

       if (link && link.includes('/busqueda-inteligente/') && !link.includes('clean_search=true')) {
        link+="&clean_search=true"
      }
      
      this.setState({ submitting: true },
        () => request.client.home.elements.create(
          this.props.clientId, {
            order: this.props.order,
            type: this.state.type === 'grid_featured' ? 'grid' : this.state.type,
            translations: this.state.translations.map(tr => ({
              ...tr,
              title: !this.state.htmlTitle ? tr.title : JSON.stringify(clearUnwantedStateStyles(convertToRaw(tr.title.getCurrentContent()))),
              additions: `${tr.author};${tr.category}`,
            })),
            dataSource: (this.state.dataSource === 'content_creator_talent' || this.state.dataSource === 'content_creator_audiovisual' ||  this.state.dataSource === 'content_creator_editorial') ? 'content_creator' : (this.state.dataSource === 'custom_embed' ? "custom" : this.state.dataSource),
            includeGold: this.state.includeGold,
            rowsToShow: this.state.rowsToShow,
            rowsToShowMobile: this.state.rowsToShowMobile,
            columns: this.state.columns,
            onlyImage: this.state.onlyImage,
            catalogs: this.state.catalogs.map(catalog => catalog.value),
            artists: this.state.artists.map(artist => artist.value),
            contentCreatorType: this.state.contentCreatorType,
            selectedTags: this.state.selectedTags,
            unselectedTags: this.state.unselectedTags,
            link: link,
            channelId: this.state.channel ? this.state.channel.id : false,
            channelPlaylist: this.state.channelPlaylist && this.state.channelPlaylist.value,
            isCreating: this.props.custom ? false : this.state.isCreating,
            shape: this.state.shape,
            fixedTitle: this.state.fixedTitle,
            withBackground: this.state.withBackground,
            numbered: this.state.numbered,
            square: this.state.square,
            hasMargin: this.state.hasMargin,
            navbarViewType: this.state.navbarViewType,
            parent: this.props.parent,
            featuredType: this.state.featuredType === 'movie_alternative' ? 'movie' : this.state.featuredType,
            alternative: this.state.featuredType === 'movie_alternative',
            useFeaturedFormat: this.state.useFeaturedFormat,
            singleContentCreator: this.state.singleContentCreator,
            spacing: this.state.spacing,
            newTab: this.state.newTab,
            imageId: this.state.imageId,
            imageMobileId: this.state.mobileImageId,
            sectionId: this.props.section ? this.props.section.value : null,
            section: this.state.section,
            rounded: this.state.rounded || false,
            halfHeight: this.state.halfHeight || false,
            mobileHalfHeight: this.state.mobileHalfHeight || false,
            backgroundColor: this.state.backgroundColor || "rgba(255,255,255, 1)",
            fontColor: this.state.fontColor || "rgba(0,0,0,1)",
            backgroundStyle: this.state.backgroundStyle || "default",
            filterByDate: isCustomDate(this.state.filterByDate) ? this.state.customDate : this.state.filterByDate,
            rangeStartDate: this.state.rangeStartDate,
            rangeEndDate: this.state.rangeEndDate,
            videoId: this.state.videoId,
            uniqueTexts: this.state.uniqueTexts.map(tr => ({
              ...tr,
              text: (tr.text && stateToHTML(tr.text.getCurrentContent()) === '<p><br></p>') ? "" : JSON.stringify(clearUnwantedStateStyles(convertToRaw(tr.text.getCurrentContent())))
            })),
            showAuthor: this.state.showAuthor,
            textPosition: this.state.textPosition,
            withText: this.state.withText,
            globalPlaylists: this.state.globalPlaylists,
            playOnHover: this.state.playOnHover,
            limitContent: this.state.limitContent,
            orderBy: this.state.orderBy,
            filterByPublishDate: isCustomDate(this.state.filterByPublishDate) ? this.state.customPublishDate : this.state.filterByPublishDate,
            subHours: this.state.filterByPublishDate === 'customHours' ? this.state.customPublishDate : 0,
            rangeStartPublishDate: this.state.rangeStartPublishDate,
            rangeEndPublishDate: this.state.rangeEndPublishDate,
            lang: this.state.selectedLanguage,
            videos: this.state.videos.filter(v => v.video_id && v.image_id).map(video => ({...video, play_on_hover: video.play_on_hover? 1: 0})),
            images: this.state.images.filter(i => i.image_id).map(image => ({...image, new_tab: image.new_tab ? 1: 0})),
            visibility: this.props.channel && !this.props.channel.redirectButton,  //create invisible to redirect urls
            mobileFontResize: this.state.mobileFontResize,
            contentType: this.state.contentType,
            embedElement: this.state.dataSource === 'custom_embed',
            embed: this.state.embed || null,
            gridFeatured: this.state.type === 'grid_featured',
            smartSearchContentType: this.state.smartSearchContentType,
            smartSearchContentCreatorType: this.state.smartSearchContentCreatorType,
            availableTagGroups: this.state.availableTagGroups,
            dateSearch: this.state.dateSearch,
            reference: this.state.reference,
            pillBackgroundColor: this.state.pillBackgroundColor,
            pillFontColor: this.state.pillFontColor,
            mobileGrid: this.state.mobileGrid
          }
        )
          .then(response => {
            if(this.state.isCreating) {
              const translation = response.data.translations.filter(trans => trans.title)[0];

              response.data.title = translation ? (this.state.htmlTitle ? EditorState.createWithContent(convertFromRaw(JSON.parse(translation.title))) : translation.title) : "";
              response.data.image_id = this.state.imageId;
              response.data.mobile_image_id = this.state.mobileImageId;
              response.data.tags = this.state.selectedTags;
              response.order_by = this.state.orderBy;
              response.data.not_tags = this.state.unselectedTags;
              response.data.link = this.state.link;
              response.data.new_tab = this.state.newTab;
              response.data.filter_by_date = this.state.filterByDate;
              response.data.start_date = this.state.rangeStartDate;
              response.data.end_date = this.state.rangeEndDate;
              response.data.filter_by_publish_date = this.state.filterByPublishDate;
              response.data.start_publish_date = this.state.rangeStartPublishDate;
              response.data.end_publish_date = this.state.rangeEndPublishDate;
              response.data.sub_hours = this.state.filterByPublishDate === 'customHours' ? this.state.customPublishDate : 0;
              response.lang = this.state.selectedLanguage;
              response.videos = this.state.videos.filter(v => v.video_id && v.image_id);
              response.images = this.state.images.filter(i => i.image_id);
              response.alternative = this.state.featuredType === 'movie_alternative';
              response.mobile_font_resize = this.state.mobileFontResize;
              response.content_type = this.state.contentType;
              response.embed_element = this.state.dataSource === 'custom_embed';
              response.data.smart_search_content_type = this.state.smartSearchContentType;
              response.data.smart_search_content_creator_type = this.state.smartSearchContentCreatorType;
              response.data.available_tag_groups = this.state.availableTagGroups;
              response.data.date_search = this.state.dateSearch;

              this.props.addElement(response.data)
              this.props.onClose('success')
            } else {
              this.props.onClose('success')
            }
            GlobalSnackbar.show({
              message: localization.get('home_editor.element_created'),
              type: GlobalSnackbarTypes.SUCCESS,
            })
          })
          .catch(error => {
            GlobalSnackbar.show({
              message: localization.get('home_editor.element_update_failed'),
              type: GlobalSnackbarTypes.ERROR,
            })
          })
          .finally(() => this.setState({ submitting: false }))
      )
    }
  }

  formNewUrl = (path) => process.env.REACT_APP_CDN_HOST.concat('/' + path)
  onChange = e => this.setState({[e.target.id || e.target.name]: e.target.value})
  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})
  onImageMobileChange = image => image ? this.setState({mobileImageId: image.id, mobileImage: {src: null, filename: null }, mobileImagePreview: this.formNewUrl(image.path)}) : this.setState({mobileImageId: null, mobileImage: { src: null, filename: null }})
  setPreview = () => {
      let item = {image: {id: this.state.imageId, src: this.state.imagePreview}}
      this.setState({image : {src: getUpdateFormImageUrlFromItem(item)}})
  }
  setMobilePreview = () => {
    let item = {mobileImage: {id: this.state.mobileImageId, src: this.state.mobileImagePreview}}
    this.setState({mobileImage : {src: getUpdateFormImageUrlFromItem(item)}})
  }

  onVideoChange = video => video ? this.setState({videoId: video.id, video: {src: null}, videoPreview: this.formNewUrl(video.path)}) : this.setState({videoId: null, video: {src: null}})
  onContentChange = (e, field) => this.setState({[field]: e})
  onChangeTranslation = (value, target, language) =>
    this.onChangeTranslatable('translations', value, target, language)
  onChangeUniqueTranslation = (value, target, language) =>
    this.onChangeTranslatable('uniqueTexts', value, target, language)

  onChangeTranslatable = (state, value, target, language) => {
    const translations = this.state[state].map(tr => {
      if (tr.language === language) {
        return {...tr, [target]: value}
      }

      return tr
    });

    return this.setState({ [state]: translations});
  }


  render = () => {
    return (
      <Dialog maxWidth={false} open={true} onEscapeKeyDown={this.handleCancel}>
        <ValidatorForm style={{width: 675, maxWidth: '100%'}} onSubmit={this.handleSubmit}>
          <DialogTitle>
            {localization.get(this.props.channel.redirectButton ? 'create_redirect_element' : 
            (this.state.type === "unique" ? "create_unique_element" :
              (this.state.type === "custom" ?  'create_custom_element' :
                this.state.type === "footer" ?  'create_footer_element' :
              ((this.state.channel && this.state.channel.creatingElements) ? 'create_navbar_button' : 'create_home_element'))
            ))}
          </DialogTitle>
          <DialogContent>
            {this.state.type !== "banner" &&
              <FormControl id="content" style={{width: '100%'}}>
                <TextValidator
                  id="reference"
                  label={localization.get('form.reference')}
                  name="reference"
                  value={this.state.reference}
                  onChange={this.onChange}
                  type="text"
                  fullWidth
                  helperText={localization.get('form.reference_helper')}
                  error={this.state.validateReference}
                  validators={['required']}
                  errorMessages={[localization.get('home_editor.validate_reference')]}
                />
              {!this.props.channel.redirectButton &&
                <>
                  <TranslatableInputs
                    ref={instance => this.child = instance}
                    children={AvailableLanguages.map(language => {
                      const currentLangTranslation = this.state.translations.find(tr => tr.language === language);
                      const translatableInputs = [
                        <>
                          {!this.state.htmlTitle ?
                            <TextValidator
                              style={{width: "100%", marginBottom: 15}}
                              id={`title${language}`}
                              label={localization.get('home_editor.title')}
                              name={`title${language}`}
                              value={currentLangTranslation.title}
                              onChange={e => this.onChangeTranslation(e.target.value, "title", language)}
                            /> :
                            <>
                              <TextValidator
                                style={{display: "none"}}
                                id={`title${language}`}
                                label={localization.get('home_editor.title')}
                                name={`title${language}`}
                                value={currentLangTranslation.title}
                                onChange={e => this.onChangeTranslation(e.target.value, "title", language)}
                              />
                              <div id="ignore">
                                <EditorialEditor
                                  toolbarOptions={['inline', 'fontSize', 'textAlign', 'colorPicker', 'link', 'emoji']}
                                  editorState={get(currentLangTranslation, "title", "")}
                                  onChange={e => this.onChangeTranslation(e, "title", language)}
                                />
                              </div>
                            </>
                          }
                        </>,
                        (this.state.type === "custom" &&
                          <>
                            <TextValidator
                              style={{width: "100%", marginBotton: 15}}
                              id={`category${language}`}
                              label={localization.get('form.section')}
                              name={`category${language}`}
                              value={currentLangTranslation.category}
                              onChange={e => this.onChangeTranslation(e.target.value, "category", language)}
                              disabled={this.state.disabled}
                            />
                            <TextValidator
                              style={{width: "100%", marginBotton: 15}}
                              id={`author${language}`}
                              label={localization.get('form.extras')}
                              name={`author${language}`}
                              value={currentLangTranslation.author}
                              onChange={e => this.onChangeTranslation(e.target.value, "author", language)}
                              disabled={this.state.disabled}
                            />
                            <textarea
                              style={{width: "100%", border: "none", marginTop: 20, borderBottom: "1px solid #ccc"}}
                              id={`synopsis${language}`}
                              label={localization.get('form.synopsis')}
                              helperText={localization.get("form.synopsis_helper")}
                              name={`synopsis${language}`}
                              value={currentLangTranslation.synopsis}
                              onChange={e => this.onChangeTranslation(e.target.value, "synopsis", language)}
                              disabled={this.state.disabled}
                              multiline
                              rows={4}
                            />
                          </>
                        )
                      ];

                      if (! ['navbar', 'custom', 'footer'].includes(this.state.type)) {
                        translatableInputs.unshift(<FormControlLabel
                          control={
                            <Checkbox
                              style={{color: "#4cc2d5"}}
                              checked={this.state.htmlTitle}
                              onChange={() => {
                                const translations = this.state.translations.map(tr => ({
                                  ...tr,
                                  title: this.state.htmlTitle ? "" : new EditorState.createEmpty(),
                                }));

                                this.setState({htmlTitle: !this.state.htmlTitle, translations})
                              }}
                            />
                          }
                          label={localization.get('form.title_html')}
                        />)
                      }
                      return {
                        language: language,
                        content: translatableInputs,
                      }
                    })}
                  />
                </>
              }
              {['navbar'].includes(this.props.type) && this.state.channel.navbar_tabs && 
                <>
                  <h4>{localization.get('home_editor.tab_color_config')}</h4>
                  <div style={{display: "flex"}}>
                    <FormControl style={{ float:"left", width: "16.6%"}}>
                      <label style={{height: 25}}> {localization.get('home_editor.background_color')} </label>
                      <ColorPicker
                        id={'backgroundColor'}
                        handleColorChange={color => this.setState({ backgroundColor: color })}
                        startColor={this.state.backgroundColor}
                        disableAlpha={true}
                      />
                    </FormControl>
                    <FormControl style={{ float:"left", width: "16.6%"}}>
                      <label style={{height: 25}}> {localization.get('home_editor.font_color')} </label>
                      <ColorPicker
                        id={'fontColor'}
                        handleColorChange={color => this.setState({ fontColor: color })}
                        startColor={this.state.fontColor}
                        disableAlpha={true}
                      />
                    </FormControl>
                  </div>
                </>
              }
              {!this.checkIfHasTitle() && !['navbar', 'custom', 'footer'].includes(this.state.type) &&
                <>
                  <span>{localization.get('home_editor.spacing')}</span>
                  <div style={{display: "flex", marginBottom: "20px" }}>
                    <RadioGroup
                      name="shape"
                      value={this.state.spacing}
                      onChange={e => this.setState({spacing: e.target.value})}
                      style={{marginRight: 30, float: "left", position: "relative", flexDirection: "row"}}
                    >
                      <FormControlLabel value="none" control={<Radio color="default"/>} label={localization.get('none')}/>
                      <FormControlLabel value="small" control={<Radio color="default"/>} label={localization.get('home_editor.spacing.small')}/>
                      <FormControlLabel value="medium" control={<Radio color="default"/>} label={localization.get('home_editor.spacing.medium')}/>
                    </RadioGroup>
                    <br />
                  </div>
                </>
              }
              {this.state.type === 'custom' &&
                <>
                  <div style={{color: "#757575", textAlign: 'center', marginTop: 10}}>{localization.get('original')}</div>
                  <FilePondUploader
                    disableForm={this.state.disableForm}
                    updateDisableForm={value => this.setState({disableForm: value})}
                    file={this.state.image}
                    id={"image"}
                    type={"image"}
                    allowedTypes={["image/*"]}
                    onIdChange={this.onImageChange}
                    setPreview={this.setPreview}
                    clientId={this.props.clientId}
                  />
                  <div style={{color: "#757575", textAlign: 'center', marginTop: 10}}>{localization.get('mobile_image')}</div>
                  <FilePondUploader
                    disableForm={this.state.disableForm}
                    updateDisableForm={value => this.setState({disableForm: value})}
                    file={this.state.mobileImage}
                    id={"mobileImage"}
                    type={"image"}
                    allowedTypes={["image/*"]}
                    onIdChange={this.onImageMobileChange}
                    setPreview={this.setMobilePreview}
                    clientId={this.props.clientId}
                  />
                </>
              }
              {!['navbar', 'custom', 'footer'].includes(this.state.type) ?
                <div>
                  <span>{localization.get("form.type")}</span>
                  <RadioGroup
                    name="type"
                    value={this.state.type}
                    onChange={this.onChange}
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel value="grid" control={<Radio color="default"/>} label={localization.get('select.grid')}/>
                    <FormControlLabel value="carousel" control={<Radio color="default"/>} label={localization.get('select.carousel')}/>
                    <FormControlLabel value="featured" control={<Radio color="default"/>} label={localization.get('select.featured')}/>
                    <FormControlLabel value="unique" control={<Radio color="default"/>} label={localization.get('form.unique')}/>
                    <FormControlLabel value="grid_featured" control={<Radio color="default"/>} label={localization.get('home_editor.grid_featured_element')}/>
                  </RadioGroup>
                </div>
              :
              (this.state.dataSource === "content_creator_audiovisual" || this.state.dataSource === "content_creator_talent") &&
                <React.Fragment>
                  <br/>
                  <RadioGroup
                    name="navbarViewType"
                    value={this.state.navbarViewType}
                    onChange={this.onChange}
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel value="grid" control={<Radio color="default"/>} label={localization.get('select.grid')}/>
                    <FormControlLabel value="carousel" control={<Radio color="default"/>} label={localization.get('select.carousel')}/>
                    <FormControlLabel value="featured" control={<Radio color="default"/>} label={localization.get('select.featured')}/>
                  </RadioGroup>
                </React.Fragment>
              }

              {((this.state.type && !['navbar', 'footer'].includes(this.state.type)) ||
                  (['navbar', 'footer'].includes(this.state.type) &&
                  (this.state.dataSource === "content_creator_audiovisual" ||
                  this.state.dataSource === "content_creator_talent" ||
                  this.state.dataSource === "content_creator_editorial" ||
                  this.state.dataSource === "channel_playlist"))) &&
                  this.state.dataSource !== 'section' &&
                  this.state.dataSource !== 'static' &&
                <HomeElementStyleSelection
                  type={!['navbar', 'footer'].includes(this.state.type) ? this.state.type : this.state.navbarViewType}
                  shape={this.state.shape}
                  useFeaturedFormat={this.state.useFeaturedFormat}
                  fixedTitle={this.state.fixedTitle}
                  withBackground={this.state.withBackground}
                  numbered={this.state.numbered}
                  onlyImage={this.state.onlyImage}
                  columns={this.state.columns}
                  rowsToShow={this.state.rowsToShow}
                  rowsToShowMobile={this.state.rowsToShowMobile}
                  inverted={this.state.inverted}
                  onChange={this.onChange}
                  featuredType={this.state.featuredType}
                  onCheckboxChange={name => this.setState({[name]: !this.state[name]})}
                  square={this.state.square}
                  hasMargin={this.state.hasMargin}
                  rounded={this.state.rounded}
                  halfHeight={this.state.halfHeight}
                  mobileHalfHeight={this.state.mobileHalfHeight}
                  backgroundColor={this.state.backgroundColor}
                  backgroundStyle={this.state.backgroundStyle}
                  isNavbar={this.props.type === 'navbar'}
                  showAuthor={this.state.showAuthor}
                  textPosition={this.state.textPosition}
                  withText={this.state.withText}
                  pillBackgroundColor={this.state.pillBackgroundColor}
                  pillFontColor={this.state.pillFontColor}
                  mobileGrid={this.state.mobileGrid}
                />
              }

              <SelectValidator
                name="dataSource"
                current={this.state.dataSource}
                title={localization.get('home_editor.data_source')}
                onChange={this.onChange}
                items={
                  this.state.dataSourceTypes.filter(ds =>
                    (this.state.type !== "unique" && ds.value !== "text" && ds.value !== "video" && ds.value !== "image" && ds.value !== "google_ad" && ds.value !== "lite_smart_search") || (this.state.type === "unique"  && (ds.value == "text" || ds.value == "video" || ds.value == "image" || ds.value == "lite_smart_search" || (ds.value == "google_ad" && this.state.channel && this.state.channel.google_publisher_id && this.state.channel.adUnits && this.state.channel.adUnits.find(adunit => adunit.purpose === 'home'))))
                  )
                    .map((dataSourceType) => ({id: dataSourceType.value, name: dataSourceType.label }))
                }
                disabled={false}
                validators={["required"]}
                errorMessages={[localization.get("validator.is_required")]}
              />

              {this.state.dataSource === 'recommended' &&
                <React.Fragment>
                  <br/>
                  <RadioGroup
                    name="selectPlaylists"
                    value={this.state.selectPlaylists}
                    onChange={e => this.setState({selectPlaylists: e.target.value})}
                    style={{marginRight: 30, float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel value="0" control={<Radio color="default"/>} label={localization.get('home_editor.use_all')}/>
                    <FormControlLabel value="1" control={<Radio color="default"/>} label={localization.get('home_editor.use_custom')}/>
                  </RadioGroup>
                  {this.state.selectPlaylists === '1' &&
                    <AsyncSelect
                      name='globalPlaylists'
                      id={'globalPlaylists'}
                      placeholder={localization.get('conversation.filter_users')}
                      loadingMessage={() => localization.get('loading')}
                      isMulti
                      autoload
                      autoBlur={true}
                      backspaceRemoves={false}
                      noOptionsMessage={() => localization.get('no_options')}
                      value={this.state.globalPlaylists}
                      onChange={globalPlaylists => this.setState({globalPlaylists})}
                      defaultOptions
                      loadOptions={(inputValue, callback) => {
                        callback(this.state.availableGlobalPlaylists.filter(i =>
                          i.label.toLowerCase().normalize("NFD").includes(inputValue.toLowerCase())
                        ))
                      }}
                    />
                  }
                  <br/>
                </React.Fragment>
              }

              {(this.state.dataSource === 'content_creator_talent' || this.state.dataSource === 'content_creator_audiovisual' || this.state.dataSource === 'content_creator_editorial') &&
                <React.Fragment>
                  <br/>
                  <Select
                    name='contentCreatorType'
                    value={this.state.contentCreatorType}
                    onChange={this.onChange}
                  >
                    {this.state.contentCreatorTypes
                        .sort((a, b) => (a.label > b.label) ? 1 : -1)
                        .map((contentCreatorType, i) => {
                          if (this.state.dataSource === 'content_creator_talent' && !contentCreatorType.isTalent) {
                            return false
                          }
                          if (this.state.dataSource === 'content_creator_audiovisual' && (contentCreatorType.isTalent || contentCreatorType.type === "editorial")) {
                            return false
                          }
                          if (this.state.dataSource === 'content_creator_editorial' && (contentCreatorType.isTalent || contentCreatorType.type !== "editorial")) {
                            return false
                          }

                          return (
                            <MenuItem key={i} value={contentCreatorType.value}>{contentCreatorType.label }</MenuItem>
                          )
                        })
                    }
                  </Select>
                  {this.state.type !== "custom" &&
                    <>
                      <FormHelperText>{localization.get('content_creator_type')}</FormHelperText>
                      {this.state.useFilterByPublishDate === "0" &&
                        <>
                          <span>{localization.get('home_editor.use_date_filter')}</span>
                          <br/>
                          <RadioGroup
                            name="useFilterByDate"
                            value={this.state.useFilterByDate}
                            onChange={e => {
                              this.setState({useFilterByDate: e.target.value})
                              if (e.target.value === "0") {
                                this.setState({filterByDate: "none", rangeStartDate: null, rangeEndDate: null})
                              }
                            }}
                            style={{marginRight: 30, float: "left", position: "relative", flexDirection: "row"}}
                          >
                            <FormControlLabel value="0" control={<Radio color="default"/>}
                                              label={localization.get('home_editor.not_use')}/>
                            <FormControlLabel value="1" control={<Radio color="default"/>}
                                              label={localization.get('home_editor.use_filter')}/>
                          </RadioGroup>
                        </>
                      }
                      {this.state.useFilterByDate === "1" ?
                        <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                          <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                            <inputLabel>{localization.get('range')}</inputLabel>
                            <Select style={{ marginRight: 15, width: 150 }} name='filterByDate' id="filterByDate" value={this.state.filterByDate} onChange={this.onChange}>
                              <MenuItem value={"none"}>{localization.get('home_editor.not_use')}</MenuItem>
                              <MenuItem value={"today"}>{localization.get('smart_search.today')}</MenuItem>
                              <MenuItem value={"tomorrow"}>{localization.get('smart_search.tomorrow')}</MenuItem>
                              <MenuItem value={"week"}>{localization.get('smart_search.this_week')}</MenuItem>
                              <MenuItem value={"next_week"}>{localization.get('smart_search.next_week')}</MenuItem>
                              <MenuItem value={"weekend"}>{localization.get('smart_search.this_weekend')}</MenuItem>
                              <MenuItem value={"month"}>{localization.get('smart_search.this_month')}</MenuItem>
                              <MenuItem value={"custom"}>{localization.get('smart_search.x_days')}</MenuItem>
                              <MenuItem value={"range"}>{localization.get('smart_search.range')}</MenuItem>
                            </Select>
                          </div>
                          {this.state.filterByDate === "custom" ?
                            <TextField
                              style={{ width: 180, marginTop: 5 }}
                              name="customDate"
                              id="customDate"
                              label={localization.get('days_from_today')}
                              value={this.state.customDate}
                              onChange={(e) => {
                                const re = /^[0-9\b]+$/;
                                // if value is not blank, then test the regex
                                if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length < 4){
                                  this.onChange({target: {id: "customDate",value: e.target.value}})
                                }
                              }}
                              min={1}
                              max={3}
                            />
                            : this.state.filterByDate === "range" ?
                              <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "15px 0"}}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    style={{ width: "40%"}}
                                    autoOk
                                    keyboard
                                    clearable
                                    onlyCalendar
                                    disablePast
                                    defaultValue={this.state.rangeStartDate}
                                    format={"dd/MM/yyyy"}
                                    label={localization.get('smart_search.date_start')}
                                    value={this.state.rangeStartDate}
                                    onChange={date=> this.setState({rangeStartDate: date})}
                                  />
                                  <DatePicker
                                    style={{ width: "40%" }}
                                    autoOk
                                    keyboard
                                    clearable
                                    onlyCalendar
                                    disablePast
                                    defaultValue={this.state.rangeEndDate}
                                    format={"dd/MM/yyyy"}
                                    label={localization.get('smart_search.date_end')}
                                    value={this.state.rangeEndDate}
                                    onChange={date=> this.setState({rangeEndDate: date})}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                              : null}
                        </div>
                        :this.state.dataSource === 'content_creator_editorial' ?
                          <>
                            <p>{localization.get('home_editor.use_publish_date_filter')}</p>
                          <RadioGroup
                            name="useFilterByPublishDate"
                            value={this.state.useFilterByPublishDate}
                            onChange={e => {
                              this.setState({useFilterByPublishDate: e.target.value})
                              if (e.target.value === "0") {
                                this.setState({filterByPublishDate: "none", rangeStartPublishDate: null, rangeEndPublishDate: null})
                              }
                            }}
                            style={{marginRight: 30, float: "left", position: "relative", flexDirection: "row"}}
                          >
                            <FormControlLabel value="0" control={<Radio color="default"/>} label={localization.get('home_editor.not_use')}/>
                            <FormControlLabel value="1" control={<Radio color="default"/>} label={localization.get('home_editor.use_filter')}/>
                          </RadioGroup>
                          {this.state.useFilterByPublishDate === "1" &&
                            <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                              <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                                <inputLabel>{localization.get('range')}</inputLabel>
                                <Select style={{ marginRight: 15, width: 150 }} name='filterByPublishDate' id="filterByPublishDate" value={this.state.filterByPublishDate} onChange={this.onChange}>
                                  <MenuItem value={"none"}>{localization.get('home_editor.not_use')}</MenuItem>
                                  <MenuItem value={"today"}>{localization.get('smart_search.today')}</MenuItem>
                                  <MenuItem value={"yesterday"}>{localization.get('smart_search.yesterday')}</MenuItem>
                                  <MenuItem value={"week"}>{localization.get('smart_search.last_seven_days')}</MenuItem>
                                  <MenuItem value={"month"}>{localization.get('smart_search.last_thirty_days')}</MenuItem>
                                  <MenuItem value={"custom"}>{localization.get('smart_search.x_days')}</MenuItem>
                                  <MenuItem value={"customHours"}>{localization.get('smart_search.x_hours')}</MenuItem>
                                  <MenuItem value={"range"}>{localization.get('smart_search.range')}</MenuItem>
                                </Select>
                              </div>
                              {(this.state.filterByPublishDate === "custom" || this.state.filterByPublishDate === "customHours")  ?
                                <TextField
                                  style={{ width: 550, marginTop: 5 }}
                                  name="customPublishDate"
                                  id="customPublishDate"
                                  label={localization.get(this.state.filterByPublishDate !== "custom" ? 'hours.past_now' :'hours_past_today')}
                                  value={this.state.customPublishDate}
                                  onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    // if value is not blank, then test the regex
                                    if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length < 4){
                                      this.onChange({target: {id: "customPublishDate", value: e.target.value}})
                                    }
                                  }}
                                  min={1}
                                  max={3}
                                />
                                : this.state.filterByPublishDate === "range" ?
                                  <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "15px 0"}}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <DatePicker
                                        style={{ width: "40%"}}
                                        autoOk
                                        keyboard
                                        clearable
                                        onlyCalendar
                                        disableFuture
                                        defaultValue={this.state.rangeStartPublishDate}
                                        format={"dd/MM/yyyy"}
                                        label={localization.get('smart_search.date_start')}
                                        value={this.state.rangeStartPublishDate}
                                        onChange={date=> this.setState({rangeStartPublishDate: date})}
                                      />
                                      <DatePicker
                                        style={{ width: "40%" }}
                                        autoOk
                                        keyboard
                                        clearable
                                        onlyCalendar
                                        disableFuture
                                        defaultValue={this.state.rangeEndPublishDate}
                                        format={"dd/MM/yyyy"}
                                        label={localization.get('smart_search.date_end')}
                                        value={this.state.rangeEndPublishDate}
                                        onChange={date=> this.setState({rangeEndPublishDate: date})}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </div>
                                  : null}
                            </div>
                          }
                        </>
                      : null}
                      <HomeElementCreateDialogTagSelection clearAllTags={this.clearAllTags} tagGroups={this.state.tagGroups} onSelectedTagsChanged={this.onSelectedTagsChanged} onTest={this.onTagsTest} />
                    </>
                  }

                  <TextField
                    id="limitContent"
                    label={localization.get('form.limit_content')}
                    name="limitContent"
                    value={this.state.limitContent}
                    onChange={this.onChange}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                  />
                  <span>{localization.get('account.license.order_by')}</span>
                  <RadioGroup
                    name="orderBy"
                    style={{width: "100%", flexDirection: "row" }}
                    value={this.state.orderBy}
                    onChange={event => this.setState({ orderBy: event.target.value })}
                  >
                    <FormControlLabel value="default" control={<Radio color="default" />} label={localization.get('form.order_weight')} />
                    <FormControlLabel value="alphabetical_asc" control={<Radio color="default" />} label={localization.get('form.alphabetical')} />
                    <FormControlLabel value="random" control={<Radio color="default" />} label={localization.get('form.order_random')} />
                  </RadioGroup>
                  </React.Fragment>
              }

              {this.state.dataSource === 'video_content_creation' &&
              <React.Fragment>
                <br/>
                <Select
                  name='contentCreatorType'
                  value={this.state.contentCreatorType}
                  onChange={this.onChange}
                >
                  {this.state.contentCreatorTypesForCreations
                    .sort((a, b) => (a.label > b.label) ? 1 : -1)
                    .map((contentCreatorType, i) =>
                        <MenuItem key={i} value={contentCreatorType.value}>{contentCreatorType.label}</MenuItem>
                    )}
                </Select>
                <span>{localization.get('account.license.order_by')}</span>
                <RadioGroup
                  name="contentType"
                  style={{width: "100%", flexDirection: "row" }}
                  value={this.state.contentType}
                  onChange={event => this.setState({ contentType: event.target.value })}
                >
                  <FormControlLabel value="all" control={<Radio color="default" />} label={localization.get('all')} />
                  <FormControlLabel value="video" control={<Radio color="default" />} label={localization.get('table.allow_video')} />
                  <FormControlLabel value="audio" control={<Radio color="default" />} label={localization.get('table.allow_audio')} />
                  <FormControlLabel value="image" control={<Radio color="default" />} label={localization.get('table.allow_image')} />
                  <FormControlLabel value="binary" control={<Radio color="default" />} label={localization.get('table.allow_binary')} />
                  <FormControlLabel value="embed" control={<Radio color="default" />} label={localization.get('table.allow_embed')} />
                </RadioGroup>
                <FormHelperText>{localization.get('content_creator_type')}</FormHelperText>
                <HomeElementCreateDialogTagSelection clearAllTags={this.clearAllTags} tagGroups={this.state.tagGroups} onSelectedTagsChanged={this.onSelectedTagsChanged}/>
                <TextField
                    id="limitContent"
                    label={localization.get('form.limit_content')}
                    name="limitContent"
                    value={this.state.limitContent}
                    onChange={this.onChange}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                  />
              </React.Fragment>
              }

              {this.state.dataSource === 'channel_playlist' &&
              <React.Fragment>
                <br/>
                <FormHelperText>{localization.get('playlist')}</FormHelperText>
                <AsyncSelect
                  name='channelPlaylist'
                  id={'channelPlaylist'}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  autoload
                  autoBlur={true}
                  noOptionsMessage={() => localization.get('no_options')}
                  value={this.state.channelPlaylist}
                  onChange={channelPlaylist => this.setState({channelPlaylist})}
                  onMenuOpen={() => document.getElementById('content').scrollTop = 100}
                  menuShouldScrollIntoView={true}
                  defaultOptions={this.props.availableChannelPlaylists}
                  loadOptions={(inputValue, callback) => {
                   callback(this.props.availableChannelPlaylists.filter(i =>
                     i.label.toLowerCase().includes(inputValue.toLowerCase())
                   ))
                  }}
                />
                <br/>
              </React.Fragment>
              }

              {this.state.dataSource === 'user_library' &&
              <FormControlLabel
                control={
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={this.state.includeGold}
                    onChange={() => this.setState({includeGold: !this.state.includeGold})}
                  />
                }
                label={localization.get('form.include_gold')}
              />
              }


              {this.state.dataSource === 'catalog' &&
                <>
                <FormControl fullWidth style={{marginTop: 30}}>
                  <span>{localization.get('home_editor.select_catalogs')}</span>
                  <AsyncSelect
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 10
                      })
                    }}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0
                    })}
                    placeholder={localization.get('catalogs')}
                    loadingMessage={() => localization.get('loading')}
                    noOptionsMessage={() => localization.get('no_options')}
                    onChange={catalogs => this.setState({catalogs})}
                    value={this.state.catalogs}
                    loadOptions={inputValue =>
                      request.catalog
                        .getAll({filterBy: [
                          {column: "client_id", filter: this.props.clientId},
                          {column: "name", filter: inputValue},
                          {column: "confirmed", filter: true},
                          {column: 'filter_content', filter: 'music'}
                        ]})
                        .then(res => res.map(catalog => ({value: catalog.id, label: catalog.name})))
                    }
                    defaultOptions
                    isMulti
                  />

                  
                </FormControl>
               <FormControl fullWidth>
                <span>{localization.get('form.artist')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  name={"artist"}
                  placeholder={localization.get('form.artist')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={artists => this.setState({artists})}
                  value={this.state.artists}
                  defaultOptions
                  isMulti
                  validators={['required']}
                  errorMessages={[localization.get('validator.is_required')]}
                  loadOptions={inputValue =>
                    request.artist
                      .getAll({ filterBy: [{ column: "name", filter: inputValue }] })
                      .then(res => res.map(artist => ({ value: artist.id, label: `${artist.name} - (${localization.get(artist.type)})` })))
                  }
                />
                </FormControl>

                  {this.state.type !== "custom" &&
                    <HomeElementCreateDialogTagSelection
                      clearAllTags={this.clearAllTags}
                      tagGroups={this.state.tagGroups}
                      onSelectedTagsChanged={this.onSelectedTagsChanged}
                      onTest={this.onTagsTest}
                    />
                  }
              </>
              }

              {this.state.dataSource === 'single_content_creator' &&
              <FormControl fullWidth style={{marginTop: 30}}>
                <span>{localization.get('home_editor.select_content_creator')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 100,
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('content_creator')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={singleContentCreator => this.setState({singleContentCreator})}
                  value={this.state.singleContentCreator}
                  loadOptions={inputValue => {
                    let filterBy = [
                      {column: "title", filter: inputValue},
                      {column: "client_id", filter: this.props.clientId}
                    ];
                    if (this.props.channelId) {
                      filterBy.push({column: "channel_id", filter: this.props.channelId})
                    }
                    return request.contentCreator.getAll({filterBy: filterBy})
                      .then(response => response.map(cc => ({value: cc.id, label: cc.name})))
                  }}
                />
              </FormControl>
              }

              {this.state.dataSource === 'link' &&
                <>
                  <TextValidator
                    style={{ width: "100%", marginBottom: "20px" }}
                    label={localization.get('url')}
                    name="link"
                    value={this.state.link}
                    onChange={e => this.setState({link: e.target.value})}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={this.state.newTab}
                        onChange={() => this.setState({newTab: !this.state.newTab})}
                      />
                    }
                    label={localization.get('form.new_tab')}
                  />
                </>
              }

              {this.state.dataSource === 'embed' &&
                <>
                  <RadioGroup
                    name="contentType"
                    style={{width: "100%", flexDirection: "row" }}
                    value={"youtube"}
                    disabled
                  >
                    <FormControlLabel value="youtube" disabled control={<Radio color="default" />} label={"YouTube"} />
                  </RadioGroup>
                  <TextValidator
                    style={{ width: "100%", marginBottom: "20px" }}
                    label={"Youtube URL (https://www.youtube.com/watch?v=ID_VIDEO)"}
                    name="embed"
                    value={this.state.embed}
                    onChange={e => this.setState({embed: e.target.value})}
                  />
               </>
              }
            </FormControl>
            }
            {this.state.dataSource === 'section' &&
              <React.Fragment>
                <br/>
                <br/>
                <FormHelperText>{localization.get('section')}</FormHelperText>
                <AsyncSelect
                  name='section'
                  id={'section'}
                  placeholder={localization.get('section')}
                  loadingMessage={() => localization.get('loading')}
                  autoload
                  autoBlur={true}
                  noOptionsMessage={() => localization.get('no_options')}
                  value={this.state.section}
                  onChange={section => this.setState({section})}
                  defaultOptions={this.state.sections}
                  loadOptions={(inputValue, callback) => {
                    callback(this.state.sections.filter(i =>
                      i.label.toLowerCase().includes(inputValue.toLowerCase())
                    ))
                  }}
                />
                <br/>
              </React.Fragment>
            }

            {this.state.dataSource === "text" &&
              <>
                <br />
                <br />
                <TranslatableInputs
                  color="dark"
                  isEditorialContent
                  children={AvailableLanguages.map(language => {
                    const currentLangTranslation = this.state.uniqueTexts.find(tr => tr.language === language);

                    return {
                      language: language,
                      content: [
                        <TextValidator
                          style={{display: "none"}}
                          id={`text${language}`}
                          label={localization.get('form.note')}
                          name={`text${language}`}
                          value={currentLangTranslation.text}
                          onChange={e => this.onChangeUniqueTranslation(e.target.value, 'text', language)}
                        />,
                        <div id="ignore">
                          <EditorialEditor
                            editorState={get(currentLangTranslation, "text", "")}
                            onChange={e => this.onChangeUniqueTranslation(e, 'text', language)}
                            noColors
                          />
                        </div>
                      ]
                    }
                  })}
                />
                <h4>{localization.get("form.mobile_font")}</h4>
                <TextValidator
                  style={{ width: "100%" }}
                  id="mobileFontResize"
                  validators={['minNumber:0', 'maxNumber:100']}
                  type="number"
                  label={`${localization.get("form.mobile_resize_percentage")} %`}
                  name="mobileFontResize"
                  value={this.state.mobileFontResize}
                  onChange={e => this.setState({mobileFontResize: e.target.value})}
                />
              </>
            }

            {(this.state.dataSource === "image" || this.state.dataSource === "video") &&
              <TranslatableInputs
                color="dark"
                isEditorialContent
                disabled={this.state.disableForm}
                children={AvailableLanguages.map(language => {
                  return {
                    language: language,
                    content: [
                      <>
                        {this.state.dataSource === "image" &&
                          <>
                            <br/>
                            <FormControl style={{width: '100%', marginTop: '10px'}}>
                              <ValidatorForm>
                                <TextValidator
                                  style={{width: "100%", marginBottom: "20px"}}
                                  label={localization.get('url')}
                                  id="link"
                                  name="link"
                                  value={this.state.images.find(image => image.language === language).link || ""}
                                  onChange={e => this.onChangeTranslatable('images', e.target.value, 'link', language)}
                                />
                              </ValidatorForm>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    style={{color: "#4cc2d5"}}
                                    checked={this.state.images.find(image => image.language === language).new_tab || ""}
                                    onChange={e => this.onChangeTranslatable('images', !this.state.newTab, 'new_tab', language)}
                                  />
                                }
                                label={localization.get('form.new_tab')}
                              />
                            </FormControl>
                            <br/>

                            <div style={{
                              color: "#757575",
                              textAlign: 'center',
                              marginTop: 10
                            }}>{localization.get('image')}</div>
                            {this.state.images.find(image => image.language === language) &&
                              <ImagePicker
                                image={request.file.image.makeCroppedUrl(this.state.images.find(image => image.language === language).imageId, 400, 400)}
                                previewOnly={true}
                                id={this.state.images.find(image => image.language === language).imageId}/>}
                            <br/>
                            <FilePondUploader
                              disableForm={this.state.disableForm}
                              updateDisableForm={value => this.setState({disableForm: value})}
                              file={this.state.image}
                              id={"image"}
                              type={"image"}
                              allowedTypes={["image/*"]}
                              clientId={this.props.clientId}
                              onIdChange={async image => {
                                if (image) {
                                  await this.onChangeTranslatable('images', image.id, 'image_id', language);
                                  await this.onChangeTranslatable('images', {src: null, filename: null}, 'image', language);
                                  await this.onChangeTranslatable('images', this.formNewUrl(image.path), 'imagePreview', language);
                                } else {
                                  await this.onChangeTranslatable('images', null, 'image_id', language);
                                  await this.onChangeTranslatable('images', {src: null, filename: null}, 'image', language);
                                }
                              }}
                              setPreview={() => {
                                let item = {
                                  image: {
                                    id: this.state.images.find(video => video.language === language).image_id,
                                    src: this.state.images.find(video => video.language === language).imagePreview
                                  }
                                }
                                this.onChangeTranslatable('images', {src: getUpdateFormImageUrlFromItem(item)}, 'image', language);
                              }}
                            />
                            <br/>
                            {this.state.mobileImage && this.state.mobileImage.src &&
                              <ImagePicker image={this.state.mobileImage.src} previewOnly={true}
                                           id={this.state.mobileImage.id}/>}
                            <div style={{
                              color: "#757575",
                              textAlign: 'center',
                              marginTop: 10
                            }}>{localization.get('image_mobile')}</div>
                            <FilePondUploader
                              disableForm={this.state.disableForm}
                              updateDisableForm={value => this.setState({disableForm: value})}
                              file={this.state.mobileImage}
                              id={"mobileImage"}
                              type={"image"}
                              allowedTypes={["image/*"]}
                              clientId={this.props.clientId}
                              onIdChange={async image => {
                                if (image) {
                                  await this.onChangeTranslatable('images', image.id, 'mobile_image_id', language);
                                  await this.onChangeTranslatable('images', {src: null, filename: null}, 'mobileImage', language);
                                  await this.onChangeTranslatable('images', this.formNewUrl(image.path), 'imageMobilePreview', language);
                                } else {
                                  await this.onChangeTranslatable('images', null, 'mobile_image_id', language);
                                  await this.onChangeTranslatable('images', {src: null, filename: null}, 'mobileImage', language);
                                }
                              }}
                              setPreview={() => {
                                let item = {
                                  image: {
                                    id: this.state.images.find(image => image.language === language).mobile_image_id,
                                    src: this.state.images.find(image => image.language === language).imageMobilePreview
                                  }
                                }
                                this.onChangeTranslatable('images', {src: getUpdateFormImageUrlFromItem(item)}, 'mobileImage', language);
                              }}
                            />
                            <br/>
                          </>
                        }

                        {this.state.dataSource === "video" &&
                          <>
                            <br/>
                            <div style={{
                              color: "#757575",
                              textAlign: 'center',
                              marginTop: 10
                            }}>{localization.get('image')}</div>
                            {this.state.videos.find(video => video.language === language).image_id &&
                              <ImagePicker
                                image={request.file.image.makeCroppedUrl(this.state.videos.find(video => video.language === language).image_id, 400, 400)}
                                previewOnly={true}
                                id={this.state.videos.find(video => video.language === language).image_id}/>}
                            <br/>
                            <FilePondUploader
                              disableForm={this.state.disableForm}
                              updateDisableForm={value => this.setState({disableForm: value})}
                              file={this.state.image}
                              id={"image"}
                              type={"image"}
                              allowedTypes={["image/*"]}
                              clientId={this.props.clientId}
                              onIdChange={async image => {
                                if (image) {
                                  await this.onChangeTranslatable('videos', image.id, 'image_id', language);
                                  await this.onChangeTranslatable('videos', {src: null, filename: null}, 'image', language);
                                  await this.onChangeTranslatable('videos', this.formNewUrl(image.path), 'imagePreview', language);
                                } else {
                                  await this.onChangeTranslatable('videos', null, 'image_id', language);
                                  await this.onChangeTranslatable('videos', {src: null, filename: null}, 'image', language);
                                }
                              }}
                              setPreview={() => {
                                let item = {
                                  image: {
                                    id: this.state.videos.find(video => video.language === language).image_id,
                                    src: this.state.videos.find(video => video.language === language).imagePreview
                                  }
                                }
                                this.onChangeTranslatable('videos', {src: getUpdateFormImageUrlFromItem(item)}, 'image', language);
                              }}
                            />
                            <br/>
                            <div style={{
                              color: "#757575",
                              textAlign: 'center',
                              marginTop: 10
                            }}>{localization.get('video')}</div>
                            {this.state.videos.find(video => video.language === language).video &&
                              <video width='100%' controls>
                                <source
                                  src={this.state.videos.find(video => video.language === language).video.url}
                                  type='video/mp4'/>
                                Your browser does not support HTML5 video.
                              </video>}
                            <br/>
                            <FilePondUploader
                              disableForm={this.state.disableForm}
                              updateDisableForm={value => this.setState({disableForm: value})}
                              file={this.state[`video${language}`]}
                              id={"video"}
                              type={"video"}
                              allowedTypes={["video/mp4", "video/x-m4v", "video/*"]}
                              clientId={this.props.clientId}
                              onIdChange={async video => {
                                if (video) {
                                  await this.onChangeTranslatable('videos', video.id, 'video_id', language);
                                  await this.onChangeTranslatable('videos', {src: null}, 'video', language);
                                  await this.onChangeTranslatable('videos', this.formNewUrl(video.path), 'videoPreview', language);
                                } else {
                                  await this.onChangeTranslatable('videos', null, 'video_id', language);
                                  await this.onChangeTranslatable('videos', {src: null}, 'video', language);
                                }
                              }}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{color: "#4cc2d5"}}
                                  checked={this.state.videos.find(video => video.language === language).play_on_hover}
                                  onChange={e => this.onChangeTranslatable('videos', ! this.state.videos.find(video => video.language === language).play_on_hover, 'play_on_hover', language)}
                                />
                              }
                              label={localization.get('form.play_on_hover')}
                            />
                          </>
                        }
                      </>
                    ]
                  }
                })}
              />
            }
            {this.state.dataSource === "change_language" && this.state.channel.languages && this.state.channel.languages.length > 0 &&
              <>
                <div style={{marginTop: 15, marginBottom: 10}}>{localization.get('artist_demo.select_language')}</div>
                <Select
                  name='selectedLanguage'
                  value={this.state.selectedLanguage}
                  onChange={this.onChange}
                >
                  {this.state.channel.languages.map(lang => <MenuItem
                    value={lang.language}>{localization.get(`language.${lang.language}`)}</MenuItem>)}
                </Select>
              </>
            }
            {this.state.dataSource === 'lite_smart_search' &&
              <ValidatorForm>
                <inputLabel>{localization.get('global_playlist.destination_type')}</inputLabel>
                <RadioGroup
                    name="smartSearchContentType"
                    style={{width: "100%", flexDirection: "row" }}
                    value={this.state.smartSearchContentType}
                    onChange={e => {
                      this.onChange(e)
                      this.setState({ 
                        dateSearch: e.target.value == "material" ? "none" : this.state.smartSearchContentType,
                        availableTagGroups: []
                      })
                      if (!!this.state.smartSearchContentType) this.onEditTags(e.target.value === 'material' ? 'video_content_creation' : 'content_creator', this.state.smartSearchContentCreatorType)
                    }}
                  >
                  <FormControlLabel value="material" control={<Radio color="default" />} label={localization.get("content_creations.materials")} />
                  <FormControlLabel value="content" control={<Radio color="default" />} label={localization.get("content_creators")} />
                  </RadioGroup>
                  <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <inputLabel>{localization.get('content_creator_type')}</inputLabel>
                    <Select
                      name='smartSearchContentCreatorType'
                      value={this.state.smartSearchContentCreatorType}
                      onChange={e => {
                        this.onChange(e)
                        this.onEditTags(this.state.smartSearchContentType === 'material' ? 'video_content_creation' : 'content_creator', e.target.value)
                      }}
                    >
                    {this.state.contentCreatorTypes
                      .sort((a, b) => (a.label > b.label) ? 1 : -1)
                      .map((contentCreatorType, i) => {
                        return (<MenuItem key={i} value={contentCreatorType.value}>{contentCreatorType.label}</MenuItem>)
                      })}
                  </Select>
                </div>
                <br/>
                <TextField
                  id="limitContent"
                  label={localization.get('form.limit_content')}
                  name="limitContent"
                  value={this.state.limitContent}
                  onChange={this.onChange}
                  type="number"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                />
                <br/>
                <br/>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                  <inputLabel>{localization.get('tag_groups')}</inputLabel>
                  <AsyncSelect 
                    name='availableTagGroups'
                    id={'availableTagGroups'}
                    placeholder={localization.get('tag_groups')}
                    loadingMessage={() => localization.get('loading')}
                    isMulti
                    autoload
                    autoBlur={true}
                    backspaceRemoves={false}
                    noOptionsMessage={() => localization.get('no_options')}
                    value={this.state.availableTagGroups}
                    onChange={availableTagGroups => this.setState({availableTagGroups})}
                    defaultOptions={this.state.tagGroups.map(tg => ({value: tg.id, label: tg.name}))}
                    loadOptions={(inputValue, callback) => {
                      callback(this.state.tagGroups
                        .map(tg => ({value: tg.id, label: tg.name}))
                        .filter(i =>i.label.toLowerCase().normalize("NFD").includes(inputValue.toLowerCase())))
                    }}
                  />
                </div>
                <br/>
                <inputLabel>{localization.get('content_creators.date_search')}</inputLabel>
                <RadioGroup
                    name="dateSearch"
                    style={{width: "100%", flexDirection: "row" }}
                    value={this.state.dateSearch}
                    onChange={e => this.onChange(e)}
                  >
                    <FormControlLabel disabled={this.state.smartSearchContentType == "material"} value="none" control={<Radio color="default" />} label={localization.get("content_creators.none")} />
                    <FormControlLabel disabled={this.state.smartSearchContentType == "material"} value="event" control={<Radio color="default" />} label={localization.get("content_creators.events")} />
                    <FormControlLabel disabled={this.state.smartSearchContentType == "material"} value="publish" control={<Radio color="default" />} label={localization.get("content_creators.publish")} />
                  </RadioGroup>
              </ValidatorForm>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} disabled={this.state.submitting}>{localization.get('cancel')}</Button>
            <Button type="submit">{localization.get('store')}</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    )
  }
}

export default HomeElementCreateDialog
