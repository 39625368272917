import localization from "../../../config/localization";
import {TextValidator} from "react-material-ui-form-validator";
import React from "react";
import ColorPicker from "../../../components/Letflow/ColorPicker";
import {Checkbox, FormControl, FormControlLabel,  Radio, RadioGroup, Tooltip} from "@material-ui/core";
import Select from "react-select";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import AsyncSelect from "react-select/lib/Async";
import TextField from "../../../components/Letflow/Form/TextField";

const CustomizationForm = ({
  onChange,
  channel,
  titleColor,
  titleSize,
  titleFont,
  titleWeight,
  containerTitleColor,
  containerTitleColorHover,
  containerTitleSize,
  containerTitleWeight,
  containerDescriptionColor,
  containerDescriptionColorHover,
  containerDescriptionSize,
  containerDescriptionWeight,
  liveButtonColor,
  liveButtonIcon,
  liveButtonShape,
  radioButtonColor,
  radioButtonIcon,
  radioButtonShape,
  textFont,
  favIcon,
  interactionColor,
  elementBackground,
  showMoreButton,
  fonts,
  icons,
  weights,
  mainFont,
  mainColor,
  mainFontColor,
  secondaryColor,
  secondaryFontColor,
  footerColor,
  footerFontColor,
  navbarButtonSize,
  contentCreatorTitleColor,
  contentCreatorDescriptionColor,
  contentCreatorCreationColor,
  contentCreatorCardColor,
  contentCreatorCardShape,
  contentCreatorCreationInverted,
  contentCreatorShowBox,
  navbarFontColor,
  navbarCenterMode,
  editorialBackgroundColor,
  editorialTitleColor,
  contentCreatorEventsPosition,
  contentCreatorBackgroundColor,
  useContentCreatorBackgroundColor,
  useContentCreatorBackgroundGradient,
  contentCreatorCreationSubtitleFontSize,
  contentCreatorCreationSubtitleMargin,
  onUseContentCreatorBackgroundColorChange,
  onUseContentCreatorBackgroundGradientChange,
  contentCreatorBuyButtonPosition,
  contentCreatorBuyButtonBackgroundColor,
  contentCreatorBuyButtonFontColor,
  useCanonicals,
  useBlurOnLogin,
  onChangeUseBlurOnLogin,
  contentCreatorShowCreationBorder,
  contentCreatorCreationCarouselBackgroundColor,
  mobileFontResize,
  editorialHideCoverImage,
  favoriteTemplate,
  favoriteTemplates,
  subNavbarFontColor,
  subNavbarBackgroundColor,
  showNavbarMenu,
  sharePosition,
  socialFill,
  contentCreatorSocialPosition,
  contentCreatorShowMoreButton,
  homePadding,
  sidebarFontColor,
  sidebarBackgroundColor,
  sidebarButtonFontColor,
  sidebarButtonBackgroundColor,
  sidebarSelectorFontColor,
  sidebarSelectorBackgroundColor,
  contentCreatorEventButtonColor,
  contentCreatorEventButtonBackgroundColor,
  contentCreatorEventButtonText,
  contentCreatorEditorialUseSynopsis,
  floatingButtonBackgroundColor,
  floatingButtonBorderColor,
  creationHideCreator,
}) => {
  return (
  <div>
    <div style={{position: "relative", float: "left", width: "100%"}}>
      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creator-theme', 'arrow-theme')}>
        {localization.get('form.theme_color')}
        <ExpandLess style={{display: "none",position:"absolute", right:15, top:12}} id={"arrow-theme-up"}/>
        <ExpandMore style={{display: "block", position:"absolute", right:15, top:12}} id={"arrow-theme-down"}/>
      </h3>
      <div style={{width: "100%", display: "none"}} id={'content-creator-theme'} >
      <h4>{localization.get('form.main_colors')}</h4>
      <div style={{width: "100%", float: "left", marginBottom: 15}}>
        <FormControl style={{ float:"left", width: "16.6%"}}>
          <Tooltip title={localization.get('form.main_color_tooltip')} placement='top'>
            <label style={{height: 40}}> {localization.get('form.main_color')} </label>
          </Tooltip>
          <ColorPicker
            id={'mainColor'}
            handleColorChange={color =>
              onChange({target: {
                  id: "mainColor",
                  value: color
                }})
            }
            startColor={mainColor}
            disableAlpha={true}
          />
        </FormControl>
        <FormControl style={{ float:"left", width: "16.6%"}}>
          <Tooltip title={localization.get('form.secondary_color_tooltip')} placement='top'>
            <label style={{height: 40}}> {localization.get('form.secondary_color')} </label>
          </Tooltip>
          <ColorPicker
            id={'secondaryColor'}
            handleColorChange={color =>
              onChange({target: {
                  id: "secondaryColor",
                  value: color
                }})
            }
            startColor={secondaryColor}
            disableAlpha={true}
          />
        </FormControl>
        <FormControl style={{ float:"left", width: "16.6%"}}>
          <label style={{height: 40}}> {localization.get('form.interaction_color')} </label>
          <ColorPicker
            id={'interactionColor'}
            handleColorChange={color =>
              onChange({target: {
                  id: "interactionColor",
                  value: color
                }})
            }
            startColor={interactionColor}
          />
        </FormControl>
        <FormControl style={{ float:"left", width: "16.6%"}}>
          <label style={{height: 40}}> {localization.get('form.footer_color')} </label>
          <ColorPicker
            id={'footerColor'}
            handleColorChange={color =>
              onChange({target: {
                  id: "footerColor",
                  value: color
                }})
            }
            startColor={footerColor}
          />
        </FormControl>
      </div>
      <h4>{localization.get('form.characters')}</h4>
      <div style={{width: "100%", float: "left"}}>
        <div style={{ float:"left", width: "33%", padding: "0 30px 0 0"}}>
          <span style={{color: "#97877f"}}>{localization.get('form.font_family')}</span>

          <AsyncSelect
            styles={{ menu: base => ({ ...base, zIndex: 10 }) }}
            value={mainFont}
            onChange={value =>
              onChange({target: {
                  id: "mainFont",
                  value: value
                }})
            }
            options={fonts}
            noOptionsMessage={() => localization.get('no_options')}
            loadOptions={(inputValue, callback) => {
              callback(fonts.filter(i =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
              ))
            }}
            defaultOptions={fonts.sort((a, b) => a.label.localeCompare(b.label))}
          />
        </div>
        <FormControl style={{ float:"left", width: "16.6%"}}>
          <Tooltip title={localization.get('form.main_color_font_tooltip')} placement='top'>
            <label style={{height: 40}}> {localization.get('form.main_color_font')} </label>
          </Tooltip>
          <ColorPicker
            id={'mainFontColor'}
            handleColorChange={color =>
              onChange({target: {
                  id: "mainFontColor",
                  value: color
                }})
            }
            startColor={mainFontColor}
          />
        </FormControl>
        <FormControl style={{ float:"left", width: "16.6%"}}>
          <Tooltip title={localization.get('form.secondary_font_color_tooltip')} placement='top'>
            <label style={{height: 40}}> {localization.get('form.secondary_font_color')} </label>
          </Tooltip>
          <ColorPicker
            id={'secondaryFontColor'}
            handleColorChange={color =>
              onChange({target: {
                  id: "secondaryFontColor",
                  value: color
                }})
            }
            startColor={secondaryFontColor}
          />
        </FormControl>
        <FormControl style={{ float:"left", width: "16.6%"}}>
          <label style={{height: 40}}> {localization.get('form.footer_font_color')} </label>
          <ColorPicker
            id={'footerFontColor'}
            handleColorChange={color =>
              onChange({target: {
                  id: "footerFontColor",
                  value: color
                }})
            }
            startColor={footerFontColor}
          />
        </FormControl>
        </div>
        {channel && channel.use_floating_button && 
          <div>
            <h4 style={{color: "#97877f"}}>{localization.get('form.floating_button')}</h4>
            <FormControl style={{ float:"left", width: "16.6%"}}>
              <label style={{height: 40}}> {localization.get('home_editor.background_color')} </label>
              <ColorPicker
                id={'floatingButtonBackgroundColor'}
                handleColorChange={color =>
                  onChange({target: {
                      id: "floatingButtonBackgroundColor",
                      value: color
                    }})
                }
                startColor={floatingButtonBackgroundColor}
              />
            </FormControl>
            <FormControl style={{ float:"left", width: "16.6%"}}>
              <label style={{height: 40}}> {localization.get('home_editor.border_color')} </label>
              <ColorPicker
                id={'floatingButtonBorderColor'}
                handleColorChange={color =>
                  onChange({target: {
                      id: "floatingButtonBorderColor",
                      value: color
                    }})
                }
                startColor={floatingButtonBorderColor}
              />
            </FormControl>
          </div>
      }
      </div>
    </div>
    <br/>
    <br/>
        <div style={{position: "relative", float: "left", width: "100%"}}>
          <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creator-element', 'arrow-element')}>
            {localization.get('content_creator.element')}
            <ExpandLess style={{display: "none",position:"absolute", right:15, top:12}} id={"arrow-element-up"}/>
            <ExpandMore style={{display: "block", position:"absolute", right:15, top:12}} id={"arrow-element-down"}/>
          </h3>
          <div style={{width: "100%", display: "none"}} id={'content-creator-element'} >

          <h4 style={{width: "100%", float: "left"}}>{localization.get('form.home_margin')}</h4>
          <div style={{width: "100%", float: "left"}}>
          <TextValidator
              style={{ marginBottom: "20px", marginTop: 10, float:"left" , width: "20%", paddingRight: 30}}
              id="homePadding"
              label={localization.get('form.home_padding')+"(px)"}
              name="homePadding"
              value={homePadding}
              type={"number"}
              onChange={onChange}
            />
          </div>
          <h4 style={{width: "100%", float: "left"}}>{localization.get('form.titles')}</h4>
          <div style={{width: "100%", float: "left"}}>
            <div style={{ float:"left", width: "30%", paddingRight: 30}}>
              <span style={{color: "#97877f"}}>{localization.get('form.font_family')}</span>
              <AsyncSelect
                styles={{ menu: base => ({ ...base, zIndex: 10 }) }}
                value={titleFont}
                onChange={value =>
                  onChange({target: {
                      id: "titleFont",
                      value: value
                    }})
                }
                options={fonts}
                noOptionsMessage={() => localization.get('no_options')}
                loadOptions={(inputValue, callback) => {
                  callback(fonts.filter(i =>
                    i.label.toLowerCase().includes(inputValue.toLowerCase())
                  ))
                }}
                defaultOptions={fonts.sort((a, b) => a.label.localeCompare(b.label))}
              />
            </div>
            <TextValidator
              style={{ marginBottom: "20px", marginTop: 10, float:"left" , width: "20%", paddingRight: 30}}
              id="titleSize"
              label={localization.get('form.font_size')+"(px)"}
              name="titleSize"
              value={titleSize}
              type={"number"}
              onChange={onChange}
            />
            <FormControl style={{ float:"left", width: "25%"}}>
              <label style={{height: 40}}> {localization.get('form.color_text')} </label>
              <ColorPicker
                id={'titleColor'}
                handleColorChange={color =>
                  onChange({target: {
                      id: "titleColor",
                      value: color
                    }})
                }
                startColor={titleColor}
              />
            </FormControl>
            <div style={{ float:"left", width: "25%", paddingRight: 30}}>
              <span style={{color: "#97877f"}}>{localization.get('form.font_weight')}</span>
              <Select
                styles={{ menu: base => ({ ...base, zIndex: 10 }) }}
                value={titleWeight}
                onChange={value =>
                  onChange({target: {
                      id: "titleWeight",
                      value: value
                    }})
                }
                options={weights}
              />
            </div>
          </div>

          <br/>
          <br/>
          <h4>{localization.get('form.title_cards')}</h4>
          <div style={{width: "100%", float: "left"}}>
            <div style={{ float:"left", width: "30%", paddingRight: 30}}>
              <span style={{color: "#97877f"}}>{localization.get('form.font_family')}</span>

              <AsyncSelect
                styles={{ menu: base => ({ ...base, zIndex: 10 }) }}
                value={textFont}
                onChange={value =>
                  onChange({target: {
                      id: "textFont",
                      value: value
                    }})
                }
                options={fonts}
                noOptionsMessage={() => localization.get('no_options')}
                loadOptions={(inputValue, callback) => {
                  callback(fonts.filter(i =>
                    i.label.toLowerCase().includes(inputValue.toLowerCase())
                  ))
                }}
                defaultOptions={fonts.sort((a, b) => a.label.localeCompare(b.label))}
              />
            </div>
            <TextValidator
              style={{ marginBottom: "20px", marginTop: 10, float:"left", width: "20%", paddingRight: 30}}
              id="containerTitleSize"
              label={localization.get('form.font_size')+"(px)"}
              name="containerTitleSize"
              value={containerTitleSize}
              type={"number"}
              onChange={onChange}
            />
            <FormControl style={{ float:"left", width: "10%"}}>
              <label style={{marginBottom: 8,marginTop: 2}}> {localization.get('form.color_text')} </label>
              <ColorPicker
                id={'containerTitleColor'}
                handleColorChange={color =>
                  onChange({target: {
                    id: "containerTitleColor",
                    value: color
                    }})
                }
                startColor={containerTitleColor}
              />
            </FormControl>
            <FormControl style={{ float:"left", width: "15%"}}>
              <label style={{marginBottom: 8,marginTop: 2}}> {localization.get('form.color_hover_text')} </label>
              <ColorPicker
                id={'containerTitleColorHover'}
                handleColorChange={color =>
                  onChange({target: {
                      id: "containerTitleColorHover",
                      value: color
                    }})
                }
                startColor={containerTitleColorHover}
              />
            </FormControl>
            <div style={{ float:"left", width: "25%", paddingRight: 30}}>
              <span style={{color: "#97877f"}}>{localization.get('form.font_weight')}</span>
              <Select
                styles={{ menu: base => ({ ...base, zIndex: 10 }) }}
                value={containerTitleWeight}
                onChange={value =>
                  onChange({target: {
                      id: "containerTitleWeight",
                      value: value
                    }})
                }
                options={weights}
              />
            </div>
          </div>

          <br/>
          <br/>
          <h4>{localization.get('form.conteiner_description')}</h4>
          <div style={{width: "100%", float: "left"}}>
            <TextValidator
              style={{ marginBottom: "20px", marginTop: 10, float:"left", width: "25%", paddingRight: 30 }}
              id="containerDescriptionSize"
              label={localization.get('form.font_size')+"(px)"}
              name="containerDescriptionSize"
              value={containerDescriptionSize}
              type={"number"}
              onChange={onChange}
            />
            <FormControl style={{ float:"left", width: "16.6%"}}>
              <label style={{height: 40}}> {localization.get('form.element_background')} </label>
              <ColorPicker
                id={'elementBackground'}
                handleColorChange={color =>
                  onChange({target: {
                      id: "elementBackground",
                      value: color
                    }})
                }
                startColor={elementBackground}
              />
            </FormControl>
            <FormControl style={{ float:"left", width: "16.6%"}}>
              <label style={{height: 40}}> {localization.get('form.color_text')} </label>
              <ColorPicker
                id={'containerDescriptionColor'}
                handleColorChange={color =>
                  onChange({target: {
                      id: "containerDescriptionColor",
                      value: color
                    }})
                }
                startColor={containerDescriptionColor}
              />
            </FormControl>
            <FormControl style={{ float:"left", width: "16.6%"}}>
              <label style={{height: 40}}> {localization.get('form.color_hover_text')} </label>
              <ColorPicker
                id={'containerDescriptionColorHover'}
                handleColorChange={color =>
                  onChange({target: {
                      id: "containerDescriptionColorHover",
                      value: color
                    }})
                }
                startColor={containerDescriptionColorHover}
              />
            </FormControl>
            <div style={{ float:"left", width: "25%", paddingRight: 30}}>
              <span style={{color: "#97877f"}}>{localization.get('form.font_weight')}</span>
              <Select
                styles={{ menu: base => ({ ...base, zIndex: 10 }) }}
                value={containerDescriptionWeight}
                onChange={value =>
                  onChange({target: {
                      id: "containerDescriptionWeight",
                      value: value
                    }})
                }
                options={weights}
              />
            </div>
            <h4 style={{width: "100%", float: "left"}}>{localization.get('form.buttons')}</h4>
            <div style={{ float:"left", width: "33%", padding: "0 30px 0 0"}}>
              <span style={{color: "#97877f"}}>{localization.get('form.fav_icon')}</span>
              <Select
                styles={{ menu: base => ({ ...base, zIndex: 10 }) }}
                id={"favIcon"}
                value={favIcon}
                onChange={value =>
                  onChange({target: {
                      id: "favIcon",
                      value: value
                    }})
                }
                options={icons}
              />
            </div>
            <FormControl style={{ float: "left", position: "relative"}}>
              <label> {localization.get('form.show_more_button')} </label>
              <RadioGroup
                name="showMoreButton"
                value={showMoreButton}
                onChange={e =>
                  onChange({target: {
                      id: "showMoreButton",
                      value: e.target.value
                    }})
                }
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="rounded" control={<Radio color="default"/>} label={localization.get("form.rounded")}/>
                <FormControlLabel value="square" control={<Radio color="default"/>} label={localization.get("form.square")}/>
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div style={{position: "relative", float: "left", width: "100%"}}>
          <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creator-buttons', 'arrow-buttons')}>
            {localization.get('navbar.styles')}
            <ExpandLess style={{display: "none",position:"absolute", right:15, top:12}} id={"arrow-buttons-up"}/>
            <ExpandMore style={{display: "block", position:"absolute", right:15, top:12}} id={"arrow-buttons-down"}/>
          </h3>
          <div style={{width: "100%", display: "none"}} id={'content-creator-buttons'} >
            <h4 style={{width: "100%", float: "left"}}>{localization.get('navbar.view_type')}</h4>
            <FormControl style={{float: "left", position: "relative", width: "auto"}}>
              <RadioGroup
                name="navbarCenterMode"
                value={navbarCenterMode}
                onChange={e =>
                  onChange({target: {
                      id: "navbarCenterMode",
                      value: e.target.value
                    }})
                }
                style={{ position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="0" control={<Radio color="default"/>} label={localization.get("default")}/>
                <FormControlLabel value="1" control={<Radio color="default"/>} label={localization.get("navbar.center_mode")}/>
              </RadioGroup>
            </FormControl>
            {channel && channel.use_tools &&
              <FormControlLabel
                control={
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={showNavbarMenu}
                    onChange={() => onChange({target: {value: !showNavbarMenu, id: "showNavbarMenu"}})}
                  />
                }
                label={localization.get('navbar.show_menu')}
              />
            }
            <h4 style={{width: "100%", float: "left"}}>{localization.get('texts')}</h4>
            <TextValidator
              style={{ marginBottom: "20px", marginTop: 10, float:"left", width: "33%", paddingRight: 30 }}
              id="navbarButtonSize"
              label={localization.get('form.navbar_button_font_size')+"(px)"}
              name="navbarButtonSize"
              value={navbarButtonSize}
              type={"number"}
              onChange={onChange}
            />
            <FormControl style={{ float:"left", width: "10%"}}>
              <label style={{height: 40}}> {localization.get('header.colors')}</label>
              <ColorPicker
                id={'navbarFontColor'}
                handleColorChange={color =>
                  onChange({target: {
                      id: "navbarFontColor",
                      value: color
                    }})
                }
                startColor={navbarFontColor}
              />
            </FormControl>
            <h4 style={{width: "100%", float: "left"}}>{localization.get('Sidebar')}</h4>
            <div style={{width: "100%", float: "left", display: "flex", flexWrap: "wrap"}}>
            <FormControl style={{width: "30%"}}>
                <label style={{height: 40}}> {localization.get('form.sidebar_font_color')} </label>
                <ColorPicker
                  id={'sidebarFontColor'}
                  handleColorChange={color =>
                    onChange({target: {
                        id: "sidebarFontColor",
                        value: color
                      }})
                  }
                  startColor={sidebarFontColor}
                />
              </FormControl>
              <FormControl style={{width: "30%"}}>
                <label style={{height: 40}}> {localization.get('form.sidebar_background_color')} </label>
                <ColorPicker
                  id={'sidebarBackgroundColor'}
                  handleColorChange={color =>
                    onChange({target: {
                        id: "sidebarBackgroundColor",
                        value: color
                      }})
                  }
                  startColor={sidebarBackgroundColor}
                />
              </FormControl>
              {channel && !!channel.navbar_tabs &&
                <>
                  <FormControl style={{width: "30%"}}>
                    <label style={{height: 40}}> {localization.get('form.sidebar_button_font_color')} </label>
                    <ColorPicker
                      id={'sidebarButtonFontColor'}
                      handleColorChange={color =>
                        onChange({target: {
                            id: "sidebarButtonFontColor",
                            value: color
                          }})
                      }
                      startColor={sidebarButtonFontColor}
                    />
                  </FormControl>
                  <FormControl style={{width: "30%"}}>
                    <label style={{height: 40}}> {localization.get('form.sidebar_button_background_color')} </label>
                    <ColorPicker
                      id={'sidebarButtonBackgroundColor'}
                      handleColorChange={color =>
                        onChange({target: {
                            id: "sidebarButtonBackgroundColor",
                            value: color
                          }})
                      }
                      startColor={sidebarButtonBackgroundColor}
                    />
                  </FormControl>
                  <FormControl style={{width: "30%"}}>
                    <label style={{height: 40}}> {localization.get('form.sidebar_selector_font_color')} </label>
                    <ColorPicker
                      id={'sidebarSelectorFontColor'}
                      handleColorChange={color =>
                        onChange({target: {
                            id: "sidebarSelectorFontColor",
                            value: color
                          }})
                      }
                      startColor={sidebarSelectorFontColor}
                    />
                  </FormControl>
                  <FormControl style={{width: "30%"}}>
                    <label style={{height: 40}}> {localization.get('form.sidebar_selector_background_color')} </label>
                    <ColorPicker
                      id={'sidebarSelectorBackgroundColor'}
                      handleColorChange={color =>
                        onChange({target: {
                            id: "sidebarSelectorBackgroundColor",
                            value: color
                          }})
                      }
                      startColor={sidebarSelectorBackgroundColor}
                    />
                  </FormControl>
                </>
              }
            </div>
            <h4 style={{width: "100%", float: "left"}}>{localization.get('live_button')}</h4>
            <div style={{width: "100%", float: "left"}}>
              <FormControl style={{ float:"left", width: "20%"}}>
                <label style={{height: 40}}> {localization.get('form.color')} </label>
                <ColorPicker
                  id={'liveButtonColor'}
                  handleColorChange={color =>
                    onChange({target: {
                        id: "liveButtonColor",
                        value: color
                      }})
                  }
                  startColor={liveButtonColor}
                />
              </FormControl>
              <FormControl style={{float: "left", position: "relative", width: "33%"}}>
                <span style={{color: "#97877f"}}>{localization.get('live_button.show_button')}</span>
                <RadioGroup
                  name="liveButtonIcon"
                  value={liveButtonIcon}
                  onChange={e =>
                    onChange({target: {
                        id: "liveButtonIcon",
                        value: e.target.value
                      }})
                  }
                  style={{ position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel value="show" control={<Radio color="default"/>} label={localization.get("show")}/>
                  <FormControlLabel value="hide" control={<Radio color="default"/>} label={localization.get("hide")}/>
                </RadioGroup>
              </FormControl>
              <FormControl style={{float: "left", position: "relative", width: "35%"}}>
                <label style={{color: "#97877f"}}> {localization.get('live_button.shape')} </label>
                <RadioGroup
                  name="liveButtonShape"
                  value={liveButtonShape}
                  onChange={e =>
                    onChange({target: {
                        id: "liveButtonShape",
                        value: e.target.value
                      }})
                  }
                  style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel value="rounded" control={<Radio color="default"/>} label={localization.get("form.rounded")}/>
                  <FormControlLabel value="square" control={<Radio color="default"/>} label={localization.get("form.square")}/>
                </RadioGroup>
                <br/>
                <br/>
              </FormControl>
            </div>
            <h4 style={{width: "100%", float: "left"}}>{localization.get('radio.live_radio_button')}</h4>
            <div style={{width: "100%", float: "left"}}>
              <FormControl style={{ float:"left", width: "20%"}}>
                <label style={{height: 40}}> {localization.get('form.color')} </label>
                <ColorPicker
                  id={'radioButtonColor'}
                  handleColorChange={color =>
                    onChange({target: {
                        id: "radioButtonColor",
                        value: color
                      }})
                  }
                  startColor={radioButtonColor}
                />
              </FormControl>
              <FormControl style={{float: "left", position: "relative", width: "33%"}}>
                <span style={{color: "#97877f"}}>{localization.get('radio.show_icon')}</span>
                <RadioGroup
                  name="radioButtonIcon"
                  value={radioButtonIcon}
                  onChange={e =>
                    onChange({target: {
                        id: "radioButtonIcon",
                        value: e.target.value
                      }})
                  }
                  style={{ position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel value="show" control={<Radio color="default"/>} label={localization.get("show")}/>
                  <FormControlLabel value="hide" control={<Radio color="default"/>} label={localization.get("hide")}/>
                </RadioGroup>
              </FormControl>
              <FormControl style={{float: "left", position: "relative", width: "35%"}}>
                <label style={{color: "#97877f"}}> {localization.get('radio.shape')} </label>
                <RadioGroup
                  name="radioButtonShape"
                  value={radioButtonShape}
                  onChange={e =>
                    onChange({target: {
                        id: "radioButtonShape",
                        value: e.target.value
                      }})
                  }
                  style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel value="rounded" control={<Radio color="default"/>} label={localization.get("form.rounded")}/>
                  <FormControlLabel value="square" control={<Radio color="default"/>} label={localization.get("form.square")}/>
                </RadioGroup>
                <br/>
                <br/>
              </FormControl>
            </div>
            <div style={{width: "100%", float: "left"}}>
              <h4 style={{width: "100%", float: "left"}}>{localization.get('sub_navbar')}</h4>
              <FormControl style={{ float:"left", width: "20%"}}>
                <label style={{height: 40}}> {localization.get('form.color')} </label>
                <ColorPicker
                  id={'subNavbarBackgroundColor'}
                  handleColorChange={color =>
                    onChange({target: {
                        id: "subNavbarBackgroundColor",
                        value: color
                      }})
                  }
                  startColor={subNavbarBackgroundColor}
                />
              </FormControl>
              <FormControl style={{ float:"left", width: "20%"}}>
                <label style={{height: 40}}> {localization.get('form.color_text')} </label>
                <ColorPicker
                  id={'subNavbarFontColor'}
                  handleColorChange={color =>
                    onChange({target: {
                        id: "subNavbarFontColor",
                        value: color
                      }})
                  }
                  startColor={subNavbarFontColor}
                />
              </FormControl>
            </div>
          <br/>
          <br/>
      </div>
    </div>
  <div style={{position: "relative", float: "left", width: "100%",  borderBottom: "1px solid #ccc"}}>
    <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creator-preferences', 'arrow-preferences')}>
      {localization.get('content_creator.styles')}
      <ExpandLess style={{display: "none",position:"absolute", right:15, top:12}} id={"arrow-preferences-up"}/>
      <ExpandMore style={{display: "block", position:"absolute", right:15, top:12}} id={"arrow-preferences-down"}/>
    </h3>
    <br/>

    <div style={{width: "100%", display: "none", marginBottom: 20}} id={'content-creator-preferences'} >
      <h4>{localization.get('title.colors')}</h4>
      <>
        <FormControl style={{float: "left", width: "16.6%"}}>
          <Tooltip title={localization.get('content_creator.title_color')} placement='top'>
            <label style={{height: 40}}> {localization.get('content_creator.title_color')} </label>
          </Tooltip>
          <ColorPicker
            id={'contentCreatorTitleColor'}
            handleColorChange={color =>
              onChange({
                target: {
                  id: "contentCreatorTitleColor",
                  value: color
                }
              })
            }
            startColor={contentCreatorTitleColor}
            disableAlpha={true}
          />
        </FormControl>
        <FormControl style={{float: "left", width: "16.6%"}}>
          <Tooltip title={localization.get('content_creator.description_color')} placement='top'>
            <label style={{height: 40}}> {localization.get('content_creator.description_color')} </label>
          </Tooltip>
          <ColorPicker
            id={'contentCreatorDescriptionColor'}
            handleColorChange={color =>
              onChange({
                target: {
                  id: "contentCreatorDescriptionColor",
                  value: color
                }
              })
            }
            startColor={contentCreatorDescriptionColor}
            disableAlpha={true}
          />
        </FormControl>
        <FormControl style={{float: "left", width: "16.6%"}}>
          <Tooltip title={localization.get('content_creator.creation_color')} placement='top'>
            <label style={{height: 40}}> {localization.get('content_creator.creation_color')} </label>
          </Tooltip>
          <ColorPicker
            id={'contentCreatorCreationColor'}
            handleColorChange={color =>
              onChange({
                target: {
                  id: "contentCreatorCreationColor",
                  value: color
                }
              })
            }
            startColor={contentCreatorCreationColor}
            disableAlpha={true}
          />
        </FormControl>
        <FormControl style={{float: "left", width: "16.6%"}}>
          <Tooltip title={localization.get('content_creator.editorial_background')} placement='top'>
            <label style={{height: 40}}> {localization.get('content_creator.editorial_background')} </label>
          </Tooltip>
          <ColorPicker
            id={'editorialBackgroundColor'}
            handleColorChange={color =>
              onChange({
                target: {
                  id: "editorialBackgroundColor",
                  value: color
                }
              })
            }
            startColor={editorialBackgroundColor}
            disableAlpha={true}
          />
        </FormControl>
        <FormControl style={{float: "left", width: "16.6%"}}>
          <Tooltip title={localization.get('content_creator.editorial_title_color')} placement='top'>
            <label style={{height: 40}}> {localization.get('content_creator.editorial_title_color')} </label>
          </Tooltip>
          <ColorPicker
            id={'editorialTitleColor'}
            handleColorChange={color =>
              onChange({
                target: {
                  id: "editorialTitleColor",
                  value: color
                }
              })
            }
            startColor={editorialTitleColor}
            disableAlpha={true}
          />
        </FormControl>

        <div style={{float: "left", width: "100%"}}>
          <h4>{localization.get('content_creator.page_style')}</h4>
          <FormControl>
            <RadioGroup
              name="useCanonicals"
              value={useCanonicals}
              id="useCanonicals"
              onChange={(e) => onChange({target: {id: 'useCanonicals', value: e.target.value}})}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="0" control={<Radio color="default"/>}
                                label={localization.get("content_creator.fullscreen")}/>
              <FormControlLabel value="1" control={<Radio color="default"/>}
                                label={localization.get("content_creator.not_fullscreen")}/>
            </RadioGroup>
          </FormControl>
        </div>

        <div style={{float: "left", width: "100%"}}>
          <h4>{localization.get("content_creator.cover_image_preferences")}</h4>
          <div style={{display: "flex", flexDirection: "row"}}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={editorialHideCoverImage}
                  onChange={() => onChange({target: {value: !editorialHideCoverImage, id: "editorialHideCoverImage"}})}
                />
              }
              label={localization.get('content_creator.show_cover_image')}
            />
             <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={socialFill}
                  onChange={() => onChange({target: {value: !socialFill, id: "socialFill"}})}
                />
              }
              label={localization.get('content_creator.social_fill')}
            />
          </div>
          <h4>{localization.get("content_creator.use_synopsis")}</h4>
          <div style={{display: "flex", flexDirection: "row"}}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={contentCreatorEditorialUseSynopsis}
                  onChange={() => onChange({target: {value: !contentCreatorEditorialUseSynopsis, id: "contentCreatorEditorialUseSynopsis"}})}
                />
              }
              label={localization.get('content_creator.use_synopsis_bis')}
            />
          </div>
        </div>
        <div style={{float: "left", width: "100%"}}>
          <h4>{localization.get("content_creator.social_position")}</h4>
          <div style={{display: "flex", flexDirection: "row"}}>
            <RadioGroup
                name="contentCreatorSocialPosition"
                value={contentCreatorSocialPosition}
                id="contentCreatorSocialPosition"
                onChange={(e) => onChange({target: {id: 'contentCreatorSocialPosition', value: e.target.value}})}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="default" control={<Radio color="default"/>}
                                  label={localization.get("content_creator.default")}/>
                <FormControlLabel value="alternative" control={<Radio color="default"/>}
                                  label={localization.get("content_creator.alternative")}/>
              </RadioGroup>
          </div>
        </div>
        <div style={{float: "left", width: "100%"}}>
          <h4>{localization.get("content_creator.show_more_button")}</h4>
          <div style={{display: "flex", flexDirection: "row"}}>
            <RadioGroup
                name="contentCreatorShowMoreButton"
                value={contentCreatorShowMoreButton}
                id="contentCreatorShowMoreButton"
                onChange={(e) => onChange({target: {id: 'contentCreatorShowMoreButton', value: e.target.value}})}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="use" control={<Radio color="default"/>}
                                  label={localization.get("content_creator.use")}/>
                <FormControlLabel value="not_use" control={<Radio color="default"/>}
                                  label={localization.get("content_creator.none")}/>
              </RadioGroup>
          </div>
        </div>
        
        <div style={{float: "left", width: "100%"}}>
          <h4>{localization.get("content_creator.share_position")}</h4>
          <div style={{display: "flex", flexDirection: "row"}}>
            <RadioGroup
                name="sharePosition"
                value={sharePosition}
                id="sharePosition"
                onChange={(e) => onChange({target: {id: 'sharePosition', value: e.target.value}})}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="none" control={<Radio color="default"/>}
                                  label={localization.get("content_creator.none")}/>
                <FormControlLabel value="top" control={<Radio color="default"/>}
                                  label={localization.get("content_creator.share_position_top")}/>
                <FormControlLabel value="bottom" control={<Radio color="default"/>}
                                  label={localization.get("content_creator.share_position_bottom")}/>
              </RadioGroup>
          </div>
        </div>
        

        <div style={{float: "left", width: "100%"}}>
          <h4>{localization.get("content_creator.container_background")}</h4>
          <div style={{display: "flex", flexDirection: "row"}}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={useContentCreatorBackgroundColor}
                  onChange={() => onUseContentCreatorBackgroundColorChange(!useContentCreatorBackgroundColor)}
                />
              }
              label={localization.get('content_creator.use_background_color')}
            />

            {useContentCreatorBackgroundColor && 
            <>
              <FormControl style={{float: "left", marginLeft: 10, marginRight: 20}}>
                <Tooltip title={localization.get('content_creator.background_color')} placement='top'>
                  <label style={{marginBottom: 10}}> {localization.get('content_creator.background_color')} </label>
                </Tooltip>
                <ColorPicker
                  id={'contentCreatorBackgroundColor'}
                  handleColorChange={color =>
                    onChange({
                      target: {
                        id: "contentCreatorBackgroundColor",
                        value: color
                      }
                    })
                  }
                  startColor={contentCreatorBackgroundColor}
                  disableAlpha={true}
                />
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={useContentCreatorBackgroundGradient}
                    onChange={() => onUseContentCreatorBackgroundGradientChange(!useContentCreatorBackgroundGradient)}
                  />
                }
                label={localization.get('content_creator.use_background_gradient')}
              />
            </>
            }
          </div>
        </div>
        <div style={{float: "left", width: "100%"}}>
          <h4>{localization.get('Caja traslucida materiales')}</h4>
          <FormControl>
            <RadioGroup
              name="contentCreatorShowBox"
              value={contentCreatorShowBox}
              id="contentCreatorShowBox"
              onChange={(e) => {
                if (e.target.value != contentCreatorShowBox && e.target.value  ==="show") onChange({target: {id: 'contentCreatorCardColor', value: "rgba(255, 255, 255, 0.4)"}})
                if (e.target.value != contentCreatorShowBox && e.target.value  ==="hide") onChange({target: {id: 'contentCreatorCardColor', value: "rgba(255, 255, 255, 0)"}})
                onChange({target: {id: 'contentCreatorShowBox', value: e.target.value}})
              }}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="show" control={<Radio color="default"/>}
                                label={localization.get('show')}/>
              <FormControlLabel value="hide" control={<Radio color="default"/>}
                                label={localization.get('hide')}/>
            </RadioGroup>
          </FormControl>
          {contentCreatorShowBox === 'show' &&
            <div style={{float: "left", width: "100%"}}>
              <FormControl style={{float: "left", width: "16.6%"}}>
                <Tooltip title={localization.get('content_creator.card_color')} placement='top'>
                  <label style={{height: 40}}> {localization.get('content_creator.card_color')} </label>
                </Tooltip>
                <ColorPicker
                  id={'contentCreatorCardColor'}
                  handleColorChange={color =>
                    onChange({
                      target: {
                        id: "contentCreatorCardColor",
                        value: color
                      }
                    })
                  }
                  startColor={contentCreatorCardColor}
                />
              </FormControl>
              <div style={{float: "left", width: "33%", paddingRight: 30}}>
                <span style={{color: "#97877f"}}>{localization.get('content_creator.card_shape')}</span>
                <Select
                  styles={{menu: base => ({...base, zIndex: 10})}}
                  value={contentCreatorCardShape}
                  onChange={value =>
                    onChange({
                      target: {
                        id: "contentCreatorCardShape",
                        value: value
                      }
                    })
                  }
                  options={[
                    {value: "rounded", label: localization.get('rounded')},
                    {value: "square", label: localization.get('square')},
                  ]}
                />
              </div>
            </div>
          }
        </div>

        <div style={{float: "left", width: "100%"}}>
          <h4>{localization.get('content_creator.carousel_border')}</h4>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <FormControl>
              <RadioGroup
                name="contentCreatorShowCreationBorder"
                value={contentCreatorShowCreationBorder}
                id="contentCreatorShowCreationBorder"
                onChange={(e) => {
                  onChange({target: {id: 'contentCreatorShowCreationBorder', value: e.target.value}})
                }}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="hide" control={<Radio color="default"/>}
                                  label={localization.get('hide')}/>
                <FormControlLabel value="show" control={<Radio color="default"/>}
                                  label={localization.get('show')}/>
              </RadioGroup>
            </FormControl>
            {contentCreatorShowCreationBorder == 'show' &&
              <FormControl style={{marginLeft: 10, marginRight: 20}}>
                <Tooltip title={localization.get('content_creator.carousel_bg_color')} placement='top'>
                  <label style={{marginBottom: 10}}> {localization.get('content_creator.carousel_bg_color')} </label>
                </Tooltip>
                <ColorPicker
                  id={'contentCreatorCreationCarouselBackgroundColor'}
                  handleColorChange={color =>
                    onChange({
                      target: {
                        id: "contentCreatorCreationCarouselBackgroundColor",
                        value: color
                      }
                    })
                  }
                  startColor={contentCreatorCreationCarouselBackgroundColor}
                  disableAlpha={true}
                />
              </FormControl>
            }
          </div>
        </div>

        <div style={{float: "left", width: "100%"}}>
          <h4>{localization.get('content_creator.creation_title_position')}</h4>
          <FormControl>
            <RadioGroup
              name="contentCreatorCreationInverted"
              value={contentCreatorCreationInverted}
              id="contentCreatorCreationInverted"
              onChange={(e) => onChange({target: {id: 'contentCreatorCreationInverted', value: e.target.value}})}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="up" control={<Radio color="default"/>}
                                label={localization.get('content_creator.material_up')}/>
              <FormControlLabel value="down" control={<Radio color="default"/>}
                                label={localization.get('content_creator.material_down')}/>
            </RadioGroup>
          </FormControl>
        </div>

        <div style={{float: "left", width: "100%"}}>
          <h4>{localization.get('content_creator.event_modal_position')}</h4>
          <FormControl>
            <RadioGroup
              name="contentCreatorEventsPosition"
              value={contentCreatorEventsPosition}
              id="contentCreatorEventsPosition"
              onChange={(e) => onChange({target: {id: 'contentCreatorEventsPosition', value: e.target.value}})}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="top" control={<Radio color="default"/>}
                                label={localization.get('content_creator.top')}/>
              <FormControlLabel value="bottom" control={<Radio color="default"/>}
                                label={localization.get('content_creator.bottom')}/>
            </RadioGroup>
          </FormControl>
        </div>

        <div style={{float: "left", width: "100%"}}>
          <h4>{localization.get('content_creator.format_subtitles')}</h4>
          <div style={{float: "left", width: "100%"}}>
            <TextValidator
              style={{ marginBottom: "20px", marginTop: 10, float:"left" , width: "33%", paddingRight: 30}}
              id="contentCreatorCreationSubtitleFontSize"
              label={localization.get('content_creator.subtitle_font_size')+"(px)"}
              name="contentCreatorCreationSubtitleFontSize"
              value={contentCreatorCreationSubtitleFontSize}
              type={"number"}
              onChange={onChange}
            />
            <TextValidator
              style={{ marginBottom: "20px", marginTop: 10, float:"left" , width: "33%", paddingRight: 30}}
              id="contentCreatorCreationSubtitleMargin"
              label={localization.get('content_creator.subtitle_margin')+"(px)"}
              name="contentCreatorCreationSubtitleMargin"
              value={contentCreatorCreationSubtitleMargin}
              type={"number"}
              onChange={onChange}
            />
          </div>
        </div>
        <div style={{float: "left", width: "100%"}}>
          <h4>{localization.get('content_creator.buy_button')}</h4>
          <div style={{float: "left", width: "100%"}}>
            <div style={{float: "left", width: "33%", paddingRight: 30}}>
              <span style={{color: "#97877f"}}>{localization.get('content_creator.buy_button.position')}</span>
              <Select
                styles={{menu: base => ({...base, zIndex: 10})}}
                value={contentCreatorBuyButtonPosition}
                onChange={value =>
                  onChange({
                    target: {
                      id: "contentCreatorBuyButtonPosition",
                      value: value
                    }
                  })
                }
                options={[
                  {value: "both", label: localization.get('content_creator.buy_button.both')},
                  {value: "description", label: localization.get('content_creator.buy_button.description')},
                  {value: "social", label: localization.get('content_creator.buy_button.social')}
                ]}
              />
            </div>
            <FormControl style={{float: "left", marginLeft: 10, marginRight: 20}}>
              <Tooltip title={localization.get('content_creator.buy_button.bg_color')} placement='top'>
                <label style={{marginBottom: 10}}> {localization.get('content_creator.buy_button.bg_color')} </label>
              </Tooltip>
              <ColorPicker
                id={'contentCreatorBuyButtonBackgroundColor'}
                handleColorChange={color =>
                  onChange({
                    target: {
                      id: "contentCreatorBuyButtonBackgroundColor",
                      value: color
                    }
                  })
                }
                startColor={contentCreatorBuyButtonBackgroundColor}
                disableAlpha={true}
              />
            </FormControl>
            <FormControl style={{float: "left", marginLeft: 10, marginRight: 20}}>
              <Tooltip title={localization.get('content_creator.buy_button.font_color')} placement='top'>
                <label style={{marginBottom: 10}}> {localization.get('content_creator.buy_button.font_color')} </label>
              </Tooltip>
              <ColorPicker
                id={'contentCreatorBuyButtonFontColor'}
                handleColorChange={color =>
                  onChange({
                    target: {
                      id: "contentCreatorBuyButtonFontColor",
                      value: color
                    }
                  })
                }
                startColor={contentCreatorBuyButtonFontColor}
                disableAlpha={true}
              />
            </FormControl>
          </div>
        </div>
        <div style={{float: "left", width: "100%", marginTop: 15}}>
          <h4>{localization.get('content_creator.event_button')}</h4>
          <TextField
            style={{ width: "33%", float: "left", }}
            name="contentCreatorEventButtonText"
            id="contentCreatorEventButtonText"
            label={localization.get('content_creator.event_button.text')}
            autoComplete={false}
            value={contentCreatorEventButtonText}
            onChange={onChange}
          />
          <FormControl style={{float: "left", marginLeft: 10, marginRight: 20}}>
            <Tooltip title={localization.get('content_creator.buy_button.font_color')} placement='top'>
              <label style={{marginBottom: 10}}> {localization.get('content_creator.buy_button.font_color')} </label>
            </Tooltip>
            <ColorPicker
              id={'contentCreatorEventButtonColor'}
              handleColorChange={color =>
                onChange({
                  target: {
                    id: "contentCreatorEventButtonColor",
                    value: color
                  }
                })
              }
              startColor={contentCreatorEventButtonColor}
              disableAlpha={true}
            />
          </FormControl>
          <FormControl style={{float: "left", marginLeft: 10, marginRight: 20}}>
            <Tooltip title={localization.get('content_creator.buy_button.font_color')} placement='top'>
              <label style={{marginBottom: 10}}> {localization.get('content_creator.buy_button.bg_color')} </label>
            </Tooltip>
            <ColorPicker
              id={'contentCreatorEventButtonBackgroundColor'}
              handleColorChange={color =>
                onChange({
                  target: {
                    id: "contentCreatorEventButtonBackgroundColor",
                    value: color
                  }
                })
              }
              startColor={contentCreatorEventButtonBackgroundColor}
              disableAlpha={true}
            />
          </FormControl>
        </div>
      </>
    </div>
      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5,margin: "0 0 20px", cursor: "pointer"}} onClick={() => hideDivs('other-preferences', 'arrow-other')}>
          {localization.get('form.other_preferences')}
          <ExpandLess style={{display: "none",position:"absolute", right:15, top:12}} id={"arrow-other-up"}/>
          <ExpandMore style={{display: "block", position:"absolute", right:15, top:12}} id={"arrow-other-down"}/>
      </h3>
      <div style={{width: "100%", display: "none"}} id={'other-preferences'} >
        <br/>
        <h4>{localization.get('form.favorite_template')}</h4>
        <Select
          id={"favoriteTemplate"}
          name="favoriteTemplate"
          value={favoriteTemplate}
          onChange={value =>
            onChange({
              target: {
                id: "favoriteTemplate",
                value: value
              }
            })
          }
          options={favoriteTemplates}
        />
        <h4>Login</h4>
        <FormControlLabel
          control={
            <Checkbox
              style={{color: "#4cc2d5"}}
              checked={useBlurOnLogin}
              onChange={() => onChangeUseBlurOnLogin(!useBlurOnLogin)}
            />
          }
          label={localization.get('channel.login_background_blur')}
        />
        <h4>{localization.get('graphboard.materials')}</h4>
        <FormControlLabel
          control={
            <Checkbox
              style={{color: "#4cc2d5"}}
              checked={creationHideCreator}
              onChange={() => onChange({
                target: {
                  id: "creationHideCreator",
                  value: !creationHideCreator
                }
              })}
            />
          }
          label={localization.get('form.creation_hide_creator')}
        />
        
        <h4>{localization.get("form.mobile_font")}</h4>
        <TextValidator
          style={{ width: "100%" }}
          id="mobileFontResize"
          validators={['minNumber:0', 'maxNumber:100']}
          type="number"
          label={`${localization.get("form.mobile_resize_percentage")} %`}
          name="mobileFontResize"
          value={mobileFontResize}
          onChange={onChange}
        />
        <br/>
        <br/>
      </div>
    </div>
  </div>
</div>
)}

export default CustomizationForm;


const hideDivs = (divId, ArrowId) => {
  let x = document.getElementById(divId);
  let up = document.getElementById(`${ArrowId}-up`);
  let down = document.getElementById(`${ArrowId}-down`);

  if (x.style.display === "none") {
    x.style.display = "inline-block";
    down.style.display = "none";
    up.style.display = "block";
  } else {
    x.style.display = "none";
    down.style.display = "block";
    up.style.display = "none";
  }
}