import {post, put} from "../utils";
import {makeFormData, makeJsonData} from "./utils";

const addPutMethodToForm = form => {
  form.append("_method", "PUT");
  return form;
};

export default host => (
  clientId,
  {
    name,
    type,
    categoryId,
    colorId,
    image,
    cuit,
    address,
    countryId,
    selectedBlogLocations,
    showBanner,
    licensePlan,
    excludeFromReports,
    demo,
    permissions,
    managedBy,
    prefix,
    reelUrl,
    useLogo,
    catalogsIds,
    socialLinks,
    phone,
    email,
    reelsLogo,
    translations,
    keywordsSEO,
    titleSEO,
    descriptionSEO,
    showAbout,
    contentCreatorTypes,
    canManageClients,
    searchPreferences,
    blacklistTypeContentCreatorTypes,
    allowFakeLivestreams,
    secure,
    hasOwnTags,
    channelId,
    transcodeVideos,
    useAccessLevel,
    accessLevel,
  }
) =>
  put(
    `${host}/clients/${clientId}`,
      makeJsonData({
        name,
        type,
        categoryId,
        colorId,
        image,
        cuit,
        address,
        countryId,
        selectedBlogLocations,
        showBanner,
        licensePlan,
        excludeFromReports,
        demo,
        permissions,
        managedBy,
        prefix,
        reelUrl,
        useLogo,
        catalogsIds,
        socialLinks,
        phone,
        email,
        reelsLogo,
        translations,
        keywordsSEO,
        titleSEO,
        descriptionSEO,
        showAbout,
        contentCreatorTypes,
        canManageClients,
        searchPreferences,
        blacklistTypeContentCreatorTypes,
        allowFakeLivestreams,
        secure,
        hasOwnTags,
        channelId,
        transcodeVideos,
        useAccessLevel,
        accessLevel,
        accessLevel,
      })
  ).then(response => response.json())
  .then(client => {
    return post(`${host}/clients/${client.data.id}`, addPutMethodToForm(makeFormData({
      image,
      socialLinks,
      translations
    })))
      .then(response => response.json())
  });
