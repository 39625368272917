import {
  Grid,
  MenuItem,
  Menu,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import { ArrowUpward, SortByAlpha } from "@material-ui/icons";
import Search from "@material-ui/icons/Search";
import _ from 'lodash';
import qs from "qs";
import React, {Component} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import 'react-input-range/lib/css/index.css';
import { request } from "../../../api-client";
import {
  getActiveChannel,
  getActiveClient, getContentCreationFavorites, getMainTranslation,
  getStoredChannel,
} from "../../../api-client/core/authentication/utils";
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";
import Button from "../../../components/CustomButtons/Button";
import CreationItem from "../../../components/Letflow/CreationItem";
import ContentCreationPlayer from "../../../components/Letflow/NewContentCreationPlayer";
import ShareOnSocials from "../../../components/Letflow/ShareOnSocials";
import GlobalSnackbar, { GlobalSnackbarTypes } from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import TagSelection from "../../../components/Letflow/TagSelection/TagSelectionSmartSearch.jsx";
import localization from "../../../config/localization";
import EventManager, { events } from "../../../utils/EventManager";
import "../Home.css";
import "./index.css";
import analyticsInstance from "../../../analytics/analyticsInstance";


class SmartSearchContentCreatorCreations extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tagGroups: [],
      searchResultsTags: [],
      contentCreatorId: props.contentCreatorId,
      type: props.type.slug,
      showTags: false,
      pageReady: false,
      favorites: getContentCreationFavorites(),
      channel: props.channel || null,
      lastPage: 0,
      currentPage: 0,
      showContentCreationDialog: false,
      currentPlayingContent: {},
      showShareDialog: false,
      sharingCreation: 1,
      shareUrl: "",
      searchInput: "",
      onReady: props.onReady,
      orderBy: 'order',
      sortType: 'asc',
      showUpButton: false,
    }
  }

  componentDidMount = () => {
    this.requestTagGroups()
      .then(() => this.requestAvailableTags())
      .then(() => this.clearUnusedTags())
      .then(() => this.requestTracks({firstRequest: true}))
      .then(() => this.setState({showTags: true}))

    EventManager.getInstance().subscribe(events.ADD_TO_FAVORITE_BUTTON_PRESS, this.addToFavorites)
    EventManager.getInstance().subscribe(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromFavorites)
    this.lastScrollTop = 0;
    this.handleScroll = this.onScroll.bind(this);
    window.addEventListener('scroll', this.handleScroll, true);
  }

  onScroll = (e) => {
    if (!e || !e.target) return;
    let element = null;
    if (this.props.modal && e.target.id === 'scrollableDiv') {
      element = e.target;
    } else if (!this.props.modal && e.target.scrollingElement) {
      element = e.target.scrollingElement;
    }
    if (element) {
      if(element.scrollTop >200 && element.scrollTop < this.lastScrollTop) {
        !this.state.showUpButton && this.setState({showUpButton: this.state.searchResultsTags && this.state.searchResultsTags.length >= 12})
      }else {
        this.state.showUpButton && this.setState({showUpButton: false})
      }
      this.lastScrollTop = element.scrollTop;
    }
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.ADD_TO_FAVORITE_BUTTON_PRESS, this.addToFavorites)
    EventManager.getInstance().unsubscribe(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromFavorites)
    window.removeEventListener('scroll', this.handleScroll);
  }

  onFavorite = (creation) => {
    if(!creation.favorite) {
      this.addToFavorites(creation.id)
    } else  {
      this.removeFromFavorites(creation.id)
    }
  }

  addToFavorites = id => {
    let onAdded = () => {
      this.state.favorites.push(id);
      this.setState(state => ({
        ...state,
        favorites: this.state.favorites,
        searchResultsTags: state.searchResultsTags.map(creation => {
          if (creation.id === id) {
            return ({...creation, favorite: true})
          }
          return creation
        })
      }))
    }
    if (getActiveChannel()) {
      request.contentCreationChannelFavorite.add(id)
        .then(() => onAdded())
    }else {
      request.contentCreationFavorite.add(id)
        .then(() => onAdded())
    }
  }

  removeFromFavorites = id => {
    let onRemoved = () => {
      if (this.state.favorites.indexOf(id) > -1) {
        this.state.favorites.splice(this.state.favorites.indexOf(id), 1)
      }
      this.setState(state => ({
        ...state,
        favorites: this.state.favorites,
        searchResultsTags: state.searchResultsTags.map(creation => {
          if (creation.id === id) {
            return ({...creation, favorite: false})
          }
          return creation
        })
      }))
    }
    if (getActiveChannel()) {
      request.contentCreationChannelFavorite.remove(id)
        .then(() => onRemoved())
    }else {
      request.contentCreationFavorite.remove(id)
        .then(() => onRemoved())
    }
  }

  requestTagGroups = () =>  {
    let paginationConfig = {filterBy: [
        { column: 'content_creations', filter: true},
        {column: 'inheritable', filter: "true"},
        { column: 'show_on_smart_search', filter: '1'},]}
    if (this.state.type) {
      paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([
        { column: 'type', filter: `${this.state.type}`}
        ])}
    }
    getActiveChannel() && paginationConfig.filterBy.push({column: 'channel_id', filter: `${getActiveChannel()}`})
    return request.tagGroup.getAll(paginationConfig, 'material')
    .then(tagGroups => {
      let availableTagGroups = tagGroups.filter(tagGroup => tagGroup.tags.length)
      if(getActiveChannel() && getStoredChannel().tagGroups.length) {
        getStoredChannel().tagGroups.filter(tg => tg.target === 'material').forEach(channelTg => {
          availableTagGroups.forEach(tg => {
            if (tg.id === channelTg.id) {
              tg.order = channelTg.order
              tg.view_type = 'dropdown'
            }
          })
        })
      }

      this.setState({ tagGroups: availableTagGroups.sort((a,b) => a.order - b.order)})
    })
  }

  makeSmartSearchTagSelector = () => {
    return (
      <div style={{marginTop: 30}}>
        <div style={{height: 'auto', display: "flex", flexDirection:"column", width: "100%"}}>
          <div style={{display: "flex", maxWidth: "100%", marginBottom: 30, width: "100%", flexDirection: 'row', justifyContent: "space-between", alignItems: "center"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <TextField
                inputProps={{
                  ref: input => {
                    this.searchInput = input;
                  },
                  className: "audiovisual-tag-selector-search",
                  spellCheck: false,
                  style:{
                    color: "var(--secondary-font-color, #2d2d2d)",
                    borderBottom: "2px solid var(--secondary-font-color, #2d2d2d)",
                    width: 250,
                    maxWidth: 250,
                    position: "relative",
                    float: "left",
                  }
                }}
                InputProps={{disableUnderline: true}}
                autoComplete={false}
                placeholder={localization.get("searchbar.contents")}
                value={this.state.searchInput}
                onChange={e => this.setState({searchInput: e.target.value})}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    this.requestTracks();
                  }
                }}
                style={{color: "var(--secondary-font-color, black)"}}
              />
              {window.innerWidth < 600 &&
                <Search style={{marginTop: 7, marginLeft: 7, color: "var(--secondary-font-color, #2d2d2d)"}}
                  onClick={this.requestTracks}/>
              }
            </div>
            <IconButton 
              style={{color: "var(--secondary-font-color, #2d2d2d)"}} 
              onClick={e => this.setState({anchorEl: e.currentTarget})}>
                <Tooltip placement="bottom" title={localization.get("smart_search.creations.order")}>
                  <SortByAlpha style={{color: "var(--secondary-font-color, #2d2d2d)"}}/>
                </Tooltip>
            </IconButton>
            <Menu
              id="sort-menu"
              anchorEl={this.state.anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(this.state.anchorEl)}
              onClose={() => this.setState({anchorEl: false})}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem selected={this.state.orderBy == 'order' && this.state.sortType == 'asc'} onClick={() => this.setState({orderBy: 'order', sortType: 'asc', anchorEl: false}, this.requestTracks)}><span>{localization.get('smart_search.creations.order.relevance')}</span></MenuItem>
              <MenuItem selected={this.state.orderBy == 'created_at' && this.state.sortType == 'desc'} onClick={() => this.setState({orderBy: 'created_at', sortType: 'desc', anchorEl: false}, this.requestTracks)}><span>{localization.get('smart_search.creations.order.newest')}</span></MenuItem>
              <MenuItem selected={this.state.orderBy == 'created_at' && this.state.sortType == 'asc'} onClick={() => this.setState({orderBy: 'created_at', sortType: 'asc', anchorEl: false}, this.requestTracks)}><span>{localization.get('smart_search.creations.order.oldest')}</span></MenuItem>
              <MenuItem selected={this.state.orderBy == 'title' && this.state.sortType == 'asc'} onClick={() => this.setState({orderBy: 'title', sortType: 'asc', anchorEl: false}, this.requestTracks)}><span>{localization.get('form.title')} A-Z</span></MenuItem>
              <MenuItem selected={this.state.orderBy == 'title' && this.state.sortType == 'desc'} onClick={() => this.setState({orderBy: 'title', sortType: 'desc', anchorEl: false}, this.requestTracks)}><span>{localization.get('form.title')} Z-A</span></MenuItem>
            </Menu>
          </div>
          {this.state.showTags &&
            <TagSelection horizontal={true} audiovisual={true} onSelectedTagsChanged={this.handleSmartSearchSelectedTagsChanged} data={this.state.tagGroups} notWriteQueryString={false}/>
          }  
        </div>
      </div>
    )
  }

  getSelectedTags = () =>  this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected')
  getUnselectedTags = () =>  this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'unselected')

  handleSmartSearchSelectedTagsChanged = (selectedTags, unselected, lastTagId, checkAvailableTags = true, writeQueryParams = true) => {
    this.requestAvailableTags({lastTagId})
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.map(tag => ({
          ...tag,
          state: selectedTags.includes(tag.id) ? 'selected' : unselected.includes(tag.id) ? 'unselected' : tag.state
        }))
      }))
    }), () => {
      this.requestTracks();
    })
  }

  clearUnusedTags = () => {
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.filter(tag => tag.state !== 'disable')
      })).filter(tg => tg.tags.length > 0)
    }))
  }

  requestAvailableTags = ({
    clientId = getActiveClient(),
    type = this.state.type,
    unselectedSearchTags = this.getUnselectedSearchTags(),
    searchTags = this.getSearchTags(),
    lastTagId = null,
    channelId = this.state.channel ? this.state.channel.id : null,
    contentCreatorId = this.state.contentCreatorId
  } = {}) => {
    return request.tag.getAvailableTagsForClient({
      clientId,
      tagGroupType: 'contentCreation',
      contentCreatorType: type,
      isCreation: true,
      tags: searchTags,
      notTags: unselectedSearchTags,
      lastTagId,
      channelId,
      contentCreatorId
    }).then(availableTags => {
      const tags = _.flatMap(this.state.tagGroups, x => x.tags)
      tags.forEach(x => x.state = x.state === 'selected' ? 'selected' : x.state === 'unselected' ? 'unselected' : availableTags.map(x => x.id).includes(x.id) ? 'available' : 'disable')
      this.setState({ tagGroups: this.state.tagGroups })
      return availableTags
    })
  }

  disableAllTagsForRequest = () => {
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.map(tag => ({
          ...tag,
          state: 'disable'
        }))
      }))
    }))
  }

  getSearchTags = () => this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected').map(tag => tag.id)

  getUnselectedSearchTags = () => this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'unselected').map(tag => tag.id)

  handleSmartSearch = () => {
    if (this.getSelectedTags().concat(this.getUnselectedTags()).length > 0) {
      this.requestTracks()
    } else {
      GlobalSnackbar.show({message: localization.get('home.smart_search.missing_tags'), type: GlobalSnackbarTypes.ERROR})
    }
  }

  closeDialog = () =>  {
    let search = qs.parse(this.props.location.search.slice(1))
    let {tags_ids, not_tags_ids} = search

    if (tags_ids || not_tags_ids ) {
      if (tags_ids) {
        if (Array.isArray(tags_ids)) {
          this.state.tagGroups.forEach(tagGroup =>
            tagGroup.tags.forEach(tag =>
              tag.state = tags_ids.includes(tag.id.toString()) ? 'selected' : 'available'
            ));
        }
      }
      if (not_tags_ids) {
        if (Array.isArray(not_tags_ids)) {
          this.state.tagGroup.tags.forEach(tag =>
            tag.state = tags_ids.includes(tag.id) && 'unselected'
          )
        }
      }
    }else {
      this.state.tagGroups.forEach(tagGroup =>
        tagGroup.tags.forEach(tag =>
          tag.state = 'available'
        ));
    }

    this.setState({tagGroups: this.state.tagGroups})
  }

  renderSearchResultsTableTags = () => {
    const { searchResultsTags } = this.state

    const Container = ({ children }) => <div ref={r => this.resultsTableTags = r} style={{
      float: 'left',
      position: 'relative',
      width: '100%',
      backgroundColor: "transparent",
      marginTop: "20px"
    }}>{children}</div>
    let content
    if (searchResultsTags.length === 0) {
      content =
        <div className="smart-search-no-result-container" style={{color: this.props.creationColor || 'var(--secondary-font-color, rgba(0,0,0,0.87))', marginTop: 0}}>
          <p style={{fontSize: window.innerWidth > 600 ? "30px" : "20px"}}>{localization.get('table.no_results')}</p>
        </div>
    } else {
      content =
      <InfiniteScroll
        style={{display: "flex", flexWrap: "wrap", position: "relative", float: "left", width: "calc(100% + 20px)", marginLeft: "-10px", marginRight: "-10px"}}
        dataLength={this.state.searchResultsTags.length}
        next={() => this.requestTracks({page: this.state.currentPage++, clearResults: false})}
        hasMore={this.state.currentPage < this.state.lastPage}
        loader={<div
          style={{height: "70px", paddingTop: 20, width: "100%", display: "flex", justifyContent: "center", alignContent: "center"}}
        >
          <img style={{width: 50, height: 50}} src={LoadingSpinner} alt="Loading logo"/>
        </div>}
        scrollableTarget="scrollableDiv"
      >
        <Grid  container fullWidth style={this.props.creationColor ?{'--main-font-color': this.props.creationColor} : {}}>
          {this.state.searchResultsTags.map((creation, key) =>
            <Grid key={key} xs={12} sm={6} md={4} lg={3} xl={3} item style={{padding: 10, width: "100%"}}>
              <CreationItem
                contentCreation={creation}
                onFavorite={() => this.onFavorite(creation)}
                onPlay={() => this.setCurrentPlayingContent(key)}
                onShare={creation => request.contentCreation.shareByChannel(creation.id)
                  .then(response => this.setState({
                    showShareDialog: true,
                    shareUrl: response.url,
                    sharingCreation: creation.id,
                    sharingCreationTitle: creation.title,
                  }))
                  .then(() => analyticsInstance.share({
                    name: getMainTranslation(creation, "title"),
                    type: "Material",
                  }))
                }
                hideAuthor
              />
            </Grid>
          )}
        </Grid>
      </InfiniteScroll>
    }

    return <Container>{content}</Container>
  }

  requestTracks = ({
       type = this.state.type || null,
       searchTags = this.getSearchTags(),
       unselectedSearchTags = this.getUnselectedSearchTags(),
       page = 0,
       clearResults = true,
       search = this.state.searchInput,
       channelId = this.state.channel ? this.state.channel.id : null,
       contentCreatorId = this.state.contentCreatorId,
       orderBy = this.state.orderBy,
       sortType = this.state.sortType,
       firstRequest = false,
     } = {}) =>
    request.contentCreation.getAllWithTags({
      type,
      tags: searchTags,
      notTags: unselectedSearchTags,
      options: {perPage: 24, page: page},
      channelId,
      search,
      contentCreatorId,
      orderBy,
      sortType,
    }).then(searchResults => {
      let results = searchResults.data;

      if (getActiveChannel()) {
        results && results.forEach(creation => creation.favorite = this.state.favorites && this.state.favorites.includes(creation.id));
      }

      if(!clearResults) {
        results = this.state.searchResultsTags.concat(searchResults.data)
      }

      this.setState({
        searchResultsTags: results, 
        showTagSelection: false,
        lastPage: searchResults.meta.last_page,
        currentPage: searchResults.meta.current_page,
        total: searchResults.meta.total})
    })
      .then(() => this.setState({pageReady: true, showUpButton: false}, () => firstRequest && this.state.onReady && this.state.onReady()))
      .catch(() => {
        GlobalSnackbar.show({
          message: 'smart_search_request_failed',
          type: GlobalSnackbarTypes.ERROR,
        })
      })

  cleanSearch = () => {
    this.setState( {
      tagGroup: this.state.tagGroups.forEach(tagGroup => tagGroup.tags.forEach(tag => tag.state = "available")),
      searchResultsTags: [],
      showUpButton: false,
    })
    this.requestTracks();
  }

  clearCurrentPlayingContent = () => {
    this.setState({showContentCreationDialog: false, hasPrev: false, hasNext: false, currentIndex: null, currentPlayingContent: {}})
  }

  setCurrentPlayingContent = (index) => {
    let hasPrev = index > 0 ;
    let hasNext = index < (this.state.searchResultsTags.length - 1);

    if ((this.state.currentPage < this.state.lastPage) && (this.state.searchResultsTags.length - index <= 8)) {
      this.requestTracks({page: this.state.currentPage++, clearResults: false});
    }
    
    if (this.state.searchResultsTags[index].blocked) {
      request.contentCreation.get(this.state.searchResultsTags[index].id)
        .then(creation => this.setState({
          showContentCreationDialog: true, currentPlayingContent: creation, hasPrev: hasPrev, hasNext: hasNext, currentIndex: index
        }))
    }else {
      this.setState({showContentCreationDialog: true, currentPlayingContent: this.state.searchResultsTags[index], hasPrev: hasPrev, hasNext: hasNext, currentIndex: index})
    }
  }

  render = () => {
    return (
      <div>
        {this.makeSmartSearchTagSelector()}
        {this.state.pageReady && this.renderSearchResultsTableTags()}
        <ShareOnSocials
          show={this.state.showShareDialog}
          handleClose={() => this.setState({showShareDialog: false, sharingCreationTitle: null})}
          url={this.state.shareUrl}
          darkMode={true}
          shareTitle={this.state.sharingCreationTitle}
        />
        <ContentCreationPlayer
          show={this.state.showContentCreationDialog}
          onNextArrow={this.state.hasNext ? () => this.setCurrentPlayingContent(this.state.currentIndex+1) : null}
          onPrevArrow={this.state.hasPrev ? () => this.setCurrentPlayingContent(this.state.currentIndex-1) : null}
          currentContentCreation={this.state.currentPlayingContent}
          handleClose={() => this.clearCurrentPlayingContent()}
          noAuthorLink
          hideAuthor
          navbarColor
          typeSlug={this.props.type.slug}
        />
        {this.state.showUpButton &&
          <Button
            className={'editorial-scroll-button'}
            onClick={() => this.props.onScrollToTop && this.props.onScrollToTop()}
            size="lg" justIcon round
          >
              <ArrowUpward />
          </Button>
        }
      </div>
    )
  }
}

export default SmartSearchContentCreatorCreations;
