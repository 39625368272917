import {addPutMethodToForm, post} from "../utils";
import {makeFormData, makeJsonData} from "./utils";

export default host => ({
  name,
  type,
  categoryId,
  colorId,
  image,
  cuit,
  address,
  email,
  countryId,
  selectedBlogLocations,
  showBanner,
  licensePlan,
  excludeFromReports,
  demo,
  permissions,
  managedBy,
  prefix,
  reelUrl,
  useLogo,
  catalogsIds,
  socialLinks,
  translations,
  keywordsSEO,
  titleSEO,
  descriptionSEO,
  showAbout,
  contentCreatorTypes,
  canManageClients,
  searchPreferences,
  blacklistTypeContentCreatorTypes,
  allowFakeLivestreams,
  secure,
  hasOwnTags,
  transcodeVideos,
  useAccessLevel,
  accessLevel,
}) =>
  post(
    `${host}/clients`,
    makeJsonData({
      name,
      type,
      categoryId,
      colorId,
      image,
      cuit,
      address,
      email,
      countryId,
      selectedBlogLocations,
      showBanner,
      licensePlan,
      excludeFromReports,
      demo,
      permissions,
      managedBy,
      prefix,
      reelUrl,
      useLogo,
      catalogsIds,
      socialLinks,
      translations,
      keywordsSEO,
      titleSEO,
      descriptionSEO,
      showAbout,
      contentCreatorTypes,
      canManageClients,
      searchPreferences,
      blacklistTypeContentCreatorTypes,
      allowFakeLivestreams,
      secure,
      transcodeVideos,
      hasOwnTags,
      useAccessLevel,
      accessLevel,
    })
  )
    .then(response => response.json())
    .then(client => {
      return post(`${host}/clients/${client.data.id}`, addPutMethodToForm(makeFormData({
        image,
        socialLinks,
        translations
      })))
        .then(response => response.json())
    });
