import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import {makeUrlWithTableParams} from "../../../utils";
import localization from "../../../config/localization";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {Delete, Done, GetApp, PlayArrow, Settings, Cached, Style} from "@material-ui/icons";
import ContentCreationPlayer from "../../../components/Letflow/ContentCreationPlayer";
import CatalogSelection from "../../../components/Letflow/CatalogSelection";
import {Button, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, Select, MenuItem, Tooltip, Checkbox} from "@material-ui/core";
import {Link} from "react-router-dom";
import {makeImageUrlWithSize, onImageError} from "../../../layouts/Home/HomeElements/utils";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TagSelection from "../../../components/Letflow/TagSelection/TagSelection";
import DialogActions from "@material-ui/core/DialogActions";
import queryString from "querystring";
import IconButton from "@material-ui/core/IconButton";
import { userisMultiClient, userIsSysAdmin} from "../../../api-client/core/authentication/utils";
import Clear from "@material-ui/icons/Clear";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import TextField from "@material-ui/core/TextField/TextField";
import {get} from "lodash";

class Table extends Component {
  state = {
    total: "",
    openCatalogDialog: false,
    openIntegrateDialog: false,
    contentCreatorId: '',
    selectedCatalog: '',
    types: [],
    showContentCreatorSelection: false,
    showContentCreationDialog: false,
    currentPlayingContent: {},
    showContent: 'all',
    showTagSelection: false,
    showTagDialog: false,
    tagGroups: [],
    tagGroupsAll: [],
    selectedTags: [],
    unselectedTags: [],
    showReTagDialog: false,
    selectedRetags: [],
    cloud: null,
    title: "",
    updateVisible: "keep",
    updateDownloadable: "keep",
    showRenameDialog: false,
    namePrefix: "",
    retagMethod: "add",
    useCreator: "0",
    selectedContentCreator: null,
    updateAccessLevel: "keep",
  };

  componentDidMount() {
    this.requestContentCreatorTypes()
      .then(() => {
        let queryParams = queryString.parse(this.props.location.search)['?table_params']
        if (queryParams && JSON.parse(queryParams).filtered.length > 0 && JSON.parse(queryParams).filtered[0].id === 'contentCreatorType') {
          this.setState({
            selectedTags: eval(localStorage.getItem("selectedTags")) ? JSON.parse(eval(localStorage.getItem("selectedTags"))) : [],
            unselectedTags: eval(localStorage.getItem("unselectedTags")) ? JSON.parse(eval(localStorage.getItem("unselectedTags"))) : [],
            showTagSelection: true,
            selectedType: this.state.types.find(type => type.id == JSON.parse(queryParams).filtered[0].value),
          }, () => {
            this.paintAllTags().then(() => this.setState({loaded: true}))
          })
        } else {
          this.setState({loaded: true})
        }
      })
  }

  requestContentCreatorTypes = () => {
    const filter = {filterBy: [ { column: "creations", filter: "true" },
        this.state.catalog ? { column: 'catalog_id', filter: this.state.catalog.value } : {}]}
    filter.filterBy.push({
      column: "is_talent",
      filter:  this.props.isTalent ? 1 : 0
    });
    return request.contentCreatorType.getAll(filter)
      .then(contentCreatorTypes => {
        const types = contentCreatorTypes.map(contentCreatorType => ({id: contentCreatorType.id, label: contentCreatorType.name, slug: contentCreatorType.slug, client: contentCreatorType.client}))
        this.setState({types})
      })
  }

  clearAllTags = () =>
    new Promise(resolve => {
      this.setState( {tagGroups: this.state.tagGroups.map(tagGroup => {
          return {...tagGroup,
            tags: tagGroup.tags.map(tag => {
              return {...tag, state: 'available'}
            })
          }
        })
      })
      resolve()
    }).then(() => this.setState({selectedTags: [], unselectedTags: []}))

  clearAllRetags = () =>
    new Promise(resolve => {
      this.setState( {tagGroupsAll: this.state.tagGroupsAll.map(tagGroup => {
          return {...tagGroup,
            tags: tagGroup.tags.map(tag => {
              return {...tag, state: 'available'}
            })
          }
        })
      })
      resolve()
    }).then(() => this.setState({selectedRetags: []}))

  setTagGroups = tagGroups => this.setState(state => ({...state, tagGroups}))

  updateTagColor = (selectedTag, value) =>
    this.setState( {tagGroups: this.state.tagGroups.map(tagGroup => {
          return {...tagGroup,
            tags: tagGroup.tags.map(tag => {
              if (tag.id === selectedTag) {
                return {...tag, state: value}
              }
              return tag
            })
          }
        }
      )
    })

  updateRetagColor = (selectedTag, value) =>
    this.setState( {tagGroupsAll: this.state.tagGroupsAll.map(tagGroup => {
          return {...tagGroup,
            tags: tagGroup.tags.map(tag => {
              if (tag.id === selectedTag) {
                return {...tag, state: value}
              }
              return tag
            })
          }
        }
      )
    })

  paintAllTags = () =>
    new Promise(resolve => {
      this.state.selectedTags.map(tag => this.updateTagColor(tag, "selected"))
      this.state.unselectedTags.map(tag => this.updateTagColor(tag, "unselected"))
      resolve()
    })

  onSelectedTagsChanged = selectedTag => {
    if (this.state.selectedTags.includes(selectedTag)) { //Remove from selected / add to unselected tags
      this.state.unselectedTags.push(selectedTag)
      this.setState({
        selectedTags: this.state.selectedTags.filter(tags => tags !== selectedTag),
        unselectedTags: this.state.unselectedTags
      }, () => this.updateTagColor(selectedTag, "unselected"))
    }else if(this.state.unselectedTags.includes(selectedTag)) { //Remove from unselected / add to available tags
      this.setState({
        unselectedTags: this.state.unselectedTags.filter(tags => tags !== selectedTag),
      }, () => this.updateTagColor(selectedTag, "available"))
    }else { //Remove from available / add to selected tags
      this.state.selectedTags.push(selectedTag);
      this.setState({selectedTags: this.state.selectedTags}, () => this.updateTagColor(selectedTag, "selected"))
    }
  }

  onSelectedRetagsChanged = selectedTag => {
    if (this.state.selectedRetags.includes(selectedTag)) {
      this.setState({
        selectedRetags: this.state.selectedRetags.filter(tags => tags !== selectedTag),
      }, () => this.updateRetagColor(selectedTag, "available"))
    }else {
      this.state.selectedRetags.push(selectedTag);
      this.setState({selectedRetags: this.state.selectedRetags}, () => this.updateRetagColor(selectedTag, "selected"))
    }
  };

  makeTagSelectionDialog = () =>
    <Dialog open={this.state.showTagDialog} onClose={() => this.setState({showTagDialog: false})} onBackdropClick={() => this.setState({showTagDialog: false})}>
      <DialogContent style={{textAlign: "center"}}>
        <TagSelection
          homeElementTagSelection={true}
          allowUnselected={true}
          data={this.state.tagGroups}
          onSelectedTagsChanged={this.onSelectedTagsChanged}
          clearAllTags={this.clearAllTags}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          localStorage.setItem("selectedTags", `"[${this.state.selectedTags}]"`)
          localStorage.setItem("unselectedTags", `"[${this.state.unselectedTags}]"`)
          this.refs.table.forceUpdate();
          this.setState({showTagDialog: false})
        }}>
          {localization.get('home_editor.tag_selection.filter')}
        </Button>
      </DialogActions>
    </Dialog>

  makeUpdateTagSelectionDialog = () =>
    <Dialog open={this.state.showReTagDialog} onClose={() => this.setState({showReTagDialog: false, retagMethod: "add", selectedContentCreator: null, useCreator: "0" }, this.clearAllRetags)} onBackdropClick={() => this.setState({showReTagDialog: false, retagMethod: "add", selectedContentCreator: null, useCreator: "0"}, this.clearAllRetags)}>
      <DialogTitle>{localization.get('form.batch_retag_alert')}</DialogTitle>
      <DialogContent style={{textAlign: "center"}}>
        <h4 style={{textAlign: "left", color: "red"}}>{localization.get('form.retag.method')}</h4>
        <Select
          style={{width: "100%"}}
          onChange={event => this.setState({retagMethod: event.target.value})}
          value={this.state.retagMethod}
        >
          <MenuItem value="add">{localization.get('form.retag.add')}</MenuItem>
          <MenuItem value="remove">{localization.get('form.retag.remove')}</MenuItem>
          <MenuItem value="mirror">{localization.get('form.retag.mirror')}</MenuItem>
        </Select>
        <h4 style={{textAlign: "left", marginTop: 20, position: "relative", float: "left"}}>{localization.get('form.tags')}</h4>
        <TagSelection
          homeElementTagSelection={true}
          allowUnselected={false}
          data={this.state.tagGroupsAll}
          onSelectedTagsChanged={tagId => this.onSelectedRetagsChanged(tagId)}
          clearAllTags={this.clearAllRetags}
        />
        <br/>
        <br/>
        <FormControl style={{float: "left", position: "relative", width: "100%"}}>
          <h4 style={{textAlign: "left"}} >{localization.get('form.downloadable')}</h4>
          <RadioGroup
            name="updateDownloadable"
            value={this.state.updateDownloadable}
            onChange={event => this.setState({ updateDownloadable: event.target.value })}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel value="keep" control={<Radio color="default" />} label={localization.get('form.keep')} />
            <FormControlLabel value="1" control={<Radio color="default" />} label={localization.get('form.downloadable')} />
            <FormControlLabel value="0" control={<Radio color="default" />} label={localization.get('form.downloadable.not')} />
          </RadioGroup>
          <h4 style={{textAlign: "left"}} >{localization.get('form.visibility')}</h4>
          <RadioGroup
            name="updateVisible"
            value={this.state.updateVisible}
            onChange={event => this.setState({ updateVisible: event.target.value })}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel value="keep" control={<Radio color="default" />} label={localization.get('form.keep')} />
            <FormControlLabel value="1" control={<Radio color="default" />} label={localization.get('form.visible')} />
            <FormControlLabel value="0" control={<Radio color="default" />} label={localization.get('form.visible_not')} />
          </RadioGroup>
        </FormControl>
        {get(this.state.selectedType, 'client.has_access_level') &&   
        <>
          <h4 style={{textAlign: "left"}} >{localization.get('form.update_access_level')}</h4>
          <RadioGroup
            name="updateVisible"
            value={this.state.updateAccessLevel}
            onChange={event => this.setState({ updateAccessLevel: event.target.value })}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel value="keep" control={<Radio color="default" />} label={localization.get('form.keep')} />
            <FormControlLabel value={"0"} control={<Radio color="default"/>} label={localization.get('smart_search.none')} />
            {["1","2","3","4","5"].map(level =>
              <FormControlLabel
                value={level}
                control={<Radio color="default"/>}
                label={level}
              />
            )}
          </RadioGroup>
        </>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          if (this.state.selectedTags.length > 0) {
            GlobalConfirmationDialog.showDoubleConfirmation({
              dialogTitle: localization.get('form.retag'),
              elementName: localization.get('form.double_confirmation_retag_many.label'),
              content: "confirmation_dialog.delete_many_materials",
              updateRequest: () => request.contentCreation.retagMany(this.state.selectedRetags, this.state.retagMethod, this.state.updateVisible, this.state.updateDownloadable, this.state.updateAccessLevel, this.state.paginationConfig)
                .then(() => this.refs.table.forceUpdate())
                .then(this.clearAllRetags)
                .then(() => this.setState({showReTagDialog: false, retagMethod: "add", selectedContentCreator: null, useCreator: "0"})),
              auxiliaryInformation: localization.get('form.double_confirmation_retag_many.aux', this.state.total),
              doubleConfirmation: true,
              doubleConfirmationText: "form.double_confirmation_retag_many",
              confirmationLabel: "form.retag",
              onSuccessText: localization.get('form.retag.success'),
              onFailText: localization.get('form.dialog.fail'),
            })
          }
        }}>
          {localization.get('form.retag')}
        </Button>
      </DialogActions>
    </Dialog>

  makeRenameDialog = () =>
    <Dialog open={this.state.showRenameDialog} onClose={() => this.setState({showRenameDialog: false})} onBackdropClick={() => this.setState({showRenameDialog: false})}>
      <DialogTitle>{localization.get('form.batch_rename_alert')}</DialogTitle>
      <DialogContent style={{textAlign: "center"}}>
        <span style={{float: "left"}}>{localization.get('content_creations.prefix_name')}</span>
        <TextField
          placeholder={localization.get('content_creations.prefix_name')}
          value={this.state.namePrefix}
          onChange={e => this.setState({namePrefix: e.target.value})}
          helperText={localization.get('content_creations.prefix_name_helper')}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          if (this.state.selectedTags.length > 0) {
            GlobalConfirmationDialog.showDoubleConfirmation({
              dialogTitle: localization.get('form.rename'),
              elementName: localization.get('form.double_confirmation_rename_many.label'),
              content: "confirmation_dialog.rename_many_materials",
              updateRequest: () => request.contentCreation.renameMany(this.state.namePrefix, this.state.paginationConfig)
                .then(() => this.refs.table.forceUpdate()),
              auxiliaryInformation: localization.get('form.double_confirmation_rename_many.aux', this.state.total),
              doubleConfirmation: true,
              doubleConfirmationText: "form.double_confirmation_rename_many",
              confirmationLabel: "form.rename",
              onSuccessText: localization.get('form.rename.success'),
              onFailText: localization.get('form.dialog.fail'),
            })
          }
          this.setState({showRenameDialog: false})
        }}>
          {localization.get('form.rename')}
        </Button>
      </DialogActions>
    </Dialog>

  handleAlertClosed = artistId => status => {
    if (status === "passed") this.refs.table.removeById(artistId);
  };

  playContent = creation => this.setState({ showContentCreationDialog: true, currentPlayingContent: creation})

  buttons = contentCreation => {
    const {id, title} = contentCreation;

    let editUrl =  ''
    let creatorEditUrl= ''
    if(this.props.isDemo) {
      editUrl = makeUrlWithTableParams(`/panel/demos/content_creations/${id}/edit?is_demo=true`);
      creatorEditUrl = makeUrlWithTableParams(`/panel/demos/content_creators/${contentCreation.content_creator_id}/edit?is_demo=true`);
    } else if (this.props.isTalent) {
      editUrl = makeUrlWithTableParams(`/panel/talents/content_creations/${id}/edit`);
      creatorEditUrl = makeUrlWithTableParams(`/panel/talents/content_creators/${contentCreation.content_creator_id}/edit`);
    } else {
      editUrl = makeUrlWithTableParams(`/panel/audiovisual/content_creations/${id}/edit`);
      creatorEditUrl = makeUrlWithTableParams(`/panel/audiovisual/content_creators/${contentCreation.content_creator_id}/edit`);
    }

    const buttonBuilder = new TableButtonsBuilder();

    buttonBuilder.withOther(<TableButton title={localization.get('tooltip.play')} onClick={() => this.playContent(contentCreation)}><PlayArrow/></TableButton>)
    buttonBuilder.withEdit(editUrl);
    buttonBuilder.withOther(
      <Link to={creatorEditUrl}>
        <TableButton title={localization.get('go_to.content_creator.edit')}>
          <Settings/>
        </TableButton>
      </Link>
    )

    buttonBuilder.withOther(
      <TableButton
        title={localization.get('preview')}
        onClick={() =>
          GlobalConfirmationDialog.showDelete({
            dialogTitle: `${localization.get('tooltip.delete')} ${contentCreation.name}`,
            content: localization.get('confirmation_dialog.delete_content_message'),
            doubleConfirmationText: localization.get('confirmation_dialog.delete_confirmation'),
            elementName: localization.get("delete"),
            deleteRequest: () => request.contentCreation.delete(id)
              .then(this.refs.table.forceUpdate),
            doubleConfirmation: true,
            auxiliaryInformation: "",
          })
        }
      >
        <Delete />
      </TableButton>
    )

    return buttonBuilder.build();
  };

  getContentCreationType = contentCreation => {
    if (contentCreation.video) {
      if (contentCreation.video.job_status == "complete") {
        return localization.get(contentCreation.require_drm ? 'content_creation.video_drm' : 'content_creation.video_transcode')
      }else if (contentCreation.video.job_status !== "not_requested") {
        return localization.get( 'content_creation.video_status', contentCreation.video.drm? "DRM": "Transcode", contentCreation.video.job_status)
      }

      return localization.get('content_creation.video_original')
    }else if (contentCreation.link) {
      return localization.get('content_creation.link')
    }else if (contentCreation.audio) {
      return localization.get('content_creation.audio')
    }else if (contentCreation.binary) {
      return localization.get('table.allow_binary')
    }else if (contentCreation.embededContent) {
      return localization.get('content_creation.embeded_content')
    }else {
      return localization.get('content_creation.image')
    }
  }

  columns = () => [
    {
      id: "image",
      accessor: contentCreation => (
        <img
          alt="ContentCreator"
          src={contentCreation.image ? makeImageUrlWithSize(contentCreation, 'xs'): makeImageUrlWithSize(contentCreation.contentCreator, 'xs')}
          width={50}
          height={50}
          onError={onImageError}
        />
      ),
      style: {
        float: "left"
      },
      resizeable: false,
      sortable: false,
      filterable: false,
      width: 60
    },
    {
      Header: localization.get("table.detail"),
      accessor: "title",
      Filter: ({filter, onChange}) => (
        <input
          onChange={event => {
            onChange(event.target.value)
            this.setState({title: event.target.value || ""})
          }}
          value={filter ? filter.value : ""}
          placeholder={localization.get("table.search")}
        />
      )
    },
    {
      Header: localization.get("content_creator"),
      id: "contentCreator[name]",
      accessor: content => content.contentCreator ? content.contentCreator.name : "",
      Filter: ({filter, onChange}) => (
        <input
          onChange={event => {
            this.setState({content_creator_id: event.target.value || ""})
            onChange(event.target.value)
          }}
          value={filter ? filter.value : ""}
          placeholder={localization.get("table.search")}
        />
      ),
      sortable: false
    },
    {
      Header: localization.get("category"),
      id: "contentCreatorType",
      accessor: content => content.contentCreator.type ? content.contentCreator.type.name : "",
      Filter: ({ filter, onChange }) => (
        <select
          style={{ width: "100%" }}
          onChange={event => {
            onChange(event.target.value)
            //this.setState({showContentCreatorSelection: !!event.target.value})
            localStorage.setItem("selectedTags", `"[]"`)
            localStorage.setItem("unselectedTags", `"[]"`)
            this.setState({
              showTagSelection: !!event.target.value,
              selectedType: this.state.types.find(type => type.id == event.target.value),
              selectedTags: [],
              unselectedTags: [],
              content_creator_type_id: event.target.value,
            })
          }}
          value={filter ? filter.value : ""}
        >
          <option value="" selected />
          {this.state.types.sort((a, b) => a.label.localeCompare(b.label)).map(type => {
            return <option value={type.id}>{localization.get(type.label)}</option>;
          })}
        </select>
      ),
      sortable: false
    },
    {
      Header: localization.get( !this.props.isTalent ? "form.status" : "table.active"),
      id: "active",
      accessor: content => (content.active ? localization.get('table.active') : (!!content.release_date ? localization.get('table.programed') : localization.get('table.inactive'))),
      Filter: ({filter, onChange}) => (
        <select
          style={{width: "100%"}}
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
        >
          <option value="" selected/>
          <option value={1}>{localization.get('table.active')}</option>
          <option value={0}>{localization.get('table.inactive')}</option>
        </select>
      ),
      sortable: false,
      width: 120
    },
    {
      Header: this.props.isTalent ? localization.get("table.email") : localization.get("form.region"),
      id: this.props.isTalent ? "email" : "only_argentina",
      accessor:  contentCreator => this.props.isTalent ? contentCreator.email : (contentCreator.only_argentina ? localization.get('form.only_argentina') : localization.get('form.all_world')),
      filterable: true,
      sortable: false,
      Filter: ({filter, onChange}) => !this.props.isTalent ?
        (
          <select
            style={{ width: "100%" }}
            onChange={e => onChange(e.target.value)}
            value={filter ? filter.value : ""}
          >
            <option value="" selected />
            <option value="1"> {localization.get('form.only_argentina')} </option>
            <option value="0"> {localization.get('form.all_world')} </option>
          </select>
        )
        : (
          <input
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
            placeholder={localization.get("table.search")}
          />
        )
    },
    {
      Header: localization.get("Login"),
      id: "require_login",
      accessor: contentCreator => (contentCreator.require_login ?
          <Tooltip title={localization.get('table.require_login')} placement="top">
            <IconButton>
              <Done />
            </IconButton>
          </Tooltip> :
          <Tooltip title={localization.get('table.require_login_not')} placement="top">
            <IconButton>
              <Clear />
            </IconButton>
          </Tooltip>
      ),
      filterable: true,
      Filter: ({filter, onChange}) => (
        <select
          style={{width: "100%"}}
          onChange={event => {
            onChange(event.target.value)
            this.setState({active: event.target.value})
          }}
          value={filter ? filter.value : ""}
        >
          <option value="" selected/>
          <option value={"1"}>{localization.get('table.require_login')}</option>
          <option value={"0"}>{localization.get('table.require_login_not')}</option>
        </select>
      ),
      sortable: false,
      style: { textAlign: 'center' },
      width: 60
    },
    {
      Header: localization.get("content_creation.material_type"),
      id: "type",
      accessor: contentCreation => this.getContentCreationType(contentCreation),
      maxWidth: 120,
      sortable: false,
      Filter: ({ filter, onChange }) =>
        <select style={{ width: "100%"}} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
          <option value="" selected />
          <option value="video">{localization.get('video')}</option>
          <option value="video_drm">{localization.get('content_creation.video_drm')}</option>
          <option value="video_transcode">{localization.get('content_creation.video_transcode')}</option>
          <option value="video_original">{localization.get('content_creation.video_original')}</option>
          <option value="audio">{localization.get('audio')}</option>
          <option value="link">{localization.get('link')}</option>
          <option value="binary">{localization.get('table.allow_binary')}</option>
          <option value="image">{localization.get('image')}</option>
          <option value="embededContent">{localization.get('form.embed_short')}</option>
        </select>
    },
    {
      Header: localization.get('table.created_at'),
      id: 'views',
      accessor: shortLink => localization.moment(shortLink.created_at).fromNow(),
      resizeable: false,
      sortable: false,
      filterable: false,
    },
    {
      id: "actions",
      Header: localization.get("table.actions"),
      sortable: false,
      filterable: false,
      accessor: this.buttons,
      width: 145,
      Footer: <span style={{float: "right", fontSize: "18px", marginTop: "15px"}}><strong>Total: </strong>{this.state.total}</span>
    }
  ];

  makeDownloadLink = () => request.authentication.refreshAccessToken().then(() => window.open(request.contentCreation.getExportLink(this.state.paginationConfig), '_blank'))

  render = () => {

    return (
      <div>
        {this.makeTagSelectionDialog()}
        {this.makeUpdateTagSelectionDialog()}
        {this.makeRenameDialog()}
        <div style={{ width: "100%", float: "left", position: "relative" }}>
          <ContentCreationPlayer
            show={this.state.showContentCreationDialog}
            currentContentCreation={this.state.currentPlayingContent}
            handleClose={() => this.setState({showContentCreationDialog: false, currentPlayingContent: {}})}
            dashboard
          />
          {!this.props.isDemo ?
            <>
            <div>
              <FormControl style={{float: "left", position: "relative"}}>
                <RadioGroup
                  name="showContent"
                  value={this.state.showContent}
                  onChange={event => {
                    localStorage.setItem("showContent", `"${event.target.value}"`)
                    this.setState({ showContent: event.target.value })
                    this.refs.table.forceUpdate();
                  }}
                  style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
                >
                  <FormControlLabel value="all" control={<Radio color="default" />} label={localization.get('all')} />
                  <FormControlLabel value="inactive" control={<Radio color="default" />} label={localization.get('clients.inactive')} />
                  <FormControlLabel value="no_thumbnail" control={<Radio color="default" />} label={localization.get('no_thumbnail')} />
                  <FormControlLabel value="with_drm" control={<Radio color="default" />} label={localization.get('content_creation.video.has_drm')} />
                </RadioGroup>
              </FormControl>

              <Tooltip title={localization.get(!this.state.showTagSelection ? 'filter_tags.disabled' : "home_editor.tag_selection.filter")} placement='top'>
                <div style={{display: "inline-block"}}>
                  <Button
                    disabled={!this.state.showTagSelection}
                    style={{marginTop: 7}}
                    onClick={() => {
                      request.tagGroup.getAllPrivate({filterBy: [
                        { column: 'type', filter: `${this.state.selectedType.slug}`},
                        { column: "creation", filter: 1 },
                        { column: "dashboard", filter: 1 }
                      ]})
                        .then(tags => this.setTagGroups(tags))
                        .then(() => this.paintAllTags())
                        .then(() => this.setState({showTagDialog: true}))
                    }}
                  >
                    {localization.get('filter_tags')}
                  </Button>
                </div>
              </Tooltip>
              { ( userIsSysAdmin() || userisMultiClient() )&&
                <>
                  <IconButton
                    onClick={() => this.makeDownloadLink()}
                    style={{position: "relative", float: "left", color: 'inherit'}}
                  >
                    <Tooltip
                      title={localization.get('download_csv')}
                      placement='top'>
                      <GetApp/>
                    </Tooltip>
                  </IconButton>
                  {this.state.selectedTags.length > 0 &&
                    <div style={{position: "relative", float: "left", color: 'inherit'}}>
                      <IconButton
                        onClick={() => {
                          request.tagGroup.getAll({filterBy: [
                              { column: 'types_ids', filter: this.state.selectedType.id },
                              { column: 'inheritable', filter: true }
                            ]})
                            .then(tags => this.setState({ tagGroupsAll: tags, showReTagDialog: true}))
                        }}
                        style={{position: "relative", float: "left", color: 'inherit'}}
                      >
                        <Tooltip
                          title={localization.get('form.batch_retag')}
                          placement='top'>
                          <Style />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          if (this.state.selectedTags.length > 0) {
                            GlobalConfirmationDialog.showDelete({
                              elementName: localization.get('form.double_confirmation_delete_many.label'),
                              content: "confirmation_dialog.delete_many_materials",
                              deleteRequest: () => request.contentCreation.deleteMany(this.state.paginationConfig)
                                .then(() => this.refs.table.forceUpdate())
                                .then(this.clearAllTags),
                              doubleConfirmation: true,
                              auxiliaryInformation: localization.get('form.double_confirmation_delete_many.aux', this.state.total),
                              doubleConfirmationText: "form.double_confirmation_delete_many",
                            })
                          }
                          this.setState({showReTagDialog: false})
                        }}
                        style={{position: "relative", float: "left", color: 'inherit'}}
                      >
                        <Tooltip
                          title={localization.get('form.batch_delete')}
                          placement='top'>
                          <Delete />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          if (this.state.selectedTags.length > 0) {
                            this.setState({showRenameDialog: true})
                          }
                        }}
                        style={{position: "relative", float: "left", color: 'inherit'}}
                      >
                        <Tooltip
                          title={localization.get('form.rename')}
                          placement='top'>
                          <Cached />
                        </Tooltip>
                      </IconButton>
                    </div>
                  }
                </>
              }
              <Link style={{marginRight: 10, marginTop: 15, float: "right", position: "relative"}} to={this.props.isTalent ? '/panel/talents/content_creators' : '/panel/audiovisual/content_creators'}>
                <span>{localization.get("content_creators.back")}</span>
              </Link>
            </div>
              {this.state.total !== "" &&
                <CatalogSelection
                  catalog={this.state.catalog}
                  onChange={(catalog) => this.setState({catalog}, () => {this.requestContentCreatorTypes().then(() => this.refs.table.forceUpdate())})}
                  hasRelation={'has_contentCreators'}
                  disabled={!!this.state.cloud}
                />
              }
            </>
          :
          <Link style={{margin: "15px 0", float: "left", position: "relative"}} to={'/panel/demos/content_creators'}>
            <span>{localization.get("content_creators.back_demos")}</span>
          </Link>
          }
          {this.state.loaded &&
          <div style={{ width: "100%", float: "left", position: "relative" }}>
            <ServerSidePaginationTable
              ref="table"
              columns={this.columns()}
              requestTableData={paginationConfig => {
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([{column: 'demo', filter: this.props.isDemo ? 1 : 0}])
                };
                if (!this.props.isDemo) {
                  paginationConfig = {
                    ...paginationConfig,
                    filterBy: paginationConfig.filterBy.concat([{column: 'is_talent', filter: this.props.isTalent ? 1 : 0}])
                  };
                }

                if(this.state.showContent !== 'all') {
                  if (this.state.showContent === 'inactive') {
                    paginationConfig = {
                      ...paginationConfig,
                      filterBy: paginationConfig.filterBy.concat([{column: 'active', filter: false}])
                    };
                  }
                  if (this.state.showContent === 'no_thumbnail') {
                    paginationConfig = {
                      ...paginationConfig,
                      filterBy: paginationConfig.filterBy.concat([{column: 'video_has_thumbnail', filter: false}])
                    };
                  }
                  if (this.state.showContent === 'with_drm') {
                    paginationConfig = {
                      ...paginationConfig,
                      filterBy: paginationConfig.filterBy.concat([{column: 'with_drm', filter: true}])
                    };
                  }
                }
                if (this.state.catalog) {
                  paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'catalog_id', filter: this.state.catalog.value }]) };
                }

                if (this.state.selectedTags.length > 0) {
                  paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'tags_ids', filter: [...this.state.selectedTags] }]) };
                }
                if (this.state.unselectedTags.length > 0) {
                  paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'not_tags_ids', filter: [...this.state.unselectedTags] }]) };
                }

                if(!paginationConfig.orderBy){
                  paginationConfig.orderBy = {column: "created_at", type: "desc"}
                }

                this.setState({ paginationConfig })

                return request.contentCreation.getAll(paginationConfig).then(response => {
                  this.setState({total: response.meta.total});
                  return response;
                });
              }}
            />
          </div>
            }
        </div>
      </div>
    );
  };
}

export default Table;
