import React, {Component} from "react";
import {request} from "../../../api-client";
import {makeFitImageUrlWithSize, setStatePromise, makeFitImageUrl} from "../../../utils";
import PropTypes from "prop-types";
import browserHistory from "../../../utils/browserHistory";
import localization from "../../../config/localization";
import {
  channelPathGenerator,
  getActiveChannel,
  getActiveClient, getStoredUser,
  getStoredChannel,
  homePathGenerator,
  setPageTitle, getMainTranslation, getContentCreationFavorites, getContentCreatorFavorites,
  userHasRestrictedAccess
} from "../../../api-client/core/authentication/utils";
import ShareDialog from "../../../components/Letflow/ShareDialog";
import styled from "styled-components";
import {makeImageUrlWithSize, makeThumbnailUrlWithSize} from "../HomeElements/utils";
import ContentCreatorBackground from "../../../assets/img/Letflow/content_creator_background.png";
import ProfilePicture from "../../../assets/img/default-avatar.png"
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import GlobalRedirectLoginConfirmationDialog from "../../../components/Letflow/Dialog/GlobalRedirectLoginConfirmationDialog";
import AudiovisualCreator from "./AudiovisualCreator";
import EditorialCreator from "./EditorialCreator";
import {get} from "lodash";
import queryString from "qs";
import DownloadImage from '../../../assets/img/Letflow/download.png'
import moment from "moment";
import 'moment/locale/es';
import analyticsInstance from "../../../analytics/analyticsInstance";

export default class ContentCreator extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  state = {
    contentCreator: null,
    contentCreations: [],
    selectedContentCreation: null,
    favorite: false,
    tags: [],
    autoPlay: false,
    key: 0,
    currentIndex: 0,
    showContactDialog: false,
    height: "175px",
    notAvailable: false,
    loaded: false,
    trackingTag: 0,
    requireLogin: false,
    origen: "",
    creationsLoaded: false,
    viewType: 'carousel',
    audioContentCreationsBySelectedTags: [],
    contentCreationsBySelectedTags: [],
    accessDenied: false,
  };

  componentDidMount = () => {
    if (userHasRestrictedAccess()) {
      this.setState({accessDenied: true})
    }else {
      this.fetchContentCreator().then(() => this.state.contentCreations.length < 1 && this.setState({height: '100%'}));
    }
    !!(getActiveClient() && !getActiveChannel()) && request.client.get(getActiveClient()).then(response => this.setState({client: response}))
    if(!this.props.modal) {
      window.scrollTo({ top: 0, left: 0, behavior: "instant"})
    }
  };

  componentDidUpdate = (prevProps) => {
    if(this.props.contentCreatorId !== prevProps.contentCreatorId) {
      this.setState({loaded: false}, () => {
        this.fetchContentCreator()
          .then(() => {
            this.state.contentCreations.length < 1 && this.setState({height: '100%'})
            if(!this.props.modal) window.scrollTo({ top: 0, left: 0, behavior: "instant"})
          });
      })
    }
  }

  fetchContentCreator = () => {
    let queryParams = queryString.parse(window.location.search)
    let allCreations = false;
    let shareToken = null;
    let preview = queryParams['preview'];

    if (queryParams['allCreations'] || queryParams['?allCreations']) {
      allCreations = true
    }

    if (queryParams['shareToken'] || queryParams['?shareToken']) {
      shareToken = queryParams['shareToken'] || queryParams['?shareToken']
    }
       
    let creatorRequest = this.props.isPublic ? request.public.contentCreator(this.props.contentCreatorId, "include=translations;type;image;coverImage;mobileCoverImage;country;socialLinks;downloadableMaterial.audio;downloadableMaterial.video;downloadableMaterial.image;downloadableMaterial.binary;suggestedPlaylist;eventDates;buyButtonProduct;mainChannel.domain") :
      (this.props.preview || preview) ? request.contentCreator.preview(preview ? preview : this.props.contentCreatorId, `include=translations;type;image;coverImage;mobileCoverImage;country;socialLinks;downloadableMaterial.audio;downloadableMaterial.video;downloadableMaterial.image;downloadableMaterial.binary;suggestedPlaylist;eventDates;buyButtonProduct;mainChannel.domain;relatedContents.image;relatedContents.type${shareToken ? `&shareToken=${shareToken}` : ""}`)
      : getActiveClient() && request.contentCreator.getForClientV2(this.props.contentCreatorId, `include=translations;type;image;coverImage;mobileCoverImage;tags.group;country;socialLinks;downloadableMaterial.audio;downloadableMaterial.video;downloadableMaterial.image;downloadableMaterial.binary;suggestedPlaylist;eventDates;mainChannel.domain;relatedContents.image;relatedContents.type;buyButtonProduct${shareToken ? `&shareToken=${shareToken}` : ""}`)

    let materialRequest = this.props.isPublic ? request.public.contentCreator(this.props.contentCreatorId, "include=type;image;coverImage;contentCreations.video.thumbnail;contentCreations.image;contentCreations.closedCaptions.binary;contentCreations.audio.waveform;contentCreations.tags.group;contentCreations.binary;contentCreations.translations;country;socialLinks;downloadableMaterial.audio;downloadableMaterial.video;downloadableMaterial.image;downloadableMaterial.binary;contentCreations.embededContent;suggestedPlaylist;eventDates;buyButtonProduct") :
      (this.props.preview || preview) ? request.contentCreation.getAllByCreator(preview ? preview : this.props.contentCreatorId, `include=translations;tags.group;video.thumbnail;image;closedCaptions.binary;audio.waveform;binary;embededContent${allCreations ? "&allCreations=true" : ""}`, {perPage: 25, orderBy: {column: "order", type: "asc"}}, true)
      : getActiveClient() && request.contentCreation.getAllByCreatorForChannel(this.props.contentCreatorId, {perPage: 25, orderBy: {column: "order", type: "asc"}})
    
    
  return Promise.all([creatorRequest, materialRequest])
    .then(values => {
      const resCreator = values.find(v => v.type);
      const resMaterials = values.find(v => this.props.isPublic ? !!v.contentCreations : !v.type);
      const resCreations = this.props.isPublic ? resMaterials.contentCreations : get(resMaterials, "data", resMaterials);
      
      resCreator.name && setPageTitle(`${resCreator.name}`)
      let viewType = 'carousel';
      let isMobile = window.innerWidth < 600;

      if (getActiveChannel() && !this.props.isPublic) {
        if (isMobile ? (resCreator.view_type_mobile == 'inherit') : (resCreator.view_type == 'inherit')) {
          let contentCreatorType = getStoredChannel().channelContentCreatorTypes.find(cct => cct.contentCreatorType.id == resCreator.type.id);
          if (contentCreatorType) {
            viewType = (isMobile ? contentCreatorType.creator_view_type_mobile : contentCreatorType.creator_view_type) || 'carousel';
          }
        } else {
          viewType = (isMobile ? resCreator.view_type_mobile : resCreator.view_type) || 'carousel';
        }
      }

      const contentCreations = resCreations.map((contentCreation, key) => ({
        id: contentCreation.id,
        blocked: contentCreation.blocked || false,
        title: contentCreation.title,
        subtitle: contentCreation.subtitle || '',
        blockedForCountry: !!contentCreation.blocked_for_country,
        description: contentCreation.description,
        favorite: false,
        active: contentCreation.active,
        channelFavorite: getContentCreationFavorites().includes(contentCreation.id),
        audioUrl: contentCreation.audio && contentCreation.audio.url,
        audio: contentCreation.audio &&
          {
            ...contentCreation.audio,
            title: contentCreation.audio.original_name,
          },
        has_pre_roll: get(contentCreation, 'has_pre_roll', false),
        video: contentCreation.video,
        url: contentCreation.video && contentCreation.video.url,
        smallUrl: contentCreation.video && contentCreation.video.url + '#t=2',
        smallImageUrl: contentCreation.image ? makeImageUrlWithSize(contentCreation, 'md-16:9') : (contentCreation.binary ? DownloadImage : null),
        imageUrl: contentCreation.image ? makeFitImageUrlWithSize(contentCreation, 'lg') : (contentCreation.binary ? DownloadImage : null),
        imageId: contentCreation.image ? contentCreation.image.id : (contentCreation.binary ? DownloadImage : null),
        image: contentCreation.image ? contentCreation.image : null,
        thumbnail: contentCreation.video && (contentCreation.image ? makeImageUrlWithSize(contentCreation, 'lg') : makeThumbnailUrlWithSize(contentCreation.video, 'lg')),
        binary: contentCreation.binary ? contentCreation.binary.url : "",
        binaryExtension: contentCreation.binary ? contentCreation.binary.original_name.split(".").pop() : "",
        link: contentCreation.link ? contentCreation.link : "",
        height: contentCreation.image ? contentCreation.image.height :  (contentCreation.video ? contentCreation.video.height : null),
        width: contentCreation.image ? contentCreation.image.width :  (contentCreation.video ? contentCreation.video.width : null),
        tags: contentCreation.tags || [],
        subtitles: contentCreation.closedCaptions || null,
        embededContent: contentCreation.embededContent || null,
        downloadable: contentCreation.downloadable,
        requireLogin: contentCreation.require_login,
        requireDrm: contentCreation.require_drm,
        limited: contentCreation.limited,
        viewLimit: contentCreation.view_limit,
        timeToTrack: contentCreation.time_to_track,
        onlyArgentina: contentCreation.only_argentina,
        allowDownload: contentCreation.allow_download,
        sentView: false,
        viewed: false,
        views: contentCreation.views,
        trackingToken: contentCreation.tracking_token || false,
        paymentRequired: contentCreation.payment_required || false,
        hashcode: contentCreation.hashcode,
        key,
        inappropriate_content: contentCreation.inappropriate_content,
        downloadLink: contentCreation.download_url || null,
        accessLevelRequired: !!contentCreation.access_level_required,
        translations: contentCreation.translations || [],
      }));

      return setStatePromise(this,{
        contentCreator: {
          id: resCreator.id,
          name: resCreator.name,
          email: resCreator.email,
          description: resCreator.description,
          hasCoverImage: !!resCreator.coverImage,
          coverImage: resCreator.coverImage || resCreator.image,
          tinyCoverImageUrl: resCreator.coverImage ? makeFitImageUrlWithSize({image: resCreator.coverImage}, 'xs') : ( resCreator.image ? makeFitImageUrlWithSize(resCreator, 'xs') : resCreator.type.type === 'audiovisual' ? ContentCreatorBackground : null),
          coverImageUrl: resCreator.coverImage ? makeFitImageUrlWithSize({image: resCreator.coverImage}, 'lg') : ( resCreator.image ? makeFitImageUrlWithSize(resCreator, 'lg') : resCreator.type.type === 'audiovisual' ? ContentCreatorBackground : null),
          mobileCoverImageUrl: resCreator.mobileCoverImage ? makeFitImageUrl({image: resCreator.mobileCoverImage}, 450, 200) : null,
          imageUrl: resCreator.image ? makeFitImageUrlWithSize(resCreator, 'md') : ProfilePicture,
          country: resCreator.country,
          type: resCreator.type,
          socialLinks: resCreator.socialLinks,
          downloadableMaterial: resCreator.downloadableMaterial,
          activityStart: resCreator.activity_start || resCreator.created_at,
          tags: (resCreator.tags && resCreator.tags.length) ? resCreator.tags.filter(t => !t.group.is_admin) : [],
          suggestedPlaylist: resCreator.suggestedPlaylist || null,
          suggestedCreators: resCreator.relatedContents && resCreator.relatedContents.length >0 ? resCreator.relatedContents : null,
          hasSuggested: resCreator.has_suggested,
          hashcode: resCreator.hashcode,
          suggestedView: resCreator.suggested_view,
          suggestedTagGroup: resCreator.suggested_tag_group,
          suggestedMultipleRows: resCreator.suggested_view_multiple_row,
          showTags: resCreator.show_tags,
          paymentRequired: resCreator.payment_required,
          preferences: get(resCreator, "preferences") ? resCreator.preferences : (get(getStoredChannel(), "preferences") ? (resCreator.type.type != 'editorial' ? get(getStoredChannel(), "preferences.content_creator") : get(getStoredChannel(), "preferences.content_creator.editorial")) : null),
          additions: resCreator.additions,
          isEvent: resCreator.is_event,
          showDate: resCreator.show_date_on_home,
          translations: resCreator.translations,
          eventDates: resCreator.eventDates.filter(ed => ed.preferences && !(ed.preferences instanceof Array && ed.preferences.length == 0))
          .map(ed => {
            if (ed.preferences && ed.preferences.button && ed.preferences.button.start_date) {
              ed.preferences.button.start_date = moment(`${ed.preferences.button.start_date} +0000`, "YYYY-MM-DD HH:mm Z");
            }

            return {
              date: moment(`${ed.date.substr(0,10)} ${ed.time} +0000`, "YYYY-MM-DD HH:mm Z"),
              duration: ed.duration,
              button: ed.preferences.button,
              clarification: ed.preferences.clarification,
              title: resCreator.name,
              description: resCreator.synopsis || '',
            }
          }),
          canContact: (getActiveChannel() && getStoredChannel() && this.getCanContactForChannel(resCreator.type)) || !!resCreator.email,
          buyButton: resCreator.buy_button_product_id ? {text: resCreator.buy_button_product_text, productId: resCreator.buy_button_product_id, showInSocials: resCreator.buy_button_show_in_socials, isGiftable: resCreator.buyButtonProduct ? resCreator.buyButtonProduct.is_giftable : false} : null,
          canonicalChannel: get(resCreator, "mainChannel.domain.url", null),
          blockedForCountry: resCreator.blocked_for_country,
          accessLevelRequired: resCreator.access_level_required,
          additional: resCreator.additional_info_size,
          synopsis: resCreator.synopsis,
          entrance: resCreator.entrance,
          when: resCreator.when,
        },
        contentCreations,
        tags: this.getTagsFromContentCreations(contentCreations),
        selectedContentCreation: contentCreations[0],
        creationsLoaded: true,
        containerType: resCreator.type.type,
        requireLogin: resCreator.require_login,
        views: resCreator.views,
        favorite: getContentCreatorFavorites().includes(resCreator.id),
        viewType: viewType,
      })

    })
    .then(() => this.state.contentCreator.blockedForCountry && request.contentCreator.canAccess(this.state.contentCreator.id))
    .then(() => this.state.contentCreator.accessLevelRequired && 
        request.user.hasAccessLevel("contentCreator" ,this.state.contentCreator.id).then(res => !res.can_access && this.setState({accessDenied: true})))
    .then(this.filterContentCreationsBySelectedTags)
    .then(() => {
      let params = (new URL(document.location)).searchParams;
      const origin = params.get('origin') ? params.get('origin') : (this.props.origin || "" )
      
      if (shareToken || this.props.isPublic) {
        analyticsInstance.visitShareContent({
          name: getMainTranslation(this.state.contentCreator, "name"),
          content_type: "Content",
        })
      }else {
        analyticsInstance.visitContent({
          name: getMainTranslation(this.state.contentCreator, "name"),
          content_type: "Content",
          from_name: origin,
        })
      }

      this.state.contentCreator.coverImageUrl && this.setState({contentCreator: { ...this.state.contentCreator}, origin: origin})
    })
    .then(() => {
      this.setState({loaded: true})
      this.props.onShowModalFooter && this.props.onShowModalFooter()
    })
    .catch((res) => {
      console.log('catch', res)
      if (res.status === 403) {
        this.setState({notAvailable: true})
      } else {
        if (!this.props.modal) {
          browserHistory.push(getActiveChannel() ? channelPathGenerator('') : homePathGenerator(''))
        } else {
          this.props.onModalClose()
        }
      }
    });
  };

  getCanContactForChannel = (type) => {
    let canContact = false;
    let currentCategory = !!getStoredChannel().channelContentCreatorTypes ? getStoredChannel().channelContentCreatorTypes.find(cType => cType.contentCreatorType.id === type.id) : '';

    if (currentCategory && currentCategory.contact_email) canContact = true;

    return canContact;
  };

  getTagsFromContentCreations = contentCreations => {
    let tags = [];
    
    contentCreations.forEach(contentCreation => {
      contentCreation.tags.forEach(tag => {
        !tags.find(t => t.id === tag.id) && tags.push(tag)
      })
    });
    return tags.sort((a, b) => a.name.localeCompare(b.name));
  };

  showLoginDialog = () => GlobalRedirectLoginConfirmationDialog.show({
    title: localization.get("login_redirect"),
    content: getStoredChannel().login_redirect_text || localization.get("login_redirect_text"),
    request: () => new Promise(resolve => {
      browserHistory.push(channelPathGenerator(`login?redirect_to=${this.props.modal ? window.location.pathname+window.location.search : window.location.pathname}`))
      resolve()
    }),
    confirmationLabel: localization.get("login_continue")
  });


  onSelectedTagChanged = selectedTag => {
    this.setState({
      tags: this.updateTagGroups(this.state.tags, selectedTag),
      lastTagSelected: selectedTag
    }, () => this.filterContentCreationsBySelectedTags())
  }

  updateTagGroups = (tags, selectedTag) => {
    return tags.map(tag => ({
      ...tag,
      state: (
        selectedTag &&
        (selectedTag !== this.state.lastTagSelected || (tag.state === 'selected' && selectedTag === this.state.lastTagSelected)) &&
        selectedTag.toString() === tag.id.toString()
      ) ? 'selected' : 'available'
    }))
  }

  filterContentCreationsBySelectedTags = () => {
    let {contentCreations} = this.state;

    let selectedTag = this.state.tags.find(tag => tag.state === 'selected');

    let contentCreationsBySelectedTags = contentCreations
      .filter(contentCreation => (
        get(contentCreation, 'link', false) ||
        get(contentCreation, 'binary', false) ||
        get(contentCreation, 'imageUrl', false) ||
        get(contentCreation, 'video.url', false) ||
        get(contentCreation, 'video.dash', false) ||
        get(contentCreation, 'video.hls', false)  ||
        get(contentCreation, 'embededContent', null)
      ));

    let audioContentCreationsBySelectedTags = contentCreations
      .filter(contentCreation => contentCreation.audio);

    if (selectedTag) {
      const filterTags = contentCreation => contentCreation.tags.some(tag => tag.id === selectedTag.id);

      contentCreationsBySelectedTags = contentCreationsBySelectedTags.filter(filterTags);
      audioContentCreationsBySelectedTags = audioContentCreationsBySelectedTags.filter(filterTags);
    }

    this.setState({contentCreationsBySelectedTags, audioContentCreationsBySelectedTags});
  }

  renderShareDialog = () => {
    return (
      <ShareDialog
        show={this.state.showShareDialog}
        handleClose={() => this.setState({showShareDialog: false})}
        entity={'contentCreator'}
        id={this.props.contentCreatorId}
      />
    )
  };

  //scroll = ()  => window.scrollTo(0, this.myRef.current.offsetTop + 175)
  scroll = ()  => document.getElementById('reference-scroll-position').scrollIntoView({
    block: "start",
    behavior: "smooth",
  })

  notAvailableDialog = () => {
    return (
      <div>
        {
          GlobalConfirmationDialog.show({
          title: localization.get("content.not_available"),
          content: localization.get("content.not_available_for_country"),
          onClose: () => this.props.modal && this.setState({notAvailable: false}),
          hideCancel: true,
          request: () => Promise.resolve().then(!this.props.modal ? browserHistory.push(getActiveChannel() ? channelPathGenerator('') : homePathGenerator('')) : this.props.onModalClose())
        })
        }
      </div>
    )
  }

  accessDeniedDialog = () => {
    return (
      <div>
        {
          GlobalConfirmationDialog.show({
            title: localization.get("content.access_denied"),
            content: localization.get("content.not_enough_permissions"),
            onClose: () => this.props.modal ? this.props.onModalClose() : browserHistory.goBack(),
            hideCancel: true,
            request: () => Promise.resolve().then(!this.props.modal ? browserHistory.push(getActiveChannel() ? channelPathGenerator('') : homePathGenerator('')) : this.props.onModalClose())
          })
        }
      </div>
    )
  }


  render = () => {
    let {loaded, contentCreator, contentCreations, accessDenied} = this.state;

    if(this.state.requireLogin && !getStoredUser() && !!getStoredChannel()){
      return (<>{this.showLoginDialog()}</>);
    }

    if (accessDenied) {
      return this.accessDeniedDialog();
    }

    if (this.state.notAvailable) {
      return this.notAvailableDialog();
    }

    if (!loaded) {
      return (
        <div style={{ height: "100%", display: "flex", justifyContent: "center", alignContent: "center" }}>
          <img style={{width: 100, height: 100, marginTop: "calc(50vh - 50px)"}} src={LoadingSpinner} alt="Loading logo"/>
        </div>
      )
    }

      if (this.state.containerType === 'audiovisual') {
        return (
          <AudiovisualCreator
            {...this.props}
            contentCreator={contentCreator}
            contentCreations={contentCreations}
            requireLogin={this.state.requireLogin}
            favorite={this.state.favorite}
            tags={this.state.tags}
            selectedContentCreation={this.state.selectedContentCreation}
            audioContentCreationsBySelectedTags={this.state.audioContentCreationsBySelectedTags}
            contentCreationsBySelectedTags={this.state.contentCreationsBySelectedTags}
            showLoginDialog={this.showLoginDialog}
            preview={this.props.preview}
            reloadContentCreator={this.fetchContentCreator}
            origen={this.state.origin}
            creationsLoaded={this.state.creationsLoaded}
            viewType={this.state.viewType}
          />
        );
      }

      if (this.state.containerType === 'editorial') {
        return (
          <EditorialCreator
            {...this.props}
            contentCreator={contentCreator}
            favorite={this.state.favorite}
            showLoginDialog={this.showLoginDialog}
          />
        )
      }

   };
}

ContentCreator.propTypes = {
  contentCreatorId: PropTypes.number.isRequired
};

const AudioBar = styled.audio`
margin-top: 10px;
margin-bottom: 20px;
width:400px;
@media (max-width: 470px) {
  width: 80vw;
}
`;

const CarouselItem = styled.div`
  background-color: white;
  height: 0;
  overflow: hidden;
  padding-top: calc(591.44 / 1127.34 * 100%);
  position: relative;
  &:hover {
    box-shadow: 0 0 5px 0 dimgray;
  }
`
