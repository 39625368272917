import LocalizedStrings from 'react-localization'
import localData from './localization.json'
import availableLanguages from './availableLanguages.json'
import {isNullOrUndefined} from 'util'
import {getActiveChannel, getStoredChannel, getStoredUser} from "../../api-client/core/authentication/utils";
import moment from 'moment-with-locales-es6';

const strings = new LocalizedStrings(localData)

const get = (id, ...replace) => {
  const value = strings[id]
  if (!value) {
    return id
  }
  return replace.reduce((acc, next, i) => acc.replace(`{${i}}`, next), value)
}

const has = (id) => !isNullOrUndefined(strings[id])

const setDefaultLanguage = () => {
  let languageCode;
  if (getStoredUser() && getStoredUser().language) {
    languageCode = getStoredUser().language
  } else {
    languageCode = strings.getInterfaceLanguage().split('-')[0]
  }

  if (getActiveChannel()) {
    languageCode = localStorage.getItem("default_language")
  }else if (!currentAvailableLanguages().includes(languageCode)) {
    languageCode = 'es'
  }

  strings.setLanguage(languageCode)
}

const setLanguage = (language) => {
  if (!currentAvailableLanguages().includes(language)) {
    language = 'es'
  }

  strings.setLanguage(language)
}

const getLanguage = () => {
  if(!getActiveChannel() && getStoredUser() && getStoredUser().language) {
    return getStoredUser().language
  }

  return strings.getLanguage()
}

const setMomentLocale = () => {
  let languageCode = localStorage.getItem("default_language")
  if (!getActiveChannel()) {
    languageCode = getStoredUser().language || strings.getLanguage()
  }
  return moment.locale(languageCode);
}

const currentAvailableLanguages = () => {
  let languages = availableLanguages;
  if (getActiveChannel() && getStoredChannel().languages) {
    languages = getStoredChannel().languages.map(lang => lang.language);
  }
  return languages.filter(lang => lang !== 'es').concat(['es'])
} 

export default {
  get,
  has,
  setLanguage,
  getLanguage,
  setDefaultLanguage,
  setMomentLocale,
  moment
}
