import React, {Component} from 'react'
import {Card, CardActions, CardContent, Grid, IconButton, Typography} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import localization from '../../../config/localization';
import HomeBannerElement from './HomeBannerElement';
import HomeBannerElementForm from './HomeBannerElementForm';
import {request} from '../../../api-client';
import GlobalConfirmationDialog from '../../../components/Letflow/Dialog/GlobalConfirmationDialog';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import GlobalSnackbar, { GlobalSnackbarTypes } from '../../../components/Letflow/Snackbar/GlobalSnackbar';

class HomeBanners extends Component {

  constructor(props) {
    super(props)
    this.state = {
      banners: props.items || [],
      showForm: false,
      showBanner: props.showBanner || ''
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.showBanner !== this.props.showBanner) {
      this.setState({showBanner: nextProps.showBanner})
    }
  }


  makeBannerForm = () => this.state.showForm &&
    <HomeBannerElementForm
      clientId={this.props.clientId}
      formTitle={localization.get('form.title')}
      onClose={() => this.setState({ showForm: false })}
      submitRequest={(active, title, subtitle, link, newTab, imageId, video, preview, hasButton, button, halfHeight, onProgress) => {
        if (!imageId && !video) {
          GlobalSnackbar.show({ message: localization.get('home_editor.banners_element_image_required'), type: GlobalSnackbarTypes.ERROR })

          return new Promise((resolve, reject) => reject());
        }

        const channelId = this.props.channelId
          return request.client.home.elements.createBannerItem(
              this.props.clientId,
              this.props.id,
              {active, title, subtitle, link, newTab, imageId, video, preview, hasButton, button, halfHeight, channelId},
              onProgress
            )
          .then(this.props.onUpdateSuccess)
          .then(() => this.setState({ showForm: false }))
          .catch(this.props.onUpdateFailed)
      }}
    />

  requestDelete = () => request.client.home.elements.delete(this.props.clientId, this.props.id).then(this.props.onUpdateSuccess).catch(this.props.onUpdateFailed)

  handleDelete = () => GlobalConfirmationDialog.showDelete({ elementName: 'Banner', deleteRequest: this.requestDelete })

  render = () =>
    <React.Fragment>
      <FormControl fullWidth style={{padding: "0 15px", marginTop: 15}}>
        <InputLabel style={{marginLeft: 15}} htmlFor="banner">Configuración Banners</InputLabel>
        <Select
          id="showBanner"
          name="showBanner"
          value={this.state.showBanner}
          onChange={(e) => {
            if(this.state.showBanner !== e.target.value) {
              this.setState({showBanner: e.target.value})
              this.props.onChangeShowBanner && this.props.onChangeShowBanner(e.target.value);
              request.channel.updateBanner(this.props.channelId, {show_banner: e.target.value})
            }
          }}
          input={<Input name="showBanner" id="showBanner" />}
          style={{marginBottom: "10px"}}
        >
          <MenuItem value="none">{localization.get('account.banner.none')}</MenuItem>
          <MenuItem value="owned">{localization.get( !this.props.channelId ? 'account.banner.owned' : 'account.banner.unique')}</MenuItem>
          {this.props.channelId && <MenuItem value="multi">{localization.get('account.banner.multi')}</MenuItem>}
        </Select>
        <FormHelperText style={{lineHeight: "1.4em"}}>{this.state.showBanner === 'none' ? 'No se mostraran banners' : this.state.showBanner === 'default' ? 'Se mostraran banners realizados por el equipo artistico de Flowlike' : ''}</FormHelperText>
      </FormControl>
      
        <Card style={{ width: '100%', minWidth: 550 }}>
          {this.makeBannerForm()}
          <CardContent style={{minHeight: 250, minWidth: 650}}>
            <Typography variant='display1'>{localization.get((this.state.showBanner === 'owned' || this.state.showBanner === 'multi') ? 'home_editor.banners': 'account.banner.none')}</Typography>
            {this.state.showBanner === 'owned' || this.state.showBanner === 'multi' ?
              <>
                {this.state.banners.length === 0 && <Typography>{localization.get('home_editor.banners_element_empty')}</Typography>}
                <Grid style={{ marginTop: '20px' }} spacing={16} container>
                  {this.state.banners.map((banner, i) => 
                    <Grid xs={12} sm={6} md={4} item>
                      < HomeBannerElement {...this.props} banner={banner} clientId={this.props.clientId} elementId={this.props.id} itemIndex={i} />
                    </Grid>
                  )}
                </Grid>
              </>
            : null }
          </CardContent>
          {(this.state.showBanner === 'owned' || this.state.showBanner === 'multi') &&
            <CardActions style={{ float: 'right' }}>
              <IconButton onClick={() => this.setState({ showForm: true })}><Add /></IconButton>
            </CardActions>
          }
        </Card>
      
    </React.Fragment>
}

export default HomeBanners