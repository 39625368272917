import "layouts/Home/HomeElements/PublicFooter.css";
import React from "react";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import {Link} from "react-router-dom";
import FreshChat from "../../../utils/FreshChat";
import localization from "../../../config/localization";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {Facebook, Instagram, Web, Youtube} from "components/Letflow/SocialIcons";
import styled from "styled-components";
import {checkIfTwins} from "../../../api-client/core/authentication/utils";

class PublicFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideMenu: "close",
      height: "0%",
    };
  }

  render() {
    return (
      <footer>
        {/*<div className="footer-top">
          <div className="container">
            <div className="row-footer">
              <div className="text-left">
                <img src={logo} alt="Logo flowlike" />
                <p style={{ color: "#777", fontSize: "11px" }}>
                  {localization.get('footer.description')}
                </p>
              </div>
              <div className="text-right footer-menu">
                <ul className="nowrap">
                  <li>
                    <Link to={"#"} onClick={(e) => {
                      e.preventDefault()
                      FreshChat.open()
                    }}>
                      <span className="link-footer">{localization.get("footer.support")}</span>
                    </Link>
                  </li>
                  <li>
                    <Tooltip title="Youtube" placement="bottom">
                      <Link className="footer-social-link" target="_blank" to="https://www.youtube.com/c/FlowlikeMusic/" rel="nofollow">
                        <Youtube />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Facebook" placement="bottom">
                      <Link className="footer-social-link" target="_blank" to="https://www.facebook.com/Flowlikemusic/" rel="nofollow">
                        <Facebook />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Instagram" placement="bottom">
                      <Link
                        className="footer-social-link"
                        target="_blank"
                        to="https://www.instagram.com/flowlikemusic/"
                        rel="nofollow"
                      >
                        <Instagram />
                      </Link>
                    </Tooltip>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>*/}
        <div className="footer-bottom" style={{bottom: -17}}>
          <div className="container-footer">
            <div className="row-footer-bottom">
              <MobileDiv>
                <h6 style={{ marginRight: 20}}>
                  <small>© {new Date().getFullYear()} Feater</small>
                </h6>
                {!this.props.hideSupport &&
                  <>
                    <FooterDocumentLink
                      requestRoute={`${process.env.REACT_APP_API_HOST}/documents/privacyPolicy/download?locale=${localization.getLanguage()}`}
                      title={localization.get('footer.privacy_policy')}
                    />
                    <FooterDocumentLink
                      requestRoute={`${process.env.REACT_APP_API_HOST}/documents/termsOfUse/download?locale=${localization.getLanguage()}`}
                      title={localization.get('footer.term_of_use')}
                    />
                  </>
                }
              </MobileDiv>

              <MobileUl className="nowrap">
                <>
                  {!this.props.noSocials &&
                  <li>
                    {checkIfTwins() &&
                      <Tooltip title="Twins" placement="bottom">
                        <Link 
                          className="footer-social-link" 
                          style={{display: 'flex', alignItems: 'center'}}
                          target="_blank"
                          to="https://www.twinsmusic.com.ar"
                          rel="nofollow">
                          <Web/>
                        </Link>
                      </Tooltip>
                    }
                    <Tooltip title="Instagram" placement="bottom">
                      <Link
                        className="footer-social-link"
                        style={{display: 'flex', alignItems: 'center'}}
                        target="_blank"
                        rel="nofollow"
                        to={checkIfTwins() ? "https://www.instagram.com/twinsmusicplatform/": "https://www.instagram.com/feater/"}
                      >
                        <Instagram/>
                      </Link>
                    </Tooltip>
                  </li>
                  }
                </>
              </MobileUl>
            </div>
          </div>
        </div>
      </footer>

    );
  }
}

export default withStyles(pagesStyle)(PublicFooter);

const FooterDocumentLink = withStyles({
  title: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
})(({ classes, requestRoute, title }) => {
  return (
    <Link to={requestRoute} target="_blank" rel="nofollow">
      <h6 style={{ marginRight: 20 }}>
        <small className={classes.title}>
          {title}
        </small>
      </h6>
    </Link>
  );
});

const MobileDiv = styled.div`
display: flex;
flex-direction: row;
align-items: center;
`;

const MobileUl = styled.ul`
margin: -5px;
place-self: end;
display: flex;
flex-direction: row;
align-items: center;
`;