import React from "react";
import localization from "../../../config/localization";
import Logo from "assets/img/logo-black.png";
import {Link} from 'react-router-dom'
import {makeFitImageUrl} from "../../../utils";
import {checkIfTwins, getDomainClient} from "../../../api-client/core/authentication/utils";
import {request} from "../../../api-client";
import {onImageError} from "./utils";

class PublicNavBar extends React.Component {
  state = {
    currentClient: null
  }

  componentDidMount() {
    if(!!getDomainClient() && getDomainClient().id) {
      request.client.getPublic(getDomainClient().id)
        .then(client => this.setState({currentClient: client}))
    }
  }

  render() {
    const {client, publicLogo, showLinks, isPlaylist, brand} = this.props


    return (
      <nav className="navbar" style={{backgroundColor: "white", position: "relative", boxShadow: "none"}}>
        <div className="navbar-image-container" style={{ marginLeft: "40px" }}>
          <div className="navbar-image-container">
            <a className="navbar-image-container-link" href={ !isPlaylist ? (checkIfTwins() ? "https://www.twinsmusic.com.ar" : process.env.REACT_APP_WEB_HOST) : '#'}>
            {publicLogo ?
                <>
                  {this.state.currentClient ?
                    <React.Fragment>
                      <img className="navbar-image" src={(this.state.currentClient && this.state.currentClient && this.state.currentClient.image) ? makeFitImageUrl(this.state.currentClient, 300,35, '', 'png') : Logo} alt="Logo Feater" onError={onImageError}/>
                    </React.Fragment>
                    :
                    <div className="navbar-public-logo">{publicLogo}</div>
                  }
                </>
                :
                <React.Fragment>
                  {(brand && brand.image) ? 
                    <img className="navbar-image" src={makeFitImageUrl(brand, 300,35, '', 'png')} alt="Logo" onError={onImageError}/>
                  :client ?
                      <img className="navbar-image" src={(client && client.image) ? makeFitImageUrl(client, 300,35, '', 'png') : ((this.state.currentClient && this.state.currentClient.image) ? makeFitImageUrl(this.state.currentClient, 300,35, '', 'png') : Logo)} alt="Logo" onError={onImageError}/>
                    :
                    <>
                      <React.Fragment>
                        <img
                          className="navbar-image"
                          src={
                            (this.state.currentClient && this.state.currentClient  && this.state.currentClient.image) ?
                              makeFitImageUrl(this.state.currentClient, 300,35, '', 'png') :
                              Logo}
                          alt="Logo" 
                          onError={onImageError}/>
                      </React.Fragment>
                    </>
                  }
                </React.Fragment>
              }
            </a>
          </div>
        </div>
        {showLinks ?
          <div className="navbar-links" style={{ marginLeft: "50px" }}>
            <a className="navbar-link" href={process.env.REACT_APP_WEB_HOST}>Home</a>
            <a className="navbar-link" href={`${process.env.REACT_APP_WEB_HOST}?section=trial`}>Trial</a>
            <a className="navbar-link" href={`${process.env.REACT_APP_WEB_HOST}?section=contact_us`}>{localization.get('public_navbar.contact_us')}</a>
            <a className="navbar-link" href={`/spotlights`}>Spotlights</a>
            <a className="navbar-link" href={`${process.env.REACT_APP_WEB_HOST}/artists`}>¿Sos artista?</a>
          </div>
          : null}
        <div className="navbar-user-container" style={{ top: "27px", width: 100, display: "block" }}>

        </div>
      </nav>
    )
  }
}

const Logout = () =>
  <a className="navbar-link" onClick={e => {
    e.preventDefault()
    request.authentication.logout()
  }}>{localization.get('logout')}</a>

PublicNavBar.defaultProps = {
  showPanel: true
}

export default PublicNavBar
