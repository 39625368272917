import React from "react";
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {request} from "../../../api-client";
import {Close} from "@material-ui/icons";
import styled from "styled-components";
import GlobalConfirmationDialog from "../Dialog/GlobalConfirmationDialog";
import localization from "../../../config/localization";
import ShakaPlayer from "../ShakaPlayer";
import {makeThumbnailUrlWithSize, onImageError} from "../../../layouts/Home/HomeElements/utils";

export default class ContentCreationPlayer extends React.Component {

  render() {
    let currentContentCreation = this.props.currentContentCreation

    if (!this.props.dashboard && currentContentCreation && currentContentCreation.only_argentina && currentContentCreation.location !== 'Argentina' && this.props.show) {
      return (
        <div>
          {
            GlobalConfirmationDialog.show({
              title: localization.get("content.not_available"),
              content: localization.get("content.not_available_for_country"),
              hideCancel: true,
              request: () => Promise.resolve().then(this.props.handleClose())
            })
          }
        </div>
      )
    }

    if (currentContentCreation && currentContentCreation.binary && currentContentCreation.binary.url) {
      window.open(currentContentCreation.binary.url, '_blank')
      this.props.handleClose();
      return null;
    }

    return (
      <Dialog open={this.props.show} onClose={() => this.props.handleClose()}>
        <DialogContent style={{display:"grid", paddingBottom:0, padding: 5}}>
          {(currentContentCreation && currentContentCreation.audio && currentContentCreation.audio.url && !currentContentCreation.video) &&
          <React.Fragment>
            <p style={{ padding:'2% 10% 5%', float:"left", position: "relative", fontSize:'1.25rem'}}>{currentContentCreation.audio.original_name}</p>
            <AudioBar src={currentContentCreation.audio.url} type="audio/mpeg" preload="metadata" controls controlsList="nodownload" autoPlay> </AudioBar>
          </React.Fragment>
          }
          {(currentContentCreation && currentContentCreation.video) &&
          <VideoDiv>
            {(currentContentCreation.video.url && currentContentCreation.video.url.toLowerCase().endsWith('.mov')) ?
              <video width='100%' height='100%' controls autoPlay controlsList={"nodownload"}
               onContextMenuCapture={(event) => {
                 event.preventDefault();
                 return false
               }}
              >
                <source src={currentContentCreation.video.url} type="video/mp4"/>
              </video> :
              <ShakaPlayer
                material={currentContentCreation}
                video={currentContentCreation.video}
                thumbnail={makeThumbnailUrlWithSize(currentContentCreation.video)}
                preview={false}
                style={{maxWidth: "100%"}}
              />
            }
          </VideoDiv>
          }
          {(currentContentCreation && currentContentCreation.image && !currentContentCreation.video) &&
          <img alt="photo" style={{maxWidth: "100%"}} src={currentContentCreation ? request.file.image.makeUrl(currentContentCreation.image.id, "file", "png") : ''} onError={onImageError}/>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.handleClose()}><Close /></Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const AudioBar = styled.audio`
/*margin: 20px 2%;*/
@media (max-width: 440px) {
  width: 100%;
}
`;

const VideoDiv = styled.div`
  width: 552px;
  height: 310.5px;
  background-color: black;
@media (max-width: 500px) {
  width: 250px;
  height: auto;
}
`;