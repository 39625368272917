import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import {makeUrlWithTableParams} from "../../../utils";
import localization from "../../../config/localization";
import TableSubcomponent from "./TableSubcomponent";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {AccessTime, Delete, Done, FilterNone, GetApp, Style, Visibility} from "@material-ui/icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel, MenuItem, Radio,
  RadioGroup, Select, TextField, Tooltip
} from "@material-ui/core";
import CatalogSelection from "../../../components/Letflow/CatalogSelection";
import AsyncSelect from "react-select/lib/Async";
import {
  ROLES,
  userIsSupervisor,
  userIsSysAdmin
} from "../../../api-client/core/authentication/utils";
import {Link} from "react-router-dom";
import TagSelection from "../../../components/Letflow/TagSelection/TagSelection";
import queryString from "querystring";
import {makeImageUrlWithSize} from "../../../layouts/Home/HomeElements/utils";
import IconButton from "@material-ui/core/IconButton";
import browserHistory from "../../../utils/browserHistory";
import Clear from "@material-ui/icons/Clear";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import Checkbox from "@material-ui/core/Checkbox";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import CustomShareContent from "./CustomShareContent";
import moment from "moment";
import _noImage from '../../../assets/img/Letflow/no-image.jpg'
import {get} from "lodash";

class Table extends Component {
  state = {
    total: "",
    openCatalogDialog: false,
    openIntegrateDialog: false,
    contentCreatorId: '',
    contentCreatorType: '',
    selectedCatalog: '',
    types: [],
    showTagSelection: false,
    regionLock: 'false',
    showTagDialog: false,
    tagGroups: [],
    selectedTags: [],
    unselectedTags: [],
    loaded: false,
    name: null,
    active: null,
    content_creator_type_id: null,
    cloud: null,
    clouds: [],
    updateVisible: "keep",
    updateWeight: false,
    selectedContentCreatorType: null,
    weight: "keep",
    selectedRetags: [],
    updateCategory: false,
    openShareDialog: false,
    sharingCreator: null,
    updateCategoryCheck: false,
    retagMethod: "add",
    updateAccessLevel: "keep",
  };

  componentDidMount() {
    this.requestContentCreatorTypes()
      .then(() => {
        let queryParams = queryString.parse(this.props.location.search)['?table_params']
        
        if (queryParams && JSON.parse(queryParams).filtered.length > 0 ) {
          const prevState = JSON.parse(queryParams).filtered.reduce((prev, state) => {
            return ({...prev, [state.id]: state.value})
          }, {})
          this.setState({
            ...prevState,
            selectedTags: eval(localStorage.getItem("selectedTags")) ? JSON.parse(eval(localStorage.getItem("selectedTags"))) : [],
            unselectedTags: eval(localStorage.getItem("unselectedTags")) ? JSON.parse(eval(localStorage.getItem("unselectedTags"))) : [],
            showTagSelection: true,
            selectedType: this.state.types.find(type => type.id == JSON.parse(queryParams).filtered[0].value),
          }, () => {
            this.paintAllTags().then(() => this.setState({loaded: true}))
          })
        } else {
          this.setState({loaded: true})
        }
      })
      .then(this.getClouds)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.requestContentCreatorTypes().then(() => this.refs.table.forceUpdate())
    }
  }

  requestContentCreatorTypes = () => {
    const filter = this.state.catalog ? {filterBy: [{ column: 'catalog_id', filter: this.state.catalog.value }]} : {filterBy: []}
    filter.filterBy.push({
      column: "is_talent",
      filter:  this.props.isTalent ? 1 : 0
    },
    {
      column: "dashboard_combos",
      filter: true
    });

    filter.filterBy.push({
      column: "type",
      filter: this.props.isEditorial ? "editorial" : "audiovisual"
    })


    filter.filterBy.push({column: "for_create", filter: true})


    return request.contentCreatorType.getAll(filter)
      .then(contentCreatorTypes => {
        const types = contentCreatorTypes.map(contentCreatorType => ({id: contentCreatorType.id, label: contentCreatorType.name, slug: contentCreatorType.slug, client: contentCreatorType.client}))
        this.setState({types})
      })
  }

  handleAlertClosed = artistId => status => {
    if (status === "passed") this.refs.table.removeById(artistId);
  };

  buttons = contentCreator => {
    const {id, name, type} = contentCreator;

    let editUrl = '';
    if(this.props.isDemo) {
      editUrl = makeUrlWithTableParams(`/panel/demos/content_creators/${id}/edit?is_demo=true`);
    } else if (this.props.isTalent) {
      editUrl = makeUrlWithTableParams(`/panel/talents/content_creators/${id}/edit`);
    } else if (this.props.isEditorial) {
      editUrl = makeUrlWithTableParams(`/panel/editorial/content_creators/${id}/edit`);
    } else {
      editUrl = makeUrlWithTableParams(`/panel/audiovisual/content_creators/${id}/edit`);
    }


    const buttonBuilder = new TableButtonsBuilder();

    buttonBuilder.withOther(
      <TableButton
        title={localization.get("content_creators.duplicate")}
        onClick={() =>
          request.contentCreator.duplicate(id)
            .then(newContentCreator => {
              let duplicateUrl = '';
              if(this.props.isDemo) {
                duplicateUrl = makeUrlWithTableParams(`/panel/demos/content_creators/${newContentCreator.id}/edit?is_demo=true`);
              } else if (this.props.isTalent) {
                duplicateUrl = makeUrlWithTableParams(`/panel/talents/content_creators/${newContentCreator.id}/edit`);
              } else if (this.props.isEditorial) {
                duplicateUrl = makeUrlWithTableParams(`/panel/editorial/content_creators/${newContentCreator.id}/edit`);
              } else {
                duplicateUrl = makeUrlWithTableParams(`/panel/audiovisual/content_creators/${newContentCreator.id}/edit`);
              }
              browserHistory.push(duplicateUrl)
            })
        }
      >
        <FilterNone />
      </TableButton>
    );

    if (this.props.isDemo) {
      buttonBuilder.withOther(
        <TableButton
          title={localization.get("demo.integrate_to_platform")}
          onClick={() => {
            this.setState({openIntegrateDialog: true, contentCreatorId: id, contentCreatorType: type.slug})
          }}
        >
          <Done/>
        </TableButton>
      );
    }else {
      buttonBuilder.withOther(
        <TableButton
          title={localization.get('preview')}
          onClick={() =>
            this.setState({
              openShareDialog: true,
              sharingCreator: contentCreator,
            })
          }
        >
          <Visibility/>
        </TableButton>
      )
    }

    buttonBuilder.withEdit(editUrl);
    buttonBuilder.withOther(
      <TableButton
        title={localization.get('preview')}
        onClick={() =>
          GlobalConfirmationDialog.showDelete({
            dialogTitle: `${localization.get('tooltip.delete')} ${contentCreator.name}`,
            content: localization.get('confirmation_dialog.delete_content_message'),
            doubleConfirmationText: localization.get('confirmation_dialog.delete_confirmation'),
            elementName: localization.get("delete"),
            deleteRequest: () => request.contentCreator.delete(id)
              .then(this.refs.table.forceUpdate),
            doubleConfirmation: true,
            auxiliaryInformation: "",
          })
        }
      >
        <Delete />
      </TableButton>
    )

    return buttonBuilder.build();
  };

  makeDownloadLink = () => request.authentication.refreshAccessToken().then(() => window.open(request.contentCreator.getDownloadLink(this.state.paginationConfig), '_blank'))


  makeIntegrateDialog = () =>
    <Dialog open={this.state.openIntegrateDialog} onBackdropClick={() => this.setState({openIntegrateDialog: false})} onEscapeKeyDown={() => this.setState({openIntegrateDialog: false})}>
      <DialogTitle>{localization.get('demo.integrate_to_platform')}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
          <div>{localization.get('demo.integrate_to_platform_warning')}</div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({openIntegrateDialog: false})}>{localization.get('cancel')}</Button>
        <Button onClick={() => this.setState({openCatalogDialog: true, openIntegrateDialog: false})}>{localization.get('accept')}</Button>
      </DialogActions>
    </Dialog>

  makeCatalogSelectionDialog = () =>
    <Dialog open={this.state.openCatalogDialog} onBackdropClick={() => this.setState({openCatalogDialog: false})} onEscapeKeyDown={() => this.setState({openCatalogDialog: false})}>
      <DialogTitle>{localization.get('form.select_catalog')}</DialogTitle>
      <DialogContent>
        <div style={{display: 'flex', flexDirection: 'column', marginBottom: 10}}>
          <div>{localization.get('catalog.assign_instructions')}</div>
        </div>
        <div style={{alignItems: "center"}}>
          <FormControl fullWidth style={{height: 220}}>
            <AsyncSelect
              menuContainerStyle={{ zIndex: 10000 }}
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 100
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('form.catalogs')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={e => this.setState({selectedCatalog: e})}
              value={this.state.selectedCatalog}
              loadOptions={inputValue => {
                let filterBy = [{column: "name", filter: inputValue}];
                if (userIsSysAdmin() || userIsSupervisor()) {
                  filterBy = filterBy.concat([{column: "created_by_admin", filter: true}, {column: 'content', filter: this.state.contentCreatorType}])
                }
                return request.catalog
                  .getAll({filterBy})
                  .then(res => res.map(catalog => ({
                    value: catalog.id,
                    label: `${catalog.name} (${localization.get(catalog.visibility)})`
                  })))
                  .then(catalogs => catalogs.sort((a, b) => a.label.localeCompare(b.label)))
              }}
              defaultOptions
            />
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({openCatalogDialog: false})}>{localization.get('cancel')}</Button>
        <Button
          onClick={() => {
            const data = {
              id: this.state.contentCreatorId,
              selectedCatalogs: [this.state.selectedCatalog.value],
              isDemo: false,
            };
            request.contentCreator.update(data)
              .then((res) => {
                if (res.email) {
                  const data = {
                    name: res.name,
                    email: res.email,
                    selectedCreators: [res.id],
                    roleId: ROLES.CONTENT_CREATOR,
                  };
                  request.user.createFromDemo(data)
                    .then(() => {
                      this.refs.table.forceUpdate();
                      this.setState({openCatalogDialog: false})
                    })
                } else {
                  this.refs.table.forceUpdate();
                  this.setState({openCatalogDialog: false})
                }
              })
          }}>{localization.get('catalog.assign')}</Button>
      </DialogActions>
    </Dialog>

  clearAllTags = () =>
    new Promise(resolve => {
      this.setState( {tagGroups: this.state.tagGroups.map(tagGroup => {
          return {...tagGroup,
            tags: tagGroup.tags.map(tag => {
              return {...tag, state: 'available'}
            })
          }
        })
      })
      resolve()
    }).then(() => this.setState({selectedTags: [], unselectedTags: []}))

  setTagGroups = tagGroups => this.setState(state => ({...state, tagGroups}))

  updateTagColor = (selectedTag, value) =>
    this.setState( {tagGroups: this.state.tagGroups.map(tagGroup => {
          return {...tagGroup,
            tags: tagGroup.tags.map(tag => {
              if (tag.id === selectedTag) {
                return {...tag, state: value}
              }
              return tag
            })
          }
        }
      )
    })

  paintAllTags = () =>
    new Promise(resolve => {
      this.state.selectedTags.map(tag => this.updateTagColor(tag, "selected"))
      this.state.unselectedTags.map(tag => this.updateTagColor(tag, "unselected"))
      resolve()
    })

  onSelectedTagsChanged = selectedTag => {
    if (this.state.selectedTags.includes(selectedTag)) { //Remove from selected / add to unselected tags
      this.state.unselectedTags.push(selectedTag)
      this.setState({
        selectedTags: this.state.selectedTags.filter(tags => tags !== selectedTag),
        unselectedTags: this.state.unselectedTags
      }, () => this.updateTagColor(selectedTag, "unselected"))
    } else if(this.state.unselectedTags.includes(selectedTag)) { //Remove from unselected / add to available tags
      this.setState({
        unselectedTags: this.state.unselectedTags.filter(tags => tags !== selectedTag),
      }, () => this.updateTagColor(selectedTag, "available"))
    } else { //Remove from available / add to selected tags
      this.state.selectedTags.push(selectedTag);
      this.setState({selectedTags: this.state.selectedTags}, () => this.updateTagColor(selectedTag, "selected"))
    }
  };

  onSelectedRetagsChanged = selectedTag => {
    if (this.state.selectedRetags.includes(selectedTag)) {
      this.setState({
        selectedRetags: this.state.selectedRetags.filter(tags => tags !== selectedTag),
      }, () => this.updateRetagColor(selectedTag, "available"))
    }else {
      this.state.selectedRetags.push(selectedTag);
      this.setState({selectedRetags: this.state.selectedRetags}, () => this.updateRetagColor(selectedTag, "selected"))
    }
  };

  clearAllRetags = () =>
    new Promise(resolve => {
      this.setState( {tagGroupsAll: this.state.tagGroupsAll.map(tagGroup => {
          return {...tagGroup,
            tags: tagGroup.tags.map(tag => {
              return {...tag, state: 'available'}
            })
          }
        })
      })
      resolve()
    }).then(() => this.setState({selectedRetags: []}))

  updateRetagColor = (selectedTag, value) =>
    this.setState( {tagGroupsAll: this.state.tagGroupsAll.map(tagGroup => {
          return {...tagGroup,
            tags: tagGroup.tags.map(tag => {
              if (tag.id === selectedTag) {
                return {...tag, state: value}
              }
              return tag
            })
          }
        }
      )
    })

  makeUpdateDialog = () =>
    <Dialog open={this.state.showReTagDialog} onClose={() => this.setState({showReTagDialog: false,updateCategoryCheck: false}, this.clearAllRetags)} onBackdropClick={() => this.setState({showReTagDialog: false}, this.clearAllRetags)}>
      <DialogTitle>{localization.get('form.batch_update_alert')}</DialogTitle>
      <DialogContent style={{textAlign: "center"}}>
        <FormControl style={{float: "left", position: "relative", width: "100%"}}>
          <FormControlLabel
            control={
              <Checkbox
                id="updateCategoryCheck"
                style={{ color: "#4cc2d5", float: "left" }}
                checked={this.state.updateCategoryCheck}
                onChange={() => {
                  if (this.state.updateCategoryCheck) {
                    return request.tagGroup.getAll({filterBy: [
                        { column: 'type', filter: this.state.selectedType.slug },
                        { column: 'creation_group', filter: '0' },
                        { column: 'dashboard', filter: 1 },
                      ]})
                      .then(tags => this.setState({updateCategoryCheck: !this.state.updateCategoryCheck, retagMethod: "add",  tagGroupsAll: tags, showReTagDialog: true, selectedContentCreatorType: { value: this.state.selectedType.id, label: this.state.selectedType.label, slug: this.state.selectedType.slug, client: this.state.selectedType.client}}))
                  }else{
                    this.setState({updateCategoryCheck: !this.state.updateCategoryCheck, retagMethod: "add"})
                  }
                }}
              />
            }
            label={localization.get('form.update_content_creator_type')}
          />
            <h4 style={{textAlign: "left"}}>{localization.get('form.select_content_creator_type')}</h4>
            <AsyncSelect
              isDisabled={!this.state.updateCategoryCheck}
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('content_creator_types')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={selectedContentCreatorType => {
                request.tagGroup.getAll({
                  filterBy: [
                    {column: 'type', filter: selectedContentCreatorType.slug},
                    {column: 'creation_group', filter: '0'},
                    {column: 'dashboard', filter: 1},
                  ]
                })
                  .then(tags => this.setState({tagGroupsAll: tags, selectedContentCreatorType}))
                  .then(this.clearAllRetags)
              }}
              value={this.state.selectedContentCreatorType}
              loadOptions={input => {
                let pagination = {
                  orderBy: {column: "name", type: "ASC"},
                  filterBy: [
                    {column: "name", filter: input},
                    {column: "is_talent", filter: this.props.isTalent},
                    {column: "dashboard_combos", filter: 1},
                    {column: "type", filter: this.props.isEditorial ? "editorial" : "audiovisual"},
                    {column: "for_create", filter: true}
                  ]
                };

                return request.contentCreatorType.getAll(pagination)
                  .then(contentCreatorTypes => contentCreatorTypes.map(type => ({
                    value: type.id,
                    label: type.name,
                    slug: type.slug,
                    type: type.type,
                    client: type.client
                  })))
              }}
              defaultOptions
            />
        </FormControl>
        <br/>
        <br/>
        {this.state.tagGroupsAll && this.state.tagGroupsAll.length > 0 &&
          <>
            <h4 style={{textAlign: "left", marginTop: 20, position: "relative", float: "left", color: "red"}}>{localization.get('form.retag.method')}</h4>
            <Select
              disabled={this.state.updateCategoryCheck}
              style={{width: "100%"}}
              onChange={event => this.setState({retagMethod: event.target.value})}
              value={this.state.retagMethod}
            >
              <MenuItem value="add">{localization.get('form.retag.add')}</MenuItem>
              <MenuItem value="remove">{localization.get('form.retag.remove')}</MenuItem>
              <MenuItem value="mirror">{localization.get('form.retag.mirror')}</MenuItem>
            </Select>
            <h4 style={{textAlign: "left", marginTop: 20, position: "relative", float: "left"}}>{localization.get('form.tags')}</h4>
            <TagSelection
              homeElementTagSelection={true}
              allowUnselected={false}
              data={this.state.tagGroupsAll}
              onSelectedTagsChanged={tagId => this.onSelectedRetagsChanged(tagId)}
              clearAllTags={this.clearAllRetags}
            />
            <br/>
            <br/>
          </>
        }
        <FormControl style={{float: "left", position: "relative", width: "100%"}}>
          <h4 style={{ textAlign: "left" }}>{localization.get('form.visibility')}</h4>
          <RadioGroup
            name="updateVisible"
            value={this.state.updateVisible}
            onChange={event => this.setState({ updateVisible: event.target.value })}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel value="keep" control={<Radio color="default" />} label={localization.get('form.keep')} />
            <FormControlLabel value="1" control={<Radio color="default" />} label={localization.get('form.visible')} />
            <FormControlLabel value="0" control={<Radio color="default" />} label={localization.get('form.visible_not')} />
          </RadioGroup>
        </FormControl>
        <FormControl style={{float: "left", position: "relative", width: "100%"}}>
          <div style={{float: "left", position: "relative", width: "100%", display: "flex"}}>
            <FormControlLabel
              control={
                <Checkbox
                  id="updateWeight"
                  style={{ color: "#4cc2d5", float: "left" }}
                  checked={this.state.updateWeight}
                  onChange={() => this.setState({
                    weight: this.state.updateWeight ? 'keep' : 0,
                    updateWeight: !this.state.updateWeight
                  })}
                />
              }
              label={localization.get('form.update_weight')}
            />
          </div>
          {this.state.updateWeight &&
            <TextField
              style={{width: "100%"}}
              id="weight"
              label={localization.get("form.weight")}
              name="weight"
              InputProps={{inputProps: {min: 0, max: 10}}}
              type={"number"}
              value={this.state.weight}
              onChange={(e) => this.setState({weight: e.target.value})}
            />
          }
          {get(this.state.selectedContentCreatorType, 'client.has_access_level') &&
             <>
             <h4 style={{textAlign: "left"}} >{localization.get('form.update_access_level')}</h4>
             <RadioGroup
               name="updateVisible"
               value={this.state.updateAccessLevel}
               onChange={event => this.setState({ updateAccessLevel: event.target.value })}
               style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
             >
               <FormControlLabel value="keep" control={<Radio color="default" />} label={localization.get('form.keep')} />
               <FormControlLabel value={"0"} control={<Radio color="default"/>} label={localization.get('smart_search.none')} />
               {["1","2","3","4","5"].map(level =>
                 <FormControlLabel
                   value={level}
                   control={<Radio color="default"/>}
                   label={level}
                 />
               )}
             </RadioGroup>
           </>
          }
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={this.state.updateCategory && !this.state.selectedContentCreatorType}
          onClick={() => {
            if((this.state.updateCategory && !this.state.selectedContentCreatorType)) {
              GlobalSnackbar.show({message: localization.get('music.must_have_audio'), type: GlobalSnackbarTypes.ERROR})
            }
            if (this.state.catalog && this.state.catalog.value && this.state.content_creator_type_id) {
              GlobalConfirmationDialog.showDoubleConfirmation({
                dialogTitle: localization.get('form.retag'),
                elementName: localization.get('form.double_confirmation_update_many.label'),
                content: "confirmation_dialog.delete_many_materials",
                updateRequest: () => request.contentCreator.updateMany(this.state.selectedRetags, this.state.retagMethod, this.state.updateVisible, this.state.weight, this.state.selectedContentCreatorType.value, this.state.updateAccessLevel, this.state.paginationConfig)
                  .then(() => this.refs.table.forceUpdate())
                  .then(this.clearAllRetags),
                auxiliaryInformation: localization.get('form.double_confirmation_update_many.aux', this.state.total),
                doubleConfirmation: true,
                doubleConfirmationText: "form.double_confirmation_update_many",
                confirmationLabel: "form.retag.content",
                onSuccessText: localization.get('form.retag.success'),
                onFailText: localization.get('form.dialog.fail'),
              })
            }
            this.setState({showReTagDialog: false})
        }}>
          {localization.get('form.retag.content')}
        </Button>
      </DialogActions>
    </Dialog>

  makeTagSelectionDialog = () =>
    <Dialog open={this.state.showTagDialog} onClose={() => this.setState({showTagDialog: false})} onBackdropClick={() => this.setState({showTagDialog: false})}>
      <DialogContent style={{textAlign: "center"}}>
        <TagSelection
          homeElementTagSelection={true}
          allowUnselected={true}
          data={this.state.tagGroups}
          onSelectedTagsChanged={this.onSelectedTagsChanged}
          clearAllTags={this.clearAllTags}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          localStorage.setItem("selectedTags", `"[${this.state.selectedTags}]"`)
          localStorage.setItem("unselectedTags", `"[${this.state.unselectedTags}]"`)
          this.refs.table.forceUpdate();
          this.setState({showTagDialog: false})
        }}>
          {localization.get('home_editor.tag_selection.filter')}
        </Button>
      </DialogActions>
    </Dialog>

  columns = () => [
    {
      id: "image",
      accessor: contentCreator => (
        <Tooltip title={contentCreator.name}>
          <div
            alt="ContentCreator"
            style={{
              backgroundImage: `url(${makeImageUrlWithSize(contentCreator, 'xs')}), url(${_noImage})`,
              width: 50,
              height: 50,
              backgroundSize: "cover",
            }}
          />
        </Tooltip>
      ),
      style: {
        float: "left"
      },
      resizeable: false,
      sortable: false,
      filterable: false,
      width: 60
    },
    {
      Header: localization.get("table.name"),
      accessor: creator =>  <Tooltip title={creator.name}><p>{creator.name}</p></Tooltip>,
      id: "title",
      Filter: ({filter, onChange}) => (
        <input
          onChange={event => {
            onChange(event.target.value)
            if(event.keyCode == 13) {
              onChange(this.state.title)
            }else {
              this.setState({title: event.target.value || ""})
            }
          }}
          value={this.state.title ? this.state.title : ""}
          placeholder={localization.get("table.search")}
        />
      ),
    },
    {
      Header: localization.get("Login"),
      id: "require_login",
      accessor: contentCreator => (contentCreator.require_login ?
          <Tooltip title={localization.get('table.require_login')} placement="top">
            <IconButton>
              <Done />
            </IconButton>
          </Tooltip> :
          <Tooltip title={localization.get('table.require_login_not')} placement="top">
            <IconButton>
              <Clear />
            </IconButton>
          </Tooltip>
      ),
      filterable: true,
      Filter: ({filter, onChange}) => (
        <select
          style={{width: "100%"}}
          onChange={event => {
            onChange(event.target.value)
            this.setState({active: event.target.value})
          }}
          value={filter ? filter.value : ""}
        >
          <option value="" selected/>
          <option value={"1"}>{localization.get('table.require_login')}</option>
          <option value={"0"}>{localization.get('table.require_login_not')}</option>
        </select>
      ),
      sortable: false,
      style: { textAlign: 'center' },
      width: 60
    },
    {
      Header: localization.get("category"),
      id: "content_creator_type_id",
      accessor: content => content.type ? content.type.name : "",
      Filter: ({ filter, onChange }) => (
        <select
          style={{ width: "100%" }}
          onChange={event => {
            onChange(event.target.value)
            localStorage.setItem("selectedTags", `"[]"`)
            localStorage.setItem("unselectedTags", `"[]"`)
            this.setState({
              showTagSelection: !!event.target.value,
              selectedType: this.state.types.find(type => type.id == event.target.value),
              selectedTags: [],
              unselectedTags: [],
              content_creator_type_id: event.target.value,
            })
          }}
          value={filter ? filter.value : ""}
        >
          <option value="" selected />
          {this.state.types.sort((a, b) => a.label.localeCompare(b.label)).map(type => {
            return <option value={type.id}>{localization.get(type.label)}</option>;
          })}
        </select>
      ),
      sortable: false,
      width: 150,
    },
    {
      Header: this.props.isDemo ? localization.get("table.created_at") : (!this.props.isTalent ? localization.get("form.status") : localization.get("table.active")),
      id: this.props.isDemo ? "created_at" : "active",
      accessor: contentCreator => this.props.isDemo ? localization.moment(contentCreator.created_at).fromNow() :
        (contentCreator.active ?
            <Tooltip title={localization.get('table.active')} placement="top">
              <IconButton>
                <Done />
              </IconButton>
            </Tooltip> :
          (!!contentCreator.release_date ?
            <Tooltip title={localization.get('table.programed')} placement="top">
              <IconButton>
                  <AccessTime />
              </IconButton>
            </Tooltip> :
            <Tooltip title={localization.get('table.inactive')} placement="top">
              <IconButton>
                  <Clear />
              </IconButton>
            </Tooltip>
          )
        ),
      filterable: !this.props.isDemo,
      Filter: ({filter, onChange}) => (
        <select
          style={{width: "100%"}}
          onChange={event => {
            onChange(event.target.value)
            this.setState({active: event.target.value})
          }}
          value={filter ? filter.value : ""}
        >
          <option value="" selected/>
          <option value={"1"}>{localization.get('table.active')}</option>
          <option value={"0"}>{localization.get('table.inactive')}</option>
        </select>
      ),
      sortable: false,
      style: { textAlign: 'center' },
      width: 60
    },
    {
      Header: this.props.isTalent ? localization.get("table.short.created_at") : localization.get("form.region"),
      id: this.props.isTalent ? "created_at" : "only_argentina",
      accessor:  contentCreator => this.props.isTalent ? moment(contentCreator.created_at).format('DD-MM-YYYY') : (contentCreator.only_argentina ? localization.get('form.only_argentina') : localization.get('form.all_world')),
      filterable: !this.props.isTalent,
      sortable: !!this.props.isTalent,
      width: 100,
      Filter: ({filter, onChange}) => !this.props.isTalent ?
        (
          <select
            style={{ width: "100%" }}
            onChange={e => onChange(e.target.value)}
            value={filter ? filter.value : ""}
          >
            <option value="" selected />
            <option value="1"> {localization.get('form.only_argentina')} </option>
            <option value="0"> {localization.get('form.all_world')} </option>
          </select>
        )
        : (
          <input
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
            placeholder={localization.get("table.search")}
          />
        )
    },
    {
      Header: this.props.isTalent ? localization.get("table.short.updated_at") : localization.get("client.type"),
      id: this.props.isTalent ? "updated_at" : "is_event",
      accessor: contentCreator => this.props.isTalent ? moment(contentCreator.updated_at).format('DD-MM-YYYY') : (contentCreator.is_event ? localization.get('table.is_event') : localization.get('table.not_event')),
      filterable: !this.props.isTalent,
      Filter: ({filter, onChange}) => (
        <select
          style={{width: "100%"}}
          onChange={event => {
            onChange(event.target.value)
            this.setState({is_event: event.target.value})
          }}
          value={filter ? filter.value : ""}
        >
          <option value="" selected/>
          <option value={"1"}>{localization.get('table.is_event')}</option>
          <option value={"0"}>{localization.get('table.not_event')}</option>
          <option value={"2"}>{localization.get('table.not_new_event')}</option>
        </select>
      ),
      sortable: !!this.props.isTalent,
      style: { textAlign: 'center' },
      width: 80
    },
    {
      Header: localization.get("table.weight"),
      id: "weight",
      filterable: false,
      accessor: contentCreator => contentCreator.weight,
      sortable: true,
      style: { textAlign: 'center' },
      width: 80
    },
    {
      id: "actions",
      Header: localization.get("table.actions"),
      sortable: false,
      filterable: false,
      accessor: this.buttons,
      width: 180,
      Footer: <span style={{float: "right", fontSize: "18px", marginTop: "15px"}}><strong>Total: </strong>{this.state.total}</span>
    }
  ];

  makeSubComponent = row => (
    <TableSubcomponent {...this.props} contentCreator={row.original}/>
  );

  getClouds = () => {
    const filterBy = [];
    if(this.state.catalog) {
      filterBy.push({column: 'catalog_id', filter: this.state.catalog.value})
    }

    return request.clouds.getAll({filterBy})
      .then(clouds => clouds.map(cloud => ({value: cloud.id, label: cloud.name})))
      .then(clouds => clouds.sort((a, b) => a.label.localeCompare(b.label)))
      .then(clouds => this.setState({clouds}))
  }

  render = () => {
    return (
      <div>
        <CustomShareContent open={this.state.openShareDialog} sharingCreator={this.state.sharingCreator} onClose={() => this.setState({openShareDialog: false, sharingCreator: null})} />
        {this.makeUpdateDialog()}
        {this.makeIntegrateDialog()}
        {this.makeCatalogSelectionDialog()}
        {this.makeTagSelectionDialog()}
        {!this.props.isDemo  ?
          <>
            <div>
              {this.props.isAudiovisual &&
                <FormControl style={{float: "left", position: "relative"}}>
                  <RadioGroup
                    name="regionLock"
                    value={this.state.regionLock}
                    onChange={event => this.setState({regionLock: event.target.value}, () => this.refs.table.forceUpdate())}
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel value="false" control={<Radio color="default"/>} label={localization.get('all')}/>
                    <FormControlLabel value="locked" control={<Radio color="default"/>}
                                      label={localization.get('no_content')}/>
                  </RadioGroup>
                </FormControl>
              }
              <IconButton
                onClick={() => this.makeDownloadLink()}
                style={{position: "relative", float: "left", color: 'inherit'}}
              >
                <Tooltip
                  title={localization.get('download_csv')}
                  placement='top'>
                  <GetApp/>
                </Tooltip>
              </IconButton>
              <Tooltip
                title={localization.get(!this.state.showTagSelection ? 'filter_tags.disabled' : "home_editor.tag_selection.filter")}
                placement='top'>
                <div style={{display: "inline-block"}}>
                  <Button
                    disabled={!this.state.showTagSelection}
                    style={{marginTop: 7}}
                    onClick={() => {
                      request.tagGroup.getAll({
                        filterBy: [
                          { column: 'type', filter: this.state.selectedType.slug},
                          {column: 'creation_group', filter: '0'},
                          { column: 'dashboard', filter: 1 },
                        ]
                      })
                        .then(tags => this.setTagGroups(tags))
                        .then(() => this.paintAllTags())
                        .then(() => this.setState({showTagDialog: true}))
                    }}
                  >
                    {localization.get('filter_tags')}
                  </Button>
                </div>
              </Tooltip>
              {this.state.catalog && this.state.catalog.value && this.state.content_creator_type_id &&
                  <IconButton
                    onClick={() => {
                      request.tagGroup.getAll({filterBy: [
                          { column: 'type', filter: this.state.selectedType.slug },
                          { column: 'creation_group', filter: '0' },
                          { column: 'dashboard', filter: 1 },
                        ]})
                        .then(tags => this.setState({ tagGroupsAll: tags, showReTagDialog: true, selectedContentCreatorType: { value: this.state.selectedType.id, label: this.state.selectedType.label, slug: this.state.selectedType.slug, client: this.state.selectedType.client }}))
                    }}
                    style={{position: "relative", float: "left", color: 'inherit'}}
                  >
                    <Tooltip
                      title={localization.get('form.batch_retag')}
                      placement='top'>
                      <Style />
                    </Tooltip>
                  </IconButton>
              }

              <Link style={{marginRight: 10, marginTop: 15, float: "right", position: "relative"}}
                    to={this.props.isTalent ? '/panel/talents/content_creations' : '/panel/audiovisual/content_creations'}>
                <span>{localization.get("content_creators.view_materials")}</span>
              </Link>
            </div>

            {this.state.total !== "" &&
              <div style={{display: "flex", float: "left", position: "relative", width: "100%", textAlign: "center", margin: "0 0 30px 0"}}>
                <CatalogSelection
                  catalog={this.state.catalog}
                  onChange={(catalog) => this.setState({catalog},
                    () => this.requestContentCreatorTypes()
                      .then(() => {
                        this.refs.table.forceUpdate()
                        this.getClouds()
                      })
                  )}
                  hasRelation={'has_contentCreators'}
                  disabled={!!this.state.cloud}
                />
                <div style={{float: "left", position: "relative", width: "100%", textAlign: "center", margin: "0 0 30px 0"}}>
                  <div style={{width: "100%", maxWidth: 300, display: "inline-block", textAlign: "left"}}>
                    <span>{localization.get('clouds')}</span>
                    <AsyncSelect
                      ref="select"
                      styles={{
                        menu: base => ({
                          ...base,
                          zIndex: 10
                        })
                      }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 5,
                      })}
                      placeholder={localization.get('conversation.filter_users')}
                      loadingMessage={() => localization.get('loading')}
                      noOptionsMessage={() => localization.get('no_options')}
                      onChange={(cloud) => this.setState({cloud}, () => {this.requestContentCreatorTypes().then(() => this.refs.table.forceUpdate())})}
                      value={this.state.cloud}
                      defaultOptions={this.state.clouds}
                      isClearable={true}
                      loadOptions={(inputValue, callback) => {
                        callback(this.state.clouds.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())))
                      }}
                    />
                  </div>
                </div>
              </div>
            }


          </>
        :
          <Link style={{margin: "15px 0", float: "left", position: "relative"}} to={'/panel/demos/content_creations'}>
            <span>{localization.get("content_creators.view_materials")}</span>
          </Link>
        }
        {this.state.loaded &&
        <div style={{ width: "100%", float: "left", position: "relative" }}>
          <ServerSidePaginationTable
            ref="table"
            columns={this.columns()}
            requestTableData={paginationConfig => {
              paginationConfig.filterBy.push({column: "is_demo", filter: this.props.isDemo ? 1 : 0});

              if (!this.props.isDemo)     paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'is_talent', filter: this.props.isTalent ? 1 : 0  }])}
              if (!this.props.isDemo)     paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: "is_editorial", filter: this.props.isEditorial ? 1 : 0 }])}
              if (this.state.catalog && !this.state.cloud)     paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'catalog_id', filter: this.state.catalog.value }]) };
              if (this.state.regionLock === 'locked')    paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'only_argentina', filter: 1  }]) };
              if (this.state.selectedTags.length > 0)    paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'tags_ids', filter: [...this.state.selectedTags] }]) };
              if (this.state.unselectedTags.length > 0)  paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'not_tags_ids', filter: [...this.state.unselectedTags] }]) };
              if (this.state.cloud )  paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'cloud_id', filter: this.state.cloud.value }]) };
              if (this.state.is_event == "2") {
                const filterBy = paginationConfig.filterBy;
                if (filterBy.hasOwnProperty("is_event"))   delete filterBy["is_event"];

                paginationConfig = { ...paginationConfig, filterBy: filterBy.concat([{ column: 'empty_events', filter: "1" }, { column: 'is_event', filter: "1" }]) };
              
              }
              paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'dashboard', filter: "true" }]) };

              if(!paginationConfig.orderBy) paginationConfig.orderBy = {column: "id", type: "desc"}


              this.setState({ paginationConfig })

              return request.contentCreator.getAll(paginationConfig, "image;type.image").then(response => {
                this.setState({total: response.meta.total});
                return response;
              });
            }}
            subComponent={this.makeSubComponent}
          />
        </div>
        }
      </div>
    );
  };
}

export default Table;
