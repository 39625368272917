import {getStoredChannel, getStoredUser} from "../api-client/core/authentication/utils";
import localization from "../config/localization";
import {get} from "lodash";
import TagManager from "react-gtm-module";

const setUserProperties = () => {
  getStoredUser() && window.gtag('set', {
    user_id: get(getStoredUser(), "id"),
  });

  window.gtag('set','user_properties', {
    language: localization.getLanguage(),
    ...(!!getStoredUser() ? { userid: get(getStoredUser(), "id") } : {}),
    user_type: !getStoredUser() ? "Anonymous" : "Authenticated",
    ...(!!getStoredChannel() ? {
      channel_id: get(getStoredChannel(), "id"),
      channel_name: get(getStoredChannel(), "name")
    } : {})
  })
}

const sendEvent = (eventName, payload) => {
  if (!!getStoredChannel() && !!getStoredChannel().google_tag_manager_id) {
    TagManager.initialize({
      gtmId: getStoredChannel().google_tag_manager_id,
      events: payload,
    })
  }

  window.gtag('event', eventName, {
    ...payload,
    send_to: "channel",
  });
}

const pageView = (data) => sendEvent("Page_View", data);
const visitContent = (data) => sendEvent("Visit_content", data);
const playContent = (data) => sendEvent("Play_Content", data);
const smartSearch = (data) => sendEvent("Smart_Search", data);
const glassSearch = (text) => sendEvent("Glass_Search", {text: text});
const menu = (data) => sendEvent("Menu", data);
const favorite = (data) => sendEvent("Favorite", data);
const download = (data) => sendEvent("Download", data);
const buy = (data) => sendEvent("Buy", data);
const videoTracking = (data) => sendEvent("Video_Tracking", data);
const visitShareContent = (data) => sendEvent("Visit_Share_Content", data);
const login = (data) => sendEvent("Login", data);
const register = (data) => sendEvent("Register", data);
const playlist = (data) => sendEvent("Playlist", data);
const banner = (data) => sendEvent('Banner', data);
const share = (data) => sendEvent("Share", data);
const contentAction = (data) => sendEvent("Content_Action", data);
const footerInteraction = (data) => sendEvent("Footer_Interaction", data);
const community = (data) => sendEvent("Community", data);


export default {
  setUserProperties,
  visitContent,
  pageView,
  playContent,
  smartSearch,
  glassSearch,
  menu,
  favorite,
  share,
  download,
  buy,
  contentAction,
  videoTracking,
  visitShareContent,
  login,
  register,
  playlist,
  banner,
  footerInteraction,
  community
};
