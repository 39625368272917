import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import AddIcon from "@material-ui/icons/Add";
import {ReactComponent as List} from "../../../components/Letflow/SvgIcons/list.svg"
import Search from "@material-ui/icons/Search";
import React from "react";
import analyticsInstance from "../../../analytics/analyticsInstance";
import { request } from "../../../api-client";
import {channelPathGenerator, getActiveClient, getMainTranslation} from "../../../api-client/core/authentication/utils";
import FeaterSpinner from "../../../assets/img/loading_spinner.gif";
import localization from "../../../config/localization";
import FormView from "../../../layouts/Home/Playlists/Form";
import GlobalSnackbar, { GlobalSnackbarTypes } from "../Snackbar/GlobalSnackbar";
import FormViewContainer from "../ViewContainer/FormViewContainer";
import TagSelection from "../TagSelection/TagSelectionSmartSearch.jsx"
import {Link} from "react-router-dom";

class AddToPlaylist extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      requesting: true,
      playlists: [],
      availablePlaylists: [],
      search: "",
      clientId: this.props.clientId,
      showPlaylistCreationDialog: false,
      showTagSelectionDialog: false,
      title: '',
      subtitle: '',
      userLibrary: '',
      visibility: '',
      allowDownload: false,
      tagGroups: [],
      selectedTags: [],
      tooltipTitle: this.props.tooltipTitle,
      tooltipPlacement: this.props.tooltipPlacement || 'bottom',
      useGlobalPlaylists: this.props.useGlobalPlaylists || false,
      brands: [],
    }
  }

  show = () => {
    if (this.props.useGlobalPlaylists) {
      request.playlist.getAllGlobal()
        .then(response => {
          let playlists = response.data.filter(playlist => playlist.tags.length === 0)
          this.setState({
            playlists: playlists,
            open: true,
            requesting: false,
            availablePlaylists: playlists,
            search: ""
          });
        });
    } else {
      request.playlist.getAllForClient(this.props.clientId, 'type=client')
        .then(response => {
          this.setState({
            open: true,
            playlists: response,
            requesting: false,
            availablePlaylists: response,
            search: "",
          })
        });
      (this.props.content === 'contentCreators' && this.props.contentCreatorType) && this.requestTagGroups(this.props.item.id)
    }
  }

  requestTagGroups = id => {
    request.tagGroup.getAll({filterBy: [
        { column: 'content_creator_id', filter: id}]})
      .then((tagGroups) => {
        tagGroups.forEach(tagGroup => tagGroup.tags.forEach(tag => {
          tag.state = "available"
        }))
        this.setState({tagGroups, selectedTags: []})
      })
  }

  handleClose = () =>
    this.setState({
      open: false,
      requesting: false,
      search: "",
      showPlaylistCreationDialog: false,
      showTagSelectionDialog: false,
    })

  addNewPlaylist = e => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({title: this.state.search}, () => this.setState({open: false, showPlaylistCreationDialog: true}))
  }

  handleToggle = (e) => {
    e.preventDefault()
    e.stopPropagation()
    !this.state.open ? this.show() : this.handleClose()
  }

  playlistClicked = (type, playlistId, musicId) => {
    if (type == 'contentCreators' && this.state.tagGroups.length > 0) {
      this.setState({playlistClickedId: playlistId}, () => this.setState({showTagSelectionDialog: true, open: false}))
    } else {
      this.addToPlaylist(type, playlistId, musicId);
    }
  }

  addToGlobalPlaylist = (playlist) => {
    const data = {musicIds: playlist.musics}
    data.musicIds.push(this.props.item)
    request.playlist
      .updateGlobal( playlist.id, data )
      .then(() => {
        GlobalSnackbar.show({ message: localization.get("playlist.add"), type: GlobalSnackbarTypes.SUCCESS });
      })
      .then(this.handleClose)
  }

  addToPlaylist = (type, playlistId, musicId, tagsIds= []) => {
    if (!this.props.isMulti) {
      return request.playlist.addToPlaylist({
        playlistId,
        musicId,
        type,
        tagsIds
      })
        .then(() => {
          const playlistName = this.state.playlists.find(x => x.id === playlistId).title;
          const contentName = getMainTranslation(this.props.item, "name");
          analyticsInstance.playlist({
            playlist: playlistName,
            type: type,
            content_name: contentName || getMainTranslation(this.props.item, "title"),
            action: "Add",
          });
          GlobalSnackbar.show({
            message: <>{localization.get("playlist.add")}<br/>
              <Link
                style={{color: "white", textDecoration: "underline"}}
                to={"#"}
                onClick={() => window.open(channelPathGenerator(`playlist/${playlistId}`), '_blank')}
              >
                {localization.get('playlists.visit')}
              </Link>
            </>,
            type: GlobalSnackbarTypes.SUCCESS
          });
        })
        .then(this.handleClose)
    }else {
      return request.playlist.addManyToPlaylist({
        playlistId,
        type,
        entityIds: this.props.item
      })
        .then(() => {
          // const playlistTile = this.state.playlists.find(x => x.id === playlistId).title;
          // analyticsInstance.addTrackToPlaylist(playlistName, musicId);
          GlobalSnackbar.show({
            message: <>{localization.get("playlist.add")}<br/>
              <Link
                style={{color: "white", textDecoration: "underline"}}
                to={""}
                onClick={() => window.open(channelPathGenerator(`playlist/${playlistId}`), '_blank')}
              >
                {localization.get('playlists.visit')}
              </Link>
            </>,
            type: GlobalSnackbarTypes.SUCCESS
          });
        })
        .then(this.handleClose)
    }
  }

  handleSelectedTagsChanged = (selectedTags) => {
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.map(tag => ({
          ...tag,
          state: selectedTags.includes(tag.id) ? 'selected' : tag.state
        }))
      })),
      selectedTags
    }))
  }

  requestPlaylistsForClient = (clientId = getActiveClient()) => request.playlist.getAllForClient(clientId)
    .then(playlists => this.setState({playlists}));

  renderTagSelectionDialog = () =>
    <Dialog open={this.state.showTagSelectionDialog} onBackdropClick={() => this.setState({showTagSelectionDialog: false})} onClick={(e) => e.stopPropagation()}>
      <DialogTitle style={{ width: "100%" }} id="simple-dialog-title">{localization.get('dialog.add_tags')}</DialogTitle>
      <DialogContent>
        <p>{
          localization.get('dialog.add_tags_helper',
            localization.get(`dialog.child.${this.props.content}`),
            localization.get(`dialog.${this.props.content}`),
            localization.get(`dialog.child.${this.props.content}`),
            localization.get(`dialog.${this.props.content}`),
          )
        }</p>
        <TagSelection horizontal={true} onSelectedTagsChanged={this.handleSelectedTagsChanged} allowUnselected={false} data={this.state.tagGroups} />
      </DialogContent>
      <DialogActions style={{marginBottom: 28, justifyContent: "center", textAlign: "center"}}>
        <Button
          style={{backgroundColor: "black", color: "white", display: "inline-block"}}
          onClick={() => this.addToPlaylist(this.props.content, this.state.playlistClickedId, this.props.item.id, this.state.selectedTags)
          }
        >
          {localization.get("playlists.save")}
        </Button>
      </DialogActions>
    </Dialog>

  renderPlaylistCreationDialog = () => {
    const {title, subtitle, userLibrary, visibility, allowDownload, brands} = this.state;
    return (
      <Dialog open={this.state.showPlaylistCreationDialog} onBackdropClick={() => this.setState({showPlaylistCreationDialog: false})} onClick={(e) => e.stopPropagation()}>
        <FormViewContainer
          title={localization.get('home_playlist.create')}
          onBack={() => this.setState({showPlaylistCreationDialog: false})}
          submitRequest={() => request.playlist.create({
            title,
            subtitle,
            clientId: getActiveClient(),
            userLibrary,
            visibility,
            allowDownload,
            brands
          })
            .then(playlist => this.requestPlaylistsForClient().
              then(() => this.addToPlaylist(this.props.content, playlist.data.id, this.props.item.id))
            )
            .then(() => this.setState({showPlaylistCreationDialog: false}))
            .then(() => this.forceUpdate())
          }
          style={{maxWidth: "600px", width: "100%", display: "table"}}
          color="black"
          smallExtraSpace={true}
        >
          <FormView
            title={title}
            subtitle={subtitle}
            onChange={e => this.setState({ [e.target.id]: e.target.value })}
            onUserLibraryChange={e => this.setState({ userLibrary: e })}
            onVisibilityLibraryChange={e => this.setState({ visibility: e })}
            userLibrary={userLibrary}
            visibility={visibility}
            allowDownload={allowDownload}
            onAllowDownloadChange={e => this.setState({ allowDownload: e })}
            updating
            brands={brands}
            onBrandsChange={e => this.setState({ brands: e })}
          />
        </FormViewContainer>
      </Dialog>
    )
  };
  render() {
    const {playlists, availablePlaylists, open, requesting, search, showPlaylistCreationDialog, showTagSelectionDialog, tooltipTitle, tooltipPlacement, useGlobalPlaylists} = this.state
    const {item, content, icon, extraClass, extraStyle} = this.props;
    const id = item.id;
    const showTooltip = tooltipTitle && tooltipTitle.length > 0;
    const renderButton = () => (
      <div
        aria-describedby={id}
        ref={node => this.anchorEl = node}
        aria-owns={open ? "menu-list-grow" : null}
        aria-haspopup="true"
        onClick={this.handleToggle}
        className={extraClass || ''}
        style={extraStyle || {}}
      >
        {icon || <List/>}
      </div>
    );
    return (
      <>
        {showPlaylistCreationDialog && this.renderPlaylistCreationDialog()}
        {showTagSelectionDialog && this.renderTagSelectionDialog()}
        {showTooltip ? 
          <Tooltip title={tooltipTitle} placement={tooltipPlacement} PopperProps={{ style: { zIndex: 11000 } }}>
            {renderButton()}
          </Tooltip>
          :
          renderButton()
        }
        <Popper
          open={open}
          id={id}
          anchorEl={this.anchorEl}
          transition
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{zIndex:11000}}
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{transformOrigin: "center right", zIndex:10}}
            >
              <Paper style={this.props.creationPlayer ? {zIndex:10, marginLeft: 74} : {zIndex:10}}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  {requesting ?
                    <img style={{width: 50, height: 50}} src={FeaterSpinner} alt="Loading logo"/>
                    :
                    <MenuList style={this.props.creationPlayer ? {paddingBottom: 0, paddingTop: 0, minWidth: 224, width: 224} : {paddingBottom: 0, paddingTop: 0, minWidth: 250}}>
                      <MenuItem
                        style={{cursor: "default"}}
                        onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}>
                        <div style={{
                          border: "1px solid #ccc",
                          borderRadius: 30,
                          backgroundColor: "rgba(255, 255, 255, 0)",
                          position: "relative",
                          float: "left",
                          width: "100%",
                        }}>
                          <Search style={{marginBottom: -8, padding: 3}}/>
                          <input
                            onChange={event => this.setState({
                              search: event.target.value,
                              availablePlaylists: playlists.filter(i =>
                                i.title.toLowerCase().includes(event.target.value.toLowerCase())
                              )
                            })}
                            value={search}
                            style={{border: "none", backgroundColor:  "transparent", width: "calc(100% - 12px - 1em)"}}
                            placeholder={localization.get('home.smart_search.search_playlist')}
                            onClick={e => {
                              e.preventDefault()
                              e.stopPropagation()
                            }}
                          />
                        </div>
                      </MenuItem>
                      {availablePlaylists.map((playlist, index) =>
                        {if(index < 3) return (
                          <MenuItem onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            if (useGlobalPlaylists) {
                              this.addToGlobalPlaylist(playlist);
                            } else {
                              this.playlistClicked(content, playlist.id, id)
                            }
                          }} style={{cursor: "pointer"}}>
                            {playlist.title.slice(0,50)}
                          </MenuItem>)
                        }
                      )}
                      {useGlobalPlaylists && availablePlaylists.length == 0 && <MenuItem style={{cursor: "pointer", background:"#ccc"}}>
                        <strong>{localization.get('playlists.empty_search')}</strong>
                      </MenuItem>}
                      {!useGlobalPlaylists && <MenuItem style={{cursor: "pointer", background:"#ccc"}} onClick={this.addNewPlaylist}>
                        <strong>{localization.get('playlists.create')}</strong>
                        <AddIcon style={{cursor: "pointer", padding: 3}} />
                      </MenuItem>}
                    </MenuList>
                  }
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    )
  }
}

export default AddToPlaylist
