import React, {Component} from "react";
import {request} from "../../../api-client";
import ClientFormView from "../ClientForm";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import {getUpdateFormImageUrlFromItem, makeUrlWithTableParams} from "../../../utils";
import localization from '../../../config/localization'
import {EditorState} from "draft-js";
import {stateToHTML} from "draft-js-export-html";
import {stateFromHTML} from "draft-js-import-html";
import { CloudDownload } from "@material-ui/icons";
import { userIsSysAdmin } from "../../../api-client/core/authentication/utils";
import { IconButton, Tooltip } from "@material-ui/core";

const options = {
  inlineStyles: {
    // Override default element (`strong`).
    BOLD: {element: 'b'},
    // Use a custom inline style. Default element is `span`.
    FONT12: {style: {fontSize: 12}},
    FONT14: {style: {fontSize: 14}},
    FONT16: {style: {fontSize: 16}},
    FONT18: {style: {fontSize: 18}},
    FONT20: {style: {fontSize: 20}},
  },
};

export default class extends Component {

  state = {
    name: "",
    type: "",
    image: { src: null, file: null },
    disabled: false,
    cuit: "",
    address: "",
    email: "",
    country: "",
    licensePlan: "",
    excludeFromReports: false,
    demo: false,
    selectedPermissions: [],
    managedBy: null,
    prefix: '',
    reelUrl: '',
    useLogo: false,
    socialLinks: [],
    expanded: false,
    aboutEs: '',
    aboutEn: '',
    showAbout: false,
    keywordsSEO: '',
    titleSEO: '',
    descriptionSEO: '',
    selectedContentCreatorTypes: [],
    canManageClients: false,
    searchPreferences: 'content_creator',
    blacklistTypeContentCreatorTypes: '2',
    allowFakeLivestreams: false,
    secure: true,
    hasOwnTags: false,
    channel: null,
    transcodeVideos: true,
    useAccessLevel: false,
    accessLevel: "0",
  };


  requestClient = () =>
    request.client.get(this.props.match.params.id, "include=category;country;image;blogLocations;contentCreatorTypesBlacklist;licensePlan;color;permissions;managedBy.image;socialLinks;translations;domain;defaultChannel").then(client => {
        let aboutEs = client.translations.find(
          translation => translation.locale === "es"
        );
        let aboutEn = client.translations.find(
          translation => translation.locale === "en"
        );
      this.setState({
        ...client,
        id: client.id,
        type: client.type,
        country: client.country && {value: client.country.id, label: client.country.name},
        image: {
          src: getUpdateFormImageUrlFromItem(client),
          file: null,
        },
        licensePlan: client.licensePlan && client.licensePlan.id,
        excludeFromReports: client.exclude_from_reports,
        demo: client.demo,
        selectedPermissions: client.permissions.map(permission => ({
          value: permission.entity,
          label: localization.get(`permissions.${permission.entity}`)
        })),
        selectedContentCreatorTypes: client.contentCreatorTypesBlacklist.map(contentCreatorTypesBlacklist => ({
          value: contentCreatorTypesBlacklist.id,
          label: contentCreatorTypesBlacklist.name
        })),
        managedBy: client.managedBy ? {value: client.managedBy.id, label: client.managedBy.name} : {value: null, label: "Feater"},
        prefix: client.domain && client.domain.url,
        reelUrl: client.reel_url,
        useLogo: client.use_logo,
        socialLinks: client.socialLinks,
        aboutEs: aboutEs && aboutEs.about,
        aboutEn: aboutEn && aboutEn.about,
        keywordsSEO: client.seo_keywords,
        titleSEO: client.seo_title,
        descriptionSEO: client.seo_description,
        showAbout: client.show_about_in_home,
        canManageClients: client.can_manage_clients,
        searchPreferences: client.content_creator_search_preference,
        secure: client.secure,
        transcodeVideos: client.transcode_videos,
        useAccessLevel: client.has_access_level,
        accessLevel: `${client.default_access_level}`,
        blacklistTypeContentCreatorTypes: (client.contentCreatorTypesBlacklist.length == 0 && client.blacklist_content_creator_types == 1) ? '2' : client.blacklist_content_creator_types.toString(),
        allowFakeLivestreams: client.allow_fake_livestreams,
        hasOwnTags: client.has_own_tags,
        channel: client.defaultChannel ? {value: client.defaultChannel.id, label: client.defaultChannel.name} : "",
      })
    }).then(() => {
      if (this.state.aboutEs) {
        this.setState({aboutEs: stateFromHTML(this.state.aboutEs)},
          () => this.setState({aboutEs: EditorState.createWithContent(this.state.aboutEs)}))
      } else {
        this.setState({aboutEs: new EditorState.createEmpty()})
      }
      if (this.state.aboutEn) {
        this.setState({aboutEn: stateFromHTML(this.state.aboutEn)},
          () => this.setState({aboutEn: EditorState.createWithContent(this.state.aboutEn)}))
      } else {
        this.setState({aboutEn: new EditorState.createEmpty()})
      }
    });

  requestClientUpdate = () => {
    return request.client
      .update(this.props.match.params.id, {
        ...this.state,
        type: this.state.type,
        image: this.state.image.file,
        countryId: this.state.country && this.state.country.value,
        permissions: this.state.selectedPermissions.map(permission => (permission.value)),
        managedBy: this.state.managedBy && this.state.managedBy.value,
        prefix: this.state.prefix,
        reelUrl: this.state.reelUrl,
        useLogo: this.state.useLogo,
        socialLinks: this.state.socialLinks,
        contentCreatorTypes: this.state.selectedContentCreatorTypes,
        canManageClients: this.state.canManageClients,
        searchPreferences: this.state.searchPreferences,
        blacklistTypeContentCreatorTypes: this.state.blacklistTypeContentCreatorTypes,
        secure: this.state.secure,
        transcodeVideos: this.state.transcodeVideos,
        useAccessLevel: this.state.useAccessLevel,
        accessLevel: this.state.accessLevel,
        translations: [
          {language: 'es', about: this.state.aboutEs && stateToHTML(this.state.aboutEs.getCurrentContent(), options)},
          {language: 'en', about: this.state.aboutEn && stateToHTML(this.state.aboutEn.getCurrentContent(), options)},
        ],
        allowFakeLivestreams: this.state.allowFakeLivestreams,
        hasOwnTags: this.state.hasOwnTags,
        channelId: this.state.channel ? this.state.channel.value : null
      })
      .then(() => this.props.history.push(makeUrlWithTableParams("/panel/clients")));
  }

  onChange = e => {
    let { target } = e;

    this.setState({ [target.id]: target.type === 'checkbox' ? target.checked : target.value });
  };

  onImageChange = image => this.setState({ image });
  onAsyncPermissionsChange = selectedPermissions => this.setState({ selectedPermissions });
  onAsyncManagedBy = managedBy => this.setState({managedBy});
  onAsyncCountriesChange = country => this.setState({ country });
  expanderOnClick = () => this.setState({expanded: !this.state.expanded})
  onAsyncContentCreatorTypesChange = selectedContentCreatorTypes => this.setState({ selectedContentCreatorTypes });

  render = () => {
    const {
      id,
      disabled,
      name,
      type,
      image,
      cuit,
      address,
      email,
      country,
      licensePlan,
      excludeFromReports,
      demo,
      selectedPermissions,
      managedBy,
      prefix,
      reelUrl,
      useLogo,
      expanded,
      aboutEs,
      aboutEn,
      showAbout,
      keywordsSEO,
      titleSEO,
      descriptionSEO,
      selectedContentCreatorTypes,
      canManageClients,
      searchPreferences,
      blacklistTypeContentCreatorTypes,
      allowFakeLivestreams,
      secure,
      transcodeVideos,
      useAccessLevel,
      accessLevel,
      hasOwnTags,
      channel,
    } = this.state;

    console.log(accessLevel)
    return (
      <div>
        <FormViewContainer
          title={localization.get('client.update_client')}
          initializationRequest={() => this.requestClient()}
          onBack={() => this.props.history.goBack()}
          submitRequest={this.requestClientUpdate}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
        >
          {userIsSysAdmin() &&
            <div style={{position: "absolute", right: 10, top: -40}}>
              <IconButton
                onClick={() => window.open(request.client.backup(id), "_blank")}
                style={{position: 'relative'}}
              >
                <Tooltip title={localization.get('download.backup')}>
                  <CloudDownload/>
                </Tooltip>
              </IconButton>
            </div>
          }
          <ClientFormView
            id={id}
            disabled={disabled}
            name={name}
            type={type}
            image={image}
            cuit={cuit}
            address={address}
            email={email}
            country={country}
            onChange={this.onChange}
            onImageChange={this.onImageChange}
            licensePlan={licensePlan}
            excludeFromReports={excludeFromReports}
            demo={demo}
            prefix={prefix}
            reelUrl={reelUrl}
            selectedPermissions={selectedPermissions}
            onAsyncPermissionsChange={this.onAsyncPermissionsChange}
            managedBy={managedBy}
            onAsyncManagedBy={this.onAsyncManagedBy}
            useLogo={useLogo}
            onAsyncCountriesChange={this.onAsyncCountriesChange}
            socialLinks={this.state.socialLinks}
            onSocialLinksChange={(socialLinks) => this.setState({socialLinks})}
            catalogClient={type === 'catalog'}
            expanded={expanded}
            expanderOnClick={this.expanderOnClick}
            onContentChange={(e, field) => this.setState({[field]: e})}
            aboutEs={aboutEs}
            aboutEn={aboutEn}
            showAbout={showAbout}
            keywordsSEO={keywordsSEO}
            titleSEO={titleSEO}
            descriptionSEO={descriptionSEO}
            contentCreatorTypes={selectedContentCreatorTypes}
            onAsyncContentCreatorTypesChange={this.onAsyncContentCreatorTypesChange}
            canManageClients={canManageClients}
            searchPreferences={searchPreferences}
            blacklistTypeContentCreatorTypes={blacklistTypeContentCreatorTypes}
            onChangeRadio={value => this.setState({searchPreferences: value.target.value})}
            onChangeBlacklistTypeContentCreatorTypes={value => this.setState({blacklistTypeContentCreatorTypes: value.target.value})}
            allowFakeLivestreams={allowFakeLivestreams}
            secure={secure}
            transcodeVideos={transcodeVideos}
            useAccessLevel={useAccessLevel}
            accessLevel={accessLevel}
            hasOwnTags={hasOwnTags}
            channel={channel}
            onChangeChannel={channel => this.setState({ channel })}
          />
        </FormViewContainer>
      </div>
    );
  };
}
