import {post} from '../utils'

const url = (host) => (`${host}/contentCreations/renameMasively`);

export default host => (rename, materialIds) => {

  console.log(materialIds)
  return post(url(host), JSON.stringify({
    name_prefix: rename,
    material_ids: materialIds,
  }))
}
