import client from "./client";
import clientCategory from './clientCategory'
import file from "./file";
import album from "./album";
import catalogCategory from "./catalogCategory";
import music from "./music";
import musicVersion from "./musicVersion";
import voice from "./voice";
import tagGroup from "./tagGroup";
import tag from "./tag";
import speaker from "./speaker";
import playlist from "./playlist";
import _public from "./public";
import user from "./user";
import role from "./role";
import authentication from "./authentication";
import musicFavorite from "./musicFavorite";
import artistFavorite from "./artistFavorite";
import favorite from "./favorite";
import savedQuery from "./savedQuery";
import log from "./log";
import reservation from "./reservation";
import speakerFavorite from "./speakerFavorite";
import license from "./license";
import yandex from "./yandex";
import originalMusic from "./originalMusic";
import rightManagement from "./rightManagement";
import artist from "./artist";
import blogTag from "./blogTag";
import blogLocation from "./blogLocation";
import blogPost from "./blogPost";
import featuredVideo from "./featuredVideo";
import featuredImage from "./featuredImage";
import contactMessage from "./contactMessage";
import spotlight from "./spotlight";
import trial from "./trial";
import licensePlan from "./licensePlan";
import document from "./document";
import forecast from './forecast';
import graphboard from './graphboard';
import messaging from './messaging';
import auditLog from './auditLog';
import pitch from './pitch';
import onlineVideos from './onlineVideos';
import rankingConfig from './rankingConfig';
import artistDemo from './artistDemo'
import shareLink from './shareLink'
import catalog from './catalog'
import country from './country'
import clientTags from './clientTags'
import clientFile from './clientFile'
import contentCreatorType from './contentCreatorType'
import contentCreator from './contentCreator'
import contentCreation from './contentCreation'
import contentCreatorFavorite from './contentCreatorFavorite'
import contentCreationFavorite from './contentCreationFavorite'
import socialLink from './socialLink'
import clientReels from './clientReels'
import catalogClient from './catalogClient'
import monthlyReport from './monthlyReport'
import unsplash from './unsplash'
import channel from './channel'
import batchUpload from './batchUpload'
import artistChannelFavorite from './artistChannelFavorite'
import musicChannelFavorite from './musicChannelFavorite'
import contentCreatorChannelFavorite from './contentCreatorChannelFavorite'
import albumChannelFavorite from './albumChannelFavorite'
import domain from './domain'
import translate from './translate'
import liveStream from './liveStream'
import loginAttempt from './loginAttempt'
import channelContentCreator from "./channelContentCreator";
import products from "./products";
import productCodes from "./productCodes";
import section from './section'
import clouds from './clouds'
import contentPacks from './contentPacks'
import playlistChannelFavorite from './playlistChannelFavorite'
import contentCreationChannelFavorite from './contentCreationChannelFavorite'
import contentCreatorEventDates from "./contentCreatorEventDates";
import gifts from "./gifts";
import brand from "./brand";
import musicDealer from "./musicDealer";
import tagSystem from "./tagSystem";

export default host => ({
  client: client(host),
  clientCategory: clientCategory(host),
  album: album(host),
  catalogCategory: catalogCategory(host),
  file: file(host),
  music: music(host),
  musicVersion: musicVersion(host),
  voice: voice(host),
  tagGroup: tagGroup(host),
  tag: tag(host),
  speaker: speaker(host),
  playlist: playlist(host),
  public: _public(host),
  user: user(host),
  role: role(host),
  authentication: authentication(host),
  favorite: favorite(host),
  speakerFavorite: speakerFavorite(host),
  musicFavorite: musicFavorite(host),
  artistFavorite: artistFavorite(host),
  savedQuery: savedQuery(host),
  log: log(host),
  reservation: reservation(host),
  license: license(host),
  yandex: yandex(),
  originalMusic: originalMusic(host),
  rightManagement: rightManagement(host),
  artist: artist(host),
  blogTag: blogTag(host),
  blogPost: blogPost(host),
  blogLocation: blogLocation(host),
  featuredVideo: featuredVideo(host),
  featuredImage: featuredImage(host),
  contactMessage: contactMessage(host),
  spotlight: spotlight(host),
  trial: trial(host),
  licensePlan: licensePlan(host),
  document: document(host),
  forecast: forecast(host),
  graphboard: graphboard(host),
  messaging: messaging(host),
  auditLog: auditLog(host),
  pitch: pitch(host),
  onlineVideos: onlineVideos(host),
  rankingConfig: rankingConfig(host),
  artistDemo: artistDemo(host),
  shareLink: shareLink(host),
  catalog: catalog(host),
  country: country(host),
  clientTags: clientTags(host),
  clientFile: clientFile(host),
  contentCreatorType: contentCreatorType(host),
  contentCreator: contentCreator(host),
  contentCreation: contentCreation(host),
  contentCreatorFavorite: contentCreatorFavorite(host),
  contentCreationFavorite: contentCreationFavorite(host),
  socialLink: socialLink(host),
  clientReels: clientReels(host),
  catalogClient: catalogClient(host),
  monthlyReport: monthlyReport(host),
  unsplash: unsplash(),
  channel: channel(host),
  channelContentCreator: channelContentCreator(host),
  batchUpload: batchUpload(host),
  artistChannelFavorite: artistChannelFavorite(host),
  musicChannelFavorite: musicChannelFavorite(host),
  contentCreatorChannelFavorite: contentCreatorChannelFavorite(host),
  albumChannelFavorite: albumChannelFavorite(host),
  domain: domain(host),
  translate: translate(host),
  liveStream: liveStream(host),
  loginAttempt: loginAttempt(host),
  products: products(host),
  productCodes: productCodes(host),
  section: section(host),
  clouds: clouds(host),
  contentPacks: contentPacks(host),
  playlistChannelFavorite: playlistChannelFavorite(host),
  contentCreationChannelFavorite: contentCreationChannelFavorite(host),
  contentCreatorEventDates: contentCreatorEventDates(host),
  gifts: gifts(host),
  brands: brand(host),
  musicDealer: musicDealer(host),
  tagSystem: tagSystem(host)
});
