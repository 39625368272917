import React from "react";
import AsyncSelect from "react-select/lib/Async";
import { channelPathGenerator, getStoredChannel, slugify } from "../../../api-client/core/authentication/utils";
import localization from "../../../config/localization";
import moment from "moment";
import browserHistory from "../../../utils/browserHistory";
import qs from "qs";
import CustomDateInput from "../../../components/Letflow/CustomDatePicker/index";
import { get } from "lodash";

class SearchByTags extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
      tagGroups: this.props.tagGroups,
      selectedTags: [],
      channel: getStoredChannel(),
      eventRangeOpen: false,
      selectedRange: null,
      fromDate: null,
      toDate: null,
		};
	}

  handleSearch = () => {
    const { channel } = this.state;
    const searchType =  channel.navbar_search_type === "material" ? "content_creations" : channel.navbar_search_type == "content" ? "contenido" : "eventos";
    const {dateStart, dateEnd} = this.getDate();
    const selectedTags =  this.props.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected').map(tag => tag.id);

      browserHistory.push({
        pathname: channelPathGenerator(`busqueda-inteligente/${searchType}/${channel.navbarContentCreatorType.id}-${slugify(channel.navbarContentCreatorType.name)}`),
        search: qs.stringify({
          type: channel.navbarContentCreatorType.slug,
          tags_ids: selectedTags,
          date_start: dateStart,
          date_end: dateEnd,
          date_range: get(this.state, "selectedRange.value", null),
        })
      }) 
      
    this.props.onClose();
  }

  getDate = () => {
    let today = moment();
    let tomorrow = moment().add(1, 'day');
    let yesterday = moment().subtract(1, 'day');
    let dateStart = '';
    let dateEnd = '';
    if (!this.state.selectedRange) return {dateStart: null, dateEnd: null};
    switch (this.state.selectedRange.value) {
      case 'today':
        dateStart = today;
        dateEnd = today;
        break;
      case 'tomorrow':
        dateStart = tomorrow;
        dateEnd = tomorrow;
        break;
      case 'thisWeekend':
        dateStart = moment().endOf('week').subtract(2, 'day');
        dateEnd = moment().endOf('week');
        break;
      case 'thisWeek':
        dateStart = today;
        dateEnd = moment().endOf('week');
        break;
      case 'nextWeek':
        dateStart = moment().add(1, 'week').startOf('week');
        dateEnd = moment().add(1, 'week').endOf('week');
        break;
      case 'next30days':
        dateStart = today;
        dateEnd = moment().add(30, 'day');
        break;
      case 'yesterday':
        dateStart = yesterday;
        dateEnd = yesterday;
        break;
      case 'lastWeek':
        dateStart= moment().subtract(7, 'day');
        dateEnd= today;
        break;
      case 'last30days':
        dateEnd = today;
        dateStart = moment().subtract(30, 'day');
        break;
      case 'custom':
        dateStart = this.state.fromDate ? moment(this.state.fromDate) : today;
        dateEnd = this.state.toDate ? moment(this.state.toDate) : moment().add(5, 'year');
        break;
      default:
        return {dateStart: null, dateEnd: null};
    }

    return {dateStart: dateStart.format('YYYY-MM-DD'), dateEnd: dateEnd.format('YYYY-MM-DD')};
  }

  render() {

    return (
      <div>
        <div className="sidebar-tag-group-container">
         {this.state.channel.navbar_search_type === "events" && 
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="sidebar-tag-group-title">{localization.get("navbar.search_by_events")}</div>
              <AsyncSelect
                key={"0"}
                placeholder={localization.get("smart_search.event_search")}
                styles={{
                  input: base => ({
                      ...base, 
                      margin: "5px 0",
                  }),
                  menu: base => ({
                      ...base,
                      zIndex: 10,
                      borderColor: "var(--sidebar-selector-font-color, black)",
                      backgroundColor: "var(--sidebar-selector-background-color, white)",
                      color: "var(--sidebar-selector-font-color, black)",
                  }),
                  control: (baseStyles, state) => {
                      return {
                      ...baseStyles,
                      borderColor: "rgba(0, 0, 0, 0)",
                      backgroundColor: "var(--sidebar-selector-background-color, white)",
                      color: "var(--sidebar-selector-font-color, black)",
                  }},
                  singleValue: styles => ({ ...styles, color: "var(--sidebar-selector-font-color, black)" }), 
                  valueContainer: styles => ({ ...styles, color: "var(--sidebar-selector-font-color, black)"}), 
                }}
                theme={theme => {
                  return {
                      ...theme,
                      backgroundColor: "var(--sidebar-selector-background-color, white)",
                      borderRadius: 5,
                      borderColor: "var(--sidebar-selector-font-color, black)",
                      backgroundColor: "var(--sidebar-selector-background-color, white)",
                      color: "var(--sidebar-selector-font-color, black)",
                  }
                }}
                style={{margin: "10px 0"}}
                isOptionDisabled={(option) => option.disabled}
                value={this.state.selectedRange}
                onChange={e => this.setState({selectedRange: e}, this.requestTracks)}
                defaultOptions={[
                  {value: "today", label: localization.get("smart_search.today")},
                  {value: "tomorrow", label: localization.get("smart_search.tomorrow")},
                  {value: "thisWeekend", label: localization.get("smart_search.this_weekend")},
                  {value: "thisWeek", label: localization.get("smart_search.this_week")},
                  {value: "nextWeek", label: localization.get("smart_search.next_week")},
                  {value: "next30days", label: localization.get("smart_search.next_thirty_days")},
                  {value: "custom", label: localization.get("smart_search.range")},
                ]}
              />
            </div>
         }
         {this.state.selectedRange && this.state.selectedRange.value === "custom" &&
          <div style={{ display: "flex", flexDirection: window.innerWidth > 800 ? "row" : "column", justifyContent: "space-between" }}>
            <CustomDateInput 
              onChange={(event) => this.setState({fromDate: event.target.value})} 
              placeholder={localization.get("form.from")}
              value={this.state.fromDate}
              minDate={new Date().toISOString().split('T')[0]}  
            />
            <CustomDateInput 
              onChange={(event) => this.setState({toDate: event.target.value})}
              placeholder={localization.get("form.to")}
              value={this.state.toDate}
              minDate={this.state.fromDate}  
            />
          </div>
         }

         {this.props.tagGroups.length > 0 && <div className="sidebar-tag-group-title">{localization.get("navbar.search_by_tags")}</div>}
         {this.props.tagGroups.map((tagGroup, key) =>
            <div style={{ margin: "10px 0",}}>
            <AsyncSelect
                input={(styles) => {
                  return { ...styles, margin: "5px 0", }
                }}
                styles={{
                  input: base => ({
                      ...base, 
                      margin: "5px 0",
                      color: "var(--sidebar-selector-font-color, black)",
                  }),
                  menu: base => ({
                      ...base,
                      zIndex: 10,
                      borderColor: "var(--sidebar-selector-font-color, black)",
                      backgroundColor: "var(--sidebar-selector-background-color, white)",
                      color: "var(--sidebar-selector-font-color, black)",
                  }),
                  control: (baseStyles, state) => {
                      return {
                      ...baseStyles,
                      borderColor: "rgba(0, 0, 0, 0)",
                      backgroundColor: "var(--sidebar-selector-background-color, white)",
                      color: "var(--sidebar-selector-font-color, black)",
                  }},
                  singleValue: styles => ({ ...styles, color: "var(--sidebar-selector-font-color, black)" }), 
                  valueContainer: styles => ({ ...styles, color: "var(--sidebar-selector-font-color, black)"}), 
                }}
                theme={theme => {
                  return {
                      ...theme,
                      backgroundColor: "var(--sidebar-selector-background-color, white)",
                      borderRadius: 5,
                      borderColor: "var(--sidebar-selector-font-color, black)",
                      backgroundColor: "var(--sidebar-selector-background-color, white)",
                      color: "var(--sidebar-selector-font-color, black)",
                  }
                }}
                isDisabled={tagGroup.tags.every(t => t.state== "disable")}
                style={{margin: "10px 0"}}
                isOptionDisabled={(option) => option.disabled}
                value={tagGroup.tags.find(t => t.state == "selected")  ? 
                 { value: tagGroup.tags.find(t => t.state == "selected").id, label: tagGroup.tags.find(t => t.state == "selected").name }  :
                 { value: "0", label: tagGroup.name }
                }
                onChange={e => {
                  const selectedValue = e ? e : { value: "0", label: tagGroup.name };
                  const prevSelectedTag = tagGroup.tags.find(t => t.state == "selected") ? tagGroup.tags.find(t => t.state == "selected").id : "0";                      
                  let currentTags = this.props.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected').map(tag => tag.id).filter(selectedTag => selectedTag != prevSelectedTag);

                  if (selectedValue.value != "0") { 
                    currentTags.push(selectedValue.value)
                  };

                  return this.props.handleSelectedTagsChanged(currentTags)
                }}
                loadOptions={(inputValue, callback) => {
                callback([{label: tagGroup.name, value: "0"}, ...tagGroup.tags.filter(t =>  t.state != "disable").map(t => ({label: t.name, value: t.id}))]
                    .filter(t => t.label.toLowerCase().includes(inputValue.toLowerCase())))
                }}
                defaultOptions={[{label: tagGroup.name, value: "0"}, ...tagGroup.tags.filter(t =>  t.state != "disable").map(t => ({label: t.name, value: t.id}))]}
                isClearable={tagGroup.tags.find(t => t.state == "selected")}
            />
            </div>
        )}
        <div className="search-button-container">
          <div className="search-button" onClick={this.handleSearch}>
            {localization.get("navbar.search")}
          </div>
        </div>
        </div>
      </div>
    );
  }
}

export default SearchByTags;