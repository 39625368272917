import React from "react";
import CreationItem from "../../../../components/Letflow/CreationItem";
import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import AsyncSelect from "react-select/lib/Async";
import { Search, Clear } from "@material-ui/icons";
import localization from "../../../../config/localization";
import EventManager, {events} from "../../../../utils/EventManager";
import { get } from "lodash"
import {request} from "../../../../api-client";
import LoadingSpinner from "../../../../assets/img/loading_spinner.gif";


export default class LiteSmartSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      tagGroups: [],
      selectedTag: [],
      searchResultsTags: [],
      loaded: false,
    }
  }

    componentDidMount = () => {
      this.fetchTagGroups()
        .then(() => this.requestAvailableTags(true));
      this.requestTracks();
    }

    fetchTagGroups = () =>  {
        let paginationConfig = {};
        paginationConfig = { filterBy: [ { column: 'playlist_id', filter: this.props.playlistId} ]};
        
        return request.tagGroup.getAll(paginationConfig, this.state.contentType)
          .then(tagGroups => this.setState({ 
            tagGroups: tagGroups.filter(tg => get(tg, "tags", []).length > 0) 
          }))
      }

      cleanSearch = () => this.setState({ searchInput: ""}, this.requestTracks);
      
      handleSmartSearchSelectedTagsChanged = (selectedTags, lastTagId = null) => {
        this.setState(state => ({
          ...state,
          tagGroups: state.tagGroups.map(tgs => ({
            ...tgs,
            tags: tgs.tags.map(tag => ({
              ...tag,
              state: selectedTags.includes(tag.id) ? 'selected' : "available"
            }))
          }))
        }), () => {
          this.requestAvailableTags()
          this.requestTracks()
        })
      }
    
  requestTracks = () => {
     const options = {perPage: get(this.props, "limitContent", 30), page: 0}; 
     options.orderBy = {column: 'order', type: 'desc'}

     const props = {
      tags: this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected').map(tag => tag.id),
      options,
      isPublic: !!this.props.isPublic,
      search: this.state.searchInput,
      playlistId: this.props.isPublic ? this.props.token : this.props.playlistId
    }

          
    return request.contentCreation.getAllForPlaylistTags(props)
      .then(searchResults => this.setState({ searchResultsTags: searchResults.data.map((c,i) => ({...c, index: i})), loaded: true}))
   }

  requestAvailableTags = (hideEmptyGroups = false) => {
    const selectedTags = this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected').map(tag => tag.id);

    return request.tag.getAvailableTagsForPlaylist({
      playlistId: this.props.playlistId,
      tags: selectedTags,
      isPublic: !!this.props.isPublic
    }).then(availableTags => {
      const mappedAvailableTags = availableTags.map(x => x.id)
      
      return this.setState({
        tagGroups: this.state.tagGroups.map(tgs => ({
          ...tgs,
          tags: tgs.tags.map(tag => ({
            ...tag,
            state: ['selected'].includes(tag.state) ? tag.state : (mappedAvailableTags.includes(tag.id) ? 'available' : 'disable')
          }))
        })).filter(tg => !(hideEmptyGroups && tg.tags.every(t => t.state == "disable")))
      })
    })
  }

  onPlayMaterial = (creation) => {
    if (this.props.isPublic) {
      this.props.onPlay(creation, this.state.searchResultsTags)
    } else {
      EventManager.getInstance().dispatch(events.OPEN_MATERIAL_MODAL, {id:  creation.id, materialList: this.state.searchResultsTags.map((item, index) => ({index, id: item.id}))});
    }
  }

  render = () => {
    const isMobile = window.innerWidth <= 600;

    return (
        <div>
            <div style={{
                display: "flex",
                width: "100%",
                color: "var(--main-font-color, black)",
                border: "1px solid var(--main-font-color, rgba(0,0,0))",
                borderColor: "var(--main-font-color, rgb(0,0,0))",
                borderRadius: 5,
                padding: 5,
                marginBottom: 8,
                justifyContent: "space-between"
            }}>
                <TextField
                inputProps={{
                  ref: input => {
                    this.searchInput = input;
                  },
                  spellCheck: false,
                  style: {color: "var(--main-font-color, black)"}
                }}
                InputProps={{disableUnderline: true}}
                autoComplete={false}
                className="smart-search-input-search"
                placeholder={localization.get("searchbar.content_creators")}
                value={this.state.searchInput}
                onChange={e => this.setState({searchInput: e.target.value})}
                onKeyPress={e => {
                    if (e.key === 'Enter') this.requestTracks()
                }}
                style={{color: "var(--main-font-color, black)", width: "100%"}}
                />
                {(this.state.searchInput) && 
                  <Clear style={{marginLeft: 7, marginTop: 5, color: "var(--main-font-color, black)"}} onClick={this.cleanSearch}/>
                }
                <Search style={{marginLeft: 7, marginTop: 5, color: "var(--main-font-color, black)"}} onClick={this.requestTracks}/>
            </div>
            {this.state.loaded ?
              <>
                {this.props.useTagFilters &&
                  <Grid container fullWidth spacing={8} >  
                    {this.state.tagGroups.map((tagGroup, key) =>
                      <Grid key={key} xs={12} sm={6} md={4} lg={3} xl={2} item style={{width: "100%"}}>
                        <AsyncSelect
                          key={key}
                          input={(styles) => ({ ...styles, margin: "5px 0", })}
                          styles={{
                            input: (styles) => ({ ...styles, color: "var(--main-font-color, black)", }),
                            placeholder: (styles) => ({ ...styles, color: "var(--main-font-color, black)", }),
                            singleValue: (styles) => ({ ...styles, color: "var(--main-font-color, black)", }),
                            menu: base => ({
                              ...base,
                              zIndex: 10,
                              borderColor: "var(--main-font-color, black)",
                              backgroundColor: "var(--main-color, white)",
                              color: "var(--main-font-color, black)",
                            }),
                            control: (baseStyles, state) => {
                              return {
                              ...baseStyles,
                              borderColor: "var(--main-font-color, black)",
                              backgroundColor: "var(--main-color, white)",
                              color: "var(--main-font-color, black)",
                            }},
                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                              return {
                                ...styles,
                                borderColor: "var(--main-font-color, black)",
                                backgroundColor: (isFocused || isSelected) ? "var(--main-font-color, black)" : "var(--main-color, white)",
                                color: (isFocused || isSelected) ? "var(--main-color, white)" : "var(--main-font-color, black)",
                                opacity: isDisabled ? 0.5 : (isSelected ? 0.7 : 1),
                              }
                            },
                          }}
                          theme={theme => {
                            return {
                              ...theme,
                              backgroundColor: "var(--main-color, white)",
                              borderRadius: 5,

                              borderColor: "var(--main-font-color, black)",
                              backgroundColor: "var(--main-color, white)",
                              color: "var(--main-font-color, black)",
                            }
                          }}
                          // isDisabled={tagGroup.tags.every(t => t.state== "disable")}
                          style={{margin: "10px 0", maxWidth: 185}}
                          isOptionDisabled={(option) => option.disabled}
                          value={tagGroup.tags.find(t => t.state == "selected")  ? 
                              { value: tagGroup.tags.find(t => t.state == "selected").id, label: tagGroup.tags.find(t => t.state == "selected").name } :
                              { value: "0", label: tagGroup.name }
                          }
                          onChange={e => {
                            const selectedValue = e ? e : { value: "0", label: tagGroup.name };
                            const prevSelectedTag = tagGroup.tags.find(t => t.state == "selected") ? tagGroup.tags.find(t => t.state == "selected").id : "0";                      
                            let currentTags = this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected').map(tag => tag.id).filter(selectedTag => selectedTag != prevSelectedTag);

                            if (selectedValue.value != "0") { 
                              currentTags.push(selectedValue.value)
                            };

                            return this.handleSmartSearchSelectedTagsChanged(currentTags, selectedValue.value)
                          }}
                          loadOptions={(inputValue, callback) => {
                            callback([
                              {label: tagGroup.name, value: "0"},
                              ...tagGroup.tags.map(t => ({label: t.name, value: t.id, disabled: t.state == "disable"}))]
                              .filter(t => t.label.toLowerCase().includes(inputValue.toLowerCase())))
                          }}
                          isClearable={tagGroup.tags.find(t => t.state == "selected")}
                          defaultOptions={[{label: tagGroup.name, value: "0"}, ...tagGroup.tags.map(t => ({label: t.name, value: t.id, disabled: t.state == "disable"}))]}
                        />
                      </Grid>
                    )}
                  </Grid>
                }
                <div style={{ margin: "20px 0",display: "flex", alignItems: "center", justifyContent: "center"}}>
                  {this.state.searchResultsTags.length == 0 ? 
                    <p style={{textAlign: "center", fontSize: 24, padding: isMobile ? "40px 0 " : "120px 0"}}>{localization.get('searchbar.no_result')}</p>
                    :
                    <Grid container fullWidth>
                      {this.state.searchResultsTags.map((creation, key) =>
                        <Grid key={key} xs={12} sm={6} md={4} lg={4} xl={3} item style={{padding: 10, width: "100%"}}>
                          <CreationItem
                            isPublic={this.props.isPublic}
                            noAuthorLink={this.props.isPublic}
                            contentCreation={creation}
                            onPlay={() => this.onPlayMaterial(creation)}
                          />
                        </Grid>
                      )}
                    </Grid>
                  }
              </div>
            </>
            :
            <div style={{
              height: "70px", 
              paddingTop: 20,
              paddingBottom: 20,
              width: "100%", 
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}>
            <img style={{width: 50, height: 50}} src={LoadingSpinner} alt="Loading logo"/>
          </div>
          } 
        </div>
    );
  }
}