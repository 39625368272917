import {addPutMethodToForm, post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
  id,
  name,
  email,
  phone,
  translations,
  imageId,
  coverImageId,
  featuredImageId,
  mobileCoverImageId,
  selectedTags,
  selectedContentCreations,
  socialLinks,
  isDemo,
  countryId,
  observation,
  active,
  selectedCatalogs,
  contentCreatorTypeId,
  weight,
  unsplashCoverImage,
  unsplashFeaturedImage,
  unsplashProfileImage,
  unsplashMobileCoverImage,
  startActivity,
  batchProfileImageId,
  batchCoverImageId,
  batchMobileCoverImageId,
  batchFeaturedImageId,
  downloadableMaterialId,
  downloadableMaterialFileType,
  downloadableMaterialText,
  downloadableMaterialLink,
  contentSupervisorId,
  onlyArgentina,
  releaseDate,
  expireDate,
  deleteDownloadableMaterial,
  batchDownloadableMaterialId,
  mainMaterial,
  viewLimit,
  timeToTrack,
  requireLogin,
  hasSuggestedCarousel,
  suggestedCarouselPlaylist,
  showDateOnHome,
  showTags,
  suggestedTagGroup,
  suggestedView,
  selectedCloud,
  hasPreferences,
  useBackgroundColor,
  backgroundColor,
  useBackgroundGradient,
  subtitleFontSize,
  subtitleMargin,
  titleColor,
  descriptionColor,
  creationColor,
  cardColor,
  cardShape,
  creationInverted,
  isEvent,
  eventsPosition,
  buyButtonText,
  buyButtonShowInSocials,
  buyButtonProductId,
  buyButtonPosition,
  buyButtonBackgroundColor,
  buyButtonFontColor,
  allowedRegions,
  hasRegionBlock,
  editorialBgColor,
  editorialTitleColor,
  editorialHideCoverImage,
  hasEditorialPreferences,
  creationCarouselBackgroundColor,
  viewType,
  viewTypeMobile,
  suggestedViewMultipleRow,
  driveProfileImage,
  driveCoverImage,
  driveFeaturedImage,
  driveMobileCoverImage,
  canonicalChannel,
  suggestedContentCreators,
  accessLevel,
  randomProfileImage,
  randomCoverImage,
  additionInfoSize,
  eventButtonText,
  eventButtonColor,
  eventButtonBackgroundColor,
  editorialUseSynopsis
}) => post(`${host}/contentCreators/${id}`, addPutMethodToForm(makeFormData({
    name,
    email,
    phone,
    translations,
    imageId,
    coverImageId,
    mobileCoverImageId,
    featuredImageId,
    selectedTags,
    selectedContentCreations,
    socialLinks,
    isDemo,
    countryId,
    observation,
    active,
    selectedCatalogs,
    contentCreatorTypeId,
    weight,
    unsplashCoverImage,
    unsplashFeaturedImage,
    unsplashProfileImage,
    unsplashMobileCoverImage,
    startActivity,
    batchProfileImageId,
    batchCoverImageId,
    batchFeaturedImageId,
    batchMobileCoverImageId,
    downloadableMaterialId,
    downloadableMaterialFileType,
    downloadableMaterialText,
    downloadableMaterialLink,
    contentSupervisorId,
    onlyArgentina,
    releaseDate,
    expireDate,
    deleteDownloadableMaterial,
    batchDownloadableMaterialId,
    mainMaterial,
    viewLimit,
    timeToTrack,
    requireLogin,
    hasSuggestedCarousel,
    suggestedCarouselPlaylist,
    showDateOnHome,
    showTags,
    suggestedTagGroup,
    suggestedView,
    selectedCloud,
    hasPreferences,
    useBackgroundColor,
    backgroundColor,
    useBackgroundGradient,
    subtitleFontSize,
    subtitleMargin,
    titleColor,
    descriptionColor,
    creationColor,
    cardColor,
    cardShape,
    creationInverted,
    isEvent,
    eventsPosition,
    buyButtonText,
    buyButtonShowInSocials,
    buyButtonProductId,
    buyButtonPosition,
    buyButtonBackgroundColor,
    buyButtonFontColor,
    allowedRegions,
    hasRegionBlock,
    editorialBgColor,
    editorialTitleColor,
    editorialHideCoverImage,
    hasEditorialPreferences,
    creationCarouselBackgroundColor,
    viewType,
    viewTypeMobile,
    suggestedViewMultipleRow,
    driveProfileImage,
    driveCoverImage,
    driveFeaturedImage,
    driveMobileCoverImage,
    canonicalChannel,
    suggestedContentCreators,
    accessLevel,
    randomProfileImage,
    randomCoverImage,
    additionInfoSize,
    eventButtonText,
    eventButtonColor,
    eventButtonBackgroundColor,
    editorialUseSynopsis
})))
  .then(response => response.json())
  .then(json => json.data)
