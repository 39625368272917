import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  XIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import styled from "styled-components";
import {Close, FilterNone} from '@material-ui/icons'
import localization from "../../../config/localization";
import {Dialog, DialogContent, DialogTitle, IconButton, TextField, Tooltip} from "@material-ui/core";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {makeStyles} from "@material-ui/styles";
import {getActiveChannel, getStoredChannel} from "../../../api-client/core/authentication/utils";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../Snackbar/GlobalSnackbar";


const ShareSocialContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
  @media (max-width: 479px) {
    min-width: 270px;
  }
  @media (min-width: 479px) {
    min-width: 400px;
  }
`

const DialogSocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default ({show, url, handleClose, darkMode = false, shareTitle = localization.get('tooltip.share')}) => {
  const classes = makeStyles((theme) =>  ({
      root: {
        style: {
          margin: 0,
        },
        [theme.breakpoints.down("xs")]: {
          alignItems: "flex-end!important" // push the dialog to bottom
        }
      },
      paper: {
        style: {
          margin: 0,
        },
        // make the content full width
        [theme.breakpoints.down("xs")]: {
          margin: 0,
          maxWidth: "100%",
          width: "100%"
        }
      }
    }))
  const copyStringToClipboard = (text) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  };

  const fallbackCopyTextToClipboard = (text) =>  {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  };

  if (show && getActiveChannel() && getStoredChannel() && getStoredChannel().custom_share) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(url);
      return handleClose();
    }

    navigator.clipboard.writeText(url).then(function () {
      GlobalSnackbar.show({
        message: localization.get('copy.success'),
        type: GlobalSnackbarTypes.SUCCESS
      })
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
      handleClose()
    });
    handleClose()
    return null;
  }

  return(
    <Dialog
      classes={{ root: classes.root, paper: classes.paper }}
      open={show}
      onBackdropClick={handleClose}
      onEscapeKeyDown={handleClose}
      PaperProps={{style: {margin: 0}}}
    >
      <IconButton style={{ position: "absolute", top: "5px", right: "5px" }} onClick={handleClose}>
        <Close style={darkMode ? {} : {color: "var(--secondary-font-color, rgba(0, 0, 0, 0.54))"}}/>
      </IconButton>
      <DialogTitle style={{background: darkMode ? "rgba(204, 204, 204, 0.5)" : "var(--secondary-color, white)"}}><span style={{color: darkMode ? "rgba(0, 0, 0, 0.87)" : "var(--secondary-font-color, rgba(0, 0, 0, 0.87))"}}>{localization.get('share')}</span></DialogTitle>
      <DialogContent style={{background: darkMode ? "rgba(204, 204, 204, 0.5)" : "var(--secondary-color, white)"}}>
        <DialogSocialContainer>
          <ShareSocialContainer>
            {window.innerWidth < 479 &&
              <CopyToClipboard style={{width: 40, height: 40, background: darkMode ? "black" : "var(--secondary-color, #ccc)"}} onCopy={() => copyStringToClipboard(url)}>
                <IconButton round={true} bgStyle={{fill: darkMode ? "black" : "var(--secondary-font-color, black)"}}>
                  <Tooltip title={localization.get('copy_link')} placement="top">
                    <FilterNone style={{fontSize: 20, zIndex: 2, color: darkMode ? "white" : "var(--secondary-color, white)"}}/>
                  </Tooltip>
                </IconButton>
              </CopyToClipboard>
            }
            <WhatsappShareButton url={url} >
              <WhatsappIcon size={window.innerWidth < 479 ? 40 : 60} round={true} bgStyle={{fill: darkMode ? "black" : "var(--secondary-font-color)"}} iconFillColor={darkMode ? 'white' : "var(--secondary-color, white)"}/>
            </WhatsappShareButton>
            <TwitterShareButton url={url} >
              <XIcon size={window.innerWidth < 479 ? 40 : 60} round={true} bgStyle={{fill: darkMode ? "black" : "var(--secondary-font-color)"}} iconFillColor={darkMode ? 'white' : "var(--secondary-color, white)"}/>
            </TwitterShareButton>
            <FacebookShareButton url={url} >
              <FacebookIcon size={window.innerWidth < 479 ? 40 : 60} round={true} bgStyle={{fill: darkMode ? "black" : "var(--secondary-font-color)"}} iconFillColor={darkMode ? 'white' : "var(--secondary-color, white)"}/>
            </FacebookShareButton>
            <LinkedinShareButton url={url} >
              <LinkedinIcon size={window.innerWidth < 479 ? 40 : 60} round={true} bgStyle={{fill: darkMode ? "black" : "var(--secondary-font-color)"}} iconFillColor={darkMode ? 'white' : "var(--secondary-color, white)"}/>
            </LinkedinShareButton>
            <TelegramShareButton url={url} >
              <TelegramIcon size={window.innerWidth < 479 ? 40 : 60} round={true} bgStyle={{fill: darkMode ? "black" : "var(--secondary-font-color)"}} iconFillColor={darkMode ? 'white' : "var(--secondary-color, white)"}/>
            </TelegramShareButton>
            <EmailShareButton url={url} subject={shareTitle}>
              <EmailIcon size={window.innerWidth < 479 ? 40 : 60} round={true} bgStyle={{fill: darkMode ? "black" : "var(--secondary-font-color)"}} iconFillColor={darkMode ? 'white' : "var(--secondary-color, white)"} />
            </EmailShareButton>
          </ShareSocialContainer>
          {window.innerWidth > 479 &&
            <div style={{display: 'flex', width: "100%", marginTop: 30}}>
              <TextField
                style={{flex: 1}}
                label={localization.get('form.receiver')}
                value={url}
                disabled={true}
                autoComplete={false}
                InputLabelProps={darkMode ? {} : {style: {color: "var(--secondary-font-color, rgba(0, 0, 0, 0.54))"}}}
                inputProps={darkMode ? {} : {style: {color: "var(--secondary-font-color, rgba(0, 0, 0, 0.54))"}}}
                InputProps={{disableUnderline: true}}
              />
              <div>
                <CopyToClipboard
                  onCopy={() => copyStringToClipboard(url)}
                >
                  <IconButton>
                    <Tooltip title={localization.get('copy_link')} placement="top">
                      <FilterNone style={darkMode ? {} : {color: "var(--secondary-font-color, rgba(0, 0, 0, 0.54))"}}/>
                    </Tooltip>
                  </IconButton>
                </CopyToClipboard>
              </div>
            </div>
          }
        </DialogSocialContainer>
      </DialogContent>
    </Dialog>
  );
}
