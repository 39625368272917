import {FilePond, registerPlugin} from "react-filepond";
import React, {Component} from "react";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import localization from "../../../config/localization"
import {request} from "../../../api-client";
import Axios from "axios";

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageValidateSize);
registerPlugin(FilePondPluginFileValidateSize);

export default class ClientFileUploader extends Component {
  constructor(props) {
    super();
    this.state = {
      type: props.type,
      disabledForm: props.disableForm,
      allowedTypes: props.allowedTypes,
      item: '',
      items: [],
      isVTT: props.allowedTypes && props.allowedTypes.length == 1 && props.allowedTypes[0] == 'text/vtt',
      filesUploading: 0
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.disabledForm !== this.state.disabledForm){
      this.setState({disabledForm: nextProps.disableForm})
    }

    if(nextProps.allowedTypes !== this.state.allowedTypes){
      this.setState({allowedTypes: nextProps.allowedTypes})
    }

    if(nextProps.type !== this.state.type){
      this.setState({type: nextProps.type})
    }
  }

  onIdChange = (item, file, type) => {
    if (!this.props.isMultiple) {
      this.setState({item}, () => this.props.onIdChange(item))
    } else {
      let newItems = this.state.items
      newItems.push({id:item.id, name: file.name, type})
      this.setState({items: newItems}, () => this.props.onIdChange(newItems))
    }
  }

  handleDisableForm = val => {
    this.props && this.props.updateDisableForm && this.props.updateDisableForm(val)
  }

  getFileName = (file) => {
    if(this.props.customProp){
      return file.name.split('.')[0] + '.vtt'
    } else {
      return file._relativePath
      && file._relativePath.replace(/\//g, '__DIR__')
      || file.name;
    }
  }

  getFileType = (file) => {
    return new Promise(resolve => {
      let type = this.props.type
      if (!type) {
        let types = ['video', 'image', 'audio', 'other']
        let mimeType = file.type ? file.type.split('/') : file.fileType ? file.fileType.split('/') : ['other'];
        if (types.includes(mimeType[0])) {
          type = mimeType[0]
        } else {
          type = 'other'
        }
      this.props.setFileType && this.props.setFileType(type)
      }
      resolve(type)
    })
  }

  processUpload = async (r, fieldName, file, load, error, progress, duration, type) => {
    const data = { 
      type,
      duration,
      filename: this.getFileName(file),
      batchUpload: this.props.batchUpload,
      isLicense: this.props.isLicense,
      size: file.size,
      ...(this.props.clientId && {clientId: this.props.clientId}),
      ...(this.props.channelId && {channelId: this.props.channelId}),
    }

    request.file.upload(data)
      .then(async res => {
        if (res.parts) {
          let done = 0;
          const promises = [];
          const axios = Axios.create()
          delete axios.defaults.headers.put['Content-Type']

          if (this.state.disabledForm !== true) {
            this.handleDisableForm(true)
          }

          for (let index = 0; index <= res.parts; index++) {
            const blob = file.slice(res[index]["start"], res[index]["end"]);
            promises.push(axios.put(res[index]["presigned_url"], blob))
          }

          for (const prom of promises) {
            prom.then(()=> {
              progress(promises.length, done, promises.length);
            })
              .catch(e => {
              progress(promises.length, done, promises.length);
            })
              .finally(() => done ++);
          }

          return await Promise.all(promises)
            .then(resParts => {
              const parts = resParts
                .map((part, index) => ({
                    ETag: part.headers.etag,
                    PartNumber: index + 1
                  }))

              return request.file.completeMultipart({uploadId: res.upload_id, parts: parts, path: res.path })
                .catch(e => {
                  request.file.abortMultipart({uploadId: res.upload_id, parts: parts, path: res.path })
                  error('oh no')
                  this.handleDisableForm(false)
                })
                .then(() => {
                  load(r.responseText)
                  this.onIdChange(res, file, type)
                  this.props.setPreview && this.props.setPreview(this.props.type)
                  this.checkAllFilesProcessed()
                })
            })
            .catch(e => console.log(e))
            
        } else if (res.presigned_url) {
          r.open('PUT', res.presigned_url);
          if (this.props.customProp && this.props.customProp === 'text/vtt') {
            Object.defineProperty(file, 'type', {
              value: 'text/vtt',
              writable: false
            })

            Object.defineProperty(file, 'name', {
              value: file.name.split('.')[0] + '.vtt',
              writable: false
            })
          }

          r.setRequestHeader('Content-Type', ['application/pdf','text/vtt'].includes(file.type) ? file.type : 'application/octet-stream')
          r.setRequestHeader('Cache-Control', 'public, immutable, max-age=31536000')
          if (file.type === "image/gif") {
            r.setRequestHeader('Content-Disposition', 'attachment')
          }
          
          r.upload.onprogress = (e) => {
            progress(e.lengthComputable, e.loaded, e.total);
            if (this.state.disabledForm !== true) {
              this.handleDisableForm(true)
            }
          };

          r.onload = function () {
            if (r.status >= 200 && r.status < 300) {
              load(r.responseText);
            } else {
              error('oh no');
            }
          };
          r.send(file);

          r.upload.onloadend = (e) => {
            if (e.loaded === e.total) {
              this.onIdChange(res, file, type)
              this.checkAllFilesProcessed();
            }
          }

          r.onloadend = (e) => {
            this.props.setPreview && this.props.setPreview(this.props.type)
          }
        }
      })
  }

  checkAllFilesProcessed = () => {
    this.setState(prevState => {
      const filesUploading = prevState.filesUploading - 1;
      if (filesUploading === 0) {
        this.handleDisableForm(false)
      }
      return { filesUploading };
    });
  }

  serverConfig = () => {
    return (
      {
        load: console.log,
        fetch: console.log,
        revert: console.log,
        restore: console.log,
        cancel: console.log,
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          const r = new XMLHttpRequest();

          this.getFileType(file).then((type) => {
            //Get duration
            let duration = 0
            if (type === 'audio' && !["aiff", "wav", "aif"].includes(file.name.split('.').pop())) {
              let reader = new FileReader()
              reader.onload = (event) => {
                let audioContext = new (window.AudioContext)();
                // Asynchronously decode audio file data contained in an ArrayBuffer.
                audioContext.decodeAudioData(event.target.result, (buffer) => {
                  // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
                  duration = Math.round(buffer.duration);
                  console.log("The duration of the audio is: " + duration + " seconds");
                })
                  .then(() => this.processUpload(r, fieldName, file, load, error, progress, duration, type) );
              }

              reader.onerror = function (event) {
                console.error("An error ocurred reading the file: ", event);
              };

              // Read file as an ArrayBuffer, important !
              reader.readAsArrayBuffer(file);
            } else {
              this.processUpload(r, fieldName, file, load, error, progress, duration, type)
            }
          })

          return {
            abort: () => {
              r.abort();
              abort();

              this.handleCancelUpload(file);
            },
          };
        },
      }
    )
  }

  // Función para manejar la cancelación de la subida
  handleCancelUpload = (file) => {
    console.log('Upload canceled for file:', file.name);
    this.checkAllFilesProcessed();
  };

  render = () => {
    return (
      <FilePond
        pasteZone={null}
        allowPaste={false}
        labelIdle={localization.get('file_uploader.initial_text')}
        labelFileTypeNotAllowed={localization.get('file_uploader.validate_file_types')}
        fileValidateTypeLabelExpectedTypes={localization.get('file_uploader.expected_file_types', this.state.isVTT ? "WebVTT" : this.state.type)}
        labelFileProcessing={localization.get('file_uploader.uploading')}
        labelFileProcessingComplete={localization.get('file_uploader.upload_complete')}
        labelTapToCancel={localization.get('file_uploader.tap_to_cancel')}
        labelTapToUndo={localization.get('file_uploader.tap_to_undo')}
        imageValidateSizeLabelImageSizeTooBig={localization.get('file_uploader.validate_image_size')}
        imageValidateSizeLabelExpectedMaxSize={localization.get('file_uploader.image_max_size')}
        acceptedFileTypes={this.state.allowedTypes}
        onaddfilestart={(e = null) => {
          this.setState(prevState => ({
            filesUploading: prevState.filesUploading + 1
          }));

          this.handleDisableForm(true)
        }}
        
        server={this.serverConfig()}
        allowMultiple={this.props.isMultiple || false}
        maxFiles={this.props.maxFiles || 1}
        maxParallelUploads={4}
        allowFileSizeValidation={!!this.props.maxFileSize}
        maxFileSize={this.props.maxFileSize}
        imageValidateSizeMeasure={(source, type) => new Promise((resolve, reject) => {
          let name = source.name.split('.')
          if (name.length === 1 || name[1] === 'tif') {
            resolve({width: 100, height: 100})
          }
        })}
        fileValidateTypeDetectType = {(source, type) => new Promise((resolve, reject) => {
          let name = source.name.split('.')

          if (name.length === 1 || name[1] === 'tif') {
            resolve('image/tif')
          }
          if (name.length === 1 || name[1] === 'vtt') {
            Object.defineProperty(source, 'type', {
              value: 'text/vtt',
              writable: false
            })
            Object.defineProperty(source, 'name', {
              value: name[0] + '.vtt',
              writable: false
            })
            resolve('text/vtt')
          }
          resolve(type)
        })}
        onprocessfilerevert={(file) => {
          this.getFileType(file).then((type) => {
            if (!this.props.isMultiple) {
              request.file.delete(this.state.item.id, type)
                .then(() => this.props.onIdChange(''))
            } else {
              let newItems = this.state.items
              let index = newItems.findIndex(item => item.name === file.filename)
              if (index > -1) {
                let itemToDelete = newItems.splice(index, 1)
                request.file.delete(itemToDelete[0].id, type)
                  .then((fileOwnerId) =>  this.setState({items: newItems},
                    () => {
                      if (!this.props.batchUpload) {
                        this.props.onIdChange(newItems)
                      } else {
                        fileOwnerId.json().then((fileId) => this.props.onBatchFileDelete(fileId))
                      }
                    })
                  )
              }
            }
          })
        }}
      />)
  }
}
