import "../Home.css";
import "./index.css"
import React, {Component} from "react";
import Button from "../../../components/CustomButtons/Button.jsx";
import TagSelection from "../../../components/Letflow/TagSelection/TagSelectionSmartSearch.jsx"
import _ from 'lodash'
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
  FormControl,
  Select
} from "@material-ui/core";
import 'react-input-range/lib/css/index.css'
import {Close, PlayArrow, Search, Share, Star, StarBorder, PlaylistAdd, Style, GridOn, List, FilterList, Menu as MenuIcon, SortByAlpha} from "@material-ui/icons";
import qs from "qs";
import browserHistory from "../../../utils/browserHistory";
import {
  channelPathGenerator,
  getActiveChannel,
  getActiveClient, getMainTranslation,
  getStoredChannel, getStoredUser, getUseTools,
  setPageTitle,
  slugify,userCanEditCreators,userHasRestrictedAccess, userisSingleClient
} from "../../../api-client/core/authentication/utils";
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";
import EventManager, {events} from "../../../utils/EventManager";
import InfiniteScroll from "react-infinite-scroll-component";
import CreationItem from "../../../components/Letflow/CreationItem";
import ContentCreationPlayer from "../../../components/Letflow/NewContentCreationPlayer";
import ShareOnSocials from "../../../components/Letflow/ShareOnSocials";
import TextField from "@material-ui/core/TextField/TextField";
import {ClientSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {makeImageUrlWithSize, makeThumbnailUrlWithSize, onImageError} from "../HomeElements/utils";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import AddToPlaylist from "../../../components/Letflow/AddToPlaylist";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import analyticsInstance from "../../../analytics/analyticsInstance";
import moment from "moment";
import {get} from "lodash";
import { DateRangePicker } from "materialui-daterange-picker";
import FilterContainer from "./FilterContainer";
import GridItem from "../HomeElements/GridItem";
import HomeElementGridContainer from "../HomeElements/HomeElementGridContainer";
import HomeElementFeaturedItem from "../HomeElements/HomeElementFeaturedItem";
import UpdateManyMaterialsDialog from "../../../components/Letflow/UpdateManyMaterialsDialog";

const makeSearchButton = (onClick) =>
  <Button className="smart-search-search-button" onClick={onClick}>
    {localization.get('home.smart_search.search')}
  </Button>

const sortTypes = [
  {value: "default", label: localization.get('sort.default'), column: "order", direction: "desc"},
  {value: "titleA", label: localization.get('sort.title.asc'), column: "title", direction: "asc"},
  {value: "titleD", label: localization.get('sort.title.desc'), column: "title", direction: "desc"},
  // {value: "contentA", label: localization.get('sort.content_creator.asc'), column: "content_creators.name", direction: "asc"},
  // {value: "contentD", label: localization.get('sort.content_creator.desc'), column: "content_creators.name", direction: "desc"},
]

class SmartSearchContentCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagGroups: [],
      searchResultsTags: [],
      searchResultsTitle: [],
      showTagSelection: false,
      showTagDialog: false,
      searchGroup: "contentCreation",
      type: '',
      selectedCountry: '',
      availableCountries: [],
      showTags: false,
      pageReady: false,
      favorites: [],
      contentCreations: [],
      channel: props.channel || null,
      lastPage: 0,
      currentPage: 0,
      showContentCreationDialog: false,
      currentPlayingContent: {},
      showShareDialog: false,
      sharingCreation: 1,
      shareUrl: "",
      contentCreatorType: null,
      searchInput: "",
      hasQueryParams: false,
      viewType: "grid4",
      openSort: false,
      sortType: "default",
      selectedContentCreations: [],
      selectedAll: false,
      createdFrom: null,
      createdTo: null,
      eventRangeOpen: false,
      selectedDateRange: "none",
      sidebar: false,
      sidebarView: false,
      total: 0,
      openSidebar: window.innerWidth > 600,
      openRetagDialog: false
    }
  }


  componentBoostrap = () => {
    this.requestHasQueryParams()
      .then(() => this.requestTagGroups())
      // .then(() => this.clearUnusedTags())
      .then(() => getActiveChannel() ? request.contentCreationChannelFavorite.getAll().then(favorites => this.setState({ favorites })) : Promise.resolve())
      .then(() => {
        const pref = (getStoredChannel() && this.state.type) ? getStoredChannel().channelContentCreatorTypes.find(ct => ct.contentCreatorType.slug === this.state.type) : {show_search_input_on_smart_search: 1, show_filter_on_smart_search: 1};
        if (pref) this.setState({
          viewType: pref.creation_view_type,
          sidebarView: pref.sidebar_view,
        })
      })
      .then(() => this.setState({showTagSelection: (this.state.tagGroups && this.state.tagGroups.length > 0) ? this.state.showTagSelection : false }, () => !(this.state.hasQueryParams || (this.state.showTagSelection)) && this.requestTracks()))
      .then(() => this.setState({showTags: true}))
      .then(() => (this.state.hasQueryParams || this.state.showTagSelection) && this.readQueryStringParams())
      .then(() => this.requestAvailableTags())
  }

  componentDidMount = () => {
    document.getElementsByTagName('html')[0].scrollTop = 0
    this.componentBoostrap();

    setPageTitle(localization.get('title.smart_search'))

    EventManager.getInstance().subscribe(events.ADD_TO_FAVORITE_BUTTON_PRESS, this.addToFavorites)
    EventManager.getInstance().subscribe(events.EXPLORE_NAVBAR_SEARCH, this.exploreGlobalSearch)
    EventManager.getInstance().subscribe(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromFavorites)

    let queryString = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
      depth: 2,
      plainObjects: true})

    if(queryString && queryString.contenido) {
      EventManager.getInstance().dispatch(events.OPEN_CONTENT_MODAL,
        {
          id: queryString.contenido.split("-")[0],
          dataType: "contentCreator",
          dataName: queryString.contenido.split("-")[1] || "contenido",
          onTagChange: this.handleNewSearch || false,
          origin: "Search"
        });
    }
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.ADD_TO_FAVORITE_BUTTON_PRESS, this.addToFavorites)
    EventManager.getInstance().unsubscribe(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromFavorites)
    EventManager.getInstance().unsubscribe(events.EXPLORE_NAVBAR_SEARCH, this.exploreGlobalSearch)
  }

  // componentWillReceiveProps(nextProps) {
  //   if(nextProps.location.search !== this.props.location.search && typeof nextProps.location.search === 'string') {
  //     this.readQueryStringParams(nextProps.location.search)
  //   }
  // }

  componentWillUpdate(nextProps, nextState) {
    if(nextProps.location.search !== this.props.location.search && typeof nextProps.location.search === 'string') {
      this.readQueryStringParams(nextProps.location.search)
    }
  }

  exploreGlobalSearch = () => this.setState(state => ({
    ...state,
    tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.map(tag => ({
            ...tag,
            state: 'available'
          })
        )}
    ))
  }), this.requestTracks)

  onFavorite = (creation) => {
    analyticsInstance.favorite({
      name: getMainTranslation(creation, "title"),
      type: "Material",
      content: creation.favorite ? "Add" : "Remove"
    })
    if(!creation.favorite) {
      this.addToFavorites(creation.id)
    } else  {
      this.removeFromFavorites(creation.id)
    }
  }

  addToFavorites = id => {
    let onAdded = () => {
      this.state.favorites.push(id);
      this.setState(state => ({
        ...state,
        favorites: this.state.favorites,
        searchResultsTags: state.searchResultsTags.map(creation => {
          if (creation.id === id) {
            return ({...creation, favorite: true})
          }
          return creation
        })
      }))
    }
    if (getActiveChannel()) {
      request.contentCreationChannelFavorite.add(id)
        .then(() => onAdded())
    }else {
      request.contentCreationFavorite.add(id)
        .then(() => onAdded())
    }
  }

  removeFromFavorites = id => {
    let onRemoved = () => {
      if (this.state.favorites.indexOf(id) > -1) {
        this.state.favorites.splice(this.state.favorites.indexOf(id), 1)
      }
      this.setState(state => ({
        ...state,
        favorites: this.state.favorites,
        searchResultsTags: state.searchResultsTags.map(creation => {
          if (creation.id === id) {
            return ({...creation, favorite: false})
          }
          return creation
        })
      }))
    }
    if (getActiveChannel()) {
      request.contentCreationChannelFavorite.remove(id)
        .then(() => onRemoved())
    }else {
      request.contentCreationFavorite.remove(id)
        .then(() => onRemoved())
    }
  }

  requestHasQueryParams = () =>
    new Promise(resolve => {
      let search = qs.parse(this.props.location.search.slice(1))
      let {tags_ids, not_tags_ids, type, name} = search
      let creatorTypes = getActiveChannel() && getStoredChannel().channelContentCreatorTypes;
      let contentCreatorType = creatorTypes && creatorTypes.find(ct => ct.contentCreatorType.slug == type);
      this.setState({type, contentCreatorType});
      if (tags_ids || not_tags_ids || name) {
        this.setState({showTagSelection: false, hasQueryParams: true})
      } else {
        this.setState({showTagSelection: (contentCreatorType && contentCreatorType.show_tag_selection) || !getActiveChannel()})
      }
      resolve()
    })

  readQueryStringParams = (queyString = this.props.location.search) => {
    let search = qs.parse(queyString.toString().slice(1))
    let {tags_ids, not_tags_ids, name, type, searching, clean_search} = search

    if (type && type !== this.state.type) {
      let creatorTypes = getActiveChannel() && getStoredChannel().channelContentCreatorTypes;
      let contentCreatorType = creatorTypes && creatorTypes.find(ct => ct.contentCreatorType.slug == type);
      this.setState({type, contentCreatorType}, this.componentBoostrap);
    }

    if (tags_ids || not_tags_ids || name || searching || clean_search) {
      if (clean_search) {
        this.cleanSearch();
      }

      if (tags_ids) {
        if (Array.isArray(tags_ids)) {
          tags_ids.forEach(tag => this.markTagAsSelected(tag))
        } else {
          this.markTagAsSelected(tags_ids)
        }
      }

      if (not_tags_ids) {
        if (Array.isArray(not_tags_ids)) {
          not_tags_ids.forEach(tag => this.markTagAsUnselected(tag))
        } else {
          this.markTagAsUnselected(not_tags_ids)
        }
      }
        
      if (name) {
        this.setState({searchInput: name})
      }

      if (clean_search) {
        browserHistory.push({
          pathname: window.location.pathname,
          search: qs.stringify({
            type,
            tags_ids: this.getSelectedTags().map(tag => tag.id),
            not_tags_ids: this.getUnselectedTags().map(tag => tag.id),
            name: name,
            searching: searching ? "true" : null,
          })
        })
      }

      this.requestTracks({});
    }
  }

  writeQueryStringParams = (searching = false) => {
    let {type, searchInput} = this.state
    browserHistory.push({
      pathname: window.location.pathname,
      search: qs.stringify({
        type,
        tags_ids: this.getSelectedTags().map(tag => tag.id),
        not_tags_ids: this.getUnselectedTags().map(tag => tag.id),
        name: searchInput,
        searching: searching ? "true" : null,
      })
    })
  }

  markTagAsSelected = tagId => new Promise(resolve => {
    const tags = _.flatMap(this.state.tagGroups, tagGroup => tagGroup.tags)
    const tag = _.find(tags, tag => tag.id.toString() === tagId.toString())
    if (tag) {
      tag.state = 'selected'
    }
    this.forceUpdate(() => resolve({}))
  })

  markTagAsUnselected = tagId => new Promise(resolve => {
    const tags = _.flatMap(this.state.tagGroups, tagGroup => tagGroup.tags)
    const tag = _.find(tags, tag => tag.id.toString() === tagId.toString())
    if (tag) {
      tag.state = 'unselected'
    }
    this.forceUpdate(() => resolve({}))
  })

  requestTagGroups = () =>  {
    let paginationConfig = {filterBy: [
        { column: 'content_creations', filter: true},
        {column: 'inheritable', filter: "true"},
        { column: 'show_on_smart_search', filter: '1'},]}
    if (this.state.type) {
      paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([
        { column: 'type', filter: `${this.state.type}`}
        ])}
    }
    getActiveChannel() && paginationConfig.filterBy.push({column: 'channel_id', filter: `${getActiveChannel()}`})
    return request.tagGroup.getAll(paginationConfig, 'material')
    .then(tagGroups => {
      let availableTagGroups = tagGroups.filter(tagGroup => tagGroup.tags.length)
      if(getActiveChannel() && getStoredChannel().tagGroups.length) {
        getStoredChannel().tagGroups.filter(tg => tg.target === 'material').forEach(channelTg => {
          availableTagGroups.forEach(tg => {
            if (tg.id === channelTg.id) {
              tg.order = channelTg.order
              tg.view_type = channelTg.view_type
            }
          })
        })
      }

      this.setState({ tagGroups: availableTagGroups.sort((a,b) => a.order - b.order)})
    })
  }


  makeSmartSearchTagSelector = () => {
    return (
      <div>
        <section style={{ backgroundColor: "var(--main-color, rgb(247, 247, 247))", display: this.state.showTagSelection ? 'block' : 'none'}} className="category">
          <div style={{ float: "left", position: "relative", width: "100%", textAlign: "center", padding: "10px 50px", height: "auto" , minHeight: "520px"}}>
            {!this.state.showTags ?
              <div style={{height: "100%", display: "flex", justifyContent: "center", alignContent: "center"}}>
                <img style={{width: 150, height: 150, marginTop: "calc(43vh - 70px)"}} src={LoadingSpinner} alt="Loading logo"/>
              </div>
              :
              <>
                {getUseTools() && this.renderDateSearch()}
                <TagSelection
                  horizontal={true}
                  onSelectedTagsChanged={this.handleSmartSearchSelectedTagsChanged}
                  data={this.state.tagGroups}
                  notWriteQueryString={false}
                  allowUnselected={this.state.contentCreatorType ? !!this.state.contentCreatorType.support_negative_search : true}
                  singleTagSelection={this.state.contentCreatorType ? !this.state.contentCreatorType.has_multiple_tags_allowed : true}
                  checkAvailableTags={(getActiveChannel() && this.state.contentCreatorType) ? !(this.state.contentCreatorType.has_multiple_tags_allowed && !this.state.contentCreatorType.restricted_material_search) : true}
                />
              </>
            }
          </div>
        </section>
      </div>
    )
  }

  getSelectedTags = () =>  this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected')
  getUnselectedTags = () =>  this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'unselected')

  handleSmartSearchSelectedTagsChanged = (selectedTags, unselected, lastTagId, checkAvailableTags = true, writeQueryParams = true) => 
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.map(tag => ({
          ...tag,
          state: selectedTags.includes(tag.id) ? 'selected' : unselected.includes(tag.id) ? 'unselected' : "available"
        }))
      }))
    }), () => {
      checkAvailableTags && this.requestAvailableTags({lastTagId})
      writeQueryParams && this.writeQueryStringParams();
      writeQueryParams && selectedTags.length === 0 && this.requestTracks(); 
    })

  clearUnusedTags = () => {
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.filter(tag => tag.state !== 'disable')
      })).filter(tg => tg.tags.length > 0)
    }))
  }

  requestAvailableTags = ({
    clientId = getActiveClient(),
    searchGroup = this.state.searchGroup,
    type = this.state.type,
    unselectedSearchTags = this.getUnselectedSearchTags(),
    searchTags = this.getSearchTags(),
    lastTagId = null,
    channelId = this.state.channel ? this.state.channel.id : null,
  } = {}) => {
    return request.tag.getAvailableTagsForClient({
      clientId,
      tagGroupType: searchGroup,
      contentCreatorType: type,
      isCreation: true,
      tags: searchTags,
      notTags: unselectedSearchTags,
      lastTagId,
      channelId
    }).then(availableTags => {
      const mappedAvailableTags = availableTags.map(x => x.id)
      return this.setState({
         tagGroups: this.state.tagGroups.map(tgs => ({
          ...tgs,
          tags: tgs.tags.map(tag => ({
            ...tag,
            state: ['selected', 'unselected'].includes(tag.state) ? tag.state : (mappedAvailableTags.includes(tag.id) ? 'available' : 'disable')
          }))
        })) 
      })
    })
  }

  getSearchTags = () => this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected').map(tag => tag.id)

  getUnselectedSearchTags = () => this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'unselected').map(tag => tag.id)

  handleSmartSearch = () => {
    if (this.getSelectedTags().concat(this.getUnselectedTags()).length > 0) {
      this.requestTracks()
      this.setState({showTagDialog: false})
    } else {
      GlobalSnackbar.show({message: localization.get('home.smart_search.missing_tags'), type: GlobalSnackbarTypes.ERROR})
    }
  }

  closeDialog = () =>  {
    let search = qs.parse(this.props.location.search.slice(1))
    let {tags_ids, not_tags_ids} = search

    if (tags_ids || not_tags_ids ) {
      if (tags_ids) {
        if (Array.isArray(tags_ids)) {
          this.state.tagGroups.forEach(tagGroup =>
            tagGroup.tags.forEach(tag =>
              tag.state = tags_ids.includes(tag.id.toString()) ? 'selected' : 'available'
            ));
        }
      }
      if (not_tags_ids) {
        if (Array.isArray(not_tags_ids)) {
          this.state.tagGroup.tags.forEach(tag =>
            tag.state = tags_ids.includes(tag.id) && 'unselected'
          )
        }
      }
    }else {
      this.state.tagGroups.forEach(tagGroup =>
        tagGroup.tags.forEach(tag =>
          tag.state = 'available'
        ));
    }

    this.setState({showTagDialog: false, tagGroups: this.state.tagGroups})
    //this.setState({showTagDialog: false})
  }

  removeSelectedTag = (id) => {
    if (this.getSelectedTags().concat(this.getUnselectedTags()).length === 1) {
      this.cleanSearch()
    } else {
      this.setState({
        tagGroups: this.state.tagGroups.map(tagGroup => ({
          ...tagGroup,
          tags: tagGroup.tags.map(tag => {
            if (tag.id === id) {
              tag.state = 'available'
            }
            return tag
          })
        }))
      }, () => this.writeQueryStringParams())
      this.requestTracks()
    }
  }

  makeHomeElementProps = () => {
    const materialIds = this.state.searchResultsTags.map((item, index) => ({index, id: item.id}))

    return {
      ...this.state.searchResultsTags,
      items: {
        data: this.state.searchResultsTags.map(item => ({
          ...item,
          kind: 'video_content_creation',
          materialIds: materialIds,
        }))
      }
    }
  }

  renderSearchResultsTableTags = () => {
    const { searchResultsTags } = this.state
    const pref = (getStoredChannel() && this.state.type) ? getStoredChannel().channelContentCreatorTypes.find(ct => ct.contentCreatorType.slug === this.state.type) : {show_search_input_on_smart_search: 1, show_filter_on_smart_search: 1};
    const viewType = this.state.viewType;
    const columns = [
      {
        id: "image",
        Header: () => <div style={{display: "flex", flexDirection: "row", height: 60}}/>,
        accessor: contentCreation => (
          <img
            alt="contentCreation"
            src={(contentCreation.video && !contentCreation.image) ? makeThumbnailUrlWithSize(contentCreation.video, 'xs') :  makeImageUrlWithSize(contentCreation, 'xs')}
            width={50}
            height={50}
            onError={onImageError}
          />
        ),
        style: {
          float: "left"
        },
        resizeable: false,
        sortable: false,
        filterable: false,
        width: 60
      },
      {
        Header: localization.get("table.name"),
          accessor: "title",
        Filter: ({filter, onChange}) => (
        <input
          onChange={event => {
            onChange(event.target.value)
            this.setState({title: event.target.value || ""})
          }}
          value={filter ? filter.value : ""}
          placeholder={localization.get("table.search")}
        />
      )
      },
      {
        Header: localization.get("content_creator"),
        id: "contentCreator[name]",
        accessor: content => content.contentCreator ? content.contentCreator.name : "",
        Filter: ({filter, onChange}) => (
        <input
          onChange={event => {
            this.setState({content_creator_id: event.target.value || ""})
            onChange(event.target.value)
          }}
          value={filter ? filter.value : ""}
          placeholder={localization.get("table.search")}
        />
      ),
        sortable: false,
        filterable: false,
        width: 300,
      },
  
      {
        id: "actions",
        Header: <div style={{display: "flex", flexDirection: "column"}}>
            <span style={{float: "right", fontSize: "18px",}}>{localization.get("table.actions")}</span>
          </div>,
        sortable: false,
        filterable: false,
        accessor: contentCreation => {
        const buttonBuilder = new TableButtonsBuilder();
  
        {!!getStoredUser() && getActiveClient() && getUseTools() &&
        buttonBuilder.withOther(
          <TableButton title={localization.get("add_to_playlist")}>
            <AddToPlaylist
              clientId={getActiveClient()}
              content={"contentCreations"}
              item={contentCreation}
              icon={<PlaylistAdd style={{marginTop: 8, marginLeft: 3, color: "var(--main-font-color, inherit)"}} />}
            />
          </TableButton>
        )
        }
        buttonBuilder.withOther(
          <TableButton
            title={localization.get('tooltip.play')}
            onClick={() => this.setCurrentPlayingContent(contentCreation.index)}>
            <PlayArrow style={{color: "var(--main-font-color, inherit)"}}/>
          </TableButton>
        )
  
        buttonBuilder.withOther(
          <TableButton
            title={localization.get(contentCreation.favorite ? "soundbar.remove_from_favorites" : "soundbar.add_to_favorites")}
            onClick={() => this.onFavorite(contentCreation)}
          >
            {!contentCreation.favorite ? <StarBorder style={{color: "var(--main-font-color, inherit)"}} /> : <Star style={{color: "var(--main-font-color, inherit)"}} />}
          </TableButton>
        )
  
        buttonBuilder.withOther(
          <TableButton
            title={localization.get('reel.share')}
            onClick={() => request.contentCreation.shareByChannel(contentCreation.id)
              .then(response => this.setState({
                showShareDialog: true,
                shareUrl: response.url,
                sharingCreation: contentCreation.id,
                sharingCreationTitle: contentCreation.title,
              }))
              .then(() => analyticsInstance.share({
                name: getMainTranslation(contentCreation, "title"),
                type: "Material",
              }))
            }
          >
            <Share style={{color: "var(--main-font-color, inherit)"}} />
          </TableButton>
        )
  
  
        return buttonBuilder.build();
        },
        width: 145,
        Footer: <span style={{float: "right", fontSize: "18px", marginTop: "15px"}}><strong>Total: </strong>{this.state.total}</span>
      }];
  
  
      if (!!getStoredUser() && getActiveClient() && getUseTools()) {
        columns.unshift({
          Header: () => {
            return (
            <div style={{display: "flex", height: 60, overflow: "visible"}}>
              <div style={{display: "flex", flexDirection: "row", position: "absolute", zIndex: 100}}>
                <TableButton title={localization.get("add_to_playlist")} style={{marginTop: 7, marginLeft: 7,}}>
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={this.state.selectedAll}
                    onChange={() => {
                      if (!this.state.selectedAll) {
                        this.setState({selectedAll: !this.state.selectedAll},
                          () => request.contentCreation.getIdsBySearch(
                            this.state.type,
                            this.getSearchTags(),
                            this.getUnselectedSearchTags(),
                            this.state.searchInput
                          ).then(res => this.setState({selectedContentCreations: res.ids})))
                      }else {
                        this.setState({selectedAll: !this.state.selectedAll, selectedContentCreations: []})
                      }
                    }}
                  />
                </TableButton>
                {this.state.selectedContentCreations.length !== 0 &&
                  <TableButton title={localization.get("add_to_playlist")} style={{marginTop: 7}}>
                    <AddToPlaylist
                      clientId={getActiveClient()}
                      content={"contentCreations"}
                      item={this.state.selectedContentCreations}
                      icon={<PlaylistAdd style={{marginTop: 11, marginLeft: 4}}/>}
                      isMulti
                    />
                  </TableButton>
                }
                {this.state.selectedContentCreations.length !== 0 && userCanEditCreators() && !userisSingleClient() &&
                  <TableButton title={localization.get("form.retag")} style={{marginTop: 7}}>
                    <Style onClick={() => this.setState({openRetagDialog: true})} />
                  </TableButton>
                }
              </div>
            </div>
        )},
          id: "check",
          accessor: contentCreation =>
            <Checkbox
              style={{color: "#4cc2d5"}}
              checked={this.state.selectedContentCreations.includes(contentCreation.id)}
              onChange={() => {
                if (this.state.selectedContentCreations.includes(contentCreation.id)) {
                  this.setState({
                    selectedContentCreations: this.state.selectedContentCreations.filter(creation => creation !== contentCreation.id)
                  })
                }else {
                  this.state.selectedContentCreations.push(contentCreation.id)
                  this.setState({
                    selectedContentCreations: this.state.selectedContentCreations
                  })
                }
              }}
            />
          ,
          resizeable: false,
          sortable: false,
          filterable: false,
          width: 60,
        })
      }
    

    const Container = ({ children }) => <div ref={r => this.resultsTableTags = r} style={{
      float: 'left',
      position: 'relative',
      width: '100%',
      backgroundColor: "var(--main-color, white)",
      marginTop: this.state.sidebarView ? "10px" : (!!pref.show_filter_on_smart_search || !!pref.show_search_input_on_smart_search) ? "80px" : "50px"
    }}>{children}</div>
    let content
    if (this.state.showTagSelection) {
      content = null
    } else if (searchResultsTags.length === 0) {
      content =
        <div className="smart-search-no-result-container">
          <p>{localization.get('smart_search.no_result')}</p>
        </div>
    } else {
      content =
      <InfiniteScroll
        style={{display: "flex", flexWrap: "wrap", position: "relative", float: "left", width: "100%", padding: this.state.sidebarView ? "0 5px" :"0 20px 0 15px"}}
        dataLength={this.state.searchResultsTags.length}
        next={() => this.requestTracks({page: this.state.currentPage++, clearResults: false})}
        hasMore={this.state.currentPage < this.state.lastPage}
        scrollableTarget={this.state.sidebarView ? 'sidebarScrollingDiv' : null}
        loader={<div
          style={{height: "70px", paddingTop: 20, width: "100%", display: "flex", justifyContent: "center", alignContent: "center"}}
        >
          <img style={{width: 50, height: 50}} src={LoadingSpinner} alt="Loading logo"/>
        </div>}
      >
        {this.state.total && (this.state.channel.use_tools || this.state.channel.use_licenses) && <p style={{textAlign: "right", width: "100%", paddingRight: 15}}>Total: {this.state.total}</p>}
        {(viewType === 'grid4') ?
          <Grid container fullWidth>
            {this.state.searchResultsTags.map((creation, key) =>
              <Grid key={key} xs={12} sm={6} md={4} lg={3} xl={3} item style={{padding: 10, width: "100%"}}>
                <CreationItem
                  contentCreation={creation}
                  onFavorite={() => this.onFavorite(creation)}
                  onPlay={() => this.setCurrentPlayingContent(key)}
                  onShare={creation => request.contentCreation.shareByChannel(creation.id)
                    .then(response => this.setState({
                      showShareDialog: true,
                      shareUrl: response.url,
                      sharingCreation: creation.id,
                      sharingCreationTitle: creation.title,
                    }))
                    .then(() => analyticsInstance.share({
                      name: getMainTranslation(creation, "title"),
                      type: "Material",
                    }))
                  }
                  noAuthorLink={userHasRestrictedAccess()}
                />
              </Grid>
            )}
          </Grid>
        : viewType === 'list' ?
          <div style={{float: "left", position: "relative", width: "100%", padding: "0px 5px 15px"}}>
            <ClientSidePaginationTable
              data={this.state.searchResultsTags.map((creation, index) => ({...creation, index}))}
              columns={columns}
              filterable={false}
              sortable={false}
              showPagination={false}
              zebraStyle={false}
              perPage={999}
              getThProps={(row) => {
                return ({
                  style: {
                    overflow: "visible"
                  }
                })
              }}
            />
          </div>
          : this.state.searchResultsTags.length > 0 ?
          <div className="smart-search-result-container" style={{margin: this.state.sidebarView ? 0 : (window.innerWidth > 600 ? "0px -50px 0 -45px": "0px -15px 0 -10px")}}>
            <HomeElementGridContainer
              {...this.props}
              hidePadding={this.state.sidebarView}
              rounded={["grid6_rounded", "grid4_rounded"].includes(viewType)}
              alternative={["movie_alternative"].includes(viewType)}
              maxPerRow={["featured2", "featured2_alternative"].includes(viewType) ? 2 : (["movie", "movie_alternative"].includes(viewType))? 1 : (["grid4", "grid4_rounded"].includes(viewType) ? 4 : 6)}
              homeElement={this.makeHomeElementProps()}
              trackType={() => "contentCreation"}
              dataType={() => "video_content_creation"}
              showSectionTitle={false}
              fixedTitle={!["grid6_rounded", "grid4_rounded"].includes(viewType)}
              sizes={{
                xs: ["movie", "movie_alternative"].includes(viewType) ? 2 : 6,
                sm: ["movie", "movie_alternative"].includes(viewType) ? 2 : 4,
                md: ["movie", "movie_alternative"].includes(viewType) ? 2 : 3,
                lg: 2,
                xl: 2,
              }}
              showMore={true}
              rowsToShow={3}
              itemComponent={(["featured2", "featured2_alternative", "movie", "movie_alternative"].includes(viewType)) ? HomeElementFeaturedItem : GridItem}
              handleNewSearch={this.handleNewSearch}
              editorial={viewType === "featured2"}
              editorialInverted={viewType === "featured2_alternative"}
              shape={(["grid6_rounded", "grid4_rounded"].includes(viewType) ? "round" : ["featured2", "featured2_alternative"].includes(viewType) ? "x2" : 'x1')}
              movie={["movie", "movie_alternative"].includes(viewType)}
              requestTracks={this.requestTracks}
              search={true}
            />
          </div>
          : null
        }
      </InfiniteScroll>
    }

    return <Container>{content}</Container>
  }
  
  requestTracks = ({
       type = this.state.type || null,
       searchTags = this.getSearchTags(),
       unselectedSearchTags = this.getUnselectedSearchTags(),
       page = 0,
       clearResults = true,
       refreshHasNext = false,
       search = this.state.searchInput,
       channelId = this.state.channel ? this.state.channel.id : null,
       createdTo =  this.state.createdTo,
       createdFrom =  this.state.createdFrom,
     } = {}) => {
    const options = {perPage: 20, page: page};
    const sort = sortTypes.find(st => st.value ===this.state.sortType);
    options.orderBy = {column: get(sort, 'column', 'order'), type: get(sort, 'direction', 'desc')}

    const startDate = createdFrom ? moment(createdFrom).format('YYYY-MM-DD') : null;
    const endDate = createdTo ? moment(createdTo).format('YYYY-MM-DD') : null;

    return request.contentCreation.getAllWithTags({
      type,
      tags: searchTags,
      notTags: unselectedSearchTags,
      options,
      channelId,
      search,
      startDate,
      endDate,
    }).then(searchResults => {
      let results = searchResults.data;
      if (getActiveChannel()) {
        results && results.forEach(creation => creation.favorite = this.state.favorites && this.state.favorites.includes(creation.id));
      }
      if (!clearResults) {
        results = this.state.searchResultsTags.concat(searchResults.data)
      } else {
        setTimeout(() => window.scrollTo({top: 0, behavior: "smooth"}), 500)
      }
      this.setState({
        searchResultsTags: results,
        showTagSelection: false,
        lastPage: searchResults.meta.last_page,
        currentPage: searchResults.meta.current_page,
        total: searchResults.meta.total
      })
      // const searchTagsNames = this.state.tagGroups.flatMap(x => x.tags).filter(x => searchTags.includes(x.id)).map(x => x.name)
      searchResults.meta.current_page === 1 && analyticsInstance.smartSearch({
        category_name: getMainTranslation(this.state.contentCreatorType.contentCreatorType, "name"),
        category_type: "material",
      })
    })
      .then(() => refreshHasNext ? this.setState({
        pageReady: true,
        hasNext: this.state.currentIndex ? (this.state.currentIndex < (this.state.searchResultsTags.length - 1)) : false
      }) : this.setState({pageReady: true}))
      .catch(() => {
        GlobalSnackbar.show({
          message: 'smart_search_request_failed',
          type: GlobalSnackbarTypes.ERROR,
        })
      })
  }

  cleanSearch = () => {
    this.setState( {
      tagGroup: this.state.tagGroups.forEach(tagGroup => tagGroup.tags.forEach(tag => tag.state = "available")),
      searchResultsTags: [],
      showTagSelection: false, // TODO check this
    })
    let ccType = this.state.contentCreatorType && this.state.contentCreatorType.contentCreatorType;
    ccType && browserHistory.push(channelPathGenerator(`busqueda-inteligente/content_creations/${ccType.id}-${slugify(ccType.name)}?type=${ccType.slug}`))
    this.requestAvailableTags()
    this.requestTracks();
    document.getElementsByTagName('html')[0].scrollTop = 0
  }

  onDateRangeChange = (dr = null) => {
    if (!dr) {
      return this.setState({
        createdFrom: "",
        createdTo: "",
      })
    }

    let today = moment();
    let tomorrow = moment().add(1, 'day');
    let yesterday = moment().subtract(1, 'day');
    let dateStart = '';
    let dateEnd = '';
    switch (dr) {
      case 'today':
        dateStart = today;
        dateEnd = today;
        break;
      case 'tomorrow':
        dateStart = tomorrow;
        dateEnd = tomorrow;
        break;
      case 'thisWeekend':
        dateStart = moment().endOf('week').subtract(2, 'day');
        dateEnd = moment().endOf('week');
        break;
      case 'thisWeek':
        dateStart = today;
        dateEnd = moment().endOf('week');
        break;
      case 'nextWeek':
        dateStart = moment().add(1, 'week').startOf('week');
        dateEnd = moment().add(1, 'week').endOf('week');
        break;
      case 'next30days':
        dateStart = today;
        dateEnd = moment().add(30, 'day');
        break;
      case 'yesterday':
        dateStart = yesterday;
        dateEnd = yesterday;
        break;
      case 'lastWeek':
        dateStart= moment().subtract(7, 'day');
        dateEnd= today;
        break;
      case 'last30days':
        dateEnd = today;
        dateStart = moment().subtract(30, 'day');
        break;
      case 'custom':
      default:
        this.setState({createdFrom: '', createdTo: '', selectedDateRange: dr});
        return;
    }

    this.setState({
      selectedDateRange: dr,
      createdFrom: dateStart.format('YYYY-MM-DD'),
      createdTo: dateEnd.format('YYYY-MM-DD'),
    });
  }


  renderDateSearch = () => {
    return (
      <div style={{paddingTop: '30px'}}>
        {this.state.selectedDateRange === 'custom' &&
          <DateRangePicker 
           maxDate={new Date()}
           wrapperClassName="date-range-picker"
           open={this.state.eventRangeOpen} 
           disableFuture
           initialDateRange={{
               startDate: moment().subtract(30, 'day').format('YYYY-MM-DD'),
               endDate: moment().format('YYYY-MM-DD'),
           }} 
           toggle={() => this.setState({eventRangeOpen: !this.state.eventRangeOpen})} 
           onChange={(range) => {
             this.setState({
              createdFrom: moment(range.startDate).format('YYYY-MM-DD'),
              createdTo: moment(range.endDate).format('YYYY-MM-DD'),
             }, () => this.setState({eventRangeOpen: false}))}}
         >
         </DateRangePicker>
        }
        <div style={window.innerWidth > 600 ? {display: 'flex', flexDirection: 'row', alignItems: "center"}:{display: 'flex', flexDirection: 'column'}}>
          <div style={{float: "left", width: 230, marginLeft: 20, marginBottom: 30}}>
            <h4 style={{margin: "10px 0px 10px 0px", fontWeight: "600", textAlign: 'left'}}>{localization.get("smart_search.date_created_at")}</h4>
            <FormControl style={{width: 200, float: 'left'}}>
              {/* <InputLabel style={{color: 'var(--secondary-font-color, rgba(0, 0, 0, 0.54))'}}>{localization.get("smart_search.date_range_label")}</InputLabel> */}
              <Select
                // disableUnderline
                className="smart-search-input-search"
                value={this.state.selectedDateRange}
                onChange={(e) => this.onDateRangeChange(e.target.value)}
                style={{color: "var(--secondary-font-color, black)", position: "relative", float: "left",}}
                MenuProps={{
                  MenuListProps: {
                    style: {
                      backgroundColor: 'var(--main-color, white)',
                    },
                  }
                }}
              >
                <MenuItem value="none" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.none")}</MenuItem>
                <MenuItem value="today" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.today")}</MenuItem>
                <MenuItem value="yesterday" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.yesterday")}</MenuItem>
                <MenuItem value="lastWeek" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.last_seven_days")}</MenuItem>
                <MenuItem value="last30days" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.last_thirty_days")}</MenuItem>
                {window.innerWidth >= 600 && <MenuItem value="custom" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("other")}</MenuItem>}
              </Select>
            </FormControl>
          </div>
        </div>
        {this.state.selectedDateRange == 'custom' &&
          <div style={{display: "flexx", flexDirection: 'row', alignItems: "center", textAlign: 'left', paddingLeft: 20}}>
            <TextField
              inputProps={{
                style:{
                  color: "var(--secondary-font-color, #2d2d2d)",
                  borderBottom: "2px solid var(--secondary-font-color, #2d2d2d)",
                  width: 100,
                  maxWidth: 100,
                },
              }}
              InputProps={{disableUnderline: true}}
              readOnly={true}
              className="smart-search-input-search"
              placeholder={localization.get("smart_search.date_start")}
              value={this.state.createdFrom}
              style={{color: "var(--secondary-font-color, black)", position: "relative", float: "left", marginRight: 20}}
              onClick={() => this.setState({eventRangeOpen: true})}
            />
            <TextField
              inputProps={{
                style:{
                  color: "var(--secondary-font-color, #2d2d2d)",
                  borderBottom: "2px solid var(--secondary-font-color, #2d2d2d)",
                  width: 100,
                  maxWidth: 100,
                },
              }}
              InputProps={{disableUnderline: true}}
              readOnly={true}
              className="smart-search-input-search"
              placeholder={localization.get("smart_search.date_end")}
              value={this.state.createdTo}
              style={{color: "var(--secondary-font-color, black)", position: "relative", float: "left", marginRight: 40}}
              onClick={() => this.setState({eventRangeOpen: true})}
            />
          </div>
        }
      </div>
    )
  }

  renderFilters = () => {
    let selectedTags = this.getSelectedTags()
    let unselectedTags= this.getUnselectedTags()
    const pref = (getStoredChannel() && this.state.type) ? getStoredChannel().channelContentCreatorTypes.find(ct => ct.contentCreatorType.slug === this.state.type) : {show_search_input_on_smart_search: 1, show_filter_on_smart_search: 1};

    if (!this.state.showTagSelection && (!!pref.show_filter_on_smart_search || !!pref.show_search_input_on_smart_search)) {
      return (
        <div className="smart-search-filters-container" style={{padding: "30px 30px 0 25px"}}>
          <div className="smart-search-filters-tag-container" style={{margin: 0}}>
            {!!pref.show_filter_on_smart_search && (selectedTags.length > 0 || unselectedTags.length > 0) ? selectedTags.concat(unselectedTags).map(tag => {
              return (
                <Chip
                  style={{
                    backgroundColor: tag.state === 'unselected' ? '#e68484' : 'var(--secondary-color, lightgray)',
                    color: tag.state === 'unselected' ? 'white' : 'var(--secondary-font-color, black)'
                  }}
                  label={tag.name}
                  className="smart-search-filters-tag"
                  onDelete={() => this.removeSelectedTag(tag.id)}
                />
              )
            }) : <span>&nbsp;</span>}
          </div>
          {this.renderFilterInput()}
        </div>
      )
    } else {
      return null
    }
  }

  renderFilterInput = () =>  {
    const pref = (getStoredChannel() && this.state.type) ? getStoredChannel().channelContentCreatorTypes.find(ct => ct.contentCreatorType.slug === this.state.type) : {show_search_input_on_smart_search: 1, show_filter_on_smart_search: 1};

    return (
      <div className="smart-search-filters-buttons-container" style={{right: 0, top: 0, display: "flex", justifyContent: "end"}}>
        {!!pref.show_search_input_on_smart_search &&
          <div style={{
            marginBottom: 10,
            marginRight: 10,
            position: "relative",
            float: "left",
            display: "flex",
            maxWidth: 185
          }}>
            <TextField
              inputProps={{
                ref: input => {
                  this.searchInput = input;
                },
                spellCheck: false,
                style: {
                  color: "var(--main-font-color, black)",
                  borderBottom: `2px solid var(--main-font-color, rgba(0,0,0, .5))`,
                  width: 250,
                  maxWidth: 250,
                },
              }}
              InputProps={{disableUnderline: true}}
              autoComplete={false}
              className="smart-search-input-search"
              placeholder={localization.get("searchbar.content_creators")}
              value={this.state.searchInput}
              onChange={e => this.setState({searchInput: e.target.value})}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  this.writeQueryStringParams(true)
                }
              }}
              style={{color: "var(--main-font-color, black)", position: "relative", float: "left",}}
            />
            {window.innerWidth < 600 &&
              <Search style={{marginTop: 7, marginLeft: 7, color: "var(--main-font-color, black)"}}
                      onClick={this.writeQueryStringParams}/>
            }
          </div>
        }
        {get(this.state,'tagGroups', []).length > 0 && 
        (!!get(pref, 'show_filter_on_smart_search', 1) && !this.state.sidebarView || (this.state.sidebarView && window.innerWidth < 600)) &&
          <TableButton
            title={localization.get('searchbar.content_creators_for_tags')}
            onClick={() => {
              if (this.state.sidebarView) { 
                this.setState({openSidebar: true})
              }else {
                this.setState({showTagDialog: true})
              }
            }}
          >
              <FilterList style={{color: "var(--main-font-color, black)"}}/>
          </TableButton>
        }
        <Tooltip title={localization.get('searchbar.sort')} placement={"top"}>
        <IconButton
          style={{position: "relative", float: "left"}}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            this.setState({openSort: true})
          }}
        >
          <div
            aria-describedby={"id"}
            ref={node => this.anchorEl = node}
            aria-owns={"menu-list-grow"}
            aria-haspopup="true"

          >
            <SortByAlpha style={{color: "var(--main-font-color, black)", marginTop: -5 }} />
          </div>
        </IconButton>
        </Tooltip>
        <Popper
          open={this.state.openSort}
          id={"id"}
          anchorEl={this.anchorEl}
          transition
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}

          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{zIndex:11000}}
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{transformOrigin: "center right", zIndex:10}}
            >
              <Paper style={{zIndex:10}}>
                <ClickAwayListener onClickAway={() => this.setState({openSort: false})}>
                  <MenuList style={{paddingBottom: 0, paddingTop: 0, minWidth: 250}}>
                    {sortTypes.map(st =>
                      <MenuItem
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()

                          this.setState({openSort: false, sortType: st.value}, this.requestTracks)
                        }}
                        selected={st.value === this.state.sortType}
                        style={{cursor: "pointer"}}
                      >
                        {st.label}
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <TableButton title={localization.get("searchbar.change_view")} onClick={() => this.setState({viewType: this.state.viewType === "grid4" ? "list" : "grid4"})}>
          {this.state.viewType === "grid4" ?
            <List  style={{color: "var(--main-font-color, black)"}} /> :
            <GridOn  style={{color: "var(--main-font-color, black)"}} />
          }
        </TableButton>
      </div>)
  }

  clearCurrentPlayingContent = () => {
    this.setState({showContentCreationDialog: false, hasPrev: false, hasNext: false, currentIndex: null, currentPlayingContent: {}})
  }

  setCurrentPlayingContent = (index) => {
    let hasPrev = index > 0 ;
    let hasNext = index < (this.state.searchResultsTags.length - 1);
    if ((this.state.currentPage < this.state.lastPage) && (this.state.searchResultsTags.length - index <= 8)) {
      this.requestTracks({page: this.state.currentPage++, clearResults: false});
    }

    if (this.state.searchResultsTags[index].blocked) {
      request.contentCreation.get(this.state.searchResultsTags[index].id)
        .then(creation => this.setState({
          showContentCreationDialog: true, currentPlayingContent: creation, hasPrev: hasPrev, hasNext: hasNext, currentIndex: index
        }))
    }else {
      this.setState({showContentCreationDialog: true, currentPlayingContent: this.state.searchResultsTags[index], hasPrev: hasPrev, hasNext: hasNext, currentIndex: index})
    }
  }

  render = () => {
    return (
      <div id="innerinner" style={{marginBottom: '10px'}}>
        {this.makeSmartSearchTagSelector()}
        <div style={{display: this.state.showTagSelection ? 'block' : 'none'}}
             className="smart-search-buttons-container">
          {makeSearchButton(() => this.handleSmartSearch())}
          {/* {hasAnySelectedTag(this.state.tagGroups) > 0 && makeCleanTagsButton(this.cleanSearch)} */}
        </div>
        {this.state.pageReady &&
        <>
        {!this.state.sidebarView ? 
          <>
            {this.renderFilters()}
            {this.renderSearchResultsTableTags()}
          </>
          :
          <FilterContainer
            onSelectTag={this.handleSmartSearchSelectedTagsChanged}
            tagGroups={this.state.tagGroups}
            selectedTags={this.getSelectedTags().map(tag => tag.id)}
            unselectedTags={this.getUnselectedTags().map(tag => tag.id)}
            children={this.renderSearchResultsTableTags()}
            filterInput={() => this.renderFilterInput()}
            onCleanSearch={this.cleanSearch}
            open={this.state.openSidebar}
            handleOpenSidebar={(value) => this.setState({openSidebar: value})}
          />
        }
        </>
        }
        {this.state.showTagDialog &&
        <Dialog
          open
          onClose={() => this.setState({showTagDialog: false})}
          PaperProps={{
            style: {
              backgroundColor: 'var(--main-color, white)', 
            },
          }}
        >
          <DialogContent style={{ float: "left", position: "relative", width: "100%", textAlign: "center", padding: window.innerWidth > 750 ? "10px 50px" : "10px 30px", height: "auto" , minHeight: "520px", backgroundColor: "var(--main-color, inherit)", color: "var(--secondary-font-color, inherit)", overflowX: 'hidden'}}>
          {getUseTools() && this.renderDateSearch()}
          <IconButton style={{position: "absolute", top: 10, right: 10, zIndex: 1}} onClick={this.closeDialog}>
              <Close/>
            </IconButton>
            <TagSelection
              modal
              horizontal={true}
              onSelectedTagsChanged={this.handleSmartSearchSelectedTagsChanged}
              singleTagSelection={this.state.contentCreatorType ? !this.state.contentCreatorType.has_multiple_tags_allowed : true}
              data={this.state.tagGroups.sort((a,b) => a.order - b.order)}
              notWriteQueryString={true}
              allowUnselected={this.state.contentCreatorType ? !!this.state.contentCreatorType.support_negative_search : true}
              checkAvailableTags={(getActiveChannel() && this.state.contentCreatorType) ? !(this.state.contentCreatorType.has_multiple_tags_allowed && !this.state.contentCreatorType.restricted_material_search) : true}
            />
          </DialogContent>
          <DialogActions style={{backgroundColor: "var(--main-color, inherit)"}}>
            <div style={{float: "left", position: "relative", width: "100%", textAlign: "center"}}>
              {makeSearchButton(this.handleSmartSearch)}
            </div>
          </DialogActions>
        </Dialog>
        }
        <ShareOnSocials
          show={this.state.showShareDialog}
          handleClose={() => this.setState({showShareDialog: false, sharingCreationTitle: null})}
          url={this.state.shareUrl}
          darkMode={true}
          shareTitle={this.state.sharingCreationTitle}
        />
        <ContentCreationPlayer
          show={this.state.showContentCreationDialog}
          onNextArrow={this.state.hasNext ? () => this.setCurrentPlayingContent(this.state.currentIndex+1) : null}
          onPrevArrow={this.state.hasPrev ? () => this.setCurrentPlayingContent(this.state.currentIndex-1) : null}
          currentContentCreation={this.state.currentPlayingContent}
          handleClose={() => this.clearCurrentPlayingContent()}
          typeSlug={this.state.type}
          noAuthorLink={userHasRestrictedAccess()}
        />
        {this.state.openRetagDialog && 
          <UpdateManyMaterialsDialog 
            open={this.state.openRetagDialog}
            selectedContentCreations={this.state.selectedContentCreations}
            onClose={() => this.setState({openRetagDialog: false})}
            contentCreatorType={get(getStoredChannel().channelContentCreatorTypes.find(ct => ct.contentCreatorType.slug === this.state.type), 'contentCreatorType')}
          />
        }
      </div>
    )
  }
}

export default SmartSearchContentCreation;
