import availableLanguages from '../../../config/localization/availableLanguages'
import {isNullOrUndefined} from "util";
import { getActiveChannel } from '../authentication/utils';

const formDataBase = data => {

  const form = new FormData()

  //Only when creating and updating from home client
  if (!isNullOrUndefined(data.title)) {
    availableLanguages.forEach(language => {
      form.append(`translations[${language}][title]`, data.title)
    })
  }
  if (!isNullOrUndefined(data.subtitle)) {
    availableLanguages.forEach(language => {
      form.append(`translations[${language}][subtitle]`, data.subtitle)
    })
  }
  //---------

  if (data.translations) {
    data.translations.forEach(translation => {
      if (!isNullOrUndefined(translation.title)) form.append(`translations[${translation.language}][title]`, translation.title)
      if (!isNullOrUndefined(translation.subtitle)) form.append(`translations[${translation.language}][subtitle]`, translation.subtitle)
    })
  }
  if (data.description) form.append('description', data.description)

  if (!isNullOrUndefined(data.userLibrary)) form.append('user_library', data.userLibrary ? 1 : 0)
  if (!isNullOrUndefined(data.visibility)) form.append('visibility', (data.visibility || (data.brands && data.brands.length > 0)) ? 'public' : 'private')
  if (!isNullOrUndefined(data.allowDownload)) form.append('allow_download', data.allowDownload ? 1 : 0)

  if (data.musicIds) data.musicIds.forEach(x => form.append('musics_ids[]', x.id))
  if (data.clearMusics) form.append('clear_musics', "1")
  if (data.imageFile) form.append('image', data.imageFile, data.imageFile.name)
  if (data.channelId) form.append('channel_id', data.channelId)
  if (data.selectedContent) data.selectedContent.forEach(x => form.append('content_ids[]', x.id))
  if (data.orderType) form.append('order_type', data.orderType)
  if (data.brands && data.brands.length > 0) data.brands.forEach(x => form.append('brands_ids[]', x.value))
  if (data.selectedTags && data.selectedTags.length > 0) {
    data.selectedTags.forEach(x => form.append('tag_ids[]', x))
    data.type == "tags" &&form.append('channel_id', getActiveChannel())
  }
  if (data.type) form.append('type', data.type)
  form.append('use_filters', data.useFilters ? 1 : 0)
  

  return form
}


export const makeFormData = data => {
  const form = formDataBase(data)
  form.append('client_id', data.clientId)
  return form
}

export const makeOrder = data => {
  const form = new FormData()
  if (data.musicIds) data.musicIds.forEach(x => form.append('musics_ids[]', x.id))
  if (data.contentIds) data.contentIds.forEach(x => form.append('contents_ids[]', x.id))
  return form
}

export const makeGlobalFormData = data => {
  data.visibility = true
  const form = formDataBase(data)
  if (data.type) form.append('type', data.type)
  if (data.order) form.append('order', data.order)
  if (data.catalogCategoryId) form.append('catalog_category_id', data.catalogCategoryId)
  if (data.selectedClients) data.selectedClients.forEach(x => form.append('clients_ids[]', x))
  if (data.selectedTags) data.selectedTags.forEach(x => form.append('tags_ids[]', x))
  if (data.unselectedTags) data.unselectedTags.forEach(x => form.append('not_tags_ids[]', x))
  if (data.brands) data.brands.forEach(x => form.append('brands_ids[]', x))
  if (data.query) form.append('query', data.query)
  if (!isNullOrUndefined(data.recent)) form.append('recent', data.recent ? 1 : 0)
  return form
}

export const includeQuery = 'include=featuredImage.image;translations;user;musics;client;musics.albums;musics.albums.image;musics.tags;musics.audio;musics.audio.waveform;musics.favorite;category'
