import React, {Component} from "react";
import {request} from "../../../api-client";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {getPropertySafe} from "../../../utils";
import localization from "../../../config/localization";
import "./AddToPlaylistDialog.css";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import PlaylistImage from "../../../assets/img/Letflow/playlist.jpg";
import FormViewContainer from "components/Letflow/ViewContainer/FormViewContainer";
import FormView from "../Playlists/Form";
import {getActiveClient} from "../../../api-client/core/authentication/utils";
import TagSelection from "../../../components/Letflow/TagSelection/TagSelectionSmartSearch.jsx"
import {onImageError} from "../../Home/HomeElements/utils";

class AddToPlaylistDialog extends Component {
  state = {
    playlists: [],
    selectedPlaylists: [],
    title: '',
    subtitle: '',
    userLibrary: '',
    visibility: '',
    contentCreatorTagsIds: this.props.contentCreatorTagsIds || []
  };

  componentDidMount = () => {
    this.requestPlaylists();
    (this.props.type === 'contentCreators' && this.props.contentCreatorType) && this.requestTagGroups(this.props.selectedTrack.id)
  };

  requestTagGroups = id => {
    request.tagGroup.getAll({filterBy: [
        { column: 'content_creator_id', filter: id}]})
      .then((tagGroups) => {
        tagGroups.forEach(tagGroup => tagGroup.tags.forEach(tag => {
          tag.state = "available"
        }))
        this.setState({tagGroups, selectedTags: []})
      })
  }

  requestPlaylists = () =>
    request.playlist
      .getAllForClient(this.props.clientId, 'include=featuredImage.image;musics;musics.audio')
      .then(playlists => {
        this.setState({
          playlists,
          selectedPlaylists: this.getSelectedPlaylistForSelectedTrack(playlists),
          step: 'adding'
        });
      })

  getSelectedPlaylistForSelectedTrack = playlists => {
    let trackIncludedInPlaylists = [];
    playlists.forEach(x => {
      const addIfIncludedInProperty = propertyName => {
        if (
          getPropertySafe(x, propertyName, [])
            .map(t => t.id)
            .includes(this.props.selectedTrack.id)
        ) {
          trackIncludedInPlaylists.push(x.id);
          return true;
        }
        return false;
      };
      addIfIncludedInProperty("musics") || addIfIncludedInProperty("voices") || addIfIncludedInProperty("songs");
    });
    return trackIncludedInPlaylists;
  };

  handleAddToPlaylist = (type, playlistId, musicId, tagsIds) =>
    request.playlist
      .addToPlaylist({ type, playlistId, musicId, tagsIds })
      .then(() => {
        const playlistName = this.state.playlists.find(x => x.id === playlistId).title;
        // analyticsInstance.addTrackToPlaylist(playlistName, musicId);
        this.setState({ addingPlaylistSuccess: true });
        GlobalSnackbar.show({ message: localization.get("playlist.add"), type: GlobalSnackbarTypes.SUCCESS });
      })


  handleRemoveFromPlaylist = (type, playlistId, musicId) =>
    request.playlist
      .removeFromPlaylist({ type, playlistId, musicId })
      .then(() => {
        this.setState({ addingPlaylistSuccess: true });
        GlobalSnackbar.show({ message: localization.get("playlist.remove"), type: GlobalSnackbarTypes.SUCCESS });
      })

  handlePlaylistClicked = (playlist) => {
    if (this.state.selectedPlaylists.find(selectedPlaylist => selectedPlaylist === playlist.id)) {
      this.handleRemoveFromPlaylist(this.props.type, playlist.id, this.props.selectedTrack.id)
        .then(() => this.props.handleClose('success'))
    } else {
      if (this.props.type === 'contentCreators') {
          if(this.state.tagGroups.length === 0) {
            this.handleAddToPlaylist(this.props.type, playlist.id, this.props.selectedTrack.id, this.state.selectedTags)
              .then(() => this.props.handleClose('success'))
          }else {
            this.setState({playlistClickedId: playlist.id, step: 'tagging'})
          }
      } else {
        this.handleAddToPlaylist(this.props.type, playlist.id, this.props.selectedTrack.id)
          .then(() => this.props.handleClose('success'))
      }
    }
  }

  handleSelectedTagsChanged = (selectedTags) => {
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.map(tag => ({
          ...tag,
          state: selectedTags.includes(tag.id) ? 'selected' : tag.state
        }))
      })),
      selectedTags
    }))
  }


  //----------------Bodies-------------------------

  renderPlaylistSelection = () => {
    return (
      this.state.playlists.length > 0 ? (
        <div style={{ margin: "30px" }}>
          <Grid spacing={16} justify="center" container>
            {this.state.playlists.map(playlist => (
              <Grid item>
                <div
                  className={`add-to-playlist-dialog-playlist ${
                    this.state.selectedPlaylists.find(selectedPlaylist => selectedPlaylist === playlist.id) ? "active" : null
                    }`}
                  style={{ cursor: "pointer", overflow: "hidden", position: "relative" }}
                  onClick={() => this.handlePlaylistClicked(playlist)}
                >
                  <img
                    style={{ maxWidth: "150px" }}
                    src={
                      (playlist.created_by_admin && playlist.image) ?
                        request.file.image.makeCroppedUrl(playlist.image.id, 200, 200)
                          : playlist.featuredImage && playlist.featuredImage.image ?
                            request.file.image.makeCroppedUrl(playlist.featuredImage.image.id, 200, 200)
                            : PlaylistImage
                    }
                    onError={onImageError}
                    alt="playlist"
                  />
                    <div
                      className="featured-image-title"
                      style={{
                        color: playlist.featuredImage ? playlist.featuredImage.color : "black",
                        fontSize: 20,
                        letterSpacing: -2,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        padding: "0 10px",
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        textAlign: "center"
                      }}
                    >
                      {playlist.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}
                    </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      ) : (
        <div style={{ margin: "30px" }}>{localization.get('alert.no_playlist_available')}</div>
      )
    )
  }

  renderPlaylistCreation = () => {
    const {title, subtitle, userLibrary, visibility} = this.state
    return (
      <FormViewContainer
        title={localization.get('home_playlist.create')}
        onBack={() => this.setState({step: 'adding'})}
        submitRequest={() => request.playlist.create({
          title,
          subtitle,
          clientId: getActiveClient(),
          userLibrary,
          visibility
        })
          // .then(() => analyticsInstance.createPlaylist(title))
          .then(() => this.requestPlaylists())
          .then(() => this.setState({step: 'adding'}))
        }
        style={{maxWidth: "600px", width: "100%"}}
        color="black"
      >
        <FormView
          title={title}
          subtitle={subtitle}
          onChange={e => this.setState({ [e.target.id]: e.target.value })}
          onUserLibraryChange={e => this.setState({ userLibrary: e })}
          onVisibilityLibraryChange={e => this.setState({ visibility: e })}
          userLibrary={userLibrary}
          visibility={visibility}
        />
      </FormViewContainer>
    )
  }

  renderTagSelection = () =>
    <React.Fragment>
      <p>{
        localization.get('dialog.add_tags_helper',
          localization.get(`dialog.child.${this.props.type}`),
          localization.get(`dialog.${this.props.type}`),
          localization.get(`dialog.child.${this.props.type}`),
          localization.get(`dialog.${this.props.type}`),
        )
      }</p>
      <TagSelection horizontal={true} onSelectedTagsChanged={this.handleSelectedTagsChanged} allowUnselected={false} data={this.state.tagGroups} />
    </React.Fragment>


  //----------------Buttons-------------------------

  makeCreateNewPlaylistButton = () =>
    <Button
      style={{backgroundColor: "black", color: "white", display: "inline-block"}}
      onClick={() => this.setState({step: "creating"})}
    >
      {localization.get("playlists.create")}
    </Button>

  makeFinishButton = () =>
    <Button
      style={{backgroundColor: "black", color: "white", display: "inline-block"}}
      onClick={() => this.handleAddToPlaylist(this.props.type, this.state.playlistClickedId, this.props.selectedTrack.id, this.state.selectedTags)
          .then(() => this.props.handleClose('success'))
      }
    >
      {localization.get("playlists.save")}
    </Button>


  //----------------Render--------------------------

  renderDialog = (title, body, buttons) => (
    <Dialog open={true} onClose={this.props.handleClose} style={{ textAlign: "center", minWidth: "350px" }}>
      <DialogTitle style={{ width: "100%" }} id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions style={{marginBottom: 28, justifyContent: "center", textAlign: "center"}}>{buttons}</DialogActions>
    </Dialog>
  );

  render = () => {
    const {step} = this.state
    let title, body, buttons = []

    switch (step) {
      case 'adding':
        title = localization.get('dialog.select_playlist')
        body = this.renderPlaylistSelection()
        buttons.push(this.makeCreateNewPlaylistButton())
        break;
      case 'creating':
        body = this.renderPlaylistCreation()
        break;
      case 'tagging':
        title = localization.get('dialog.add_tags')
        body = this.renderTagSelection()
        buttons.push(this.makeFinishButton())
        break;
    }

    return this.renderDialog(title, body, buttons)
  }

}

export default AddToPlaylistDialog;
