import "layouts/Home/Home.css";
import React from "react";
import {addHttpsToUrl, getActiveChannel, getStoredChannel} from "../../../api-client/core/authentication/utils";
import {convertFromRaw, EditorState} from "draft-js";
import RichEditor from "../../../components/Letflow/RichTextEditor/EditorialEditor";
import {request} from "../../../api-client";
import ShakaPlayer from "../../../components/Letflow/ShakaPlayer";
import styled from "styled-components";
import GoogleAdsenseAd from "../../../components/Letflow/GoogleAd";
import LiteSmartSearch from "../../../components/Letflow/LiteSmartSearch";
import localization from "../../../config/localization";
import HomeElementTitle from "./HomeElementTitle";
import {get} from "lodash";
import {onImageError} from "./utils";

class HomeElementUniqueContainer extends React.Component {
  constructor(props) {
    super(props);
    let language = localization.getLanguage();
    const currentTranslation = props.translations.find(tr => tr.language === language)

    const mobileFontResize = props.homeElement.mobile_font_resize ? (Number(props.homeElement.mobile_font_resize) / 100):  1;
    this.state = {
      editorContent: null,
      language: language,
      text: currentTranslation ? currentTranslation.text : EditorState.createEmpty(),
      mobileFontResize: mobileFontResize,
      hasGoogleAdsense: (getActiveChannel() && getStoredChannel().google_publisher_id && getStoredChannel().adUnits)
    }
  }

  componentDidMount = () => {
    this.fixResponsiveEmbedSize();
  } 

  getAdUnit = () => {
    if (!this.state.hasGoogleAdsense) return null;
    
    let channel = getStoredChannel();
    let defaultAdUnit = channel && channel.adUnits && channel.adUnits.find(adUnit => adUnit.purpose === 'home');
    defaultAdUnit = defaultAdUnit ? defaultAdUnit.ad_unit : null;
    let mobileAdUnit = channel && channel.adUnits && channel.adUnits.find(adUnit => adUnit.purpose === 'home-mobile');
    mobileAdUnit = mobileAdUnit ? mobileAdUnit.ad_unit : null;
    return (window.innerWidth < 650 && mobileAdUnit) ? mobileAdUnit : defaultAdUnit;
  }

  fixResponsiveEmbedSize = () => {
    if (!this.state.text || this.state.text.length == 0) return;
    let editorContent = JSON.parse(this.state.text);
    if (!editorContent) return;
    let entityMap = editorContent.entityMap;
    if (entityMap) {
      Object.entries(entityMap).forEach(([k,v]) => {
        let isEmbed = v.type == "EMBEDDED_LINK";
        let isImg = v.type == "IMAGE";
        if ( isEmbed || isImg ) {
          if (v.data && v.data.width) {
            let maxWidth = (window.innerWidth < 600) ? (window.innerWidth - 40) : (window.innerWidth - 140);
            let width = v.data.width.endsWith("px") ? v.data.width.substr(0, v.data.width.length -2) : null;
            if (!width || (width > maxWidth)) {
              if (isEmbed) {
                v.data.width = `${maxWidth}px`;
                v.data.height = `${Math.round(maxWidth/16*9)}px`;
              } else if (isImg) {
                v.data.width = v.data.width == "auto" ? "auto" : `${maxWidth}px`;
                v.data.height = "auto";
              }
            }
          }
        }
      })
      if (window.innerWidth <= 600) {
        let blocks = editorContent.blocks;
        if (blocks) {
          blocks.map(b => {
            b.inlineStyleRanges = b.inlineStyleRanges.map(isr => {
              isr.style = (isr.style && isr.style.startsWith("fontsize-")) ? `fontsize-${Math.round(Number(isr.style.split("fontsize-")[1]) * this.state.mobileFontResize)}` : isr.style;
              return isr;
            });
            return b;
          });
        }
      }
    }
    this.setState({editorContent: editorContent});
  }

  mobileFontSize = (fontSize) => {
    if (fontSize) {
      let size = fontSize.endsWith('px') ? fontSize.substr(0,fontSize.length-2) : null;
      if (size && size > 24) {
        return "24px";
      } else {
        return fontSize;
      }
    }
  }

  getImage = () => {
    const imageComponent = this.getLocatedResource("images", "image");
    const image = (window.innerWidth < 650 && imageComponent.mobileImage) ? imageComponent.mobileImage : imageComponent.image

    if (image.original_name.endsWith('.gif')) {
      return image.url;
    }
    return image ? ((image.height > 900 || image.width > 1600) ? request.file.image.makeFittedUrl(image.id, 1600, 900) : request.file.image.makeUrl(image.id)): null;
  }

  renderImage = () => <img style={{maxWidth: "100%", height: "auto"}} src={this.getImage()} onError={onImageError}/>

  getLocatedResource = (resource, defaultProp) => {
    let language = localization.getLanguage();

    if (this.props[resource]) {
      if (this.props[resource][language]) {
        return this.props[resource][language];
      }else if (this.props[resource][getStoredChannel().language]) {
        return this.props[resource][getStoredChannel().language]
      }else {
        let obj = null;
        for(let key in this.props[resource]) {
          if(this.props[resource].hasOwnProperty(key)) {
            obj = this.props[resource][key];
            break;
          }
        }
        return obj;
      }
    }else {
      return {[defaultProp]: this.props[defaultProp] ? this.props[defaultProp] : null};
    }
  }

  render() {
    let {editorContent} = this.state;

    const video = this.getLocatedResource("videos", "video");
    const image = this.getLocatedResource("images", "image");

    const autoplay = video ? (get(video, "play_on_hover", "0") === "1") : !!this.props.playOnHover;

    return (
      <section>
        {/* Divisor */}
        <div
          style={{
            left: 0,
            width: "100%",
            position: "absolute",
            height: this.props.backgroundStyle === 'default' ? "0px" : `${this.props.backgroundStyle}%`,
            backgroundColor: this.props.backgroundColor
          }}
        />
        <div style={{ width: "100%", position: "relative", float: "left", paddingBottom: this.props.bottomPadding, display: 'flex', flexDirection: 'column', textAlign: this.props.dataType === 'image' ? 'center' : 'inherit'}}>
          <HomeElementTitle {...this.props} />
          
          {this.props.dataType === "text" ?
            <EditorContainer mobileDefaultFontSize={Math.round(14 * this.state.mobileFontResize)}>
              {editorContent && <RichEditor
                editorState={EditorState.createWithContent(convertFromRaw(editorContent))}
                readOnly={true}
                onChange={() => console.log('read only')}
              />}
            </EditorContainer>
            :
            this.props.dataType === "video" && (this.props.video || this.props.videos) ?
              <ShakaPlayer
                showThumbnail
                playOnHover={autoplay}
                video={video ? video.video : this.props.video}
                thumbnail={(video && video.image || this.props.image) ?  request.file.image.makeFittedUrl((video && video.image) ? video.image.id : this.props.image.id, 1600, 900): null}
              />
            :
            this.props.dataType === "image" && (this.props.image || this.props.images) ?
              ((image.link || this.props.link) ?
                <a href={addHttpsToUrl((image && image.link) ? image.link : this.props.link)} target={((!!image.new_tab && image.new_tab != 0) || !!this.props.newTab) ? "_blank" : "_self"}>
                  {this.renderImage()}
                </a>
              :
                this.renderImage()
              )
            : this.props.dataType === "google_ad" ?
                <GoogleAdsenseAd data={this.getAdUnit()}/>
              : this.props.dataType == "lite_smart_search" ?
                <LiteSmartSearch 
                  contentCreatorType={this.props.homeElement.content_creator_type}
                  contentType={this.props.homeElement.content_type}
                  tagGroups={this.props.homeElement.tag_groups}
                  dateSearch={this.props.homeElement.date_search}
                  limitContent={this.props.homeElement.limit_content}
                  viewType={this.props.homeElement.search_view_type}
                />
              : null
          }
        </div>
      </section>
    )
  }

}

const EditorContainer = styled.div`
  .rdw-link-decorator-icon {
    display: none;
  }
  padding: 20px 0px 0;
  overflow-wrap: break-word;
  @media (max-width: 650px) {
    font-size: ${props => props.mobileDefaultFontSize ? `${props.mobileDefaultFontSize}px` : "14px"};
  }
`;

HomeElementUniqueContainer.defaultProps = {
  showSectionTitle: true
}

export default HomeElementUniqueContainer
