import {
  AccountBox,
  Album as AlbumIcon,
  Book,
  People,
  Folder,
  Group,
  LibraryMusic,
  List,
  Message,
  MonetizationOn,
  Movie as MovieIcon,
  MusicNote,
  PieChart,
  PlaylistPlay,
  Settings,
  ShoppingCart,
  ShowChart,
  Sort,
  VideocamRounded as Videocam,
  Public, RssFeed
} from "@material-ui/icons";

import Clients from "../views/Clients";
import Musics from "../views/Musics";
import Tags from "../views/Tags";
import TagGroups from "../views/TagGroups";
import Albums from "../views/Albums";
import Artists from "../views/Artists";
import Playlists from "../views/Playlists";
import GlobalPlaylists from "../views/GlobalPlaylists";
import Users from "../views/Users";
import Trials from "../views/Trials";
import TagSupplyRanking from "../views/Tags/SupplyRanking";
import TagDemandRanking from "../views/Tags/DemandRanking";
import MusicRanking from "../views/Musics/Ranking";
import ArtistRanking from "../views/Artists/Ranking";
import Graphboard from "../views/Graphboard";
import localization from "../config/localization";
import UserProfile from "../views/UserProfile";
import Search from "../views/Search";
import SadaicPricingConfiguration from "../views/SADAIC/PricingConfiguration/PricingConfiguration";
import PricingConfiguration from "../views/PricingConfiguration";
import OriginalMusic from "../views/OriginalMusic";
import RightManagement from "../views/RightManagement";
import LicenseListing from "../views/Licenses/LicenseListing";
import LicensesReport from "../views/Licenses/LicensesReport";
import LicensesPending from "../views/Licenses/LicensesPending";
import ContactMessage from "../views/ContactMessage";
import ComercialExportLicenses from "../views/Comercial/ExportLicenses.jsx";
import ComercialGdrive from "../views/Comercial/Gdrive.jsx";
import ComercialSendy from "../views/Comercial/Sendy.jsx";
import ComercialSupport from "../views/Comercial/Support.jsx";
import LicensePlans from "../views/LicensePlans";
import AuditLog from "../views/AuditLog";
import Pitches from "../views/Pitches";
import RankingConfigs from "../views/RankingConfigs";
import ComercialExportEntities from "../views/Comercial/ExportEntities.jsx";
import ContentCreators from "../views/ContentCreators";
import ContentCreations from "../views/ContentCreations";
import Catalogs from "../views/Catalogs";
import Links from "../views/Links";
import ContentCreatorTypes from "../views/ContentCreatorTypes"
import CatalogsReport from "../views/Catalogs/Report";
import CatalogRanking from "../views/Catalogs/Ranking";
import MonthlyReport from "../views/Reports/MonthlyActivity";
import Channels from "../views/Channels";
import CatalogAccess from "../views/CatalogAccess";
import BatchUpload from "../views/BatchUpload";
import ChannelLists from "../views/Channels/Lists";
import Reels from "../views/Reels"
import Clouds from "../views/Clouds"
import Brands from "../views/Brands"

import {
  getStoredUser,
  ROLES,
  setActiveClient, storeActiveChannel,
  storeClientName, storeIsPublicChannel,
  userIsAnyOf, userIsContentSupervisor, userisMultiClient,
  userIsOrOutranksRole,
  userIsSupervisor, userIsSysAdmin
} from "../api-client/core/authentication/utils";

import {isNullOrUndefined} from "util";
import UsersToApprove from "../views/Channels/UsersToApprove";
import ChannelUsers from "../views/Channels/ChannelUsers";
import LoginAttempts from "../views/LoginAttempts";
import Products from "../views/Products";
import Communities from "../views/Communities";
import ExportContentCreators from "../views/Comercial/ExportContentCreators";
import MusicDealers from "../views/MusicDealers/All";
import TagSystems from "../views/TagSystem/All";

const redirectToDashboardKey = "dashboard.redirect_to_dashboard";

export const getRedirectToDashboardStoredValue = () => {
  let val = localStorage.getItem(redirectToDashboardKey);

  if (isNullOrUndefined(val)) {
    setRedirectToDashboardStoredValue(true);

    return true;
  }

  return val === "true";
};

export const setRedirectToDashboardStoredValue = flag =>
  localStorage.setItem(redirectToDashboardKey, flag);

const makeRoutes = () => {
  const clientsRoute = {
    collapse: true,
    name: localization.get("clients"),
    state: "openClient",
    icon: AccountBox,
    forRoleIn: [ROLES.SUPERVISOR, ROLES.SYS_ADMIN, ROLES.MULTI_CLIENT],
    mustHasPermission: "can_access_clients",
    views: [
      {
        path: "/panel/clients",
        component: Clients,
        name: localization.get("clients.list")
      },
      {
        path: "/panel/users",
        name: localization.get("users"),
        icon: Group,
        component: Users,
        forRoleIn: [ROLES.SUPERVISOR, ROLES.SYS_ADMIN, ROLES.MULTI_CLIENT],
      },
      {
        path: "/panel/pitches",
        name: localization.get("projects"),
        icon: Message,
        component: Pitches,
      },
      {
        path: "/panel/playlists",
        name: localization.get("playlists"),
        icon: PlaylistPlay,
        component: Playlists,
        mustHasPermission: "can_access_music"
      },
      {
        path: "/panel/links",
        name: localization.get("links"),
        icon: Message,
        component: Links,
      },
      {
        path: "/panel/reels",
        name: localization.get("reels"),
        component: Reels,
      },
      {
        path: "/panel/clouds",
        component: Clouds,
        name: localization.get("clouds.list")
      },
    ]
  };

  const channelsRoute = {
    collapse: true,
    name: localization.get("title.channels"),
    icon: Public,
    state: "openChannel",
    forRoleIn: [ROLES.SUPERVISOR, ROLES.SYS_ADMIN, ROLES.MULTI_CLIENT],
    views: [
      {
        path: "/panel/channels",
        name: localization.get("title.channels"),
        icon: Message,
        component: Channels
      },
      {
        path: "/panel/lists/channels",
        name: localization.get("playlist"),
        component: ChannelLists,
      },
      {
        path: "/panel/channel_users",
        name: localization.get("users"),
        icon: Group,
        component: ChannelUsers,
      },
      {
        path: "/panel/approved/channel_users",
        name: localization.get("users.to_approved"),
        icon: Book,
        component: UsersToApprove
      },
      {
        path: "/panel/products",
        name: localization.get("monetization"),
        icon: Book,
        component: Products
      },
      {
        path: "/panel/community",
        name: localization.get("channel.community"),
        icon: People,
        component: Communities
      },
    ]
  };

  const libraryRoute = {
    collapse: true,
    name: localization.get("library"),
    icon: LibraryMusic,
    state: "openLibrary",
    forRoleIn: [ROLES.SUPERVISOR, ROLES.SYS_ADMIN, ROLES.MULTI_CLIENT],
    mustHasPermission: "can_access_music",
    views: [
      {
        path: "/panel/music_catalogs",
        name: localization.get("catalogs"),
        icon: Book,
        component: Catalogs,
      },
      {
        path: "/panel/artists",
        name: localization.get("artists"),
        icon: AlbumIcon,
        component: Artists,
      },
      {
        path: "/panel/albums",
        name: localization.get("albums"),
        icon: AlbumIcon,
        component: Albums,
      },
      {
        path: "/panel/musics",
        name: localization.get("musics"),
        icon: LibraryMusic,
        component: Musics,
      },
      {
        path: "/panel/global_playlists",
        name: localization.get("global_playlists"),
        icon: PlaylistPlay,
        component: GlobalPlaylists,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT,
      },
      {
        path: "/panel/music/tag_groups",
        name: localization.get("tag_groups"),
        component: TagGroups
      },
      {
        path: "/panel/music/tags",
        name: localization.get("tags"),
        component: Tags
      },
    ],
  };

  const talentsRoute = {
    collapse: true,
    name: localization.get("talents"),
    icon: Videocam,
    state: "openTalents",
    forRoleIn: [ROLES.SUPERVISOR, ROLES.SYS_ADMIN, ROLES.MULTI_CLIENT, ROLES.contentSupervisorId],
    views: [
      {
        path: "/panel/talents_catalogs",
        name: localization.get("catalogs"),
        icon: Book,
        component: Catalogs,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      },
      {
        path: "/panel/talents/content_creator_types",
        name: localization.get("content_creator_categories"),
        component: ContentCreatorTypes,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      },
      {
        path: "/panel/talents/content_creators",
        name: localization.get("content_creators"),
        component: ContentCreators
      },
      {
        path: "/panel/talents/content_creations",
        name: localization.get("content_creations.materials"),
        component: ContentCreations,
        hidden: true,
      },
      {
        path: "/panel/talents/tag_groups",
        name: localization.get("tag_groups"),
        component: TagGroups,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      },
      {
        path: "/panel/talents/tags",
        name: localization.get("tags"),
        component: Tags,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      },
      {
        path: "/panel/demos/content_creators",
        name: localization.get("demos"),
        component: ContentCreators,
        forRoleEqualOrHigherThan: ROLES.SYS_ADMIN
      },
      {
        path: "/panel/demos/content_creations",
        name: localization.get("demos"),
        component: ContentCreations,
        forRoleEqualOrHigherThan: ROLES.SYS_ADMIN,
        hidden: true,
      },
      {
        path: "/panel/talents/batch_upload",
        name: localization.get("batch_upload"),
        component: BatchUpload
      },
    ],
  };

  const audiovisualRoute = {
    collapse: true,
    name: localization.get("production"),
    icon: MovieIcon,
    state: "openAudiovisual",
    forRoleIn: [ROLES.SUPERVISOR, ROLES.SYS_ADMIN, ROLES.MULTI_CLIENT, ROLES.contentSupervisorId],
    views: [
      {
        path: "/panel/audiovisual_catalogs",
        name: localization.get("catalogs"),
        icon: Book,
        component: Catalogs,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      },
      {
        path: "/panel/audiovisual/content_creator_types",
        name: localization.get("content_creator_categories"),
        component: ContentCreatorTypes,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      },
      {
        path: "/panel/audiovisual/content_creators",
        name: localization.get("content_creators"),
        component: ContentCreators
      },
      {
        path: "/panel/audiovisual/content_creations",
        name: localization.get("content_creations.materials"),
        component: ContentCreations,
        hidden: true,
      },
      {
        path: "/panel/audiovisual/tag_groups",
        name: localization.get("tag_groups"),
        component: TagGroups,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      },
      {
        path: "/panel/audiovisual/tags",
        name: localization.get("tags"),
        component: Tags,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      },
      {
        path: "/panel/audiovisual/batch_upload",
        name: localization.get("batch_upload"),
        component: BatchUpload
      },
    ],
  };

  const editorialRoute = {
    collapse: true,
    name: localization.get("Editorial"),
    icon: RssFeed,
    state: "openEditorial",
    forRoleIn: [ROLES.SUPERVISOR, ROLES.SYS_ADMIN, ROLES.MULTI_CLIENT, ROLES.contentSupervisorId],
    views: [
      {
        path: "/panel/editorial/content_creator_types",
        name: localization.get("content_creator_categories"),
        component: ContentCreatorTypes,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      },
      {
        path: "/panel/editorial/content_creators",
        name: localization.get("content_creators"),
        component: ContentCreators
      },
      {
        path: "/panel/editorial/tag_groups",
        name: localization.get("tag_groups"),
        component: TagGroups,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      },
      {
        path: "/panel/editorial/tags",
        name: localization.get("tags"),
        component: Tags,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      },
      {
        path: "/panel/audiovisual/batch_upload",
        name: localization.get("batch_upload"),
        component: BatchUpload
      },
    ],
  };

  const managementRoute = {
    collapse: true,
    name: localization.get("management"),
    icon: List,
    state: "openManagement",
    forRoleEqualOrHigherThan: ROLES.SYS_ADMIN,
    views: [
      {
        path: "/panel/right_management",
        name: localization.get("right_management"),
        icon: Folder,
        component: RightManagement,
        forRoleEqualOrHigherThan: ROLES.SYS_ADMIN
      },
      {
        path: "/panel/original_music",
        name: localization.get("original_music"),
        icon: MusicNote,
        component: OriginalMusic,
        forRoleEqualOrHigherThan: ROLES.SYS_ADMIN
      },
      {
        path: "/panel/contact_messages",
        name: localization.get("contact_messages"),
        icon: Message,
        component: ContactMessage,
        forRoleEqualOrHigherThan: ROLES.SYS_ADMIN
      },
      {
        path: "/panel/trials",
        name: localization.get("trials"),
        icon: Message,
        component: Trials,
        forRoleEqualOrHigherThan: ROLES.SYS_ADMIN
      }
    ]
  };

  const configurationRoute = {
    collapse: true,
    name: localization.get("settings"),
    state: "openConfiguration",
    icon: Settings,
    forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT,
    mustHasPermission: 'can_access_config',
    views: [
      /*{
        path: "/panel/videos",
        name: localization.get("banners"),
        component: VideoConfig
      },
      {
        path: "/panel/catalog_categories",
        name: localization.get("catalog_categories"),
        component: CatalogCategories
      },
      {
        path: "/panel/client_categories",
        name: localization.get("client_categories"),
        component: ClientCategories
      },
      {
        path: "/panel/featured_images",
        name: localization.get("featured_images"),
        component: FeaturedImages
      },*/
      {
        path: "/panel/rankingConfigs",
        name: localization.get("ranking_configs"),
        component: RankingConfigs
      },
      /*{
        path: "/panel/forecasts",
        name: localization.get("forecasts"),
        component: Forecasts
      },*/
      {
        path: "/panel/pricing",
        name: localization.get("prices"),
        component: PricingConfiguration
      },
      {
        path: "/panel/sadaic_pricing",
        name: localization.get("sadaic_prices"),
        component: SadaicPricingConfiguration
      },
      {
        path: "/panel/licensePlans",
        name: localization.get("license_plans"),
        component: LicensePlans
      },
      {
        path: "/panel/brands",
        name: localization.get("brands"),
        component: Brands,
      },
      {
        path: "/panel/music_dealers",
        name: localization.get("music_dealers"),
        component: MusicDealers,
        mustHasPermission: "can_access_music",
      },
      {
        path: "/panel/tag_systems",
        name: localization.get("tag_systems"),
        component: TagSystems,
        mustHasPermission: "can_access_music",
      }
    ],
    forRoleIn: [ROLES.SUPERVISOR, ROLES.SYS_ADMIN, ROLES.MULTI_CLIENT],
  };

  const licensesRoute = {
    collapse: true,
    name: localization.get("licenses"),
    state: "openLicenses",
    icon: ShoppingCart,
    forRoleIn: [ROLES.SUPERVISOR, ROLES.SYS_ADMIN, ROLES.MULTI_CLIENT],
    mustHasPermission: "can_access_license",
    views: [
      {
        path: "/panel/licenses",
        name: localization.get("licenses.all"),
        component: LicenseListing,
      },
      {
        path: "/panel/pending/licenses",
        name: localization.get("comercial.pending_licenses"),
        component: LicensesPending,
        // forRoleIn: [ROLES.SUPERVISOR, ROLES.SYS_ADMIN],
      },
      {
        path: "/panel/export/licenses",
        name: localization.get("comercial.export_licenses"),
        component: ComercialExportLicenses,
        // forRoleIn: [ROLES.SUPERVISOR, ROLES.SYS_ADMIN],
      }
    ]
  };

  const comercialRoute = {
    collapse: true,
    name: localization.get("comercial"),
    state: "openComercial",
    icon: MonetizationOn,
    views: [
      { path: "/panel/comercial/entities/export",
        name: localization.get("comercial.export_entities"),
        component: ComercialExportEntities
      },
      {
        path: "/panel/comercial/sendy",
        name: localization.get("comercial.sendy"),
        component: ComercialSendy
      },
      {
        path: "/panel/comercial/gdrive",
        name: localization.get("comercial.gdrive"),
        component: ComercialGdrive
      },
      {
        path: "/panel/comercial/support",
        name: localization.get("comercial.support"),
        component: ComercialSupport
      }
    ],
    forRoleEqualOrHigherThan: ROLES.SYS_ADMIN
  };

  const reportViews = []
  const reportsRoute = {
    collapse: true,
    name: localization.get("reports"),
    icon: PieChart,
    state: "openReports",
    forRoleIn: [ROLES.SUPERVISOR, ROLES.SYS_ADMIN, ROLES.MULTI_CLIENT],
    mustHasPermission: "can_access_reports",
    views: [
      {
        path: "/panel/report/licenses",
        name: localization.get("report.licenses"),
        icon: Sort,
        component: LicensesReport,
        mustHasPermission: "can_access_music",
      },
      {
        path: "/panel/report/catalogs",
        name: localization.get("catalogs.report"),
        component: CatalogsReport,
        mustHasPermission: "can_access_music",
      },
      {
        path: "/panel/catalog_access",
        name: localization.get("catalog_access"),
        icon: Message,
        component: CatalogAccess,
        mustHasPermission: "can_access_music",
      },
      {
        path: "/panel/report/monthly",
        name: localization.get("monthly_activity.report"),
        icon: Sort,
        component: MonthlyReport,
        forRoleEqualOrHigherThan: ROLES.SYS_ADMIN,
      },
      {
        path: "/panel/auditLogs",
        name: localization.get("audit"),
        icon: Sort,
        component: AuditLog,
        forRoleEqualOrHigherThan: ROLES.SYS_ADMIN,
      },
      {
        path: "/panel/loginAttempts",
        name: localization.get("login_attempts"),
        component: LoginAttempts,
        forRoleEqualOrHigherThan: ROLES.SYS_ADMIN,
      },
      { path: "/panel/comercial/entities/export",
        name: localization.get("comercial.export_entities"),
        component: ComercialExportEntities,
        forRoleEqualOrHigherThan: ROLES.SYS_ADMIN
      },
      { path: "/panel/comercial/exportCreators",
        name: localization.get("comercial.export_events"),
        component: ExportContentCreators,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT,
        mustHasPermission: "can_access_reports",
      },
    ]
  };

  const rankingRoute = {
    collapse: true,
    name: localization.get("rankings"),
    icon: ShowChart,
    state: "openRanking",
    forRoleIn: [ROLES.SUPERVISOR, ROLES.SYS_ADMIN, ROLES.MULTI_CLIENT],
    mustHasPermission: 'can_access_ranking',
    views: [
      {
        path: "/panel/ranking/tags/supply",
        name: localization.get("ranking.tags.supply"),
        icon: Sort,
        component: TagSupplyRanking,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      },
      {
        path: "/panel/ranking/tags/demand",
        name: localization.get("ranking.tags.demand"),
        icon: Sort,
        component: TagDemandRanking,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      },
      {
        path: "/panel/ranking/musics",
        name: localization.get("ranking.musics"),
        icon: Sort,
        component: MusicRanking,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      },
      {
        path: "/panel/ranking/artists",
        name: localization.get("ranking.artists"),
        icon: Sort,
        component: ArtistRanking,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      },
      {
        path: "/panel/ranking/catalogs",
        name: localization.get("ranking.catalogs"),
        icon: Sort,
        component: CatalogRanking,
        forRoleEqualOrHigherThan: ROLES.MULTI_CLIENT
      }
    ]
  };

  const dashboardRoute = {
    hidden: true,
    path: "/panel/dashboard",
    component: Graphboard,
    forRoleIn: [ROLES.SUPERVISOR, ROLES.SYS_ADMIN]
  };

  const searchRoute = {
    hidden: true,
    path: "/panel/search",
    component: Search,
    forRoleEqualOrHigherThan: ROLES.SYS_ADMIN
  };

  const profileRoute = {
    hidden: true,
    path: "/panel/profile",
    component: UserProfile,
    forRoleIn: [ROLES.SUPERVISOR, ROLES.SYS_ADMIN, ROLES.MULTI_CLIENT],
  };

  const redirectRoute = () => {
    let pathTo;
    let name;
    if (userIsSupervisor()) {
      if (getRedirectToDashboardStoredValue()) {
        pathTo = "/panel/dashboard";
        name = "Dashboard";
      } else {
        pathTo = "/panel/clients";
        name = localization.get("clients");
      }
    } else if (userIsContentSupervisor() || (userisMultiClient() && !getStoredUser().can_access_clients) ) {
      pathTo = "/panel/audiovisual/content_creators";
      name = localization.get("content_creators");
    } else {
      pathTo = "/panel/clients";
      name = localization.get("clients");
    }
    return {
      redirect: true,
      path: "/",
      pathTo: pathTo,
      name: name
    };
  };

  const routes = [];

  const userCanAccess = item => {
    if (
      !isNullOrUndefined(item.forRoleEqualOrHigherThan) &&
      !userIsOrOutranksRole(item.forRoleEqualOrHigherThan)
    ) {
      return false;
    }
    if (!isNullOrUndefined(item.forRoleIn) && !userIsAnyOf(item.forRoleIn)) {
      return false;
    }

    if (!isNullOrUndefined(item.mustHasPermission) && !userIsSysAdmin() && !getStoredUser()[item.mustHasPermission]) {
      return false;
    }

    return true;
  };

  const addRoute = route => {
    if (!userCanAccess(route)) {
      return routes;
    }
    if (!isNullOrUndefined(route.views)) {
      const acceptedViews = [];
      route.views.forEach(view => {
        if (userCanAccess(view)) {
          acceptedViews.push(view);
        }
      });
      route.views = acceptedViews;
    }

    routes.push(route);
    return routes;
  };

  setActiveClient(null);
  storeClientName("");
  storeIsPublicChannel(false);
  storeActiveChannel(false);

  addRoute(clientsRoute);
  addRoute(channelsRoute);
  addRoute(libraryRoute);
  addRoute(talentsRoute);
  addRoute(audiovisualRoute);
  addRoute(editorialRoute)

  addRoute(licensesRoute);
  //addRoute(managementRoute);
  // addRoute(demosRoute);
  addRoute(reportsRoute);
  addRoute(rankingRoute);
  //addRoute(comercialRoute);
  addRoute(configurationRoute);

  addRoute(searchRoute);
  addRoute(profileRoute);
  addRoute(dashboardRoute);
  addRoute(redirectRoute());

  return routes;
};

export default makeRoutes;
