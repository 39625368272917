import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import localization from "../../../config/localization";
import moment from "moment";
import 'moment/locale/es';
import { Chip } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { Event } from "@material-ui/icons";
import './index.css';
import Zoom from '@material-ui/core/Zoom';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: props => ({
    backgroundColor: 'var(--secondary-color, #f5f5f9)',
    color: 'var(--secondary-font-color, rgba(0, 0, 0, 0.87))',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    marginTop: props.marginTop, // Apply the marginTop dynamically
  }),
}))(Tooltip);

class EventDatesInfo extends React.Component {
  constructor(props) {
    super(props);
    moment.locale('es');
    let today = moment();
    let eventDates = this.props.eventDates.map((ed) => {
      if (ed.preferences && ed.preferences.button && ed.preferences.button.start_date) {
        ed.preferences.button.start_date = moment(`${ed.preferences.button.start_date} +0000`, "YYYY-MM-DD HH:mm Z");
      }
      let date = moment(`${ed.date.substr(0, 10)} ${ed.time} +0000`, "YYYY-MM-DD HH:mm Z");
      return {
        date: date,
        duration: ed.duration,
        button: ed.preferences.button,
        clarification: ed.preferences.clarification,
        hasStarted: date.isBefore(today),
      };
    }).sort((d1, d2) => d1.date.isBefore(d2.date) ? -1 : 1);
    
    this.state = {
      events: eventDates,
    };
  }

  onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClick && this.props.onClick(e);
    return false;
  }

  render() {
    const { events } = this.state;
    const onlyTimes = this.props.onlyTimes || false;
    const tooltipPlacement = this.props.tooltipPlacement || 'top';
    const chipFontColor = this.props.chipFontColor || "#4c4c4c";
    const chipColor = this.props.chipColor || "var(--title-hover, #e0e0e0)";
    const isMobile = window.innerWidth < 600;
    const eventsCount = events.length;

    // Calculate marginTop based on the number of events
    const marginTop = 30 + Math.min(eventsCount * 10, 100);

    let chip = (
      <Chip 
        style={{ cursor: "pointer", color: chipFontColor, background: chipColor, height: "2em" }}
        label={events.length > 1 ? localization.get(onlyTimes ? 'event_count.schedule' : 'event_count', events.length) : events[0].date.format('DD/MM')}
        avatar={<Event style={{ color: chipFontColor, height: 18, width: 18, marginLeft: "5px", marginRight: "-8px" }} />}
        onClick={this.onClick}
      />
    );

    return (
      <>
        {events.length > 0 ? (
          <HtmlTooltip
            TransitionComponent={Zoom}
            placement={tooltipPlacement}
            disableFocusListener={isMobile}
            disableHoverListener={isMobile}
            disableTouchListener={isMobile}
            open={isMobile ? this.props.open : undefined}
            marginTop={`-${marginTop}px`} // Pass marginTop as a prop
            title={
              <React.Fragment>
                {events.slice(0, 10).map((e, i) => (
                  <p key={i} style={e.hasStarted ? { textDecoration: 'line-through' } : {}}>{onlyTimes ? `${e.date.format("HH:mm")} hs` : e.date.format('lll')}</p>
                ))}
                {eventsCount > 10 && <p>{localization.get('event_count.more', eventsCount - 10)}</p>}
              </React.Fragment>
            }
          >
            {chip}
          </HtmlTooltip>
        ) : chip}
      </>
    );
  }
}

export default EventDatesInfo;