import React, {Component} from "react";
import {request} from "../../../api-client";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import FormContent from "../FormContent";
import localization from "../../../config/localization";
import {getUpdateFormImageUrlFromItem} from "../../../utils";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {stateToHTML} from "draft-js-export-html";
import { EditorState, convertToRaw } from "draft-js";
import { clearUnwantedStateStyles } from "../../../components/Letflow/RichTextEditor/utils";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";
import { get } from "lodash";
import {  userIsContentSupervisor } from "../../../api-client/core/authentication/utils";


export default class extends Component {
  state = {
    email: '',
    phone: '',
    image: { src: null, file: null },
    coverImage: { src: null, file: null },
    featuredImage: { src: null, file: null },
    mobileCoverImage: { src: null, file: null },
    selectedTags: [],
    tagGroups: [],
    country: '',
    observation: '',
    active: "1",
    selectedCatalogs: [],
    contentCreatorTypes: [],
    selectedContentCreatorType: '',
    socialLinks: [],
    weight: 0,
    disableForm: false,
    unsplashCoverImage: '',
    unsplashFeaturedImage: '',
    unsplashProfileImage: '',
    unsplashMobileCoverImage: '',
    coverImageRadio: 'filepond',
    featuredImageRadio: 'filepond',
    profileImageRadio: 'filepond',
    mobileCoverImageRadio: 'filepond',
    startActivity: null,
    countryIsCompleted: true,
    canonicalIsCompleted: true,
    selectedBatchProfileImageFile: '',
    selectedBatchCoverImageFile: '',
    selectedBatchFeaturedImageFile: '',
    selectedBatchMobileCoverImageFile: '',
    selectedBatchProfileLink: '',
    selectedBatchCoverLink: '',
    selectedBatchMobileCoverLink: '',
    selectedBatchFeaturedLink: '',
    downloadableMaterialFileType: '',
    downloadableMaterialId: '',
    downloadableMaterialText: '',
    contentSupervisor: {value: 0, label: localization.get('unsupervised')},
    onlyArgentina: "false",
    releaseDate: null,
    expireDate: null,
    hasExpiredDate: false,
    isPermanentContent: "1",
    downloadableMaterialRadio: 'filepond',
    selectedBatchDMaterialLink: '',
    selectedBatchDMaterialFile: '',
    downloadableMaterialLink: '',
    showHelper: false,
    hasSuggestedCarousel: false,
    suggestedCarouselRadio: null,
    suggestedCarouselPlaylist: null,
    requireLogin: "0",
    showDateOnHome: false,
    showTags: false,
    suggestedTagGroup: null,
    suggestedView: "16:9",
    hasDownloadableMaterial: "0",
    clouds: [],
    selectedCloud: "",
    access: "sysadmin",
    hasPreferences: false,
    useBackgroundColor: false,
    backgroundColor: "rgba(255, 255, 255, 1)",
    useBackgroundGradient: true,
    subtitleFontSize: 20,
    subtitleMargin: 10,
    titleColor: "rgba(255, 255, 255, 1)",
    descriptionColor: "rgba(255, 255, 255, 1)",
    creationColor: "rgb(0,0,0, 1)",
    cardColor: "rgba(255, 255, 255, 0.4)",
    cardShape: {value: "rounded", label: localization.get('rounded')},
    creationInverted: "down",
    showBox: "show",
    showCreationBorder: "show",
    creationCarouselBackgroundColor: 'rgba(171, 171, 171, 1)',
    eventsPosition: "top",
    eventDates: [],
    isEvent: "0",
    buyButtonPosition: {value: "both", label: localization.get('content_creator.buy_button.both')},
    buyButtonBackgroundColor: "rgb(0,0,0,1)", 
    buyButtonFontColor: "rgb(255,255,255,1)",
    hasBuyButton: "0",
    buyButtonText: "",
    buyButtonShowInSocials: false,
    buyButtonProduct: null,
    selectedBuyButtonProduct: null,
    channel: null,
    products: [],
    hasRegionBlock: "false",
    allowedRegions: [],
    hasEditorialPreferences: false,
    editorialBgColor: 'rgba(255,255,255,1)',
    editorialTitleColor: 'rgba(0,0,0,1)',
    editorialHideCoverImage: false,
    viewType: "inherit",
    viewTypeMobile: "inherit",
    suggestedViewMultipleRow: false,
    driveProfileImage: null,
    driveCoverImage: null,
    driveFeaturedImage: null,
    driveMobileCoverImage: null,
    canonicalChannel: null,
    domainChannels: [],
    suggestedContentCreators: [],
    translations: AvailableLanguages.map(language => ({
        language: language,
        name: "",
        description: new EditorState.createEmpty(),
        author: "",
        category: "",
        synopsis: "",
        when: "",
        entrance: "",
    })),
    contentCreationsOrders:[],
    reorderingCreations:false,
    accessLevel: "0",
    additionInfoSize: "md",
    eventButtonColor: "rgba(0,0,0,1)",
    eventButtonBackgroundColor: "rgba(255,255,255,1)",
    eventButtonText: "eventos",
    editorialUseSynopsis: false,
  };

  requestContentCreatorCreation = () => {
    const {
      email,
      phone,
      imageId,
      coverImageId,
      featuredImageId,
      mobileCoverImageId,
      selectedTags,
      country,
      observation,
      active,
      selectedCatalogs,
      selectedContentCreatorType,
      socialLinks,
      weight,
      unsplashCoverImage,
      unsplashFeaturedImage,
      unsplashProfileImage,
      unsplashMobileCoverImage,
      startActivity,
      selectedBatchProfileImageFile,
      selectedBatchCoverImageFile,
      selectedBatchFeaturedImageFile,
      selectedBatchMobileCoverImageFile,
      downloadableMaterialId,
      downloadableMaterialFileType,
      downloadableMaterialText,
      contentSupervisor,
      onlyArgentina,
      releaseDate,
      expireDate,
      selectedBatchDMaterialFile,
      downloadableMaterialLink,
      hasSuggestedCarousel,
      suggestedCarouselPlaylist,
      showDateOnHome,
      showTags,
      suggestedTagGroup,
      suggestedView,
      selectedCloud,
      hasPreferences,
      useBackgroundColor,
      backgroundColor,
      useBackgroundGradient,
      subtitleFontSize,
      subtitleMargin,
      titleColor,
      descriptionColor,
      creationColor,
      cardColor,
      cardShape,
      creationInverted,
      eventsPosition,
      isEvent,
      eventDates,
      buyButtonText,
      buyButtonShowInSocials,
      buyButtonProduct,
      selectedBuyButtonProduct,
      buyButtonPosition,
      buyButtonBackgroundColor,
      buyButtonFontColor,
      hasRegionBlock,
      allowedRegions,
      hasEditorialPreferences,
      editorialBgColor,
      editorialTitleColor,
      editorialHideCoverImage,
      viewType,
      viewTypeMobile,
      suggestedViewMultipleRow,
      driveProfileImage,
      driveCoverImage,
      driveFeaturedImage,
      driveMobileCoverImage,
      canonicalChannel,
      translations,
      suggestedContentCreators,
      eventButtonText,
      eventButtonColor,
      eventButtonBackgroundColor,
      editorialUseSynopsis,
    } = this.state;

    const contentCreatorData = {
      email,
      phone,
      translations: translations.map(tr => ({
        language: tr.language,
        description: tr.description && (selectedContentCreatorType.type === 'editorial' ?
          (stateToHTML(tr.description.getCurrentContent()) === '<p><br></p>' ? "" : JSON.stringify(clearUnwantedStateStyles(convertToRaw(tr.description.getCurrentContent())))) :
          stateToHTML(tr.description.getCurrentContent())),
        name: tr.name,
        additions: `${tr.author};${tr.category}`,
        synopsis: tr.synopsis,
        when: tr.when,
        entrance: tr.entrance,
      })),
      imageId,
      coverImageId,
      featuredImageId,
      mobileCoverImageId,
      selectedTags,
      countryId: country.value,
      observation,
      active,
      selectedCatalogs: selectedCatalogs.map(catalog => catalog.value),
      contentCreatorTypeId: selectedContentCreatorType.value,
      socialLinks,
      weight,
      unsplashCoverImage,
      unsplashFeaturedImage,
      unsplashProfileImage,
      unsplashMobileCoverImage,
      startActivity,
      batchProfileImageId: selectedBatchProfileImageFile.value,
      batchCoverImageId: selectedBatchCoverImageFile.value,
      batchFeaturedImageId: selectedBatchFeaturedImageFile.value,
      batchMobileCoverImageId: selectedBatchMobileCoverImageFile.value,
      downloadableMaterialId,
      downloadableMaterialFileType,
      downloadableMaterialText,
      contentSupervisorId: contentSupervisor.value,
      onlyArgentina: onlyArgentina,
      releaseDate: releaseDate,
      expireDate: expireDate,
      batchDownloadableMaterialId: selectedBatchDMaterialFile.value,
      downloadableMaterialLink,
      hasSuggestedCarousel,
      suggestedCarouselPlaylist: suggestedCarouselPlaylist && suggestedCarouselPlaylist.value,
      showDateOnHome,
      showTags,
      suggestedTagGroup: suggestedTagGroup && suggestedTagGroup.value,
      suggestedView,
      selectedCloud: selectedCloud && selectedCloud.value,
      hasPreferences,
      useBackgroundColor,
      backgroundColor,
      useBackgroundGradient,
      subtitleFontSize,
      subtitleMargin,
      titleColor,
      descriptionColor,
      creationColor,
      cardColor,
      cardShape: cardShape && cardShape.value,
      creationInverted: creationInverted === "down",
      eventsPosition,
      isEvent,
      buyButtonProductId: selectedBuyButtonProduct ? selectedBuyButtonProduct.id : buyButtonProduct ? buyButtonProduct.id : null,
      buyButtonText,
      buyButtonShowInSocials,
      buyButtonPosition: buyButtonPosition.value,
      buyButtonBackgroundColor,
      buyButtonFontColor,
      hasRegionBlock,
      allowedRegions,
      editorialBgColor,
      editorialTitleColor,
      editorialHideCoverImage,
      hasEditorialPreferences: selectedContentCreatorType.type === 'editorial' ? hasEditorialPreferences : null,
      creationCarouselBackgroundColor: this.state.showCreationBorder == 'hide' ? 'transparent' : this.state.creationCarouselBackgroundColor,
      viewType,
      viewTypeMobile,
      suggestedViewMultipleRow,
      driveProfileImage,
      driveCoverImage,
      driveFeaturedImage,
      driveMobileCoverImage,
      canonicalChannel: canonicalChannel && canonicalChannel.value,
      suggestedContentCreators: this.state.suggestedContentCreators.map(c => c.value),
      accessLevel: this.state.accessLevel,
      additionInfoSize: this.state.additionInfoSize,
      eventButtonText,
      eventButtonColor,
      eventButtonBackgroundColor,
      editorialUseSynopsis,
    }

    return request.contentCreator.create(contentCreatorData)
      .then((response) => {
        let creator = response.data; 
        return request.contentCreatorEventDates.update({ id: creator.id, dates: eventDates.flat()})
      })
      .then(() => this.props.history.goBack())
  };

  requestTags = (contentCreatorType) => request.tagGroup.getAll({filterBy: [{ column: 'type', filter: contentCreatorType},{ column: 'creation_group', filter: '0'},{ column: 'dashboard', filter: '1'}]}).then((tagGroups) => this.setState({tagGroups}))

  onChange = e => this.setState({ [e.target.id]: e.target.value });

  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})
  onCoverImageChange = coverImage => coverImage ? this.setState({coverImageId: coverImage.id, coverImage: {src: null, filename: null }, imagePreview: this.formNewUrl(coverImage.path)}) : this.setState({coverImageId: null, coverImage: { src: null, filename: null }})
  onFeaturedImageChange = featuredImage => featuredImage ? this.setState({featuredImageId: featuredImage.id, featuredImage: {src: null, filename: null }, imagePreview: this.formNewUrl(featuredImage.path)}) : this.setState({featuredImage: { src: null, filename: null }})
  onMobileImageChange = mobileCoverImage => mobileCoverImage ? this.setState({mobileCoverImageId: mobileCoverImage.id, mobileCoverImage: {src: null, filename: null }, imagePreview: this.formNewUrl(mobileCoverImage.path)}) : this.setState({mobileCoverImageId: null, mobileCoverImage: { src: null, filename: null }})
  onMobileCoverImageChange = mobileCoverImage => mobileCoverImage ? this.setState({mobileCoverImageId: mobileCoverImage.id, mobileCoverImage: {src: null, filename: null }, imagePreview: this.formNewUrl(mobileCoverImage.path)}) : this.setState({mobileCoverImageId: null, mobileCoverImage: { src: null, filename: null }})

  onSetCoverImageFromUnsplash = (url, name) => this.setState({unsplashCoverImage: {url, name}})
  onSetFeaturedImageFromUnsplash = (url, name) => this.setState({unsplashFeaturedImage: {url, name}})
  onSetProfileImageFromUnsplash = (url, name) => this.setState({unsplashProfileImage: {url, name}})
  onSetMobileCoverImageFromUnsplash = (url, name) => this.setState({unsplashMobileCoverImage: {url, name}})

  onBatchProfileLinkChange = (selectedBatchProfileLink) => {
    this.setState({selectedBatchProfileLink: '', selectedBatchProfileImageFile: ''}, () => this.setState({selectedBatchProfileLink}))
  }
  onBatchCoverLinkChange = (selectedBatchCoverLink) => {
    this.setState({selectedBatchCoverLink: '', selectedBatchCoverImageFile: ''}, () => this.setState({selectedBatchCoverLink}))
  }
  onBatchFeaturedLinkChange = (selectedBatchFeaturedLink) => {
    this.setState({selectedBatchFeaturedLink: '', selectedBatchFeaturedImageFile: ''}, () => this.setState({selectedBatchFeaturedLink}))
  }
  onBatchDMaterialLinkChange = (selectedBatchDMaterialLink) => {
    this.setState({selectedBatchDMaterialLink: '', selectedBatchDMaterialFile: ''}, () => this.setState({selectedBatchDMaterialLink}))
  }
  onBatchMobileCoverLinkChange = (selectedBatchMobileCoverLink) => {
    this.setState({selectedBatchMobileCoverLink: '', selectedBatchMobileCoverImageFile: ''}, () => this.setState({selectedBatchMobileCoverLink}))
  }

  onDownloadableMaterialChange = downloadableMaterial => this.setState({downloadableMaterialId: downloadableMaterial ? downloadableMaterial.id : null})

  onHasDMaterialChange = hasDMaterial => { 
    if (hasDMaterial == "0") {
      this.setState({
        selectedBatchDMaterialLink: '',
        selectedBatchDMaterialFile: '',
        downloadableMaterialId: '',
        downloadableMaterialLink: '',
        hasDownloadableMaterial: "0"
      })
    } else {
      this.setState({hasDownloadableMaterial: hasDMaterial});
    }
  }

  onHasBuyButtonChange = hasBuyButton => {
    if (hasBuyButton == "0") {
      this.setState({
        hasBuyButton: "0",
        buyButtonProduct: null,
        selectedBuyButtonProduct: null,
        buyButtonText: '',
        buyButtonShowInSocials: false,
        channel: null
      })
    } else {
      this.setState({hasBuyButton: hasBuyButton});
    }
  }

  onImageRadiosChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
    if (e.target.name === 'coverImageRadio') {
      this.setState({
        coverImageId: '',
        coverImage: {src: null, filename: null },
        unsplashCoverImage: '',
        selectedBatchCoverLink: '',
        selectedBatchCoverImageFile: '',
        driveCoverImage: null,
      })
    }
    if (e.target.name === 'featuredImageRadio') {
      this.setState({
        featuredImageId: '',
        featuredImage: {src: null, filename: null },
        unsplashFeaturedImage: '',
        selectedBatchFeaturedLink: '',
        selectedBatchFeaturedImageFile: '',
        driveFeaturedImage: null,
      })
    }
    if (e.target.name === 'profileImageRadio') {
      this.setState({
        imageId: '',
        image: {src: null, filename: null },
        unsplashProfileImage: '',
        selectedBatchProfileImageFile: '',
        selectedBatchProfileLink: '',
        driveProfileImage: null,
      })
    }
    if (e.target.name === 'downloadableMaterialRadio') {
      this.setState({
        selectedBatchDMaterialLink: '',
        selectedBatchDMaterialFile: '',
        downloadableMaterialId: '',
        downloadableMaterialLink: '',
        downloadableMaterialFilename: '',
        deleteDownloadableMaterial: true,
      })
    }
    if (e.target.name === 'suggestedCarouselRadio') {
      this.setState({suggestedCarouselPlaylist: null})
    }
    if (e.target.name === 'mobileCoverImageRadio') {
      this.setState({
        mobileCoverImageId: '',
        mobileCoverImage: {src: null, filename: null },
        unsplashMobileCoverImage: '',
        selectedBatchMobileCoverLink: '',
        selectedBatchMobileCoverImageFile: '',
        driveMobileCoverImage: null,
      })
    }
  }

  onAsyncCountriesChange = country => this.setState({ country , countryIsCompleted: true});

  onSelectedTagsChanged = selectedTags => this.setState({ selectedTags, tagGroups: this.updateTagGroups(this.state.tagGroups, selectedTags) })

  onSelectedContentCreatorTypeChange = (selectedContentCreatorType, isCloud = false) => {
    this.setState({selectedContentCreatorType: '', selectedCatalogs: isCloud ? this.state.selectedCatalogs : []},
      () => this.setState({selectedContentCreatorType, accessLevel: `${get(selectedContentCreatorType, "client.default_access_level", 0)}`},
        () => this.requestTags(selectedContentCreatorType.slug)
          .then(this.getDomainChannels)))
  }

  formNewUrl = (path) => {
    return process.env.REACT_APP_CDN_HOST.concat('/' + path)
  }

  setPreview = (type) => {
    if (type === 'video') {
      this.setState({video: { src: this.state.videoPreview, filename: null}})
    } else if (type === 'image') {
      this.setState({image : {src: this.state.imagePreview}})
    }
  }

  setCoverPreview = (type) =>  {
    if (type === 'image') {
      this.setState({coverImage : {src: this.state.imagePreview}})
    }
  }
  setFeaturedPreview = (type) =>  {
    if (type === 'image') {
      this.setState({featuredImage : {src: this.state.imagePreview}})
    }
  }
  setMobileCoverPreview = (type) =>  {
    if (type === 'image') {
      this.setState({mobileCoverImage : {src: this.state.imagePreview}})
    }
  }

  updateTagGroups = (tagGroups, selectedTags) => {
    return tagGroups.map(tagGroup => ({
      ...tagGroup,
      tags: tagGroup.tags.map(tag => ({
        ...tag,
        state: selectedTags.find(selectedTag => selectedTag.toString() === tag.id.toString()) ? 'selected' : 'available'
      }))
    }))
  };

  onSubmitValidation = () => {
    if (!this.state.country && this.props.location.pathname.includes('talents')) {
      GlobalSnackbar.show({message: localization.get('form.country_is_required'), type: GlobalSnackbarTypes.ERROR});
      this.setState({countryIsCompleted: false})
      return false
    } else if (!!this.state.releaseDate && !!this.state.expireDate && this.state.releaseDate > this.state.expireDate) {
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get('form.content_creator.dates_validation')})
      return false
    } else if (this.state.selectedCatalogs && this.state.selectedCatalogs.length === 0) {
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get('content_creators.must_select_catalog')})
      return false;
    } else if (this.props.location.pathname.includes('editorial') && !this.state.startActivity) {
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get('form.publish_date_required')})
      return false;
    } else if(!this.state.canonicalChannel && this.state.domainChannels.length) {
      let x = document.getElementById("content-creator-seo-configuration");
      let up = document.getElementById(`arrow-seo-up`);
      let down = document.getElementById(`arrow-seo-down`);

      if (x.style.display === "none") {
        x.style.display = "inline-block";
        down.style.display = "none";
        up.style.display = "block";
      }

      const element = document.getElementById('canonicalChannel');
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
      }


      this.setState({canonicalIsCompleted: false})
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get('form.content_creator.must_select_canonicals')})
      return false;
    }

    this.setState({countryIsCompleted: true, canonicalIsCompleted: true})

    let synopsisIsLong, additionsIsLong = "";

    this.state.translations.forEach(translation => {
      if (!synopsisIsLong && !additionsIsLong && translation.synopsis && translation.synopsis.length > 600) {
        synopsisIsLong = translation.language;
      }

      if (!synopsisIsLong && !additionsIsLong && `${translation.author};${translation.category}` && `${translation.author};${translation.category}`.length > 400) {
        additionsIsLong = translation.language;
      }
    })

    if (!!synopsisIsLong) {
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get(`form.content_creator.synopsis_validation_${synopsisIsLong}`)})
      return false;
    }
    if (!!additionsIsLong) {
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get(`form.content_creator.additions_validation_${additionsIsLong}`)})
      return false;
    }

    return true
  }

  getSynopsis = description => {
    const blocks = convertToRaw(description.getCurrentContent()).blocks;
    const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

    if (value.length < 247) return value+ "...";
    const shortDescription = value.substring(0, 300)
    const textInChars = shortDescription.split("")
    let text = "";
    let spaceText = "";

    for (let index = textInChars.length; index > 0; index--) {
      if(textInChars[index] === ".") {
        text = shortDescription.substring(0, index+1)
        break;
      }
      if(textInChars[index] === ",") {
        if (shortDescription.substring(0, index).length < 300) {
          text = shortDescription.substring(0, index) + "..."
          break;
        }
      }
      if(!spaceText && textInChars[index] === " " && shortDescription.substring(0, index).length < 300) {
        spaceText = shortDescription.substring(0, index) + "..."
      }
    }

    if (text.length < 100) text = spaceText;

    return text;
  }

  getClouds = () => request.clouds.getAll({filterBy: [
    {column: "catalog_ids", filter: this.state.selectedCatalogs.map(c => c.value)}]})
    .then(res => this.setState({ clouds: res.map(cloud => ({
        value: cloud.id,
        label: cloud.name,
        catalog: cloud.catalog,
        contentCreatorTypes: cloud.contentCreatorTypes
        .filter(c => c.client)
        .filter(c => {
          if (this.props.location.pathname.includes('talents')) {
            return c.is_talent === 1 && c.type === "audiovisual"
          }else if (this.props.location.pathname.includes('editorial')) {
            return c.is_talent === 0 && c.type === "editorial"
          }
          return c.is_talent === 0 && c.type === "audiovisual"
        }).map(c => ({...c, value: c.id, label: c.name, client: c.client, client_id: c.client.id})),
    }))}))

  getDomainChannels = () => {
    let filterBy = [{column: "has_domain", filter: true}];

    console.log(this.state.selectedContentCreatorType)
    if (!!this.state.selectedContentCreatorType.client) {
      filterBy = filterBy.concat([{column: "client_id", filter: get(this.state, "selectedContentCreatorType.client.id")}])
    }else if (userIsContentSupervisor()) {

      filterBy = filterBy.concat([{column: "client_id", filter: get(this.state, "selectedContentCreatorType.client.id")}])
    }

    return request.channel.getAll({filterBy})
      .then(res => {
        let results = res['data'] ? res['data'] : res;

        this.setState({
          domainChannels: results.map(content => ({
            ...content,
            value: content.id,
            label: `${content.domain.url}`
          }))
        })
      })
      .then(() => {
        if (!this.state.canonicalChannel && this.state.domainChannels.length === 1) {
          this.setState({ canonicalChannel: this.state.domainChannels[0] })
        }
      })
  }


  render = () => {
    return (
      <div>
        <FormViewContainer
          title={localization.get('view.content_creator.create')}
          submitRequest={() => this.requestContentCreatorCreation()}
          onBack={() => this.props.history.goBack()}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          hasTranslatableInputs
          disabled={this.state.disableForm}
          validation={this.onSubmitValidation}
          actionsExtraSpace={true}
          initializationRequest={this.getClouds}
        >
          <FormContent
            image={this.state.image}
            onChange={this.onChange}
            onImageChange={this.onImageChange}
            tagGroups={this.state.tagGroups}
            onSelectedTagsChanged={this.onSelectedTagsChanged}
            country={this.state.country}
            onAsyncCountriesChange={this.onAsyncCountriesChange}
            observation={this.state.observation}
            active={this.state.active}
            onActiveChange={(active) => this.setState({active}, () => this.state.active && this.setState({releaseDate: null, expireDate: null}))}
            selectedCatalogs={this.state.selectedCatalogs}
            onCatalogChange={selectedCatalogs => this.setState({selectedCatalogs, hasSuggestedCarousel: false, suggestedCarouselPlaylist: null}, this.getClouds)}
            selectedContentCreatorType={this.state.selectedContentCreatorType}
            socialLinks={this.state.socialLinks}
            onSocialLinksChange={socialLinks => this.setState({socialLinks})}
            onSelectedContentCreatorTypeChange={this.onSelectedContentCreatorTypeChange}
            coverImage={this.state.coverImage}
            featuredImage={this.state.featuredImage}
            mobileCoverImage={this.state.mobileCoverImage}
            onCoverImageChange={this.onCoverImageChange}
            onFeaturedImageChange={this.onFeaturedImageChange}
            onMobileCoverImageChange={this.onMobileCoverImageChange}
            weight={this.state.weight}
            updateDisableForm={value => this.setState({disableForm: value})}
            onSetPreview={this.setPreview}
            onSetCoverPreview={this.setCoverPreview}
            onSetFeaturePreview={this.setFeaturedPreview}

            disableForm={this.state.disableForm}
            coverImageRadio={this.state.coverImageRadio}
            onMobileImageChange={this.onMobileImageChange}
            featuredImageRadio={this.state.featuredImageRadio}
            profileImageRadio={this.state.profileImageRadio}
            mobileCoverImageRadio={this.state.mobileCoverImageRadio}
            onSetCoverImageFromUnsplash={this.onSetCoverImageFromUnsplash}
            onSetFeaturedImageFromUnsplash={this.onSetFeaturedImageFromUnsplash}
            onSetProfileImageFromUnsplash={this.onSetProfileImageFromUnsplash}
            onSetMobileCoverImageFromUnsplash={this.onSetMobileCoverImageFromUnsplash}
            onImageRadiosChange={this.onImageRadiosChange}
            startActivity={this.state.startActivity}
            handleStartActivityChange={date => this.setState({startActivity: date})}
            countryIsCompleted={this.state.countryIsCompleted}
            isTalent={this.props.location.pathname.includes('talents')}
            isEditorial={this.props.location.pathname.includes('editorial')}
            onContentChange={(e, field) => this.setState({[field]: e})}
            onBatchProfileLinkChange={this.onBatchProfileLinkChange}
            selectedBatchProfileLink={this.state.selectedBatchProfileLink}
            selectedBatchProfileImageFile={this.state.selectedBatchProfileImageFile}
            onBatchProfileFileChange={selectedBatchProfileImageFile => this.setState({selectedBatchProfileImageFile})}
            onBatchCoverLinkChange={this.onBatchCoverLinkChange}
            selectedBatchCoverLink={this.state.selectedBatchCoverLink}
            selectedBatchCoverImageFile={this.state.selectedBatchCoverImageFile}
            onBatchCoverFileChange={selectedBatchCoverImageFile => this.setState({selectedBatchCoverImageFile})}
            onBatchFeaturedLinkChange={this.onBatchFeaturedLinkChange}
            selectedBatchFeaturedLink={this.state.selectedBatchFeaturedLink}
            selectedBatchFeaturedImageFile={this.state.selectedBatchFeaturedImageFile}
            onBatchFeaturedFileChange={selectedBatchFeaturedImageFile => this.setState({selectedBatchFeaturedImageFile})}
            onBatchMobileCoverLinkChange={this.onBatchMobileCoverLinkChange}
            selectedBatchMobileCoverLink={this.state.selectedBatchMobileCoverLink}
            selectedBatchMobileCoverImageFile={this.state.selectedBatchMobileCoverImageFile}
            onDownloadableMaterialChange={this.onDownloadableMaterialChange}
            setDownloadableMaterialFileType={downloadableMaterialFileType => this.setState({downloadableMaterialFileType})}
            downloadableMaterialText={this.state.downloadableMaterialText}
            onContentSupervisorChange={contentSupervisor => this.setState({contentSupervisor})}
            contentSupervisor={this.state.contentSupervisor}
            onlyArgentina={this.state.onlyArgentina}
            releaseDate={this.state.releaseDate}
            expireDate={this.state.expireDate}
            hasExpiredDate={this.state.hasExpiredDate}
            onHasExpiredDateChange={hasExpiredDate => this.setState({hasExpiredDate}, () => this.state.hasExpiredDate && this.setState({expireDate: null}))}
            isPermanentContent={this.state.isPermanentContent}
            onIsPermanentContentChange={isPermanentContent => this.setState({isPermanentContent}, () => !this.state.isPermanentContent && this.setState({expireDate: null, releaseDate: null}))}
            downloadableMaterialRadio={this.state.downloadableMaterialRadio}
            onBatchDMaterialLinkChange={this.onBatchDMaterialLinkChange}
            selectedBatchDMaterialLink={this.state.selectedBatchDMaterialLink}
            onBatchDMaterialFileChange={selectedBatchDMaterialFile => this.setState({selectedBatchDMaterialFile})}
            selectedBatchDMaterialFile={this.state.selectedBatchDMaterialFile}
            downloadableMaterialLink={this.state.downloadableMaterialLink}
            showHelper={this.state.showHelper}
            updateHelper={() => this.setState({showHelper: !this.state.showHelper})}
            hasSuggestedCarousel={this.state.hasSuggestedCarousel}
            onSuggestedCarouselChange={hasSuggestedCarousel => this.setState({hasSuggestedCarousel, suggestedCarouselPlaylist: null, suggestedTagGroup: null})}
            suggestedCarouselRadio={this.state.suggestedCarouselRadio}
            onSuggestedCarouselPlaylistChange={suggestedCarouselPlaylist => this.setState({suggestedCarouselPlaylist})}
            suggestedCarouselPlaylist={this.state.suggestedCarouselPlaylist}
            requireLogin={this.state.requireLogin}
            onRequireLoginChange={requireLogin => this.setState({requireLogin: requireLogin})}
            showDateOnHome={this.state.showDateOnHome}
            showTags={this.state.showTags}
            suggestedTagGroup={this.state.suggestedTagGroup}
            suggestedView={this.state.suggestedView}
            onSuggestedCarouselRadioChange={suggestedCarouselRadio => this.setState({suggestedCarouselRadio,  suggestedCarouselPlaylist: null, suggestedTagGroup: null})}
            hasDownloadableMaterial={this.state.hasDownloadableMaterial}
            onHasDMaterialChange={this.onHasDMaterialChange}
            onShowTagsChange={showTags =>  this.setState({ showTags })}
            suggestedTagGroupChange={suggestedTagGroup =>  this.setState({ suggestedTagGroup })}
            getSynopsis={description => this.getSynopsis(description)}
            access={this.state.access}
            onAccessChange={value => this.setState({access: value.target.value})}
            selectedCloud={this.state.selectedCloud}
            onSelectedCloudsChange={value => this.setState({ selectedCloud: value})}
            clouds={this.state.clouds}
            titleColor={this.state.titleColor}
            descriptionColor={this.state.descriptionColor}
            creationColor={this.state.creationColor}
            cardColor={this.state.cardColor}
            cardShape={this.state.cardShape}
            hasPreferences={this.state.hasPreferences}
            onHasPreferencesChange={value => this.setState({hasPreferences: value})}
            useBackgroundColor={this.state.useBackgroundColor}
            backgroundColor={this.state.backgroundColor}
            onUseBackgroundColorChange={value => this.setState({useBackgroundColor: value})}
            useBackgroundGradient={this.state.useBackgroundGradient}
            onUseBackgroundGradientChange={value => this.setState({useBackgroundGradient: value})}
            subtitleFontSize={this.state.subtitleFontSize}
            subtitleMargin={this.state.subtitleMargin}
            setDefaultStyles={() => this.setState({
              backgroundColor: "rgba(255, 255, 255, 1)",
              useBackgroundGradient: true,
              useBackgroundColor: false,
              subtitleFontSize: 20,
              subtitleMargin: 10,
              titleColor: "rgba(255, 255, 255, 1)",
              descriptionColor: "rgba(255, 255, 255, 1)",
              creationColor: "rgb(0,0,0, 1)",
              cardColor: "rgba(255, 255, 255, 0.4)",
              cardShape: {value: "rounded", label: localization.get('rounded')},
              creationInverted: false,
              showBox: "show",
              showCreationBorder: "show",
              creationCarouselBackgroundColor: "rgba(171, 171, 171, 1)",
              eventsPosition: "top",
              buyButtonPosition: {value: "both", label: localization.get('content_creator.buy_button.both')},
              buyButtonBackgroundColor: "rgb(0,0,0,1)", 
              buyButtonFontColor: "rgb(255,255,255,1)",
            })}
            creationInverted={this.state.creationInverted}
            eventsPosition={this.state.eventsPosition}
            showBox={this.state.showBox}
            showCreationBorder={this.state.showCreationBorder}
            creationCarouselBackgroundColor={this.state.creationCarouselBackgroundColor}
            isEvent={this.state.isEvent}
            eventDates={this.state.eventDates}
            onEventDatesChange={eventDates => this.setState({eventDates: eventDates, eventsUpdated: true})}
            hasBuyButton={this.state.hasBuyButton}
            onHasBuyButtonChange={this.onHasBuyButtonChange}
            buyButtonProduct={this.state.buyButtonProduct}
            selectedBuyButtonProduct={this.state.selectedBuyButtonProduct}
            channel={this.state.channel}
            buyButtonText={this.state.buyButtonText}
            buyButtonShowInSocials={this.state.buyButtonShowInSocials}
            buyButtonPosition={this.state.buyButtonPosition}
            buyButtonBackgroundColor={this.state.buyButtonBackgroundColor}
            buyButtonFontColor={this.state.buyButtonFontColor}
            products={this.state.products}
            hasRegionBlock={this.state.hasRegionBlock}
            onHasRegionBlockChange={hasRegionBlock => {
              if(hasRegionBlock == "false") {
                this.setState({ allowedRegions: [] })
              }
              this.setState({hasRegionBlock})
            }}
            allowedRegions={this.state.allowedRegions}
            onChangeAllowedRegions={allowedRegions => this.setState({allowedRegions})}
            hasEditorialPreferences={this.state.hasEditorialPreferences}
            onHasEditorialPreferencesChange={value => this.setState({hasEditorialPreferences: value})}
            editorialBgColor={this.state.editorialBgColor}
            editorialTitleColor={this.state.editorialTitleColor}
            editorialHideCoverImage={this.state.editorialHideCoverImage}
            viewType={this.state.viewType}
            onChangeViewType={e => this.setState({viewType: e.target.value})}
            viewTypeMobile={this.state.viewTypeMobile}
            onChangeViewTypeMobile={e => this.setState({viewTypeMobile: e.target.value})}
            suggestedViewMultipleRow={this.state.suggestedViewMultipleRow}
            onDriveImageChange={(target, id) => this.setState({ [target]: id })}
            canonicalChannel={this.state.canonicalChannel}
            domainChannels={this.state.domainChannels}
            getDomainChannels={this.getDomainChannels}
            canonicalIsCompleted={this.state.canonicalIsCompleted}
            translations={this.state.translations}
            onChangeTranslation={(value, target, language) => {
              const translations = this.state.translations.map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });

              this.setState({ translations });
            }}
            suggestedContentCreators={this.state.suggestedContentCreators}
            suggestedContentCreatorsChange={creators => this.setState({suggestedContentCreators: creators})}
            contentCreationsOrders={this.state.contentCreationsOrders}
            reorderingCreations={this.state.reorderingCreations}
            requestContentCreations={() => null}
            requestingMaterials={false}
            page={0}
            lastPage={0}
            accessLevel={this.state.accessLevel}
            eventButtonText={this.state.eventButtonText}
            eventButtonColor={this.state.eventButtonColor}
            eventButtonBackgroundColor={this.state.eventButtonBackgroundColor}
            editorialUseSynopsis={this.state.editorialUseSynopsis}
          />
        </FormViewContainer>
      </div>
    )
  }
}
