import { post} from "../utils";

export default host => (id, {
  titleColor,
  titleSize,
  titleFont,
  titleWeight,
  containerTitleColor,
  containerTitleColorHover,
  containerTitleSize,
  containerTitleWeight,
  containerDescriptionColor,
  containerDescriptionColorHover,
  containerDescriptionSize,
  containerDescriptionWeight,
  liveButtonColor,
  liveButtonIcon,
  liveButtonShape,
  radioButtonColor,
  radioButtonIcon,
  radioButtonShape,
  textFont,
  favIcon,
  interactionColor,
  elementBackground,
  showMoreButton,
  mainFont,
  mainColor,
  mainFontColor,
  secondaryColor,
  secondaryFontColor,
  footerColor,
  footerFontColor,
  navbarButtonSize,
  contentCreatorTitleColor,
  contentCreatorDescriptionColor,
  contentCreatorCreationColor,
  contentCreatorCardColor,
  contentCreatorCardShape,
  contentCreatorCreationInverted,
  contentCreatorShowBox,
  contentCreatorCreationCarouselBackgroundColor,
  navbarFontColor,
  editorialHideCoverImage,
  editorialBackgroundColor,
  editorialTitleColor,
  contentCreatorEventsPosition,
  contentCreatorBackgroundColor,
  useContentCreatorBackgroundColor,
  useContentCreatorBackgroundGradient,
  contentCreatorCreationSubtitleFontSize,
  contentCreatorCreationSubtitleMargin,
  contentCreatorBuyButtonPosition,
  contentCreatorBuyButtonBackgroundColor,
  contentCreatorBuyButtonFontColor,
  useCanonicals,
  useBlurOnLogin,
  mobileFontResize,
  favoriteTemplate,
  navbarCenterMode,
  subNavbarFontColor,
  subNavbarBackgroundColor,
  showNavbarMenu,
  sharePosition,
  socialFill,
  contentCreatorSocialPosition,
  contentCreatorShowMoreButton,
  homePadding,
  sidebarFontColor,
  sidebarBackgroundColor,
  sidebarButtonFontColor,
  sidebarButtonBackgroundColor,
  sidebarSelectorFontColor,
  sidebarSelectorBackgroundColor,
  contentCreatorEventButtonColor,
  contentCreatorEventButtonBackgroundColor,
  contentCreatorEventButtonText,
  contentCreatorEditorialUseSynopsis,
  floatingButtonBackgroundColor,
  floatingButtonBorderColor,
  creationHideCreator
}) => {
  return post(`${host}/channels/${id}/preferences`, makeJsonDataPreferences({
      titleColor,
      titleSize,
      titleFont,
      titleWeight,
      containerTitleColor,
      containerTitleColorHover,
      containerTitleSize,
      containerTitleWeight,
      containerDescriptionColor,
      containerDescriptionColorHover,
      containerDescriptionSize,
      containerDescriptionWeight,
      liveButtonColor,
      liveButtonIcon,
      liveButtonShape,
      radioButtonColor,
      radioButtonIcon,
      radioButtonShape,
      textFont,
      favIcon,
      interactionColor,
      elementBackground,
      showMoreButton,
      mainFont,
      mainColor,
      mainFontColor,
      secondaryColor,
      secondaryFontColor,
      footerColor,
      footerFontColor,
      navbarButtonSize,
      contentCreatorTitleColor,
      contentCreatorDescriptionColor,
      contentCreatorCreationColor,
      contentCreatorCardColor,
      contentCreatorCardShape,
      contentCreatorCreationInverted,
      contentCreatorShowBox,
      contentCreatorCreationCarouselBackgroundColor,
      navbarFontColor,
      editorialHideCoverImage,
      editorialBackgroundColor,
      editorialTitleColor,
      contentCreatorEventsPosition,
      contentCreatorBackgroundColor,
      useContentCreatorBackgroundColor,
      useContentCreatorBackgroundGradient,
      contentCreatorCreationSubtitleFontSize,
      contentCreatorCreationSubtitleMargin,
      contentCreatorBuyButtonPosition,
      contentCreatorBuyButtonBackgroundColor,
      contentCreatorBuyButtonFontColor,
      useCanonicals,
      useBlurOnLogin,
      mobileFontResize,
      favoriteTemplate,
      navbarCenterMode,
      subNavbarFontColor,
      subNavbarBackgroundColor,
      showNavbarMenu,
      sharePosition,
      socialFill,
      contentCreatorSocialPosition,
      contentCreatorShowMoreButton,
      homePadding,
      sidebarFontColor,
      sidebarBackgroundColor,
      sidebarButtonFontColor,
      sidebarButtonBackgroundColor,
      sidebarSelectorFontColor,
      sidebarSelectorBackgroundColor,
      contentCreatorEventButtonColor,
      contentCreatorEventButtonBackgroundColor,
      contentCreatorEventButtonText,
      contentCreatorEditorialUseSynopsis,
      floatingButtonBackgroundColor,
      floatingButtonBorderColor,
      creationHideCreator
    }))
    .then(response => response.json())
    .then(response => response.data)
}

const makeJsonDataPreferences = ({
   titleColor,
   titleSize,
   titleFont,
   titleWeight,
   containerTitleColor,
   containerTitleColorHover,
   containerTitleSize,
   containerTitleWeight,
   containerDescriptionColor,
   containerDescriptionColorHover,
   containerDescriptionSize,
   containerDescriptionWeight,
   liveButtonColor,
   liveButtonIcon,
   liveButtonShape,
   radioButtonColor,
   radioButtonIcon,
   radioButtonShape,
   textFont,
   favIcon,
   interactionColor,
   elementBackground,
   showMoreButton,
   mainFont,
   mainColor,
   mainFontColor,
   secondaryColor,
   secondaryFontColor,
   footerColor,
   footerFontColor,
   navbarButtonSize,
   contentCreatorTitleColor,
   contentCreatorDescriptionColor,
   contentCreatorCreationColor,
   contentCreatorCardColor,
   contentCreatorCardShape,
   contentCreatorCreationInverted,
   contentCreatorShowBox,
   contentCreatorCreationCarouselBackgroundColor,
   navbarFontColor,
   editorialBackgroundColor,
   editorialTitleColor,
   contentCreatorEventsPosition,
   contentCreatorBackgroundColor,
   useContentCreatorBackgroundColor,
   useContentCreatorBackgroundGradient,
   contentCreatorCreationSubtitleFontSize,
   contentCreatorCreationSubtitleMargin,
   contentCreatorBuyButtonPosition,
   contentCreatorBuyButtonBackgroundColor,
   contentCreatorBuyButtonFontColor,
   useCanonicals,
   useBlurOnLogin,
   mobileFontResize,
   editorialHideCoverImage,
   favoriteTemplate,
   navbarCenterMode,
   subNavbarFontColor,
   subNavbarBackgroundColor,
   showNavbarMenu,
   sharePosition,
   socialFill,
   contentCreatorSocialPosition,
   contentCreatorShowMoreButton,
   homePadding,
   sidebarFontColor,
   sidebarBackgroundColor,
   sidebarButtonFontColor,
   sidebarButtonBackgroundColor,
   sidebarSelectorFontColor,
   sidebarSelectorBackgroundColor,
   contentCreatorEventButtonColor,
   contentCreatorEventButtonBackgroundColor,
   contentCreatorEventButtonText,
   contentCreatorEditorialUseSynopsis,
   floatingButtonBackgroundColor,
   floatingButtonBorderColor,
   creationHideCreator
 }) => {
  let object = {};

  object.use_canonicals = useCanonicals;
  object.title_color = titleColor;
  object.title_size = titleSize;
  object.title_font = titleFont;
  object.title_weight = titleWeight;
  object.container_title_color = containerTitleColor;
  object.container_title_color_hover = containerTitleColorHover;
  object.container_title_size = containerTitleSize;
  object.container_title_weight = containerTitleWeight;
  object.container_description_color = containerDescriptionColor;
  object.container_description_color_hover = containerDescriptionColorHover;
  object.container_description_size = containerDescriptionSize;
  object.container_description_weight = containerDescriptionWeight;
  object.live_button_color = liveButtonColor;
  object.live_button_icon = liveButtonIcon;
  object.live_button_shape = liveButtonShape;
  object.radio_button_color = radioButtonColor;
  object.radio_button_icon = radioButtonIcon;
  object.radio_button_shape = radioButtonShape;
  object.text_font = textFont;
  object.fav_icon = favIcon;
  object.interaction_color = interactionColor;
  object.element_background = elementBackground;
  object.show_more_button = showMoreButton;
  object.main_font = mainFont;
  object.main_color = mainColor;
  object.main_font_color = mainFontColor;
  object.secondary_color = secondaryColor;
  object.secondary_font_color = secondaryFontColor;
  object.footer_color = footerColor;
  object.footer_font_color =  footerFontColor;
  object.navbar_button_size =  navbarButtonSize;
  object.content_creator_title_color = contentCreatorTitleColor;
  object.content_creator_description_color = contentCreatorDescriptionColor;
  object.content_creator_creation_color = contentCreatorCreationColor;
  object.content_creator_card_color = contentCreatorCardColor;
  object.content_creator_card_shape = contentCreatorCardShape;
  object.content_creator_creation_inverted = contentCreatorCreationInverted;
  object.content_creator_show_box = contentCreatorShowBox;
  object.content_creator_creation_carousel_background_color = contentCreatorCreationCarouselBackgroundColor;
  object.navbar_font_color = navbarFontColor;
  object.editorial_background_color = editorialBackgroundColor;
  object.editorial_title_color = editorialTitleColor;
  object.editorial_hide_cover_image = editorialHideCoverImage;
  object.content_creator_event_position = contentCreatorEventsPosition;
  if (useContentCreatorBackgroundColor && contentCreatorBackgroundColor) {
    object.content_creator_background_color = contentCreatorBackgroundColor;
  } else {
    object.content_creator_background_color = "none";
  }
  object.content_creator_background_gradient = useContentCreatorBackgroundGradient;
  object.content_creator_subtitle_font_size = contentCreatorCreationSubtitleFontSize;
  object.content_creator_subtitle_margin = contentCreatorCreationSubtitleMargin;
  object.content_creator_buy_button_position = contentCreatorBuyButtonPosition;
  object.content_creator_buy_button_bg_color = contentCreatorBuyButtonBackgroundColor;
  object.content_creator_buy_button_font_color = contentCreatorBuyButtonFontColor;
  object.use_blur_on_login = useBlurOnLogin;
  object.mobile_font_resize = Math.round(mobileFontResize);
  object.favorite_template = favoriteTemplate;
  object.navbar_center_mode = navbarCenterMode;
  object.sub_navbar_font_color = subNavbarFontColor;
  object.sub_navbar_background_color = subNavbarBackgroundColor;
  object.navbar_show_menu = showNavbarMenu;
  object.editorial_share_position = sharePosition;
  object.editorial_social_fill = socialFill;
  object.content_creator_social_position = contentCreatorSocialPosition;
  object.content_creator_show_more_button = contentCreatorShowMoreButton;
  object.home_padding = homePadding;
  object.sidebar_font_color = sidebarFontColor;
  object.sidebar_background_color = sidebarBackgroundColor;
  object.sidebar_button_font_color = sidebarButtonFontColor;
  object.sidebar_button_background_color = sidebarButtonBackgroundColor;
  object.sidebar_selector_font_color = sidebarSelectorFontColor;
  object.sidebar_selector_background_color = sidebarSelectorBackgroundColor;
  object.content_creator_event_button_color = contentCreatorEventButtonColor;
  object.content_creator_event_button_background_color = contentCreatorEventButtonBackgroundColor;
  object.content_creator_event_button_text = contentCreatorEventButtonText;
  object.content_creator_editorial_use_synopsis = contentCreatorEditorialUseSynopsis;
  object.floating_button_background_color =  floatingButtonBackgroundColor
  object.floating_button_border_color = floatingButtonBorderColor
  object.creation_hide_creator = creationHideCreator
  

  object._method = "PUT"

  return JSON.stringify(object)
}
