import React from 'react'
import {IconButton, Tooltip} from '@material-ui/core';

const TableButton = ({ title, onClick, children, disabled = false, placement = 'top', style = {} }) => {
  // if the button is disabled, the tooltip will freeze and cause a graphical bug,
  // changing the tooltip with a harmless container will solve this issue
  const Containment = ({children}) => (disabled || !title) ? <span>{children}</span> : <Tooltip placement={placement} title={title}>{children}</Tooltip>
  return (
    <Containment>
      <IconButton onClick={onClick} style={{ width: "35px", height: "35px", opacity: !disabled ? 1 : 0.2, ...style }} disabled={disabled}>
        {children}
      </IconButton>
    </Containment>
  )
}


export default TableButton
