import React, {Component} from "react";
import {request} from "../../../api-client";
import ChannelForm from "../FormContent";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import localization from '../../../config/localization'
import {getUpdateFormImageUrlFromItem, makeCustomImageUrl, makeUrlWithTableParams} from "../../../utils";
import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
import {stateToHTML} from "draft-js-export-html";
import {stateFromHTML} from "draft-js-import-html";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {Dialog, IconButton, Tooltip} from "@material-ui/core";
import HomeGridAndCarouselBase from "../../HomeEditor/HomeGridAndCarouselBase";
import {
  addHttpsToUrl,
  getStoredUser,
  slugify
} from "../../../api-client/core/authentication/utils";
import {Brush, Home, Settings, LiveTv} from "@material-ui/icons";
import browserHistory from "../../../utils/browserHistory";
import AddressDialog from "../../../components/Letflow/AddressInput/AddressDialog";
import ShipmentDialog from "../../../components/Letflow/ShipmentDialog";
import {clearUnwantedStateStyles} from "../../../components/Letflow/RichTextEditor/utils";
import {paths} from "../../../config/channelPaths.json";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";
import { ValidatorForm } from "react-material-ui-form-validator";
import {get} from 'lodash'
import {communityValidationOptions as validationOptions, channelPurposes} from "../utils"
import LiveStreams from "../LiveStreams";


const options = {
  inlineStyles: {
    // Override default element (`strong`).
    BOLD: {element: 'b'},
    // Use a custom inline style. Default element is `span`.
    FONT12: {style: {fontSize: 12}},
    FONT14: {style: {fontSize: 14}},
    FONT16: {style: {fontSize: 16}},
    FONT18: {style: {fontSize: 18}},
    FONT20: {style: {fontSize: 20}},
  },
};

export default class extends Component {
  state = {
    id: "",
    name: "",
    email: "",
    image: {src: null, file: null},
    imageId: "",
    loginImage: {src: null, file: null},
    faviconId: "",
    favicon: { src: null, file: null },
    loginImageId: "",
    footerImage: {src: null, file: null},
    footerImageId: "",
    sidebarImage: {src: null, file: null},
    sidebarImageId: "",
    detail: "",
    type: "content",
    visibility: "public",
    client: "",
    googleAnalyticsId: "",
    googleTagManagerId: "",
    customUrl: "",
    channelContentCreatorTypes: [],
    socialLinks: [],
    catalogs: [],
    disableForm: false,
    showHelper: false,
    mailDialogOpen: false,
    showAdvancedSearch: "none",
    showSupport: false,
    showAbout: "none",
    channelNavbarButtons: [],
    openNavbarDialog: false,
    redirectButton: null,
    tags: [],
    contentCreatorTypes: [],
    navbarElements: [],
    navbarElementsToDelete: [],
    binaryId: "",
    binary: {src: null, file: null},
    hasBinary: false,
    policyId: "",
    policy: {src: null, file: null},
    hasPolicy: false,
    bloomcloudChannel: true,
    loginType: "default",
    language: "es",
    languages: [{language: "es", isMain: true}],
    canDownloadTracks: false,
    openEditDialog: false,
    editingElement: false,
    keywordsSEO: '',
    descriptionSEO: '',
    openDropDownDialog: false,
    dropDownElement: null,
    availableTagGroups: [],
    tagGroups: [],
    openTagGroupDialog: false,
    supportNegativeSearch: "0",
    trackingGroup: null,
    showLoginButton: false,
    showDate: false,
    newElements: [],
    availableChannelPlaylists: [],
    trackingTagGroups: [],
    preRollVideoId: "",
    preRollVideo: { src: null, file: null, status: "not_requested" },
    hasStoredPreRollVideo: false,
    hasFpsCertificate: "0",
    hasExtraInformation: false,
    extraRegistration: "",
    showTagSelection: "0",
    dashboardLoginType: "",
    loginClientId: "",
    loginClientSecret: "",
    dashboardLoginClientId: "",
    dashboardLoginClientSecret: "",
    userCanSeeAuth: false,
    showMercadoPagoForm: false,
    mercadoPagoClientId: "",
    mercadoPagoClientSecret: "",
    showPaypalForm: false,
    paypalClientId: "",
    paypalClientSecret: "",
    showGoogleAdsenseForm: false,
    googleAdsensePublisherId: "",
    showHomeAds: false,
    showContentAds: false,
    adUnitHomeDesktop: "",
    adUnitHomeMobile: "",
    adUnitContentDesktop: "",
    adUnitContentMobile: "",
    sections: [],
    searchType: "single",
    hasEvents: "unactivated",
    channel: null,
    showFilterOnSmartSearch: "active",
    selectedCCTTG: 0,
    notifyEndpoint: "",
    notifySecret: "",
    radioEnabled: false,
    radioLink: "",
    contactMail: "",
    shipmentMethod: "addresses",
    addresses: [],
    channelShipments: [],
    showAddressDialog: false,
    editingAddress: null,
    showShippingDialog: false,
    editingShipping: null,
    showShipmentDialog: false,
    commercialMail: "",
    hasCommunity: false,
    communityCatalog: "",
    communityCCTypes: [],
    communityCanDeleteMaterials: "1",
    customShare: false,
    openEditCategoryDialog: false,
    editingCategory: null,
    useTools: false,
    useCustomRedirects: false,
    channelRedirects: [],
    noIndex: false,
    translations: AvailableLanguages.map(language => ({
      language: language,
      about: new EditorState.createEmpty(),
      welcome: new EditorState.createEmpty(),
      register: new EditorState.createEmpty(),
      registerConfirmed: new EditorState.createEmpty(),
      registerEvaluating: new EditorState.createEmpty(),
      evaluateApproved: new EditorState.createEmpty(),
      regionLimit: new EditorState.createEmpty(),
      loginRedirectText: "",
      globalSearchText: "",
      viewsLimit: "",
      restrictedContentText: '',
      fpsCertificateLimit: '',
      requireDrmText: '',
      loginText: "Login",
      supportText: "",
      boughtCompleteText: "",
      keywordsSEO: "",
      descriptionSEO: "",
      suggestedText: "",
      liveText: "",
      radioText: "",
    })),
    communityTranslations: AvailableLanguages.map(language => ({
      language: language,
      title: "",
      description: "",
      slogan: "",
      successMessage: "",
      pendingMessage: "",
      approvedMessage: "",
      declinedMessage: "",
    })),
    customScripts: "",
    skipTimePreRoll: null,
    subNavbarElement: null,
    subNavbarElements: [],
    parentElement: null,
    footerElements: [],
    openFooterDialog: false,
    footerElementTranslations: AvailableLanguages.map(language => ({
      language: language,
      title: "",
    })),
    dropDownList: [],
    footerType: "simple",
    footerLogoSide: "left",
    footerTranslation: AvailableLanguages.map(language => ({
      language: language,
      title: new EditorState.createEmpty(),
    })),
    footerSocialPosition: "center",
    footerMargin: "default",
    communityRequiresApproval: "not_required",
    communityEmail: "",
    communityPreferences: {
      redirectTo: "home",
      validations : validationOptions.map(val => ({[val]: false}))
    },
    useLicenses: false,
    usePlaylists: false,
    tools: "none",
    hasExtendRegistration: false,
    extendRegistration: {
        birthdate: "none",
        address: "none",
        identityNumber: "none"
      },
    useBrandButton: false,
    isDemo: "0",
    editableFor: "none",
    navbarContentCreatorType: null,
    mobileNavbarType: "default",
    navbarSearchType: "content",
    useSidebarDesktop: "none",
    useFloatingContactButton: false,
    floatingButtonImage: {src: null, file: null},
    floatingButtonImageId: null,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.channelContentCreatorTypes.length !== this.state.channelContentCreatorTypes.length) {
      this.requestTagGroups()
    }
  }

  cleanAdUnit = (adunit) => {
    let cleanedAdUnit = "";
    if (adunit && adunit.length > 0) {
      let insStartIndex = adunit.indexOf('<ins ');
      cleanedAdUnit = adunit.slice(insStartIndex);
      let insEndIndex = cleanedAdUnit.indexOf('>');
      cleanedAdUnit = insEndIndex ? cleanedAdUnit.slice(0, insEndIndex + 1) : "";
      cleanedAdUnit = cleanedAdUnit.concat('<ins/>');
    }
    return cleanedAdUnit;
  }

  requestChannel = () =>
    request.channel.getForDashboard(this.props.match.params.id,
      'include=channelRedirects;sections;paymentGateways;preRollVideo;image;client.permissions;channelContentCreatorTypes.translations;channelContentCreatorTypes.contentCreatorType;channelContentCreatorTypes.tagGroup;tagGroups;tagGroups.tags;catalogs;translations;socialLinks;loginImage;footerImage;faviconImage;tags;binary;policy;domain;tagGroups.types.contentCreatorType;trackingGroup.types.contentCreatorType;addresses;channelShipments.prices;community;community.catalog;community.contentCreatorTypes;community.translations;adUnits;languages;navbarContentCreatorType;sidebarImage;floatingButtonImage&isDashboard=true')
      .then(channel => {
        let usersIds = process.env.REACT_APP_WHITELIST_USER_EXTRA_CONFIG;
        usersIds = usersIds && usersIds.split(",").map(i => Number(i));

        const communityTranslations = AvailableLanguages.map(language => {
          const translation = channel.community && channel.community.translations.find(tr => tr.locale === language)
          
          if (translation) {
            const termsOfUse = translation.terms_of_use ? (translation.terms_of_use.startsWith('<') ? 
              stateFromHTML(translation.terms_of_use) : convertFromRaw(JSON.parse(translation.terms_of_use)))
              : new EditorState.createEmpty();

            return {
            ...translation,
            language: language,
            successMessage: translation.success_message,
            pendingMessage: translation.pending_message,
            approvedMessage: translation.approved_message,
            declinedMessage: translation.declined_message,
            termsOfUse: translation.terms_of_use ? EditorState.createWithContent(termsOfUse) : new EditorState.createEmpty(),
          }
        }

          return {
            language: language,
            title: "",
            description: "",
            slogan: "",
            successMessage: "",
            pendingMessage: "",
            approvedMessage: "",
            declinedMessage: "",
            termsOfUse: new EditorState.createEmpty(),
          }
        });

        const translations = AvailableLanguages.map(language => {
          const translation = channel.translations.find(tr => tr.locale === language)

          if (translation) {
            const about = translation.about ?
              (translation.about.startsWith('<') ? stateFromHTML(translation.about) : convertFromRaw(JSON.parse(translation.about)))
              : new EditorState.createEmpty();
            const regionLimit = translation.region_blocked_text ?
              (translation.region_blocked_text.startsWith('<') ? stateFromHTML(translation.region_blocked_text) : convertFromRaw(JSON.parse(translation.region_blocked_text)))
              : new EditorState.createEmpty();
            const welcome = translation.welcome && stateFromHTML(translation.welcome);
            const register = translation.register && stateFromHTML(translation.register);
            const registerEvaluating = translation.register_evaluating && stateFromHTML(translation.register_evaluating);
            const registerConfirmed = translation.register_confirmed && stateFromHTML(translation.register_confirmed);
            const evaluateApproved = translation.evaluate_approved && stateFromHTML(translation.evaluate_approved);


            return {
              language: language,
              about: translation.about ? EditorState.createWithContent(about) : new EditorState.createEmpty(),
              welcome: translation.welcome ? EditorState.createWithContent(welcome) : new EditorState.createEmpty(),
              register: translation.register? EditorState.createWithContent(register) : new EditorState.createEmpty(),
              registerConfirmed: translation.register_confirmed? EditorState.createWithContent(registerConfirmed) : new EditorState.createEmpty(),
              registerEvaluating: translation.register_evaluating? EditorState.createWithContent(registerEvaluating) : new EditorState.createEmpty(),
              evaluateApproved: translation.evaluate_approved ? EditorState.createWithContent(evaluateApproved) : new EditorState.createEmpty(),
              regionLimit: translation.region_blocked_text ? EditorState.createWithContent(regionLimit) : new EditorState.createEmpty(),
              loginRedirectText: translation.login_redirect_text,
              globalSearchText: translation.global_search_text,
              login: translation.login_text,
              viewsLimit: translation.views_limit,
              restrictedContentText: translation.restricted_content_yext,
              fpsCertificateLimit: translation.fps_certificate_limit,
              requireDrmText: translation.require_drm_text,
              supportText: translation.support_text,
              boughtCompleteText: translation.bought_complete_text,
              keywordsSEO: translation.seo_keywords,
              descriptionSEO: translation.seo_description,
              suggestedText: translation.suggested_text,
              liveText: translation.live_text,
              radioText: translation.radio_text,
            };
          }

          return {
            language: language,
            about: new EditorState.createEmpty(),
            welcome: new EditorState.createEmpty(),
            register: new EditorState.createEmpty(),
            registerConfirmed: new EditorState.createEmpty(),
            registerEvaluating: new EditorState.createEmpty(),
            evaluateApproved: new EditorState.createEmpty(),
            regionLimit: new EditorState.createEmpty(),
            loginRedirectText: "",
            globalSearchText: "",
            login: "",
            viewsLimit: "",
            restrictedContentText: "",
            fpsCertificateLimit: "",
            requireDrmText: "",
            supportText: "",
            boughtCompleteText: "",
            keywordsSEO: "",
            descriptionSEO: "",
            suggestedText: "",
            liveText: "",
            radioText: "",
          };
        })

        const footerTranslations = AvailableLanguages.map(language => {
          const translation = get(channel, "footer_preferences.translations", []).find(tr => tr.locale === language)

          if (translation && !!translation.title) { 
            return {
              ...translation,
              language: language,
              title: EditorState.createWithContent(convertFromRaw(JSON.parse(translation.title))),
            }
          }

          return {
            language: language,
            title: new EditorState.createEmpty(),
          }
        });

        let adUnits = channel.adUnits;
        let homeAdUnit = adUnits && adUnits.find(adUnit => adUnit.purpose === 'home');
        homeAdUnit = homeAdUnit ? homeAdUnit.ad_unit : "";
        let homeMobileAdUnit = adUnits && adUnits.find(adUnit => adUnit.purpose === 'home-mobile');
        homeMobileAdUnit = homeMobileAdUnit ? homeMobileAdUnit.ad_unit : "";
        let contentAdUnit = adUnits && adUnits.find(adUnit => adUnit.purpose === 'content');
        contentAdUnit = contentAdUnit ? contentAdUnit.ad_unit : "";
        let contentMobileAdUnit = adUnits && adUnits.find(adUnit => adUnit.purpose === 'content-mobile');
        contentMobileAdUnit = contentMobileAdUnit ? contentMobileAdUnit.ad_unit : "";
        const extendRegistration = JSON.parse(get(channel, "extend_registration", null));

        this.setState({
          channel: channel,
          id: channel.id,
          name: channel.name,
          email: channel.email,
          type: channel.type,
          customUrl: channel.domain ? channel.domain.url : "",
          googleAnalyticsId: channel.google_analytics_id,
          googleTagManagerId: channel.google_tag_manager_id,
          loginType: channel.login_type,
          showLoginButton: channel.show_login_button,
          showDate: channel.show_date, 
          showTagSelection: channel.show_tag_selection ? "1" : "0",
          showMercadoPagoForm: channel.paymentGateways && !!channel.paymentGateways.find(paymentGateway => paymentGateway.gateway === "mercadopago"),
          mercadoPagoClientId: (channel.paymentGateways && !!channel.paymentGateways.find(paymentGateway => paymentGateway.gateway === "mercadopago")) ? channel.paymentGateways.find(paymentGateway => paymentGateway.gateway === "mercadopago").provider_key : "",
          showPaypalForm: channel.paymentGateways && !!channel.paymentGateways.find(paymentGateway => paymentGateway.gateway === "paypal"),
          paypalClientId: (channel.paymentGateways && !!channel.paymentGateways.find(paymentGateway => paymentGateway.gateway === "paypal")) ? channel.paymentGateways.find(paymentGateway => paymentGateway.gateway === "paypal").provider_key : "",
          notifyEndpoint: "",
          notifySecret: "",
          showGoogleAdsenseForm: !!channel.google_publisher_id,
          googleAdsensePublisherId: channel.google_publisher_id || "",
          showHomeAds: !!(homeAdUnit || homeMobileAdUnit),
          showContentAds: !!(contentAdUnit || contentMobileAdUnit),
          adUnitHomeDesktop: homeAdUnit,
          adUnitHomeMobile: homeMobileAdUnit,
          adUnitContentDesktop: contentAdUnit,
          adUnitContentMobile: contentMobileAdUnit,
          image: channel.image && {
            id: channel.image.id,
            src: getUpdateFormImageUrlFromItem(channel),
            file: null
          },
          loginImage: channel.loginImage && {
            id: channel.loginImage.id,
            src: makeCustomImageUrl(channel.loginImage),
            file: null
          },
          footerImage: channel.footerImage && {
            id: channel.footerImage.id,
            src: makeCustomImageUrl(channel.footerImage),
            file: null
          },
          sidebarImage: channel.sidebarImage && {
            id: channel.sidebarImage.id,
            src: makeCustomImageUrl(channel.sidebarImage),
            file: null
          },
          favicon: channel.faviconImage && {
            id: channel.faviconImage.id,
            src: channel.faviconImage.url,
            file: null
          },
          preRollVideo: channel.preRollVideo
            ? { src: channel.preRollVideo.url, file: null, filename: channel.preRollVideo.original_name, status: channel.preRollVideo.job_status}
            : { src: null, file: null, status: "not_requested" },
          hasStoredPreRollVideo: channel.preRollVideo != null,
          client: channel.client,
          detail: channel.detail,
          visibility: channel.visibility,
          catalogs: channel.catalogs.length > 0 ? channel.catalogs.map(catalog => ({
            value: catalog.id,
            label: catalog.name
          })) : [],
          socialLinks: channel.socialLinks,
          channelContentCreatorTypes: channel.channelContentCreatorTypes.length > 0 ?
            channel.channelContentCreatorTypes.filter(channelContentCreatorType => !!channelContentCreatorType.contentCreatorType)
              .map(channelContentCreatorType => ({
                content_creator_type_id: channelContentCreatorType.contentCreatorType.id,
                name: channelContentCreatorType.contentCreatorType.name,
                show_on_global_search: !!channelContentCreatorType.show_on_global_search,
                show_on_smart_search: !!channelContentCreatorType.show_on_smart_search,
                view_type: channelContentCreatorType.view_type,
                creation_view_type: channelContentCreatorType.creation_view_type,
                contact_email: channelContentCreatorType.contact_email,
                show_tag_selection: channelContentCreatorType.show_tag_selection,
                show_filter_on_smart_search: channelContentCreatorType.show_filter_on_smart_search,
                show_search_input_on_smart_search: channelContentCreatorType.show_search_input_on_smart_search,
                showFilterOnSmartSearch: channelContentCreatorType.show_filter_on_smart_search ? "1" : "0",
                showSearchInputOnSmartSearch: channelContentCreatorType.show_search_input_on_smart_search  ? "1" : "0",
                showTagSelection: channelContentCreatorType.show_tag_selection ? "1" : "0",
                support_negative_search: channelContentCreatorType.support_negative_search,
                support_publish_search: channelContentCreatorType.support_publish_search,
                has_multiple_tags_allowed: channelContentCreatorType.has_multiple_tags_allowed,
                allow_content_search: channelContentCreatorType.allow_content_search,
                allow_creation_search: channelContentCreatorType.allow_creation_search,
                allow_event_search: channelContentCreatorType.allow_event_search,
                allow_tag_search: channelContentCreatorType.allow_tag_search,
                tracking_tag_group_id: channelContentCreatorType.tracking_tag_group_id,
                viewType: channelContentCreatorType.view_type,
                searchType: (!channelContentCreatorType.support_negative_search && !channelContentCreatorType.has_multiple_tags_allowed) ? "single" : (!!channelContentCreatorType.has_multiple_tags_allowed ? "multi" : "negative"),
                material_image_id: channelContentCreatorType.material_image_id || null,
                content_image_id: channelContentCreatorType.content_image_id || null,
                event_image_id: channelContentCreatorType.event_image_id || null,
                translations: channelContentCreatorType.translations,
                creator_view_type: channelContentCreatorType.creator_view_type,
                creator_view_type_mobile: channelContentCreatorType.creator_view_type_mobile,
                restricted_content_search: channelContentCreatorType.restricted_content_search ? "1": "0",
                restricted_material_search: channelContentCreatorType.restricted_material_search ? "1": "0",
                sidebar_view: !!channelContentCreatorType.sidebar_view,
                content_sidebar_view: !!channelContentCreatorType.content_sidebar_view,
                tagGroup: {
                  value: channelContentCreatorType.tagGroup && channelContentCreatorType.tagGroup.id,
                  label: channelContentCreatorType.tagGroup && channelContentCreatorType.tagGroup.name,
                },
                contentCreatorType: {
                  id: channelContentCreatorType.contentCreatorType.id,
                  value: channelContentCreatorType.contentCreatorType.id,
                  label: channelContentCreatorType.contentCreatorType.name,
                  slug: channelContentCreatorType.contentCreatorType.slug,
                  isTalent: channelContentCreatorType.contentCreatorType.is_talent,
                  allowVideo: channelContentCreatorType.contentCreatorType.allow_video,
                  allowAudio: channelContentCreatorType.contentCreatorType.allow_audio,
                  type: channelContentCreatorType.contentCreatorType.type,
                },
                preferences: channelContentCreatorType.preferences,
              })) : [],
          availableContentCreatorTypes: [],
          disableForm: false,
          showAdvancedSearch: channel.show_advanced_search,
          showTagsSearch: channel.show_tags_search,
          showSupport: channel.show_support,
          links: channel.links,
          tags: channel.tags ? channel.tags.map(tag => ({value: tag.id, label: tag.name})) : [],
          showAbout: channel.show_about,
          hasBinary: channel.binary && !!channel.binary.id,
          hasPolicy: channel.policy && !!channel.policy.id,
          bloomcloudChannel: channel.bloomcloud_channel,
          language: channel.language,
          languages: channel.languages.map(lang => ({lang: lang.language, isMain: lang.language === channel.language})),
          canDownloadTracks: channel.can_download_tracks,
          tagGroups: channel.tagGroups || [],
          supportNegativeSearch: channel.support_negative_search ? "1" : "0",
          hasFpsCertificate: channel.has_fps_certificate ? "1" : "0",
          trackingGroup: channel.trackingGroup ? {value: channel.trackingGroup.id, label: `${channel.trackingGroup.name} ${channel.trackingGroup.types.length && `- ${channel.trackingGroup.types[0].contentCreatorType.name}`}`} : null,
          hasExtraInformation: !!channel.extra_registration, 
          extraRegistration: channel.extra_registration,
          dashboardLoginType: channel.dashboard_login_type,
          loginClientId: channel.login_client_id,
          dashboardLoginClientId: channel.dashboard_login_client_id,
          loginClientSecret: "",
          dashboardLoginClientSecret: "",
          userCanSeeAuth: !!usersIds.filter(id => id === getStoredUser().id),
          sections: channel.sections.map(section => ({value: section.id, label: section.name, main: section.main})) || [],
          searchType: channel.support_negative_search ? "negative" : channel.has_multiple_tags_allowed ? "multi" : "single",
          hasEvents: channel.has_events ? "active" : "unactivated",
          showFilterOnSmartSearch: channel.show_filter_on_smart_search ? "active" : "unactivated",
          radioLink: channel.radio_link,
          radioEnabled: channel.radio_enabled == "1",
          addresses: channel.addresses,
          channelShipments: channel.channelShipments,
          commercialMail: channel.commercial_mail,
          noIndex: channel.no_index,
          customScripts: channel.scripts,
          skipTimePreRoll: channel.skip_time_pre_roll,
          channelRedirects: channel.channelRedirects && channel.channelRedirects.length > 0 ?
            channel.channelRedirects.map(redirect => ({
              id: redirect.id,
              targetPath: redirect.target_path,
              redirectTo: redirect.redirect_to,
              type: redirect.home_element_id ? 'search' : redirect.is_permanent ? 'permanent' : 'link',
              home_element_id: redirect.home_element_id,
            })) : [],
          useCustomRedirects: !!(channel.channelRedirects && channel.channelRedirects.length > 0),
          communityCatalog: channel.community && channel.community.catalog && {
            value: channel.community.catalog.id,
            label: channel.community.catalog.name
          },
          communityCCTypes: (channel.community && channel.community.contentCreatorTypes.length > 0) ? channel.community.contentCreatorTypes.map(ccType => ({
            id: ccType.id,
            name: ccType.name,
            value: ccType.id,
            label: ccType.name,
            slug: ccType.slug,
            isTalent: ccType.is_talent,
            allowVideo: ccType.allow_video,
            allowAudio: ccType.allow_audio,
            type: ccType.type,
          })) : [],
          communityCanDeleteMaterials: !!get(channel, "community.can_delete_materials") ? "1" : "0",
          hasCommunity: !!channel.community,
          customShare: channel.custom_share,
          useTools: channel.use_tools,
          translations: translations,
          communityTranslations: communityTranslations,
          communityRequiresApproval: get(channel, "community.requires_approval", "not_required"),
          hasExtraTermsOfUse: communityTranslations.some(t => t.termsOfUse.getCurrentContent().hasText()),
          communityPreferences: {
            redirectTo: get(channel, "community.preferences.redirect_to", "home"),
            validations: get(channel, "community.preferences.validations", [])
          },
          communityEmail: get(channel, "community.email", this.state.communityEmail),
          isDemo: channel.demo ? "1" : "0",
          footerType: get(channel, "footer_preferences.type", "simple"),
          footerLogoSide: get(channel, "footer_preferences.logo_side", "left"),
          footerTranslations: footerTranslations,
          footerSocialPosition: get(channel, "footer_preferences.social_position", "center"),
          footerMargin: get(channel, "footer_preferences.margin", "default"),
          useLicenses: !!channel.use_licenses,
          usePlaylists: !!channel.use_playlists,
          tools: channel.use_tools ? "tools" : (channel.use_licenses||channel.use_playlists) ? "musicTools" : "none",
          hasExtendRegistration: channel.extend_registration,
          extendRegistration: {
            address: get(extendRegistration, "address", "none"),
            birthdate: get(extendRegistration, "birthdate", "none"),
            identityNumber: get(extendRegistration, "identity_number", "none"),
          },
          useBrandButton: !!channel.use_brand_button,
          editableFor: channel.editable_for,
          purpose: channel.purpose ? channelPurposes.find(p => p.value == channel.purpose) : null,
          navbarContentCreatorType: channel.navbarContentCreatorType && {value: channel.navbarContentCreatorType.id, label: channel.navbarContentCreatorType.name, slug: channel.navbarContentCreatorType.slug},
          mobileNavbarType: channel.navbar_tabs ? "tabs" : "default",
          navbarSearchType: channel.navbar_search_type,
          useSidebarDesktop: channel.use_sidebar_desktop ? "sidebar" : "none", 
          useFloatingContactButton: channel.use_floating_button,
          floatingButtonImage: channel.floatingButtonImage && {
            id: channel.floatingButtonImage.id,
            src: makeCustomImageUrl(channel.floatingButtonImage),
            file: null
          },
        }, () => {
          if (channel.allow_music) {
            let channelContentCreatorTypes = [
              {
                content_creator_type_id: "music",
                name: localization.get('music'),
                show_on_global_search: !!channel.show_on_global_search,
                show_on_smart_search: !!channel.show_on_smart_search
              }].concat(this.state.channelContentCreatorTypes)
            this.setState({channelContentCreatorTypes})
          }
        });
      })
      .then(() => this.requestNavbarElements())
      .then(() => this.requestCCTypes())
      .then(() => this.requestTagGroups())
      .then(() => this.requestChannelPlaylist())

  requestChannelCreation = () => {
    let navbarElements = this.state.navbarElements;
    if (this.state.subNavbarElement && this.state.subNavbarElements.length) {
      //remove subnavbar elements for adding ordered later
      navbarElements = navbarElements.filter(e => e.parent !== this.state.subNavbarElement.id)
      navbarElements = navbarElements.concat(this.state.subNavbarElements);
      navbarElements.push(this.state.subNavbarElement);
    }

    const navbarElementsToDelete = this.state.navbarElementsToDelete.concat(this.state.navbarElements
          .filter(element => !element.visible && !this.state.channelRedirects.some(cr => cr.home_element_id === element.id))
          .map(e => e.id))

    return request.channel
      .update(this.props.match.params.id, {
        ...this.state,
        name: this.state.name,
        email: this.state.email,
        imageId: this.state.imageId,
        loginImageId: this.state.loginImageId,
        footerImageId: this.state.footerImageId,
        sidebarImageId: this.state.sidebarImageId,
        faviconId: this.state.faviconId,
        detail: this.state.detail,
        visibility: this.state.visibility,
        type: this.state.type,
        googleAnalyticsId: this.state.googleAnalyticsId,
        googleTagManagerId: this.state.googleTagManagerId,
        customUrl: this.state.customUrl,
        socialLinks: this.state.socialLinks,
        catalogs: this.state.catalogs,
        channelContentCreatorTypes: this.state.channelContentCreatorTypes,
        showAdvancedSearch: this.state.showAdvancedSearch,
        showTagsSearch: this.state.showTagsSearch,
        showSupport: this.state.showSupport,
        links: this.state.links,
        tags: this.state.tags,
        navbarElements: navbarElements.concat(this.state.footerElements),
        navbarElementsToDelete: navbarElementsToDelete,
        binaryId: this.state.binaryId,
        policyId: this.state.policyId,
        bloomcloudChannel: this.state.bloomcloudChannel,
        loginType: this.state.loginType,
        languages: this.state.languages,
        canDownloadTracks: this.state.canDownloadTracks,
        keywordsSEO: this.state.keywordsSEO,
        descriptionSEO: this.state.descriptionSEO,
        supportNegativeSearch: this.state.supportNegativeSearch,
        tagGroups: this.state.tagGroups,
        trackingGroup: this.state.trackingGroup,
        showLoginButton: this.state.showLoginButton,
        showDate: this.state.showDate,
        preRollVideoId: this.state.preRollVideoId,
        extraRegistration: this.state.extraRegistration,
        showTagSelection: this.state.showTagSelection,
        loginClientId: this.state.loginClientId,
        loginClientSecret: this.state.loginClientSecret,
        dashboardLoginClientId: this.state.dashboardLoginClientId,
        dashboardLoginClientSecret: this.state.dashboardLoginClientSecret,
        dashboardLoginType: this.state.dashboardLoginType,
        mercadoPagoClientId: this.state.mercadoPagoClientId,
        mercadoPagoClientSecret: this.state.mercadoPagoClientSecret,
        paypalClientId: this.state.paypalClientId,
        paypalClientSecret: this.state.paypalClientSecret,
        googleAdsensePublisherId: this.state.googleAdsensePublisherId,
        adUnitHomeDesktop: this.cleanAdUnit(this.state.adUnitHomeDesktop),
        adUnitHomeMobile: this.cleanAdUnit(this.state.adUnitHomeMobile),
        adUnitContentDesktop: this.cleanAdUnit(this.state.adUnitContentDesktop),
        adUnitContentMobile: this.cleanAdUnit(this.state.adUnitContentMobile),
        searchType: this.state.searchType,
        hasEvents: this.state.hasEvents,
        showFilterOnSmartSearch: this.state.showFilterOnSmartSearch,
        notifyEndpoint: this.state.notifyEndpoint,
        notifySecret: this.state.notifySecret,
        radioEnabled: this.state.radioEnabled,
        radioLink: this.state.radioLink,
        addresses: this.state.addresses,
        channelShipments: this.state.channelShipments,
        commercialMail: this.state.commercialMail,
        customShare: this.state.customShare,
        useTools: this.state.useTools,
        channelRedirects: this.state.channelRedirects,
        noIndex: this.state.noIndex,
        translations: this.state.translations.map(translation => ({
          language: translation.language,
          about: translation.about && stateToHTML(translation.about.getCurrentContent()) === '<p><br></p>' ? "" : JSON.stringify(clearUnwantedStateStyles(convertToRaw(translation.about.getCurrentContent()))),
          welcome: translation.welcome && stateToHTML(translation.welcome.getCurrentContent(), options),
          register: translation.welcome && stateToHTML(translation.register.getCurrentContent(), options),
          registerConfirmed: translation.welcome && stateToHTML(translation.registerConfirmed.getCurrentContent(), options),
          registerEvaluating: translation.welcome && stateToHTML(translation.registerEvaluating.getCurrentContent(), options),
          evaluateApproved: translation.evaluateApproved && stateToHTML(translation.evaluateApproved.getCurrentContent(), options),
          regionLimit: translation.regionLimit && stateToHTML(translation.regionLimit.getCurrentContent(), options),
          loginRedirectText: translation.loginRedirectText,
          globalSearchText: translation.globalSearchText,
          login: translation.loginText,
          viewsLimit: translation.viewsLimit,
          restrictedContentText: translation.restrictedContentText,
          fpsCertificateLimit: translation.fpsCertificateLimit,
          requireDrmText: translation.requireDrmText,
          supportText: translation.supportText,
          boughtCompleteText: translation.boughtCompleteText,
          keywordsSEO: translation.keywordsSEO,
          descriptionSEO: translation.descriptionSEO,
          suggestedText: translation.suggestedText,
          liveText: translation.liveText,
          radioText: translation.radioText,
        })),
        communityTranslations: this.state.communityTranslations.map(translation => ({
          ...translation,
          termsOfUse: translation.termsOfUse && stateToHTML(translation.termsOfUse.getCurrentContent(), options),
        })),
        customScripts: this.state.customScripts,
        communityCatalog: this.state.hasCommunity && this.state.communityCatalog,
        communityCCTypes: this.state.hasCommunity && this.state.communityCCTypes,
        communityCanDeleteMaterials: this.state.communityCanDeleteMaterials,
        hasCommunity: this.state.hasCommunity,
        isDemo: this.state.isDemo,
        skipTimePreRoll: this.state.skipTimePreRoll,
        footerType: this.state.footerType,
        footerLogoSide: this.state.footerLogoSide,
        footerSocialPosition: this.state.footerSocialPosition,
        footerMargin: this.state.footerMargin,
        footerTranslations: this.state.footerTranslations.map(tr => ({
          ...tr,
          title: (tr.title && stateToHTML(tr.title.getCurrentContent()) === '<p><br></p>') ? "" : JSON.stringify(clearUnwantedStateStyles(convertToRaw(tr.title.getCurrentContent())))
        })),
        communityRequiresApproval: this.state.communityRequiresApproval,
        communityPreferences: this.state.communityPreferences,
        communityEmail: this.state.communityEmail,
        useLicenses: this.state.useLicenses,
        usePlaylists: this.state.usePlaylists,
        useBrandButton: this.state.useBrandButton,
        editableFor: this.state.editableFor,
        purpose: this.state.purpose,
        navbarContentCreatorTypeId: (!this.state.hasCommunity && this.state.tools == "none" && this.state.navbarContentCreatorType && this.state.mobileNavbarType == "tabs") ? this.state.navbarContentCreatorType.value : null,
        navbarSearchType: this.state.navbarSearchType,
        mobileNavbarType: this.state.mobileNavbarType == "tabs",
        useSidebarDesktop: this.state.useSidebarDesktop == "sidebar",
        useFloatingContactButton: this.state.email && this.state.useFloatingContactButton,
        floatingButtonImageId: this.state.floatingButtonImageId,
      })
      .then(() => this.props.history.push(makeUrlWithTableParams("/panel/channels")));
  }

  cleanDuplicateTagGroups = tagGroups => {
    let unique = [];
    tagGroups.forEach(tg => {if(unique.find(u => u.id == tg.id) == null) unique.push(tg)});
    return unique;
  }

  onChange = e => {
    let {target} = e;
    this.setState({[target.id]: target.type === 'checkbox' ? target.checked : target.value});
  };

  requestTagGroups = () =>  request.tagGroup.getAll({filterBy: [
      { column: 'types', filter: `${this.state.channelContentCreatorTypes.map(type => type.contentCreatorType.slug)}`},
      // { column: 'creation_group', filter: '0'},
      { column: 'show_on_smart_search', filter: '1'},
    ]}, 'include=tagGroups')
    .then(tagGroups => this.setState({ availableTagGroups: tagGroups.filter(tagGroup => tagGroup.tags.length).map(tagGroup => ({...tagGroup, view_type: "pill"})) }))

  requestCCTypes = () => {
    request.contentCreatorType.getAll({
      filterBy: [
        {column: "private_client_id", filter: this.state.client.id},
        {column: "visibility", filter: 'private'}
      ]
    })
      .then(contentCreatorTypes => contentCreatorTypes.sort((a, b) => a.name.localeCompare(b.name)))
      .then(cCTypes => this.setState({contentCreatorTypes: cCTypes}))
  }

  requestNavbarElements = (editingElement = false) => {
    request.channel.getElementsMetadata(this.props.match.params.id, false, true, true)
      .then(res => {
        let navbarElements = res.filter(element => element.type == "navbar");
        let footerElements = res.filter(element => element.type == "footer");
        
        let subNavbarElements = [];

        this.state.newElements.forEach(element => {
          if (editingElement && editingElement.id == element.id) {
            element = editingElement
          }
          navbarElements.push(element)
        })

        const subNavbarElement = navbarElements.find(element => element.data_source === "sub_navbar");
        navbarElements = navbarElements.filter(element => !this.state.navbarElementsToDelete.includes(element.id) && element.data_source !== "sub_navbar");

        if (subNavbarElement) {
          subNavbarElements = navbarElements.filter(element => element.parent === subNavbarElement.id);
        }

        this.setState({navbarElements: navbarElements, subNavbarElement: subNavbarElement, subNavbarElements: subNavbarElements, footerElements})
      })
  }


  onPreRollVideoChange = video => video ? this.setState({preRollVideoId: video.id, preRollVideo: {src: null, status: "not_requested"}, preRollVideoPreview: this.formNewUrl(video.path)}) : this.setState({preRollVideoId: null, preRollVideo: {src: null, status: "not_requested"}})
  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})
  onLoginImageChange = image => image ? this.setState({loginImageId: image.id, loginImage: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({loginImageId: null, loginImage: { src: null, filename: null }})
  onFooterImageChange = image => image ? this.setState({footerImageId: image.id, footerImage: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({footerImageId: null, footerImage: { src: null, filename: null }})
  onSidebarImageChange = image => image ? this.setState({sidebarImageId: image.id, sidebarImage: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({sidebarImageId: null, sidebarImage: { src: null, filename: null }})
  onFloatingButtonImageChange = image => image ? this.setState({floatingButtonImageId: image.id, floatingButtonImage: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({floatingButtonImageId: null, floatingButtonImage: { src: null, filename: null }})
  onFaviconChange = image => image ? this.setState({faviconId: image.id, favicon: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({faviconId: null, favicon: { src: null, filename: null }})
  onBinaryChange = binary => binary ? this.setState({binaryId: binary.id, binary: {src: null, filename: null }}) : this.setState({binaryId: null, binary: { src: null, filename: null }})
  onPolicyChange = policy => policy ? this.setState({policyId: policy.id, policy: {src: null, filename: null }}) : this.setState({policyId: null, policy: { src: null, filename: null }})

  formNewUrl = (path) => {
    return process.env.REACT_APP_CDN_HOST.concat('/' + path)
  }

  setPreview = (type) => {
    if (type === 'video') {
      this.setState({preRollVideo: { src: this.state.preRollVideoPreview, filename: null, status: "not_requested"}})
    } else if (type === 'image') {
      let item = {image: {id: this.state.imageId, src: this.state.imagePreview}}
      this.setState({image : {src: getUpdateFormImageUrlFromItem(item)}})
    }
  }

  onSubmitValidation = () => {
    if(this.state.openNavbarDialog) {
      return false
    }else if(this.state.catalogs.length === 0) {
      GlobalSnackbar.show({message: localization.get('form.catalog_required'), type: GlobalSnackbarTypes.ERROR});
      return false
    }else if(this.state.channelRedirects.length > 0) {
      let allowed = true;

      this.state.channelRedirects.forEach(link => {
          paths.forEach(path => {
            if (link.type != "permanent" && (!link.targetPath || link.targetPath.startsWith(path))) {
              allowed = false;
              link.error = true;
            }

          this.setState({channelRedirects: this.state.channelRedirects})
        })
      })

      if (!allowed) {
        GlobalSnackbar.show({
          message: localization.get('form.custom_url_check'),
          type: GlobalSnackbarTypes.ERROR
        });
      }else {
        const valueArr = this.state.channelRedirects.map(function(item){ return item.targetPath });
        const isDuplicate = valueArr.some(function(item, idx){
          return valueArr.indexOf(item) != idx
        });

        if (isDuplicate ){
          GlobalSnackbar.show({
            message: localization.get('form.custom_url_check_repeated'),
            type: GlobalSnackbarTypes.ERROR
          });

          return false;
        }
      }
      return allowed
    }else if(this.state.isDemo === "1" && this.state.customUrl && !this.state.customUrl.endsWith('feater.me')) {
        GlobalSnackbar.show({
          message: localization.get('form.validate_domain_demo'),
          type: GlobalSnackbarTypes.ERROR
        });

        return false;
    }

    if(!this.state.communityEmail && this.state.hasCommunity && this.state.communityRequiresApproval != "not_required") {
      GlobalSnackbar.show({
        message: localization.get('form.community_email_required'),
        type: GlobalSnackbarTypes.ERROR
      });

      return false
    }

    return true
  }

  addElement = element => {
    if(this.state.parentElement && this.state.parentElement.data_source === "sub_navbar") {
      this.state.subNavbarElements.push(element)
    }else if(this.state.parentElement && this.state.parentElement.type === "footer") {
      this.state.footerElements.push(element);
      return this.setState({footerElements: this.state.footerElements })
    }else{
      this.state.newElements.push(element)
      this.state.navbarElements.push(element)

      if (this.state.redirectButton) {
        return this.setState({
          navbarElements: this.state.navbarElements,
          newElements: this.state.newElements,
          channelRedirects: this.state.channelRedirects.map(redirectElement => {
            if (redirectElement.id === this.state.redirectButton) {
              return {
                ...redirectElement,
                home_element_id: element.id,
                redirectTo: `${addHttpsToUrl(this.state.customUrl)}/browse/${element.id}-search`
              }
            }

            return redirectElement;
          })
        })
      }
    }

    return this.setState({
      navbarElements: this.state.navbarElements,
      newElements: this.state.newElements,
      subNavbarElements: this.state.subNavbarElements,
      parentElement: this.state.openDropDownDialog ? this.state.parentElement : null
    })
  }

  removeNavbarElement = (elementId) => {
    this.state.navbarElementsToDelete.push(elementId)
    return this.setState({
      navbarElements: this.state.navbarElements.filter(element => element.id != elementId),
      footerElements: this.state.footerElements.filter(element => element.id != elementId),
      subNavbarElements: this.state.subNavbarElements.filter(element => element.id != elementId),
      navbarElementsToDelete: this.state.navbarElementsToDelete
    })
  }

  requestChannelPlaylist = () =>
    request.playlist.getAll(
      {
        filterBy: [
          {column: "channel_lists", filter: true},
          {column: 'channel_id', filter: this.props.match.params.id}
        ]
      }, true)
      .then(res =>
        this.setState({
          availableChannelPlaylists: res.map(playlist => ({value: playlist.id, label: playlist.title}))
        }))

  openCloseEditDropDownDialog = (dropDownElement= null, dropDownList = []) => this.setState({openDropDownDialog: !this.state.openDropDownDialog, dropDownElement: dropDownElement, parentElement:dropDownElement, dropDownList: dropDownList })

  makeDownloadTagLink = (tagGroupId) => {
    request.tag.generateDownloadLink(`?tag_group_id=${tagGroupId}`).then(url => {
      window.open(url, '_blank')
    })
  };

  updateAddress = (address) => {
    if (address.id || address.temporaryId) {
      const newAddresses = this.state.addresses.map(addr => {
        if (addr.id === address.id || (addr.temporaryId && addr.temporaryId === address.temporaryId)) {
          return ({...address})
        }

        return addr
      })

      this.setState({addresses: newAddresses})
    }else {
      this.state.addresses.push({...address, temporaryId: Math.random().toString(36).slice(-6)})
      this.setState({addresses: this.state.addresses})
    }
    this.setState({showAddressDialog: false, editingAddress: null})
  }

  updateShipment = (shipment) => {
    const newShipment = {
      id: shipment.id || null,
      temporaryId: shipment.temporaryId || null,
      location: shipment.location,
      prices: [
        {currency: "ARS", price: shipment.priceArs},
        {currency: "USD", price: shipment.priceUsd},
      ],
    }

    if (newShipment.id || newShipment.temporaryId) {
      const newChannelShipments = this.state.channelShipments.map(ship => {
        if (ship.id === newShipment.id || (ship.temporaryId && ship.temporaryId === newShipment.temporaryId)) return ({...newShipment})

        return ship
      })
      this.setState({channelShipments: newChannelShipments})
    }else {
      this.state.channelShipments.push({...newShipment, temporaryId: Math.random().toString(36).slice(-6)})
      this.setState({channelShipments: this.state.channelShipments})
    }

    this.setState({showShipmentDialog: false, editingShipping: null})
  }

  onAddSubNavbar = () => request.client.home.elements
    .create(this.state.client.id, {type: 'navbar', dataSource: 'sub_navbar'})
    .then(res => this.setState({subNavbarElement: res.data}))


  render = () => {
    const {
      id,
      name,
      email,
      imageId,
      image,
      detail,
      visibility,
      client,
      type,
      googleAnalyticsId,
      googleTagManagerId,
      customUrl,
      socialLinks,
      channelContentCreatorTypes,
      catalogs,
      loginImage,
      loginImageId,
      footerImage,
      footerImageId,
      sidebarImage,
      sidebarImageId,
      favicon,
      faviconId,
      showAdvancedSearch,
      tags,
      showSupport,
      showAbout,
      contentCreatorTypes,
      navbarElements,
      binary,
      binaryId,
      hasBinary,
      policy,
      policyId,
      hasPolicy,
      bloomcloudChannel,
      loginType,
      language,
      languages,
      canDownloadTracks,
      openDropDownDialog,
      dropDownElement,
      tagGroups,
      availableTagGroups,
      openTagGroupDialog,
      supportNegativeSearch,
      trackingGroup,
      showLoginButton,
      showDate,
      availableChannelPlaylists,
      openTrackingGroupsDialog,
      preRollVideo,
      preRollVideoId,
      hasFpsCertificate,
      hasExtraInformation,
      extraRegistration,
      showTagSelection,
      showFilterOnSmartSearch,
      addresses,
      channelShipments,
      customShare,
      useTools,
      useCustomRedirects,
      channelRedirects,
      noIndex,
      translations,
      communityTranslations,
      customScripts,
      skipTimePreRoll,
      isDemo,
      purpose,
      navbarContentCreatorType
    } = this.state;

    const editingType = ((!this.state.parentElement && !this.state.editingElement) ||  (get(this.state, 'editingElement.type') == "navbar" || get(this.state, 'parentElement.type')== "navbar")) ? 'navbar' : 'footer';

    return (
      <div>
        <LiveStreams showDialog={this.state.showLiveStreamSettings} channelId={this.state.activeChannel} allowFakeLivestreams={this.state.allowFakeLivestreams} channel={this.state.activeChannelComplete} handleCloseDialog={() => this.setState({showLiveStreamSettings: false})}/>
        <Dialog
          open={this.state.openEditDialog}
          onBackdropClick={() => this.setState({openEditDialog: false, editingElement: false})}
          onEscapeKeyDown={() => this.setState({openEditDialog: false, editingElement: false})}
        >
          <HomeGridAndCarouselBase
            {...this.state.editingElement}
            clientId={client.id}
            permissions={client.permissions}
            contentCreatorTypes={channelContentCreatorTypes.length ?
              channelContentCreatorTypes.map(ccType => {
                return {
                  type: ccType.contentCreatorType.type,
                  value: ccType.contentCreatorType.slug,
                  label: ccType.contentCreatorType.label,
                  isTalent: ccType.contentCreatorType.isTalent,
                  allowVideo: ccType.contentCreatorType.allowVideo,
                  allowAudio: ccType.contentCreatorType.allowAudio,
                }
              }) : []}
            isCreating={true}
            channelId={id || false}
            redirectButton={this.state.redirectButton}
            channel={{...this.state.channel, navbar_tabs: this.state.mobileNavbarType == "tabs", creatingElements: true, redirectButton: this.state.redirectButton}}
            type={editingType}
            onClose={(element = false) => this.setState({openEditDialog: false, editingElement: false}, () => this.requestNavbarElements(element))}
            isModal={true}
            parent={dropDownElement}
            availableChannelPlaylists={availableChannelPlaylists}
            sections={this.state.sections}
          />
        </Dialog>
        <AddressDialog
          showDialog={this.state.showAddressDialog}
          handleCloseDialog={() => this.setState({showAddressDialog: false, editingAddress: null})}
          onSubmit={(address) => this.updateAddress(address)}
          address={this.state.editingAddress}
          isDashboard
        />
        <ShipmentDialog
          showDialog={this.state.showShipmentDialog}
          handleCloseDialog={() => this.setState({showShipmentDialog: false, editingShipping: null})}
          onSubmit={(shipment) => this.updateShipment(shipment)}
          shipment={this.state.editingShipping}
        />
        <FormViewContainer
          title={localization.get('channel.update_form', name)}
          submitRequest={this.requestChannelCreation}
          initializationRequest={() => this.requestChannel()}
          onBack={() => this.props.history.goBack()}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          disabled={this.state.disableForm}
          validation={this.onSubmitValidation}
        >
          <div style={{position: "absolute", right: 10, top: -40}}>
            <IconButton
              onClick={() => {
                let url = !!this.state.channel.domain ? addHttpsToUrl(this.state.channel.domain.url) : `/this.state.channel/${this.state.channel.id}-${slugify(this.state.channel.name)}`;

                if(this.state.channel.language !== "es") url+=`/${this.state.channel.language}`

                return window.open(url, "_blank")
              }}
              style={{position: 'relative'}}
            >
              <Tooltip title={localization.get('channel.visit')}>
                <Home/>
              </Tooltip>
            </IconButton>
            <IconButton
              title={localization.get('livestreams.schedule')}
              onClick={() => this.setState({showLiveStreamSettings: true, activeChannel: id, allowFakeLivestreams: client.allow_fake_livestreams, activeChannelComplete: this.state.channel})}
            >
              <LiveTv />
            </IconButton>
            <IconButton
              onClick={() => browserHistory.push(`/panel/channels/${id}/preferences`)}
              style={{position: 'relative'}}
            >
              <Tooltip title={localization.get(localization.get('channel.customization'))}>
                <Brush />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={() => browserHistory.push(`/panel/channels/${id}/editor`)}
              style={{position: 'relative'}}
            >
              <Tooltip title={localization.get('channel.editor')}>
                <Settings />
              </Tooltip>
            </IconButton>
          </div>
          <ValidatorForm onSubmit={this.onSubmitValidation} >
          <ChannelForm
            id={id}
            channel={this.state.channel}
            name={name}
            email={email}
            image={image}
            imageId={imageId}
            onImageChange={this.onImageChange}
            loginImage={loginImage}
            loginImageId={loginImageId}
            onLoginImageChange={this.onLoginImageChange}
            footerImage={footerImage}
            footerImageId={footerImageId}
            onFooterImageChange={this.onFooterImageChange}
            sidebarImage={sidebarImage}
            sidebarImageId={sidebarImageId}
            onSidebarImageChange={this.onSidebarImageChange}
            favicon={favicon}
            faviconId={faviconId}
            onFaviconChange={this.onFaviconChange}
            detail={detail}
            type={type}
            visibility={visibility}
            googleAnalyticsId={googleAnalyticsId}
            googleTagManagerId={googleTagManagerId}
            client={client}
            onClientChange={client => this.setState({client})}
            channelContentCreatorTypes={channelContentCreatorTypes}
            onChangeAllowedTypeContentCreatorTypes={allowedTypeContentCreatorTypes => this.setState({allowedTypeContentCreatorTypes}, () => this.requestTagGroups())}
            customUrl={customUrl}
            catalogs={catalogs}
            onCatalogsChange={catalogs => this.setState({catalogs})}
            socialLinks={socialLinks}
            onChange={this.onChange}
            onSocialLinksChange={socialLinks => this.setState({socialLinks})}
            onSetPreview={this.setPreview}
            disableForm={this.state.disableForm}
            updateDisableForm={value => this.setState({disableForm: value})}
            onContentChange={(e, field) => this.setState({[field]: e})}
            onChangeVisibility={value => this.setState({visibility: value.target.value})}
            onChangeType={value => value.target.value === "mixed" ?
              this.setState({type: "", nextType: value.target.value}, () =>   this.setState({type: this.state.nextType})) :
              (value.target.value === "music" ?
                this.setState({type: "", nextType: value.target.value}, () =>  this.setState({type: this.state.nextType, channelContentCreatorTypes: [], catalogs: []})) :
                this.setState({type: "", nextType: value.target.value}, () =>  this.setState({type: this.state.nextType, catalogs: []})))
            }
            showHelper={this.state.showHelper}
            updateHelper={() => this.setState({showHelper: !this.state.showHelper})}
            contactMail={this.state.contactMail}
            showAdvancedSearch={showAdvancedSearch}
            tags={tags}
            onTagsChange={tags => this.setState({ tags })}
            showSupport={showSupport}
            showAbout={showAbout}
            redirectButton={this.state.redirectButton}
            openNavbarDialog={this.state.openNavbarDialog}
            openCloseNavbarDialog={(redirectButton = null) => this.setState({openNavbarDialog: !this.state.openNavbarDialog, redirectButton})}
            contentCreatorTypes={contentCreatorTypes}
            navbarElements={navbarElements}
            addElement={element => this.addElement(element)}
            removeNavbarElement={element => this.removeNavbarElement(element)}
            hasBinary={hasBinary}
            binary={binary}
            binaryId={binaryId}
            onBinaryChange={this.onBinaryChange}
            hasPolicy={hasPolicy}
            policy={policy}
            policyId={policyId}
            onPolicyChange={this.onPolicyChange}
            setNavbarElements={(elements, footerElements = false) => {
              const state = footerElements ? "footerElements" : "navbarElements";

              return this.setState({
                [state]: elements,
                dropDownList: this.state.dropDownList.length > 0 ? elements : [],
              })
            }}
            bloomcloudChannel={bloomcloudChannel}
            loginType={loginType}
            language={language}
            languages={languages}
            canDownloadTracks={canDownloadTracks}
            openEditDialog={this.state.openEditDialog}
            openCloseEditDialog={(element, redirectButton= null) => this.setState({editingElement: element, openEditDialog: !this.state.openEditDialog, redirectButton: redirectButton})}
            openCloseEditDropDownDialog={this.openCloseEditDropDownDialog}
            openDropDownDialog={openDropDownDialog}
            dropDownElement={dropDownElement}
            tagGroups={tagGroups}
            availableTagGroups={availableTagGroups}
            openTagGroupDialog={openTagGroupDialog}
            openCloseTagGroupDialog={(tg) => {
              if(!!this.state.openTagGroupDialog) {
                return this.setState({openTagGroupDialog: false, selectedCCTTG: null})
              }else {
                return this.setState({openTagGroupDialog: true, selectedCCTTG: tg})
              }
            }}
            setTagGroup={(tagGroups, categoryId=null) => {
              if (categoryId) {
                let newTagGroups = this.state.tagGroups.filter(tagGroup => !tagGroup.types.some(t => t.contentCreatorType && t.contentCreatorType.id == categoryId));
                tagGroups.forEach(tg => newTagGroups.push(tg));
                this.setState({tagGroups: newTagGroups});
              } else {
                this.setState({tagGroups: tagGroups});
              }
            }}
            addTagGroup={tagGroup => {
              let availableTagGroups = this.state.tagGroups

              availableTagGroups.push(tagGroup)
              this.setState({tagGroups: availableTagGroups.map((element, index) => {
                  element.order = index
                  return element;
                })})
            }}
            removeFromTagGroup={tagGroup => {
              let availableTagGroups = this.state.tagGroups.filter(element => element.id !== tagGroup.id)

              this.setState({tagGroups: availableTagGroups.map((element, index) => {
                  element.order = index
                  return element;
                })
              })
            }}
            supportNegativeSearch={supportNegativeSearch}
            trackingGroup={trackingGroup}
            onTrackingGroupChange={tagGroup => this.setState({trackingGroup: tagGroup}) }
            downloadLink={this.makeDownloadTagLink}
            showLoginButton={showLoginButton}
            showDate={showDate}
            availableChannelPlaylists={availableChannelPlaylists}
            openTrackingGroupsDialog={openTrackingGroupsDialog}
            openCloseEditTrackingGroupsDialog={() => this.setState({openTrackingGroupsDialog: !this.state.openTrackingGroupsDialog})}
            preRollVideo={preRollVideo}
            preRollVideoId={preRollVideoId}
            onPreRollVideoChange={this.onPreRollVideoChange}
            hasFpsCertificate={hasFpsCertificate}
            hasExtraInformation={hasExtraInformation}
            extraRegistration={extraRegistration}
            showTagSelection={showTagSelection}
            dashboardLoginType={this.state.dashboardLoginType}
            loginClientId={this.state.loginClientId}
            loginClientSecret={this.state.loginClientSecret}
            dashboardLoginClientId={this.state.dashboardLoginClientId}
            dashboardLoginClientSecret={this.state.dashboardLoginClientSecret}
            userCanSeeAuth={this.state.userCanSeeAuth}
            showMercadoPagoForm={this.state.showMercadoPagoForm}
            mercadoPagoClientId={this.state.mercadoPagoClientId}
            mercadoPagoClientSecret={this.state.mercadoPagoClientSecret}
            showPaypalForm={this.state.showPaypalForm}
            paypalClientId={this.state.paypalClientId}
            paypalClientSecret={this.state.paypalClientSecret}
            showGoogleAdsenseForm={this.state.showGoogleAdsenseForm}
            googleAdsensePublisherId={this.state.googleAdsensePublisherId}
            showHomeAds={this.state.showHomeAds}
            showContentAds={this.state.showContentAds}
            adUnitHomeDesktop={this.state.adUnitHomeDesktop}
            adUnitHomeMobile={this.state.adUnitHomeMobile}
            adUnitContentDesktop={this.state.adUnitContentDesktop}
            adUnitContentMobile={this.state.adUnitContentMobile}
            sections={this.state.sections}
            searchType={this.state.searchType}
            hasEvents={this.state.hasEvents}
            showFilterOnSmartSearch={showFilterOnSmartSearch}
            selectedCCTTG={this.state.selectedCCTTG}
            onSelectedCCTTGChange={(e, newValue) => {
              this.setState({selectedCCTTG: newValue})}
            }
            notifyEndpoint={this.state.notifyEndpoint}
            notifySecret={this.state.notifySecret}
            hasStoredPreRollVideo={this.state.hasStoredPreRollVideo}
            onRemovePreRollVideo={() => {
              this.onPreRollVideoChange(null);
              this.setState({preRollVideoId: -1})
            }}
            radioEnabled={this.state.radioEnabled}
            radioLink={this.state.radioLink}
            shipmentMethod={this.state.shipmentMethod}
            onShipmentMethodChange={(e, newValue) => {
              this.setState({shipmentMethod: newValue})}
            }
            addresses={addresses}
            channelShipments={channelShipments}
            handleAddressDialog={(editingAddress = null) => {
              this.state.showAddressDialog ?
                this.setState({showAddressDialog: false, editingAddress: null}) :
                this.setState({showAddressDialog: true, editingAddress: editingAddress})
            }
            }
            handleShipmentDialog={(editingShipping = null) => {
              this.state.showShippingDialog ?
                this.setState({showShipmentDialog: false, editingShipping: null}) :
                this.setState({showShipmentDialog: true, editingShipping: editingShipping})
            }}
            commercialMail={this.state.commercialMail}
            hasCommunity={this.state.hasCommunity}
            communityCatalog={this.state.communityCatalog}
            onCommunityCatalogChange={catalog => this.setState({communityCatalog: catalog})}
            communityCCTypes={this.state.communityCCTypes}
            communityCanDeleteMaterials={this.state.communityCanDeleteMaterials}
            onCommunityCCTypesChange={ccTypes => this.setState({communityCCTypes: ccTypes})}
            removeCommunityCCType={ccType => {
              let availableCCTypes = this.state.communityCCTypes.filter(element => element.value !== ccType.value)
              this.setState({communityCCTypes: availableCCTypes.map((element, index) => {
                  element.order = index
                  return element;
                })
              })
            }}
            customShare={customShare}
            openEditCategoryDialog={this.state.openEditCategoryDialog}
            editingCategory={this.state.editingCategory}
            onEditCategory={(category =null) => this.setState({openEditCategoryDialog: !!category, editingCategory: category})}
            useTools={useTools}
            useCustomRedirects={useCustomRedirects}
            channelRedirects={channelRedirects}
            onChangeChannelRedirects={channelRedirects => this.setState({ channelRedirects })}
            noIndex={noIndex}
            translations={translations}
            onChangeTranslation={(value, target, language, state = "translations") => {
              const translations = this.state[state].map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });

              this.setState({ [state]: translations });
            }}
            communityTranslations={communityTranslations}
            onChangeCommunityTranslations={(value, target, language) => {
              const communityTranslations = this.state.communityTranslations.map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });

              this.setState({ communityTranslations });
            }}
            customScripts={customScripts}
            isDemo={isDemo}
            skipTimePreRoll={skipTimePreRoll}
            subNavbarElements={this.state.subNavbarElements}
            setSubNavbarElements={subNavbarElements => this.setState({ subNavbarElements })}
            subNavbarElement={this.state.subNavbarElement}
            addSubNavbar={this.onAddSubNavbar}
            openSubNavbarCreateDialog={() => this.setState({openNavbarDialog: true, parentElement: this.state.subNavbarElement})}
            parentElement={this.state.parentElement}
            footerElements={this.state.footerElements}
            hasFooterLogo={this.state.hasFooterLogo}
            onAddFooter={(parentElement = null) => 
              this.setState({openFooterDialog: true, parentElement: parentElement, footerElement: parentElement})
            }
            openFooterDialog={this.state.openFooterDialog}
            footerElementTranslations={this.state.footerElementTranslations}
            openCloseFooterDialog={() => this.setState({
              openFooterDialog: false,
              parentElement: null,
              footerElementTranslations: AvailableLanguages.map(language => ({
                language: language,
                title: "",
              }))}
            )}
            saveFooterElement={() => request.client.home.elements
              .create(this.state.client.id, {
                  translations: this.state.footerElementTranslations.map(tr => ({
                    ...tr,
                    title: tr.title,
                  })),
                  type: 'footer',
                  dataSource: 'drop_down',
                  channelId: id,
              })
              .then(res => {
                const newFooterElements = this.state.footerElements
                newFooterElements.push(res.data);

                return this.setState({
                  openFooterDialog: false, 
                  parentElement: null,
                  footerElements: newFooterElements,
                  footerElementTranslations: AvailableLanguages.map(language => ({
                    language: language,
                    title: "",
                  }))
                })
              })
              .catch(err => {
                GlobalSnackbar.showGenericError();
                console.log(err)
              })
            }
            dropDownList={this.state.dropDownList}
            footerType={this.state.footerType}
            footerLogoSide={this.state.footerLogoSide}
            footerTranslations={this.state.footerTranslations}
            footerSocialPosition={this.state.footerSocialPosition}
            footerMargin={this.state.footerMargin}
            communityRequiresApproval={this.state.communityRequiresApproval}
            communityPreferences={this.state.communityPreferences}
            communityEmail={this.state.communityEmail}
            communityValidations={validationOptions}
            hasExtraTermsOfUse={this.state.hasExtraTermsOfUse}
            useLicenses={this.state.useLicenses}
            usePlaylists={this.state.usePlaylists}
            tools={this.state.tools}
            hasExtendRegistration={this.state.hasExtendRegistration}
            extendRegistration={this.state.extendRegistration}
            useBrandButton={this.state.useBrandButton}
            editableFor={this.state.editableFor}
            purpose={purpose}
            navbarContentCreatorType={navbarContentCreatorType}
            mobileNavbarType={this.state.mobileNavbarType}
            navbarSearchType={this.state.navbarSearchType}
            useSidebarDesktop={this.state.useSidebarDesktop}
            useFloatingContactButton={this.state.useFloatingContactButton}
            floatingButtonImage={this.state.floatingButtonImage}
            onFloatingButtonImageChange={this.onFloatingButtonImageChange}
          />
          </ValidatorForm>
        </FormViewContainer>
      </div>
    );
  };
}
