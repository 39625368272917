import React from 'react'
import PropTypes from 'prop-types'
import {Checkbox, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {TextValidator} from "react-material-ui-form-validator";
import localization from 'config/localization';
import {userHasRestrictedAccess, userIsMusicSupervisor, userIsSupervisor, userIsTrial} from "../../../../api-client/core/authentication/utils";
import styled from "styled-components";
import AsyncSelect from "react-select/lib/Async";
import {request} from "../../../../api-client";
import TagSelection from "../../../../components/Letflow/TagSelection/TagSelection";

const FormView = ({
  title,
  subtitle,
  onChange,
  disabled = false,
  onUserLibraryChange,
  onVisibilityLibraryChange,
  userLibrary,
  visibility,
  allowDownload,
  onAllowDownloadChange,
  brands = [],
  onBrandsChange,
  type,
  updating,
  tagGroups = [],
  onSelectedTagsChanged,
  useFilters
}) => {
  return (
    <RespD>
      <TextValidator
        style={{ width: "100%" }}
        id="title"
        label={localization.get('form.title')}
        name="title"
        value={title}
        onChange={onChange}
        disabled={disabled}
        validators={['required']}
        errorMessages={[localization.get('validator.is_required')]}
      />
      <br />
      <br />
      <TextValidator
        style={{ width: "100%" }}
        id="subtitle"
        label={localization.get('form.subtitle')}
        name="subtitle"
        value={subtitle}
        onChange={onChange}
        disabled={disabled}
      />
      <br />
      <br />
      {userIsSupervisor() && 
      <>
        <span>{localization.get('brands')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('brands')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onBrandsChange}
            value={brands}
            isMulti
            defaultOptions
            loadOptions={inputValue =>
              request.brands.getAll({filterBy: [{column: "name", filter: inputValue}]})
                .then(res => res.map(brand => ({value: brand.id, label: brand.name})))
            }
          />
          <br />
        </>
        }
      
      {!updating && 
        <>
          <span>{localization.get('form.type')}</span>
          <RadioGroup
            name="type"
            value={type}
            onChange={event => onChange({target: {id: "type", value: event.target.value}})}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="client" control={<Radio color="default"/>} label={localization.get('playlist.classic')}/>
            <FormControlLabel value="tags" control={<Radio color="default"/>} label={localization.get('playlist.by_tags')}/>
          </RadioGroup>
        </>
      }
      {type === "tags" && 
        <>
          {<h4>{localization.get("tags")}</h4>}
          <TagSelection
            homeElementTagSelection 
            data={tagGroups}
            onSelectedTagsChanged={onSelectedTagsChanged}
            cardView
            showFilter
            colapsable
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{color: "black"}}
                checked={useFilters}
                onChange={() => onChange({target: {id: "useFilters", value: !useFilters}})}
              />
            }
            style={{width: "100%", height: "35px"}}
            label={localization.get('form.show_tag_filters')}
          />
        </>
      }

      {!(userIsTrial() || userIsMusicSupervisor()) && 
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={allowDownload}
              onChange={() => onAllowDownloadChange(!allowDownload)}
            />
          }
          style={{ width: "100%", height: "35px" }}
          label={localization.get('form.allow_download')}
        />
      }
      {!userHasRestrictedAccess() && 
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={visibility}
              onChange={() => onVisibilityLibraryChange(!visibility)}
            />
          }
          style={{ width: "100%", height: "35px" }}
          label={localization.get('form.public_playlist')}
        />
      }
    </RespD>
  )
}

FormView.propTypes = {
  onChange: PropTypes.func.isRequired,
  onImageChange: PropTypes.func.isRequired,
  client: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  disableClientInput: PropTypes.bool,
};

export default FormView

const RespD = styled.div`

`;