import {get} from "../utils";
import {getActiveChannel, isPublicChannel, userHasRestrictedAccess} from "../authentication/utils";
import localization from "../../../config/localization";


export default host => (channelId, id, withTimestamp = false) => {
  let url = `${host}${isPublicChannel() ? "/public": ""}${userHasRestrictedAccess() ? "/cms" : ""}/channels/${channelId}/elements/${id}?include=translations${getActiveChannel() ? `&cLang=${localization.getLanguage()}` : ""}`

  if (userHasRestrictedAccess()) {
    url += "&new_cache=" + Date.now();
  }

  return get(url, null, withTimestamp)
    .then(response => response.json())
    .then(json => json.data)
}